import React from 'react';
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce } from 'react-table'
import { Table, Card, InputGroup, FormControl } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import AuthorizationWrapper from '../../components/authorizationWrapper';
import UpdateTemplateModal from '../Templates/UpdateTemplateModal'
import DeleteTemplateModal from '../Templates/DeleteTemplateModal'
import styled from 'styled-components'

const Styles = styled.div`
  headers{
    textAlign  :  'left',
    lineHeight :  '2rem',
  },
  img{
    height :  'auto',
    width  :  '24px',
  },
  table{
    height :  '75vh',
    border :  'none',
  },
  tableContainer{
    fontSize        :  '.94rem',
    boxSizing       :  'border-box',
    borderRadius    :  '4px',
    backgroundColor :  'rgb(250, 250, 250)',
    boxShadow'      :  'rgba(0, 0, 0, 0.2) 0px 0px 10px -2px',
  },
  tablePadding{
    borderLeft   : '1px solid #ddd',
    borderRight  : '1px solid #ddd',
    borderBottom : '1px solid #ddd',
  },
  unset{
    whiteSpace: 'unset'
  },
  prewrap {
    whiteSpace: 'pre-wrap'
  },
};`

const updateTemplateAction = "edit_template"
const deleteTemplateAction = "delete_template"

function RenderTable({ columns, data }) {
  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      // defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
    },
    useFilters, // useFilters!
    useGlobalFilter // useGlobalFilter!
  )

  // Define a default UI for filtering
  function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
  }) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
      setGlobalFilter(value || undefined)
      document.getElementById("search-bar").focus()
    }, 800)

    return (
      <span>
        <InputGroup>
          <InputGroup.Prepend style={{margin: 'auto .5rem'}}>Filter{' '}</InputGroup.Prepend>
          <FormControl id="search-bar" value={value || ""}
            onChange={e => {
              setValue(e.target.value);
              onChange(e.target.value);
            }}
            placeholder={`${count} records...`}
            style={{
              fontSize: '1.1rem',
              borderTop: '0',
              borderBottom: '0'
            }} />
        </InputGroup>
      </span>
    )
  }

  return (
    <>
     <Card>
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </Card>
    
      <Table bordered {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => {
                const {render, getHeaderProps} = column
                return (
                  <th {...getHeaderProps()}>{render("Header")}</th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            let styling = row && row.values.flag == 'Special' ? {backgroundColor: 'hsl(120deg 100% 50% / 17%)'} : row.values.flag == 'Emergency' ? {backgroundColor: 'hsl(33deg 100% 50% / 17%)'} : {};            
            return (

              <tr {...row.getRowProps()} style={styling}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}

const TemplateTable = ({RowData, template_type}) => {
  const { t } = useTranslation(["translation"]);
  const headerStyle = {
    textAlign  :  'left',
    lineHeight :  '2rem',
  }
  const message_columns = React.useMemo(
    () => [
        {
          Header: <b data-cy='message-type-header'>Template Type</b>,
          headerStyle: headerStyle,
          id: 'template_type',
          width: 150,
          accessor: (data) => data.template_type,
          filter: 'includes'
        },
        {
          Header: <b data-cy='message-type-header'>Message Type</b>,
          headerStyle: headerStyle,
          id: 'message_type',
          width: 220,
          accessor: (data) => data.message_type,
          filter: 'includes'
        },
        {
          Header: <b data-cy='affected-service-header'>Affected Service</b>,
          headerStyle: headerStyle,
          id: 'affected_service',
          width: 220,
          accessor: (data) => data.affected_service,
          filter: 'includes'
        },
        {
          Header: <b data-cy='title-header'>Title</b>,
          headerStyle: headerStyle,
          id: 'title',
          width: 220,
          accessor: (data) => data.title,
          filter: 'includes'
        },  
        {
          Header: <b data-cy='body-header'>Body</b>,
          headerStyle: headerStyle,
          id: 'notification_body',
          width: 500,
          accessor: (data) => data.body,
          filter: 'includes'
        },

        {
          Header      : <b data-cy='edit-header'></b>,
          headerStyle : headerStyle,
          id          : 'edit',
          width       : 120,
          accessor    : (data) => <AuthorizationWrapper requiredAction={updateTemplateAction}>
                                    <UpdateTemplateModal pkey={data.pkey} notificationType={data.notification_type} template_type={template_type} title={data.title} body={data.body} messageType={data.message_type} affectedService={data.affected_service}/>
                                  </AuthorizationWrapper>
        },
        {
          Header: <b data-cy='delete-header'></b>,
          headerStyle: headerStyle,
          id: 'delete',
          width: 50,
          accessor: (data) =>  <AuthorizationWrapper requiredAction={deleteTemplateAction}>
                                  <DeleteTemplateModal templateId={data.pkey} title={data.title} body={data.body} messageType={data.message_type} affectedService={data.affected_service}/>
                                </AuthorizationWrapper>
        }
      ],
    [])
  const notification_columns = React.useMemo(
    () => [
        {
          Header: <b data-cy='message-type-header'>Template Type</b>,
          headerStyle: headerStyle,
          id: 'template_type',
          width: 150,
          accessor: (data) => data.template_type,
          filter: 'includes'
        },
        {
          Header: <b data-cy='message-type-header'>Notification Type</b>,
          headerStyle: headerStyle,
          id: 'message_type',
          width: 220,
          accessor: (data) => data.notification_type,
          filter: 'includes'
        },
        {
          Header: <b data-cy='title-header'>Title</b>,
          headerStyle: headerStyle,
          id: 'title',
          width: 220,
          accessor: (data) => data.title,
          filter: 'includes'
        },  
        {
          Header: <b data-cy='body-header'>Body</b>,
          headerStyle: headerStyle,
          id: 'notification_body',
          width: 500,
          accessor: (data) => data.body,
          filter: 'includes'
        },

        {
          Header      : <b data-cy='edit-header'></b>,
          headerStyle : headerStyle,
          id          : 'edit',
          width       : 120,
          accessor    : (data) => <AuthorizationWrapper requiredAction={updateTemplateAction}>
                                    <UpdateTemplateModal template_type={template_type} notificationType={data.notification_type} pkey={data.pkey} title={data.title} body={data.body} messageType={data.message_type} affectedService={data.affected_service}/>
                                  </AuthorizationWrapper>
        },
        {
          Header: <b data-cy='delete-header'></b>,
          headerStyle: headerStyle,
          id: 'delete',
          width: 50,
          accessor: (data) =>  <AuthorizationWrapper requiredAction={deleteTemplateAction}>
                                  <DeleteTemplateModal templateId={data.pkey} title={data.title} body={data.body} messageType={data.message_type} affectedService={data.affected_service}/>
                                </AuthorizationWrapper>
        }
      ],
    [])

 
  
  return (
    <Styles>
      <RenderTable columns={template_type == 'message' ? message_columns : notification_columns} data={RowData} />
    </Styles>
      
  )
}

export default TemplateTable;
