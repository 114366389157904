import React, { Component } from 'react';
import {
  Button, InputGroup, FormControl
} from 'react-bootstrap';

class CopyToClipboard extends Component {
  copyToClipboard = (e) => {
    this.refs.textarea.select();
    document.execCommand('copy');
    e.target.focus();
  };
  render() {
    const { ciList } = this.props;
    
    let copyCiList = [];
    ciList.forEach(element => {
      copyCiList.push(element.name);
    });

    return (
      <div>
        <div className="copy-to-clipboard">
          {
            ciList.length > 0 && Array.isArray(ciList) &&
            <div>
              <InputGroup>
                <FormControl as="textarea" ref="textarea" aria-label="With textarea" value={copyCiList.join(', ')} readOnly />
                <InputGroup.Append>
                  <Button variant="outline-secondary" onClick={this.copyToClipboard}>Copy</Button>
                </InputGroup.Append>
              </InputGroup>
            </div>
          }
        </div>
      </div>
    )
  }
}

export default CopyToClipboard;
