import { applyMiddleware, createStore, compose } from 'redux';
import createSagaMiddleware from 'redux-saga';
import * as config from '../config';
import rootSaga from './sagas';
import reducer from './reducers';

// middleware
const sagaMiddleware =  createSagaMiddleware();
const middleware     =  applyMiddleware(sagaMiddleware);


// configure redux devtools on prod vs dev environemnt
let store = null;
if (config.NODE_ENV === 'production') {
  store = createStore(reducer, middleware);
} else {
  const reduxDevtoolOptions = { trace: true, traceLimit: 25 };
  const composeEnhancers =  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__  // eslint-disable-next-line
  && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__(reduxDevtoolOptions)    // eslint-disable-next-line
  || compose;

  store = createStore(reducer, composeEnhancers(middleware));
  store.subscribe(() => console.log('in store.js: store changed: ', store.getState()));
}
store.runSaga =  sagaMiddleware.run(rootSaga);

export default store;
