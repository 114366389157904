import React, { useState } from 'react';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  actions as uiActions,
} from '../../../ducks/ui/ui.index';
import {
  actions as maintenanceWindowActions
} from '../../../ducks/maintenanceEvent/maintenanceEvent.index';
import { Modal, Button, Form, Tooltip, OverlayTrigger, Row, Col, Card } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import * as yup from 'yup';
import "react-datepicker/dist/react-datepicker.css";
/* ========= PACKAGE IMPORTS ========= */

const mapStateToProps = ({uiState, userState}) => ({
  pageLoading         :  uiState.pageLoading,
  email               :  userState.user.email, 
});
const mapDispatchToProps =  (dispatch) => {
  const combinedActionCreators = {
    setPageLoading                :  uiActions.setPageLoading,
  };

  return bindActionCreators(combinedActionCreators, dispatch);
};

const DeleteMaintenanceEventModal = ({submitDeleteMaintenanceEvent, maintenance_event_details}) => {
  const [modalIsOpen,setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  }
  const closeModal = () => {
    setIsOpen(false);
  }

  const submitDelete = (id) => {
    submitDeleteMaintenanceEvent(id);
    window.history.back()
  }

  return (
    <div>
      {/* Overlay Tooltip for button */}
      <OverlayTrigger
        key='left'
        placement='left'
        overlay=
        {
          <Tooltip id={`tooltip-left`}>Deletes this Maintenance Event</Tooltip>
        }
      >
        <Button className="data-cy-edit-message-modal-button" variant="danger" onClick={openModal} style={{width: '8rem'}}>Delete</Button>
      </OverlayTrigger>
      
      <Modal show={modalIsOpen} onHide={closeModal} className="data-cy-edit-message-modal">
        <Modal.Header closeButton>
          <Modal.Title>Delete Maintenance Event</Modal.Title>
        </Modal.Header>
        <Card>
          <Card.Body>
            <div>Confirm: Are you sure you want to delete this?</div>
          </Card.Body>
        </Card>
        <div style={{display: 'flex'}}>
          <Button variant="secondary" onClick={closeModal} className="data-cy-create-message-modal-close" style={{margin: '1vw', width: '8rem', marginRight: 'auto'}}>Cancel</Button>
          <Button variant="danger" onClick={() => submitDelete(maintenance_event_details.id)} style={{float: 'right', width: '8rem', margin: '1vw'}}>Delete</Button>
        </div>
      </Modal>
    </div>
  );
}

export default connect(mapStateToProps,mapDispatchToProps)(DeleteMaintenanceEventModal);