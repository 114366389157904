import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, isAuthenticated, isRestricted, ...rest }) => {

  var isDisplayIntended = true;

  if(isRestricted === true) {
    // If the route is restricted, determine whether this page is intended to be rendered for this user
    const userRestrictedPermissions = rest.userState.user.appRights.restricted_page_access;
    isDisplayIntended = (
      typeof userRestrictedPermissions !== 'undefined'
      && userRestrictedPermissions.indexOf(rest.title) > -1
    );
  }

  return (
    <Route
      {...rest}
      render={ props => {
        return isAuthenticated && isDisplayIntended
          ? <Component {...props} />
          : <Redirect to={{ pathname: "/login", from: rest.location.pathname }} />
      }}
    />
  )
};


export default PrivateRoute;
