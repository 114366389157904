import { string, number, arrayOf, shape, bool, object } from 'prop-types';


export const priorityTypes = {
  'p1': 'P1',
  'p2': 'P2',
};

export const editablePriortyTypes = ['P2'];

/* ===== STATE_DEFINITION ===== */
const initialState = {
  maintenance_events          : [],
  active_maintenance_events   : [],
  maintenance_event_details   : object,
  loading                     : true,
  error                       : false,
  all_data_centers            : [],
};

const maintenanceEventShape = {
  id: number,
  title: string,
  is_internal: bool,
  flag: string,
  jira_id: string,
  notification_id: number,
  notification_body: string,
  start_date: string,
  start_epoch: number,
  end_date: string,
  end_epoch: number,
  comm_src: string,
  created_by: string,
  created_date: string,
  created_epoch: number,
  is_test: bool,
  data_centers: arrayOf(string)
};

export const maintenanceWindowProptypes = {
  maintenance_events          : arrayOf(shape(maintenanceEventShape)),
  active_maintenance_events   : arrayOf(shape(maintenanceEventShape)),
  loading                     : bool,
  error                       : bool,
};


/* ===== TYPES ===== */
export const types = {
  REQUEST_MAINTENANCE_EVENTS       : '[maintenance_events] REQUEST_MAINTENANCE_EVENTS',
  SUBMIT_CREATE_MAINTENANCE_EVENT  : '[maintenance_events] SUBMIT_CREATE_MAINTENANCE_EVENT',
  SUBMIT_UPDATE_MAINTENANCE_EVENT  : '[maintenance_events] SUBMIT_UPDATE_MAINTENANCE_EVENT',
  SET_MAINTENANCE_EVENTS           : '[maintenance_events] SET_MAINTENANCE_EVENTS',
  SET_ACTIVE_MAINTENANCE_EVENTS    : '[maintenance_events] SET_ACTIVE_MAINTENANCE_EVENTS',
  SET_MAINTENANCE_EVENT_DETAILS    : '[maintenance_events] SET_MAINTENANCE_EVENT_DETAILS',
  SET_MAINTENANCE_EVENT_LOADING    : '[maintenance_events] SET_MAINTENANCE_EVENT_LOADING',
  SET_MAINTENANCE_EVENT_ERROR      : '[maintenance_events] SET_MAINTENANCE_EVENT_ERROR',
  SUBMIT_DELETE_MAINTENANCE_EVENT  : '[maintenance_events] SUBMIT_DELETE_MAINTENANCE_EVENT',
  REQUEST_ALL_DATA_CENTERS         : '[maintenance_events] REQUEST_ALL_DATA_CENTERS',
  SET_ALL_DATA_CENTERS             : '[maintenance_events] SET_ALL_DATA_CENTERS',
};


/* ===== ACTION_CREATORS ===== */
export const actions = {
  requestMaintenanceEvents     : ()                      => ({type: types.REQUEST_MAINTENANCE_EVENTS}),
  submitCreateMaintenanceEvent : (maintenance_events)    => ({type: types.SUBMIT_CREATE_MAINTENANCE_EVENT, payload: maintenance_events}),
  submitUpdateMaintenanceEvent : (maintenance_events)    => ({type: types.SUBMIT_UPDATE_MAINTENANCE_EVENT, payload: maintenance_events}),
  submitDeleteMaintenanceEvent : (id)                    => ({type: types.SUBMIT_DELETE_MAINTENANCE_EVENT, payload: id}),
  setMaintenanceEvents         : (maintenance_events)    => ({type: types.SET_MAINTENANCE_EVENTS, payload: maintenance_events}),
  setActiveMaintenanceEvents   : (maintenance_events)    => ({type: types.SET_ACTIVE_MAINTENANCE_EVENTS, payload: maintenance_events}),
  setMaintenanceEventDetails   : (maintenance_event)     => ({type: types.SET_MAINTENANCE_EVENT_DETAILS, payload: maintenance_event}),
  setMaintenanceEventLoading   : (bool)                  => ({type: types.SET_MAINTENANCE_EVENT_LOADING, payload: bool}),
  setMaintenanceEventError     : (bool)                  => ({type: types.SET_MAINTENANCE_EVENT_ERROR, payload: bool}),
  requestAllDataCenters         : ()                      => ({type: types.REQUEST_ALL_DATA_CENTERS}),
  setAllDataCenters             : (data_centers)          => ({type: types.SET_ALL_DATA_CENTERS, payload: data_centers}),
};


/* ===== SELECTORS ===== */
export const selectors = {
  selectMaintenanceEventDetails   :  (state, id) => {
    return state.maintenance_events.find( window => window.id == id);
  },
  selectCurrentMaintenanceEvent: (maintenance_events) => {
    const currentDate = new Date();
    return maintenance_events.map((item) => item.end_date > currentDate)
  },
  selectHistoricMaintenanceEvents: (state) => {
    return state.maintenance_events;
  },
};


/* ===== REDUCER ===== */
export default function reducer(state=initialState, action) {
  switch (action.type) {

    case types.SET_MAINTENANCE_EVENTS: {
      return { ...state, maintenance_events : action.payload.maintenance_windows };
    }

    case types.SET_ACTIVE_MAINTENANCE_EVENTS: {
      return { ...state, active_maintenance_events : action.payload };
    }

    case types.SET_MAINTENANCE_EVENT_DETAILS: {
      return { ...state, maintenance_event_details : action.payload };
    }

    case types.SET_MAINTENANCE_EVENT_LOADING: {
      return { ...state, loading : action.payload };
    }

    case types.SET_MAINTENANCE_EVENT_ERROR: {
      return { ...state, error : action.payload };
    }

    case types.SET_ALL_DATA_CENTERS: {
      return { ...state, all_data_centers : action.payload };
    }

    default: { return state; }
  }
}
