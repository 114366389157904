import _ from 'lodash'
import React from 'react'
import Moment from 'react-moment'
import ReactTable from "react-table-6"
import { Link } from 'react-router-dom'
import ReadMore from '../../Landing/OpiCard/ReadMore';

import "react-table-6/react-table.css"
import * as config from '../../../config';
import { sortSymbol, formatDurationToNearestMinute, sortOpi, dateTimeToUTCString, sortNumber, sortDate, getActiveStatus, joinArrayToString } from '../../../utils/formatFunctions'


const styles = {
  'headers': {
    'textAlign'    : 'left',
    'lineHeight'   : '2rem',
  },
  'headerCenter': {
    'textAlign'    : 'center',
    'lineHeight'   : '2rem',
  },
  'unset' : {
    'whiteSpace': 'unset'
  },
  'alert': {
    'display'        : 'flex',
    'justifyContent' : 'center',
    'alignItems'     : 'center',
  }
};



const CustomerIncidentsTable = ({ incidents, incidentLoading, selectedCompany, }) => {
  const { headerCenter, headers, unset } = styles;
  const jiraUrl = config.REACT_APP_JIRA_URL;

  const columns = [
    {
      'Header'      : <b className="info-header">i</b>,
      'headerStyle' : headerCenter,
      'id'          : 'severity',
      'width'       : 50,
      'accessor'    : 'severity',
      Cell          : data => <div className={`${data.value} symbol`}></div>,
      sortMethod    : (a, b) => sortSymbol(a, b),
    },
    {
      'Header'      : <b className="details-header">Details</b>,
      'headerStyle' : headers,
      'id'          : 'details',
      'width'       : 100,
      'accessor'    : 'jira_id',
      Cell          : data => <Link to={`/details/${data.value}`} className={`${data.value} view more`}>View Details</Link>,
      sortMethod    : (a, b) => sortSymbol(a, b),
    },
    {
      'Header'      : <b className="opi-header">OPI Number</b>,
      'headerStyle' : headers,
      'accessor'    : 'jira_id',
      'id'          : 'jira_id',
      'width'       : 120,
      Cell          : data => <a className={`${data.value}-jira-link`} href={`${jiraUrl}/browse/${data.value}`} target="_blank" rel="noopener noreferrer" >{data.value}</a>,
      sortMethod    : (a, b) => sortOpi(a, b),
    },
    {
      'Header'      : <b className="status-header">Status</b>,
      'headerStyle' : headers,
      'id'          : 'status',
      'accessor'    : (data) => ({
        'active' : getActiveStatus(data.start_date, data.end_date, data.status),
        'jiraId' : data.jira_id
      }),
      Cell          : data => {
        const  { value: { active, jiraId } } = data
        return <p className = {`${jiraId}-active`}>{ active ? "Active" : "Closed" }</p>
      },
      'width'    : 100,
      sortMethod : (a, b) => {
        return ( (a.active ? 1 : 0) - (b.active ? 1 : 0) )
        }
    },
    {
      'Header'      : <b className="services-header">Affected Service(s)</b>,
      'headerStyle' : headers,
      'id'          : 'affected_services',
      'accessor'    : (data) => joinArrayToString(data.affected_services),
      'width'       : 180,
      'style'       : unset,
    },
    {
      'Header'           : <b className="start-header">Start Date</b> ,
      'headerStyle'      : headers,
      'id'               : 'start_date',
      'width'            : 280,
      'accessor'         : (data) => dateTimeToUTCString(data.start_date),
      sortMethod         : (a, b) => sortDate(a, b),
    },
    {
      'Header'           : <b className="end-header">End Date</b>,
      'headerStyle'      : headers,
      'width'            : 280,
      'id'               : 'end_date',
      'accessor'         : (data) => dateTimeToUTCString(data.end_date),
      sortMethod         : (a, b) => sortDate(a, b),
    },
    {
      'Header'      : <b className="duration-header">Duration</b>,
      'headerStyle' : headers,
      'id'          : 'duration',
      Cell: data => {
        const {value: {startDate, endDate}} = data
        return (
          endDate
          // IF there is an end date, we can supply the end date to moment to get the overall duration
          ? <Moment duration={startDate} date={endDate} filter={formatDurationToNearestMinute}/>
          // ELSE we need to get the duration to this current moment
          : <Moment date={startDate} durationFromNow filter={formatDurationToNearestMinute} />
        )
      },
      // We cannot sort the duration column based on the info in the cell,
      // the info in the cell is a react-moment object. So we need to create
      // an accessor object with the info below to pass to the sort method.
      // Then we can sort by duration length which will be an int
      'accessor'    : (data) => ({
        'startEpoch' : data.start_epoch,
        'endEpoch'   : data.end_epoch,
        'startDate'  : data.start_date,
        'endDate'    : data.end_date
      }),
      'width'       : 200,
      'style'       : unset,
      sortMethod    : (a, b) => {
        return sortNumber(
          // sort based on duration length which is calculated by endEpoch - startEpoch (milliseconds)
          // if there is not an end epoch, we use the current time in milliseconds
          ((a.endEpoch || new Date().getTime()) - a.startEpoch),
          ((b.endEpoch || new Date().getTime()) - b.startEpoch)
        )}
    },
    {
      'Header'      : <b className="irca-header">Intermediate RCA</b>,
      'headerStyle' : headers,
      'id'          : 'irca',
      'accessor'    : 'irca',
      Cell          : data => <ReadMore details={data.value} characterLimit={50} />,
      'width'       : 400,
      'style'       : unset
    },
    {
      'Header'      : <b className="rca-header">RCA</b>,
      'headerStyle' : headers,
      'id'          : 'rca',
      'accessor'    : 'rca',
      Cell          : data => <ReadMore details={data.value} characterLimit={50} />,
      'width'       : 400,
      'style'       : unset
    },
  ];

  const NoDataComponent = () => {
    // This component is visible while loading data and when there is no data available...
    return incidentLoading
    // The stock behavior of react-table is to show 'no data' ghosted in the background while loading the table data
    // IF the table is loading, do not show the NoDataComponent component
    ? null
    // ELSE, show the NoDataComponent
    : (
        <div className={`alert alert-danger mt-1 mb-0`} role='alert' style={{ ...styles.alert }}>
          <p className='mb-0 text-center'><b>{`No incidents found for ${selectedCompany.name}`}</b></p>
        </div>
    );
  };

  return (
    <ReactTable
      data            = {incidents}
      columns         = {columns}
      // setting page length to the length of the incidents array will allow for continuous scrolling in the table
      pageSize        = { incidentLoading ? 20 : incidents.length }
      NoDataComponent = {NoDataComponent}
      showPagination  = {false}
      loading         = {incidentLoading}
      defaultSorted   = {[{ id: 'status', desc: true }, {id: 'jira_id', desc: true}]}
      className       = '-striped -highlight cy-customer-incidents-table'
      style           = {{
        maxWidth  : '100%',
        // This will force the table body to have the css property 'overflow: scroll'
        maxHeight : '100%'
      }}
    />
  );
}

export default CustomerIncidentsTable;
