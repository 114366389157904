import { takeEvery }        from 'redux-saga/effects';
import { types }            from './error.index';
import { postErrorReport }  from './error.api';

// worker sagas
export function* sendErrorReport(action) {
  const errorObj = action.payload;
  yield postErrorReport(errorObj);
}


// watcher saga
export default function errorSagas() {
  return [
    takeEvery(types.RAISE_ERROR, sendErrorReport),
  ];
}
