import { takeEvery, put, call, select } from "redux-saga/effects";

import {
  getV2MaintenanceEvents,
  getAllV2DataCenters,
  createV2MaintenanceEvent,
  updateV2MaintenanceEvent,
  deleteV2MaintenanceEvent,
  createV2Notification,
  updateV2Notification,
  deleteV2Notification,
  getV2ActiveNotifications,
  sendMail,
 } from './v2MaintenanceEvent.api';
import { types as v2MaintenanceEventTypes, actions as v2MaintenanceEventActions } from './v2MaintenanceEvent.index';
import { actions as uiActions }     from '../ui/ui.index';
import { actions as errorActions }  from '../error/error.index';
import { actions as toastActions }  from '../toast/toast.index';


export function* fetchMaintenanceEvents() {
  try {
    let response =  yield call(getV2MaintenanceEvents);
    let payload  =  response.data.payload;
    yield put(v2MaintenanceEventActions.V2SetMaintenanceEvents(payload));
    const current_rows = payload.maintenance_windows.filter((item) => {
      if (new Date(item.end_date) > new Date()){
        return item;
      }
    });
    yield put(v2MaintenanceEventActions.V2SetActiveMaintenanceEvents(current_rows));
    yield put(uiActions.setRefreshTime());
    yield put(v2MaintenanceEventActions.V2SetMaintenanceEventLoading(false));
  }
  catch(error) {
    // handle ui effects
    const message      =  `Unable to check for Maintenance Events: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));
    yield put(v2MaintenanceEventActions.V2SetMaintenanceEventLoading(true));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
  }
}

export function* fetchActiveNotifications() {
  try {
    let response =  yield call(getV2ActiveNotifications);
    let payload  =  response.data.payload;

    yield put(v2MaintenanceEventActions.V2SetActiveNotifications(payload));
    let activeNotificationIDs = [];
    payload.active_notifications.forEach((item) => {
      activeNotificationIDs.push(item.id)
    })
    yield put(v2MaintenanceEventActions.V2SetActiveNotificationIds(activeNotificationIDs));
    yield put(v2MaintenanceEventActions.V2SetMaintenanceEventLoading(false));
    yield put (uiActions.setPageLoading(false));
  }
  catch(error) {
    // handle ui effects
    const message      =  `Unable to check for Active Notifications: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
  }
}

export function* submitCreateMaintenanceEvent(action) {
  try{
    // extract relevant variables
    const payload =  action.payload;
    const response = yield call(createV2MaintenanceEvent, payload);
    yield put(v2MaintenanceEventActions.V2RequestMaintenanceEvents());
    yield put(v2MaintenanceEventActions.V2SetMaintenanceEventLoading(false));
    yield put(toastActions.createToast('Maintenance Event Created Successfully!', {type: 'success'}))
  }
  catch (error) {
    const message      =  `There was an issue creating the Maintenance Event. Please try again. Reason: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));
  }
}

export function* submitUpdateMaintenanceEvent(action) {
  try{
    // extract relevant variables
    const payload =  action.payload;
    const response = yield call(updateV2MaintenanceEvent, payload);
    yield put(v2MaintenanceEventActions.V2RequestMaintenanceEvents());
    yield put(v2MaintenanceEventActions.V2SetMaintenanceEventLoading(false));
    yield put(toastActions.createToast('Maintenance Event Updated Successfully!', {type: 'success'}))
  }
  catch (error) {
    const message      =  `There was an issue updating the Maintenance Event. Please try again. Reason: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));
  }
}

export function* submitDeleteMaintenanceEvent(action) {
  try{
    // extract relevant variables
    const payload =  action.payload;
    const response = yield call(deleteV2MaintenanceEvent, payload);
    yield put(v2MaintenanceEventActions.V2RequestMaintenanceEvents());
    yield put(v2MaintenanceEventActions.V2SetMaintenanceEventLoading(false));
    yield put(toastActions.createToast('Maintenance Event deleted Successfully!', {type: 'success'}))
  }
  catch (error) {
    const message      =  `There was an issue deleting the Maintenance Event. Please try again.`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));
  }
}

export function* submitCreateNotification(action) {
  try{
    // extract relevant variables
    const payload =  action.payload;
    const response = yield call(createV2Notification, payload);
    yield put(v2MaintenanceEventActions.V2RequestMaintenanceEvents());
    yield put(v2MaintenanceEventActions.V2SetMaintenanceEventLoading(false));
    yield put(toastActions.createToast('Notification Created Successfully!', {type: 'success'}))
  }
  catch (error) {
    const message      =  `There was an issue creating the Notification. Please try again. Reason: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));
  }
}

export function* submitUpdateNotification(action) {
  try{
    // extract relevant variables
    const payload =  action.payload;
    const response = yield call(updateV2Notification, payload);
    yield put(v2MaintenanceEventActions.V2RequestMaintenanceEvents());
    yield put(v2MaintenanceEventActions.V2SetMaintenanceEventLoading(false));
    yield put(toastActions.createToast('Notification Updated Successfully!', {type: 'success'}))
  }
  catch (error) {
    const message      =  `There was an issue updating the Notification. Please try again. Reason: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));
  }
}

export function* submitDeleteNotification(action) {
  try{
    // extract relevant variables
    const payload =  action.payload;
    const response = yield call(deleteV2Notification, payload);
    yield put(v2MaintenanceEventActions.V2RequestMaintenanceEvents());
    yield put(v2MaintenanceEventActions.V2SetMaintenanceEventLoading(false));
    yield put(toastActions.createToast('Notification deleted Successfully!', {type: 'success'}))
  }
  catch (error) {
    const message      =  `There was an issue deleting the Notification. Please try again.`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));
  }
}

export function* fetchAllDataCenters(action){
  try {
    let response =  yield call(getAllV2DataCenters);
    let payload  =  response.data.payload.data;

    yield put(v2MaintenanceEventActions.setAllDataCenters(payload));
    yield put(v2MaintenanceEventActions.V2SetMaintenanceEventLoading(false));
  }
  catch(error) {
    // handle ui effects
    const message      =  `Unable to check for All Data Centers: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));
    yield put(v2MaintenanceEventActions.V2SetMaintenanceEventLoading(true));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
  }
}

export function* submitSendMail(action){
  try{
    const payload = action.payload;
    const updateResponse = yield call(updateV2Notification, payload.values);
    const response = yield call(sendMail, payload);
    yield put(v2MaintenanceEventActions.V2SetMaintenanceEventLoading(false));
    yield put(toastActions.createToast('Notification Mail Sent Successfully!', {type: 'success'}))
    
  }
  catch (error) {
    const message      =  `There was an issue sending the notification mail. Please try again.`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));
  }
}

// watcher saga
export default function maintenanceWindowsSagas() {
  return [
    takeEvery(v2MaintenanceEventTypes.V2_REQUEST_MAINTENANCE_EVENTS, fetchMaintenanceEvents),
    takeEvery(v2MaintenanceEventTypes.V2_REQUEST_ACTIVE_NOTIFICATIONS, fetchActiveNotifications),
    takeEvery(v2MaintenanceEventTypes.V2_SUBMIT_CREATE_MAINTENANCE_EVENT, submitCreateMaintenanceEvent),
    takeEvery(v2MaintenanceEventTypes.V2_SUBMIT_UPDATE_MAINTENANCE_EVENT, submitUpdateMaintenanceEvent),
    takeEvery(v2MaintenanceEventTypes.V2_SUBMIT_DELETE_MAINTENANCE_EVENT, submitDeleteMaintenanceEvent),
    takeEvery(v2MaintenanceEventTypes.V2_SUBMIT_CREATE_NOTIFICATION, submitCreateNotification),
    takeEvery(v2MaintenanceEventTypes.V2_SUBMIT_UPDATE_NOTIFICATION, submitUpdateNotification),
    takeEvery(v2MaintenanceEventTypes.V2_SUBMIT_DELETE_NOTIFICATION, submitDeleteNotification),
    takeEvery(v2MaintenanceEventTypes.V2_REQUEST_ALL_DATA_CENTERS, fetchAllDataCenters),
    takeEvery(v2MaintenanceEventTypes.V2_SUBMIT_SEND_MAIL, submitSendMail),
  ];
}
