import React, { Component } from 'react';
import {
  Button, ListGroup
} from 'react-bootstrap';
import 'antd/dist/antd.css';
import { Spin } from 'antd';

let selectedCIStyle = {
  background: "#F5F5F5"
}

class CI extends Component {

  render() {
    const { ci, ci_category, emptyCIResponse, disabledAddToListButton, ciLoading } = this.props;
    if (ci.length == 0 && !ciLoading && ci_category.length == 1){
      return (
        <div>
          <h6 className="heading">CI's </h6>
          <p>No matching CI's found </p>
        </div>
      )
    }
    else if (ci.length == 0 && ciLoading){
      return (
        <div>
          <h6 className="heading">CI's </h6>
          <Spin active="true"/>
        </div>
      )
    }
    else if (ci.length == 0 && !ciLoading && (ci_category.length > 1 || ci_category.length == 0)){
      return (
        <div>
          <h6 className="heading">CI's </h6>
          <div>Select a Ci Category</div>
        </div>
      )
    }
    let autoCompleteSearchResultsCISubTypes = ci.map((ci, index) => (
      <ListGroup.Item key={index} className={`cypress-ci-${ci}`} style={disabledAddToListButton[index] === true ? selectedCIStyle : {}}>
        {ci_category} : {ci}
        <div className="add-button float-left mr-2">
          <Button variant="primary" className={`cypress-ci-add-button-${ci}`} size="sm" onClick={() => this.props.handleAddToList(ci, index)} disabled={disabledAddToListButton[index]}>+</Button>
        </div>
      </ListGroup.Item>
    ));
    return (
      <div>
        {ci.length > 0 && <div>
          <div className="pb-2">
            <h6 className="heading">CI's </h6>
            <Button variant="primary" size="sm" className="add-button" onClick={this.props.handleAddAllToList}>Add all</Button>
          </div>
          <div>
            <ListGroup variant="flush">
              {ci.length > 0 ? autoCompleteSearchResultsCISubTypes : <ListGroup.Item>No matching CI's found </ListGroup.Item>}
            </ListGroup>
          </div>
        </div>}
      </div>
    )
  }
}

export default CI;
