import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { bindActionCreators } from 'redux';
import { connect }            from 'react-redux';
import Exclamation_Mark from '../../../assets/Exclamation-Mark_R_blue.png';
import { Row, Col, Button } from 'react-bootstrap';
import CreateEditModal from '../TypeModals/CreateEditModal';
import DeleteModal from '../TypeModals/DeleteModal';
import { MdClose } from 'react-icons/md';
import { getAssignableRoles, getAssignableActions } from './../../../utils/adminHelperFunctions';
const styles = {
  content : {
    top         : '50%',
    left        : '50%',
    right       : 'auto',
    bottom      : 'auto',
    marginRight : '-50%',
    transform   : 'translate(-50%, -50%)',
    boxShadow   : 'rgba(0, 0, 0, 0.55) 4px 4px 10px -2px',
    width       : '60vmax',
    height      : '95vh',
  },
  graphic : {
    height    : '12vmax',
    minHeight : '240px'
  },
  overlay : {
    backgroundColor: 'rgba(0, 0, 0, 0.9)'
  },
  closeButton: {
    marginLeft: 'auto'
  },
  line: {
    color: 'grey', 
    size: '1', 
    backgroundColor: 'grey',
    width: '100%'
  }
};

const mapStateToProps = ({administrationState, userState }) => ({
  roles               :  administrationState.roles,
  users               :  administrationState.users,
  actions             :  administrationState.actions,
  user                :  userState.user,
});
const mapDispatchToProps =  (dispatch) => {
  const combinedActionCreators = {
  };

  return bindActionCreators(combinedActionCreators, dispatch);
};

const AdministrationModal = ({ openState, title, selectedData, type, action, close, users, roles, actions, user }) => {
  const [isModalOpen, setIsModalOpen] = useState(openState)
  Modal.setAppElement('body');
  
  //generic values to be set based on type
  let availableOptions = [];
  let selectedOptions = [];
  let compareData = [];
  let name_accessor = '';
  let accessor = '';
  let identifier = '';
  let colName = '';
  let baseData = [];
  if (type === 'users'){
    name_accessor = 'roles_display';
    accessor = 'display_name'
    identifier = 'email';
    baseData = roles;
    compareData = users;
    colName = 'Roles';
  }
  else if (type === 'roles'){
    name_accessor = 'actions_display';
    accessor = 'display_name';
    identifier = 'display_name';
    baseData = actions;
    compareData = roles;
    colName = 'Actions'
  }
  else if (type === 'actions'){
    identifier = 'display_name'
    compareData = actions;
  }
  if (baseData){
    for (const obj of baseData){
      availableOptions.push(obj);
    }
    //add filtering logic here based on user state (need to pass in user state as well)
    if (action === 'edit'){
      availableOptions = availableOptions.filter((value1) => !selectedData[name_accessor].some((value2) => value1[accessor] === value2));
      selectedOptions = selectedData[name_accessor];
    }
    if (type === 'users'){
      availableOptions = getAssignableRoles(user.authorization, availableOptions);
    }
    else if (type === 'roles'){
      availableOptions = getAssignableActions(user.authorization, availableOptions);
    }
  }
  
  

  useEffect(() => {
    setIsModalOpen(openState)
  },[openState])

  return (
    <Modal
      isOpen                    = {isModalOpen}
      onRequestClose            = {() => close()}
      style                     = {styles}
      contentLabel              = {`${title} Modal`}
      shouldCloseOnOverlayClick = {false}
    >
      <Row className={`${title}-modal`} >
        <h2 className={`${title}-modal-Header`}>{title}</h2>
        <MdClose className={`clickable pull-right CloseAdminModal-${type}`} style={styles.closeButton} onClick={() => close()}></MdClose>
      </Row>
      <hr style={styles.line}></hr>
      <Row>
        {(action === 'edit' || action === 'create') && <CreateEditModal selectedData={selectedData} selectedOptions={selectedOptions} 
          availableOptions={availableOptions} type={type} close={close} action={action} typeData={baseData} identifier={identifier} compareData={compareData} colName={colName}/>}
        {action === 'delete' && <DeleteModal selectedData={selectedData} identifier={identifier} 
          arrayValues={selectedOptions} type={type} close={close}/>}
      </Row>
    </Modal>
  );
}
export default connect(mapStateToProps,mapDispatchToProps)(AdministrationModal);
