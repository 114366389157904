import { put, call, select, takeEvery } from "redux-saga/effects";

import { getActiveLocales } from './ui.api';
import { actions as uiActions, types as uiActionTypes } from './ui.index';
import { actions as errorActions } from '../error/error.index';
import { actions as toastActions }  from '../toast/toast.index';

export function* fetchActiveLocales() {
  try {
    const response  = yield call(getActiveLocales);
    yield put(uiActions.setActiveLocales(response.data.data));
  }
  catch(error) {
    // // send error report
    const message      =  `Unable to pull active locales`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));
    const entireState = yield select();
    yield put(errorActions.raiseError(error, entireState));
  }
}

// watcher saga
export default function uiSagas() {
  return [
    takeEvery(uiActionTypes.REQUEST_ACTIVE_LOCALES, fetchActiveLocales)
  ];
}