import http from '../../services/httpService';
import * as config from "../../config";

const stage_mapping = {
  "development": "test",
  "production": "prod",
}

const get_stage_url = () => {
  if (config.REACT_APP_STAGE === 'development' || config.REACT_APP_STAGE === 'production'){
    return stage_mapping[config.REACT_APP_STAGE];
  }
  return 'local';
}

export const getEntitiesByType = async (managementType) => await http.get(`../auth/${get_stage_url()}/get_${managementType}`);
export const createEntity = async (entityType, payload) => await http.post(`../auth/${get_stage_url()}/create_${entityType}`, payload);
export const editEntity = async (entityType, payload) => await http.put(`../auth/${get_stage_url()}/edit_${entityType}`, payload);
export const deleteEntity = async (entityType, payload) => await http.delete(`../auth/${get_stage_url()}/delete_${entityType}`, {data: payload});
