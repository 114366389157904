import React, { Component } from 'react';
import {
  Button, ButtonToolbar
} from 'react-bootstrap';
import 'antd/dist/antd.css';
import { Spin } from 'antd';

let buttonsStyle = {
  background: "white",
  color: "#343a40"
};

let selectedButtonStyle = {
  background: "#343a40",
  color: "white"
};

class ServiceListButtons extends Component {

  render() {
    const { services, selectedServices } = this.props;
    let serviceButtons = services.map((service, index) => {
      return (
        <Button variant="outline-dark"  style={selectedServices.includes(service) === true ? selectedButtonStyle : buttonsStyle} key={index} id={service} onClick={this.props.handleServicesList} className={`m-2 cypress-dora-service-${service}`}>{service}</Button>
      )
    });
    return (
      <div style={{background:"#F5F5F5"}}>
        <h6 className="heading ml-2">Services</h6>    
        <ButtonToolbar>
          <Spin spinning = {this.props.servicesLoadin}/>
          {serviceButtons}
        </ButtonToolbar>
      </div>
    )
  }
}

export default ServiceListButtons;
