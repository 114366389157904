import React                  from 'react';
import {AsyncTypeahead }      from 'react-bootstrap-typeahead';
import MagnifyingGlass        from '../../../assets/281043_Search_R_orange.png';
import { Form, InputGroup }   from 'react-bootstrap';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { useTranslation } from 'react-i18next';

const EntitySearchBar = ({
  error,
  category,
  submitEntitySearch,
  isLoading,
  availableOptions,
  setStateField,
  disabled,
  selected
}) => {
  const styles = {
    img: { maxHeight : '1.5rem' },
  };
  const { t } = useTranslation(["translation"]);
  return (
    <Form.Group className="mb-0">
      <InputGroup>
        <InputGroup.Prepend>
          <InputGroup.Text>
            <img src={MagnifyingGlass} style={styles.img} />
          </InputGroup.Text>
        </InputGroup.Prepend>
        {
          error
          ? <Form.Control
              className   =  "change-history-error-input"
              type        =  "text"
              placeholder =  {t('containers.changeHistoryEntitySearchBar.errorInput')}
              readOnly />
          : <AsyncTypeahead
              id          = "async-change-history"
              className   = "cy-async-change-history"
              selected    = { selected }
              isLoading   = { isLoading ? true : false }
              disabled    = { disabled }
              // how to render selected options
              labelKey    = { (option) => option }
              multiple    =  { true }
              minLength   =  {1}
              // AsyncTypeahead has debouncing built in, but we also want a minimum character length before firing off any API request.
              onSearch    =  {(input) => {
                if (input.length === 0) return true;
                submitEntitySearch(`${category.toLowerCase()}`, input);
                setStateField(`loading_${category.toLowerCase()}s`, true);
                return;
              }}
              options     =  {availableOptions}
              placeholder =  {t('containers.changeHistoryEntitySearchBar.search' + category)}
              // This forces pagination
              maxResults  = {100}
              onChange    = { (list) => {
                setStateField(`selected_${category.toLowerCase()}s`, list)
              } }
              renderMenuItemChildren = {(option) => ( <> <span>{option}</span> </> )}
              clearButton
          />
        }
      </InputGroup>
    </Form.Group>
  );
}

export default EntitySearchBar;
