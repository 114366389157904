import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import Loading from "../../../components/loading";


class LoginPage extends Component {
  state = {
  };
  componentDidMount() {

  }

  render() {
     if (this.props.email) return <Redirect to="/" />;
    return (
     <Loading />
    );
  }
}

export default LoginPage;
