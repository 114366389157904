import React from 'react';
import Modal from 'react-modal';
import Exclamation_Mark from '../../../assets/Exclamation-Mark_R_blue.png';
import { Row, Col, Button } from 'react-bootstrap';
import { Translation } from 'react-i18next';

const styles = {
  content : {
    top         : '50%',
    left        : '50%',
    right       : 'auto',
    bottom      : 'auto',
    marginRight : '-50%',
    transform   : 'translate(-50%, -50%)',
    boxShadow   : 'rgba(0, 0, 0, 0.55) 4px 4px 10px -2px',
    width       : '50vmax'
  },
  graphic : {
    height    : '12vmax',
    minHeight : '240px'
  },
  overlay : {
    backgroundColor: 'rgba(0, 0, 0, 0.9)'
  }
};

class DisclaimerModal extends React.Component {
  state = {
    modalIsOpen: false,
    dontShowAgain: false,
  };

  openModal = () => {
    this.setState({modalIsOpen: true});
  }

  closeModal = () => {
    this.setState({modalIsOpen: false});
  }

  handleChange = (event) => {
    const input = event.target;
    const value = input.type === 'checkbox' ? input.checked : input.value;

    this.setState({ [input.name]: value });
  };

  handleFormSubmit = () => {
    const { dontShowAgain } = this.state;
    localStorage.setItem('dontShowDisclaimerModal', dontShowAgain);
    this.closeModal();
  };

  componentDidMount(){
    const show = localStorage.getItem('dontShowDisclaimerModal') === 'true' ? false : true
    this.setState({ modalIsOpen:  show });
  }

  render() {
    return (
        <Modal
          isOpen={this.state.modalIsOpen}
          onRequestClose={this.closeModal}
          style={styles}
          contentLabel="Disclaimer Modal"
          shouldCloseOnOverlayClick={false}
        >
          <Translation>{(t) => 
            <Row className="h-100 disclaimer-modal" >
              <Col lg={2} className="my-auto">
                <img src={Exclamation_Mark} style={styles.graphic} alt="an exclamation mark" className="d-none d-lg-block mx-auto"/>
              </Col>
              <Col>
                <h2 className="disclaimer-modal-Header">{t('containers.appDisclaimerModal.title')}</h2>
                <h5>{t('containers.appDisclaimerModal.body1')}</h5>
                <h6 class="mt-4">{t('containers.appDisclaimerModal.body2')}</h6>
                <h6>{t('containers.appDisclaimerModal.body3')}</h6>
                <form onSubmit={this.handleFormSubmit}>
                  <div className="form-check mt-4">
                    <input type="checkbox" className="form-check-input disclaimer-modal-check-box-input" name="dontShowAgain" onChange={this.handleChange}/>
                    <label className="form-check-label disclaimer-modal-check-box-label" for="dontShowAgain">{t('containers.appDisclaimerModal.label')}</label>
                  </div>
                  <Button type="submit" className="btn mt-3 disclaimer-modal-accept" >{t('containers.appDisclaimerModal.button')}</Button>
                </form>
              </Col>
            </Row>
          }</Translation>
        </Modal>
    );
  }
}
export default DisclaimerModal;
