import React, { forwardRef } from 'react'
import { Card, Form, DropdownButton, Button, Dropdown, OverlayTrigger, Popover, Row, Col } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import _ from 'lodash'

const AbsoluteTimePicker = ({
  dateRangeStart,
  dateRangeEnd,
  customStartInput,
  customEndInput,
  showTimeInput,
  shouldCloseOnSelect,
  disabled,
  yearDropdown,
  maxMonthsDateRange,
  style,
  // methods
  setStartDate,
  setEndDate,
}) => {

  const shiftDateByXMonths = (inputDate, numMonths) => {
    // react-datepicker does not support moment OR react-moment for minDate and maxDate; must use pure JS
    if(!inputDate) { return false; }
    inputDate = new Date(inputDate)
    return new Date(inputDate.getFullYear(), inputDate.getMonth() + numMonths, inputDate.getDate());
  };

  return (
    <Row>
      <Col className="pl-0 pr-0"  md={12}>
        <DatePicker
          selected            = {dateRangeStart}
          onChange            = {setStartDate}
          minDate             = {shiftDateByXMonths(dateRangeEnd, -1 * maxMonthsDateRange)}
          maxDate             = {dateRangeEnd}
          showTimeInput       = {showTimeInput}
          customInput         = {customStartInput}
          shouldCloseOnSelect = {shouldCloseOnSelect}
          disabled            = {disabled}
          style               = {style}
          yearDropdown        = {yearDropdown}
          required
          isClearable
        />
      </Col>
      <Col className="pl-0 pr-0"  md={12}>
        <DatePicker
          selected            = {dateRangeEnd}
          onChange            = {setEndDate}
          minDate             = {dateRangeStart}
          maxDate             = {shiftDateByXMonths(dateRangeStart, maxMonthsDateRange)}
          showTimeInput       = {showTimeInput}
          customInput         = {customEndInput}
          shouldCloseOnSelect = {shouldCloseOnSelect}
          disabled            = {disabled}
          style               = {style}
          yearDropdown        = {yearDropdown}
          required
          isClearable
        />
      </Col>
    </Row>
  )
};

export default AbsoluteTimePicker;
