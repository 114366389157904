import React, { useState } from 'react';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  actions as uiActions,
} from '../../../ducks/ui/ui.index';
import {
  actions as incidentActions
} from '../../../ducks/incident/incident.index';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";

import { useTranslation } from 'react-i18next';
/* ========= PACKAGE IMPORTS ========= */
const mapStateToProps = ({uiState, userState, incidentState}) => ({
  pageLoading         :  uiState.pageLoading,
});
const mapDispatchToProps =  (dispatch) => {
  const combinedActionCreators = {
    setPageLoading           :  uiActions.setPageLoading,
    submitDeleteMessage      :  incidentActions.submitDeleteMessage,
  };

  return bindActionCreators(combinedActionCreators, dispatch);
};

const DeleteMessageModal = ({messageId, messageType, body, japaneseBody, priorityType, submitDeleteMessage, jiraId, setPageLoading}) => {
  const { t } = useTranslation(["translation"]);
  let styles = {
    clickableLink  :  {
      cursor    :  'pointer',
      color     :  'blue',
    },
    button        : {
      width       :  '8rem',
    },
    buttonRight   : {
      width       : '8rem',
      marginLeft  : '5px'
    },
  }
  const [modalIsOpen,setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  }

  const closeModal = () => {
    setIsOpen(false);
  }

  const submitDelete = (messageId) => {
    setPageLoading(true);
    submitDeleteMessage(messageId, priorityType, jiraId);
    closeModal();
    return true;
  }

  return (
    <div>
     <div style={styles.clickableLink} onClick={openModal} className={"data-cy-delete-message-button-" + messageId}>
        {t('containers.incidentManagementUpdateMessage.buttonDelete')}
      </div>
      <Modal show={modalIsOpen} onHide={closeModal} className="data-cy-delete-message-modal">
        <Modal.Header>
          <Row><h3>Delete Message</h3></Row> 
        </Modal.Header>
        <Modal.Body style={{'borderBottom': '1px solid rgba(0, 0, 0, 0.2)'}}>
          <Row>Are you sure you want to delete this Message?</Row>
        </Modal.Body>
        <Modal.Body >
          <Row>
            <Col style={{'marginLeft': '-15px'}}>
              <div><b>Priority Type:</b></div>
              <div><b>Type:</b></div>
              <div><b>Body:</b></div>
            </Col>
            <Col>
              <div>{priorityType}</div>
              <div>{messageType}</div>
            </Col>
          </Row>
          <Row>
            <div>{body}</div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Button style={styles.button} variant="secondary" className="data-cy-delete-message-modal-close" onClick={closeModal}>
              {t('containers.incidentManagementUpdateMessage.close')}
            </Button>
            <Button style={styles.buttonRight} variant="danger" className="data-cy-delete-message-modal-submit" onClick={e => submitDelete(messageId)}>{t('containers.incidentManagementUpdateMessage.buttonDelete')}</Button>   
          </Row>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default connect(mapStateToProps,mapDispatchToProps)(DeleteMessageModal);