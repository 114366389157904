import { takeEvery, put, select, call } from 'redux-saga/effects';
import { types as administrationTypes, actions as administrationActions, } from './administration.index';
import { getEntitiesByType, createEntity, editEntity,  deleteEntity } from './administration.api';
import { actions as errorActions } from '../error/error.index';
import { actions as toastActions }  from '../toast/toast.index';
import {capitalizeFirstLetter} from '../../utils/adminHelperFunctions';

// worker sagas
export function* fetchEntitiesByType(action) {
  const { payload } = action
  try {
    const response = yield call(getEntitiesByType, payload);
    let {data}  =  response.data;
    yield put(administrationActions.setEntitiesByType(payload, data));
  } catch (error) {
    const entireState = yield select();
    yield put(errorActions.raiseError({ error, entireState }));
  }
}

export function* submitCreateEntity(action) {
  const { entityType, data } = action.payload
  try {
    yield call(createEntity, entityType, data);
    yield put(administrationActions.getEntitiesByType(entityType + 's'));
    yield put(toastActions.createToast(`${capitalizeFirstLetter(entityType)} Created Successfully!`, {type: 'success'}))
    
  } catch (error) {
    const message      =  `Unable To Create ${capitalizeFirstLetter(entityType)}: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));
    const entireState = yield select();
    yield put(errorActions.raiseError({ error, entireState }));
  }
}

export function* submitEditEntity(action) {
  const { entityType, data } = action.payload
  try {
    yield call(editEntity, entityType, data);
    yield put(administrationActions.getEntitiesByType(entityType + 's'));
    yield put(toastActions.createToast(`${capitalizeFirstLetter(entityType)} Edited Successfully!`, {type: 'success'}))
  } catch (error) {
    const message      =  `Unable To Edit ${capitalizeFirstLetter(entityType)}: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));
    const entireState = yield select();
    yield put(errorActions.raiseError({ error, entireState }));
  }
}

export function* submitDeleteEntity(action) {
  const { entityType, data } = action.payload
  try {
    yield call(deleteEntity, entityType, data);
    yield put(administrationActions.getEntitiesByType(entityType + 's'));
    yield put(toastActions.createToast(`${capitalizeFirstLetter(entityType)} Deleted Successfully!`, {type: 'success'}))
  } catch (error) {
    const message      =  `Unable To Delete ${capitalizeFirstLetter(entityType)}: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));
    const entireState = yield select();
    yield put(errorActions.raiseError({ error, entireState }));
  }
}

// watcher saga
export default function userSagas() {
  return [
    takeEvery(administrationTypes.GET_ENTITIES_BY_TYPE, fetchEntitiesByType),
    takeEvery(administrationTypes.CREATE_ENTITY, submitCreateEntity),
    takeEvery(administrationTypes.EDIT_ENTITY, submitEditEntity),
    takeEvery(administrationTypes.DELETE_ENTITY, submitDeleteEntity),
  ];
}
