import { bool, string, number, arrayOf, object, shape, func } from 'prop-types';

export const applicationName = 'Aviary';
export const priority_types = ["P1", "P2", ];
export const subscription_components = ["P1", "P2", "notifications"];
/* ===== STATE_DEFINITION ===== */
const initialState = {
  toggleSelectAll     :  {
    'P1': false,
    'P2': false,
    'notifications': false,
  },
  subscriptionOptions :  {
    'P1': {
      services     :  [],
      data_centers :  [],
      alert_types  :  []
    },
    'P2': {
      services     :  [],
      data_centers :  [],
      alert_types  :  []
    },
    'notifications': {
      data_centers : [],
      notification_types : []
    }
  },
  commHistory: null,
  allUserSubscriptions: [],
  userExists: null,
}

/* ===== PROP_TYPES ===== */
// data returned & transformed from fetch
const subscriptionOptionShape = {
  id            :  number,
  name          :  string,
  display_order :  number,
  selected      :  bool
};

export const subscriptionProptypes = {
  selectFormValues: shape(object.isRequired),
  requestUserSubscriptions :  func,
  submitSubscriptions      :  func,
  selectAllSubscriptions   :  func,
  subscriptionOptions      :  shape({
    string: {
      services     :  arrayOf(shape(subscriptionOptionShape)),
      data_centers :  arrayOf(shape(subscriptionOptionShape)),
      alert_types  :  arrayOf(shape(subscriptionOptionShape)),
    },
    string: {
      data_centers        :  arrayOf(shape(subscriptionOptionShape)),
      notification_types  :  arrayOf(shape(subscriptionOptionShape)),
    }
  }),
};

/* ===== TYPES ===== */
export const types = {
  REQUEST_USER_SUBSCRIPTIONS      :  '[subscriptions] REQUEST_USER',
  REQUEST_COMM_HISTORY            :  '[subscriptions] REQUEST_COMM_HISTORY',
  REQUEST_ALL_USER_SUBSCRIPTIONS  :  '[subscriptions] REQUEST_ALL_USER_SUBSCRIPTIONS',
  REQUEST_USER_EXISTS             :  '[subscriptions] REQUEST_USER_EXISTS',
  SET_USER_SUBSCRIPTIONS          :  '[subscriptions] SET_USER',
  SET_COMM_HISTORY                :  '[subscriptions] SET_COMM_HISTORY',
  SET_ALL_USER_SUBSCRIPTIONS      :  '[subscriptions] SET_ALL_USER_SUBSCRIPTIONS',
  SET_USER_EXISTS                 :  '[subscriptions] SET_USER_EXISTS',
  SUBMIT_USER_SUBSCRIPTIONS       :  '[subscriptions] SUBMIT',
  SELECT_ALL_SUBSCRIPTIONS        :  '[subscriptions] SELECT_ALL',
  CLEAR_UUID                      :  '[subscriptions] CLEAR_UUID',
};

/* ===== ACTION_CREATORS ===== */
export const actions = {
  requestUserSubscriptions    :  (email)                       => ({type: types.REQUEST_USER_SUBSCRIPTIONS, payload: email}),
  requestUserExists           :  (email)                       => ({type: types.REQUEST_USER_EXISTS, payload: email}),
  requestCommHistory          :  (email)                       => ({type: types.REQUEST_COMM_HISTORY, payload: email}),
  requestAllUserSubscriptions :  (email)                       => ({type: types.REQUEST_ALL_USER_SUBSCRIPTIONS, payload: email}),
  requestClearUuid            :  (email)                       => ({type: types.CLEAR_UUID, payload: email}),
  setUserSubscriptions        :  (subscriptions)               => ({type: types.SET_USER_SUBSCRIPTIONS, payload: subscriptions}),
  setCommHistory              :  (commHistory)                 => ({type: types.SET_COMM_HISTORY, payload: commHistory}),
  setAllSubscriptionOptions   :  (subscriptions)               => ({type: types.SET_ALL_USER_SUBSCRIPTIONS, payload: subscriptions}),
  setUserExists               :  (exists)                      => ({type: types.SET_USER_EXISTS, payload: exists}),
  submitSubscriptions         :  (values, setSubmitting, auth) => ({type: types.SUBMIT_USER_SUBSCRIPTIONS, payload: {values, setSubmitting, auth}}),
  selectAllSubscriptions      :  (bool, priority_type)         => ({type: types.SELECT_ALL_SUBSCRIPTIONS, payload: {bool, priority_type}}),
  requestAllUserSubscriptions :  (email)                       => ({type: types.REQUEST_ALL_USER_SUBSCRIPTIONS, payload: email}),
};

/* ===== SELECTORS ===== */
export const selectors = {
  selectFormOptions: (subscriptionOptions) => {
    const formOptions = {};
    for (const subs_component of subscription_components){
      formOptions[subs_component] = {};
      if (subscriptionOptions && subscriptionOptions[subs_component]){
        Object
        .keys(subscriptionOptions[subs_component])
        .forEach( (option) => {
          subscriptionOptions[subs_component][option].forEach( (subOption) => {
            formOptions[subs_component][subOption.name] = subOption.selected;
          });
        });
      }
    }
    return formOptions;
  },
};

/* ===== REDUCER ===== */
export default function reducer(state=initialState, action) {
  switch (action.type) {
    case types.SET_USER_SUBSCRIPTIONS: {
      return { ...state, subscriptionOptions: action.payload };
    }
    case types.SELECT_ALL_SUBSCRIPTIONS: {
      let options = state.toggleSelectAll;
      options[action.payload.priority_type] = action.payload.bool;
      return { ...state, toggleSelectAll: options }
    }
    case types.SET_COMM_HISTORY: {
      return { ...state, commHistory: action.payload};
    }
    case types.SET_ALL_USER_SUBSCRIPTIONS: {
      return { ...state, allUserSubscriptions: action.payload};
    }
    case types.SET_USER_EXISTS: {
      return { ...state, userExists: action.payload};
    }
    default : { return state; }
  }
};
