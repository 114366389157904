import { string, number, arrayOf, shape, func, array } from 'prop-types';


/* ===== STATE_DEFINITION ===== */
export const initialState = {
  customerCatalog          : [],
  customerCatalogLoading   : false,
  incidentLoading          : false,
  error                    : false,
  selectedCompany          : null,
  selectedCompanyIncidents : []
};


/* ===== PROP_TYPES ===== */
const customerShape = {
  entity_code : string,
  id          : number,
  name        : string,
  data_center : string,
};

export const customerProptypes = {
  customerCatalog             : arrayOf(shape(customerShape)),
  selectedCompanyIncidents    : array,
  searchCustomerCatalog        : func,
  requestCompanyIncidentsById : func,
  setCustomerCatalog          : func,
  setCompanyIncidents         : func,
  setSelectedCompany          : func,
  setCustomerLoading          : func,
  setIncidentLoading          : func,
  setCustomerError            : func,
};


/* ===== TYPES ===== */
export const types = {
  SEARCH_CUSTOMER_CATALOG   : '[CUSTOMER] SEARCH_CUSTOMER_CATALOG',
  REQUEST_COMPANY_INCIDENTS : '[CUSTOMER] REQUEST_COMPANY_INCIDENTS',
  SET_CUSTOMER_CATALOG      : '[CUSTOMER] SET_CUSTOMER_CATALOG',
  SET_SELECTED_COMPANY      : '[CUSTOMER] SET_SELECTED_COMPANY',
  SET_COMPANY_INCIDENTS     : '[CUSTOMER] SET_COMPANY_INCIDENTS',
  SET_CUSTOMER_LOADING      : '[CUSTOMER] SET_CUSTOMER_LOADING',
  SET_INCIDENT_LOADING      : '[CUSTOMER] SET_INCIDENT_LOADING',
  SET_CUSTOMER_ERROR        : '[CUSTOMER] SET_ERROR',
};


/* ===== ACTION_CREATORS ===== */
export const actions = {
  searchCustomerCatalog       : (searchString) => ({ type: types.SEARCH_CUSTOMER_CATALOG,   payload: searchString }),
  requestCompanyIncidentsById : (id)           => ({ type: types.REQUEST_COMPANY_INCIDENTS, payload: id }),
  setCustomerCatalog          : (customers)    => ({ type: types.SET_CUSTOMER_CATALOG,      payload: customers }),
  setCompanyIncidents         : (incidents)    => ({ type: types.SET_COMPANY_INCIDENTS,     payload: incidents }),
  setSelectedCompany          : (company)      => ({ type: types.SET_SELECTED_COMPANY,      payload: company }),
  setCustomerLoading          : (bool)         => ({ type: types.SET_CUSTOMER_LOADING,      payload: bool }),
  setIncidentLoading          : (bool)         => ({ type: types.SET_INCIDENT_LOADING,      payload: bool }),
  setCustomerError            : (bool)         => ({ type: types.SET_CUSTOMER_ERROR,        payload: bool }),
};


/* ===== SELECTORS ===== */
export const selectors = {};


/* ===== REDUCER ===== */
export default function reducer(state=initialState, action) {
  switch (action.type) {
    case types.SET_CUSTOMER_CATALOG: {
      return { ...state, customerCatalog : action.payload };
    }
    case types.SET_COMPANY_INCIDENTS: {
      return { ...state, selectedCompanyIncidents : action.payload };
    }
    case types.SET_SELECTED_COMPANY: {
      return { ...state, selectedCompany : action.payload };
    }
    case types.SET_CUSTOMER_LOADING: {
      return { ...state, customerCatalogLoading : action.payload };
    }
    case types.SET_INCIDENT_LOADING: {
      return { ...state, incidentLoading : action.payload };
    }
    case types.SET_CUSTOMER_ERROR: {
      return { ...state, error : action.payload };
    }
    default: { return state; }
  }
}
