import React from 'react'
import { Button } from 'react-bootstrap';

const styles = {
  disabledButton: {
    backgroundColor : "#BBBBBB",
    color           : "#DDDDDD",
    border          : "1px solid #DDDDDD"
  },
  enabledButton: {
    cursor : "pointer"
  },
}

const ChangeHistoryTileFooter = ({
  we_have_api_results,
  search_change_response,
  page_number,
  // methods
  submitSearchChanges
}) => {
  const setPreviousPage = async() => {
    let pageNum = page_number <= 1 ? page_number : page_number -1
    submitSearchChanges({page_number: pageNum})
  }

  const setNextPage = async() => {
    await submitSearchChanges({page_number: page_number + 1})
  }

  return (
    <div className="d-flex justify-content-center mb-2 mt-2 ml-1 mr-1 paginationButtons">
      {
        <Button
          disabled = {page_number === 1}
          style    = {(page_number === 1) ? styles.disabledButton : styles.enabledButton}
          onClick  = {setPreviousPage}
          variant  = "outline-info mr-2 visible"
        >
          &#8592; {/* Left arrow */}
        </Button>
      }
      {
        <Button
          disabled = {!we_have_api_results}
          variant  = "info mr-2 currentPageButton"
          style    = {styles.currentPageButton}
        >
          Page {page_number}
        </Button>
      }
      {
        <Button
          disabled = {!search_change_response.has_next_page}
          style    = {search_change_response.has_next_page ? styles.enabledButton : styles.disabledButton}
          variant  = "outline-info visible"
          onClick  = {setNextPage}
        >
          &#8594;  {/* Right arrow */}
        </Button>
      }
    </div>
  );
};

export default ChangeHistoryTileFooter;
