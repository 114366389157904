import React, { useMemo, useState } from 'react';
import AdministrationModal from '../AdministrationModal/AdministrationModal';
import AdministrationTypeTable from '../AdministrationTypeTable/AdministrationTypeTable';
import { MdDeleteForever, MdModeEdit } from 'react-icons/md';
import {canModifyUser} from '../../../utils/adminHelperFunctions';
import { useTranslation } from 'react-i18next';
const styles = {
  container : { width: '90%', margin: '0 auto', marginBottom: '1rem' },
  card      : { 'boxShadow' : 'rgba(0, 0, 0, 0.2) 0px 0px 10px -2px', marginTop: "1rem", minHeight: '400px' },
  headers   : { 'textAlign' : 'left', 'lineHeight' : '2rem', },
  prewrap   : { 'whiteSpace': 'pre-wrap' },
  delete    : { color: 'red', cursor: 'pointer' },
  edit      : { color: '#ffc107', cursor: 'pointer' }
}

const type = 'actions';

export const Actions = ({actions, userState}) => {
  const { user: { authorization }}    = userState;
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalType, setModalType]     = useState("");
  const [modalAction, setModalAction] = useState("");
  const [selectedData, setSelectedData] = useState({})
  const { t } = useTranslation(["translation"]);

  const modalData = useMemo(() => {
    switch (modalType) {
      case "Delete Action":
        setModalAction("delete");
        return selectedData
      case "Edit Action":
        setModalAction("edit");
        return selectedData
      default:
        return "DEFAULT"
    }
  },[modalType, modalIsOpen])

  const closeModal = () => {
    setModalIsOpen(false);
  }

  const columns = useMemo(() => {
    const allowEdit   = authorization.is_admin || authorization?.allowed_actions?.includes("edit_action")
    const allowDelete = authorization.is_admin || authorization?.allowed_actions?.includes("delete_action")
    const returnArr = [
      {
        'Header'      : <b className="display-name-header">{t('containers.administrationActions.colId')}</b>,
        'headerStyle' : styles.headers,
        'id'          : 'user_id',
        'accessor'    : (data) => data.action_id,
        'style'       : styles.prewrap,
        'width'       : 40,
        sortMethod  : (a, b) => a.action_id - b.action_id,
      },
      {
        'Header'      : <b className="display-name-header">{t('containers.administrationActions.colName')}</b>,
        'headerStyle' : styles.headers,
        'id'          : 'email',
        'accessor'    : (data) => data.display_name,
        'style'       : styles.prewrap,
        'width'       : 200,
        sortMethod  : (a, b) => a.role_id - b.role_id,
      },
    
      {
        'Header'      : <b className="description-header">{t('containers.administrationActions.colDescription')}</b>,
        'headerStyle' : styles.headers,
        'id'          : 'description',
        'accessor'    : (data) => data.description,
        'style'       : styles.prewrap,
        'width'       : 300,
      },
      {
        'Header'      : <b className="opi-header">{t('containers.administrationActions.colManage')}</b>,
        'headerStyle' : styles.headers,
        'id'          : 'edit_action_id',
        'accessor'    : (data) => (
            <>
              { allowEdit && <MdModeEdit className="Edit-action" size={28} style={styles.edit} onClick={()=>{
                setModalType("Edit Action")
                setModalIsOpen(true)
                setSelectedData(data);
                }}>{t('containers.administration.buttonEdit')}</MdModeEdit> }
              { allowDelete && <MdDeleteForever className="Delete-action" size={28} style={styles.delete} onClick={()=>{
                setModalType("Delete Action")
                setModalIsOpen(true)
                setSelectedData(data);
              }}>{t('containers.administration.buttonDelete')}</MdDeleteForever> }
            </>
        ),
        'style' : styles.prewrap,
        'width' : 150,
      }
    ]
    return returnArr
  },[actions, userState])

  return (
    <div>
      <AdministrationModal 
        openState={modalIsOpen} 
        title={modalType} 
        type={type}
        action={modalAction} 
        selectedData={modalData} 
        close={closeModal}/>
      <AdministrationTypeTable props={{ data: actions, columns, type: type }} />
    </div>
  );

}
