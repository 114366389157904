import { all } from 'redux-saga/effects';

import incidentSagas            from '../ducks/incident/incident.sagas';
import statusSagas              from '../ducks/status/status.sagas';
import toastsSagas              from '../ducks/toast/toast.sagas';
import uiSagas                  from '../ducks/ui/ui.sagas';
import userSagas                from '../ducks/user/user.sagas';
import errorSagas               from '../ducks/error/error.sagas';
import subscriptionSagas        from '../ducks/subscription/subscription.sagas';
import severitySagas            from '../ducks/severity/severity.sagas';
import customerDetailsSagas     from '../ducks/customerDetails/customerDetails.sagas';
import changeHistorySagas       from '../ducks/changeHistory/changeHistory.sagas';
import administrationSagas      from '../ducks/administration/administration.sagas'
import maintenanceEventSagas    from '../ducks/maintenanceEvent/maintenanceEvent.sagas';
import v2MaintenanceEventSagas  from '../ducks/v2MaintenanceEvent/v2MaintenanceEvent.sagas';
import templateSagas            from '../ducks/template/template.sagas';
import doraSagas                from '../ducks/dora/dora.sagas';

export default function* rootSaga() {
  yield all([
    ...errorSagas(),
    ...incidentSagas(),
    ...maintenanceEventSagas(),
    ...v2MaintenanceEventSagas(),
    ...severitySagas(),
    ...statusSagas(),
    ...subscriptionSagas(),
    ...toastsSagas(),
    ...uiSagas(),
    ...userSagas(),
    ...customerDetailsSagas(),
    ...changeHistorySagas(),
    ...administrationSagas(),
    ...templateSagas(),
    ...doraSagas(),
  ]);
}
