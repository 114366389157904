import { object, func } from 'prop-types';


/* ===== STATE_DEFINITION ===== */
const initialState = {
  currentStatuses : {
    'Expense'                                   : {us2: 'NA', eu: 'NA', eu2: 'NA', china: 'NA', usg: 'NA', concurgovprod: 'NA', apj1: 'NA'},
    'Travel'                                    : {us2: 'NA', eu: 'NA', eu2: 'NA', china: 'NA', usg: 'NA', concurgovprod: 'NA', apj1: 'NA'},
    'Invoice'                                   : {us2: 'NA', eu: 'NA', eu2: 'NA', china: 'NA', usg: 'NA', concurgovprod: 'NA', apj1: 'NA'},
    'Request'                                   : {us2: 'NA', eu: 'NA', eu2: 'NA', china: 'NA', usg: 'NA', concurgovprod: 'NA', apj1: 'NA'},
    'Imaging'                                   : {us2: 'NA', eu: 'NA', eu2: 'NA', china: 'NA', usg: 'NA', concurgovprod: 'NA', apj1: 'NA'},
    'Analysis/Intelligence'                     : {us2: 'NA', eu: 'NA', eu2: 'NA', china: 'NA', usg: 'NA', concurgovprod: 'NA', apj1: 'NA'},
    'Mobile'                                    : {us2: 'NA', eu: 'NA', eu2: 'NA', china: 'NA', usg: 'NA', concurgovprod: 'NA', apj1: 'NA'},
    'Compleat (TMC Services)'                   : {us2: 'NA', eu: 'NA', eu2: 'NA', china: 'NA', usg: 'NA', concurgovprod: 'NA', apj1: 'NA'},
    'Japan - e-Bunsho for Expense'              : {us2: 'NA', eu: 'NA', eu2: 'NA', china: 'NA', usg: 'NA', concurgovprod: 'NA', apj1: 'NA'},
    'Japan - e-Bunsho for Invoice'              : {us2: 'NA', eu: 'NA', eu2: 'NA', china: 'NA', usg: 'NA', concurgovprod: 'NA', apj1: 'NA'},
    'Japan - Japan Public Transportation (JPT)' : {us2: 'NA', eu: 'NA', eu2: 'NA', china: 'NA', usg: 'NA', concurgovprod: 'NA', apj1: 'NA'},
    'TAVS'                                      : {us2: 'NA', eu: 'NA', eu2: 'NA', china: 'NA', usg: 'NA', concurgovprod: 'NA', apj1: 'NA'},
    'EAI'                                       : {us2: 'NA', eu: 'NA', eu2: 'NA', china: 'NA', usg: 'NA', concurgovprod: 'NA', apj1: 'NA'},
    'Billing'                                   : {us2: 'NA', eu: 'NA', eu2: 'NA', china: 'NA', usg: 'NA', concurgovprod: 'NA', apj1: 'NA'},
  },
  loading : true,
  error   : false
};


/* ===== PROP_TYPES ===== */
export const statusProptypes = {
  currentStatuses: object.isRequired,
  requestStatus :  func,
  setStatus     :  func,
};


/* ===== TYPES ===== */
export const types = {
  REQUEST_STATUS     : '[status] REQUEST',
  SET_STATUS         : '[status] SET',
  SET_STATUS_LOADING : '[status] SET_LOADING',
  SET_STATUS_ERROR   : '[status] SET_ERROR',
};


/* ===== ACTION_CREATORS ===== */
export const actions = {
  requestStatus    : ()       => ({type: types.REQUEST_STATUS}),
  setStatus        : (status) => ({type: types.SET_STATUS, payload: status}),
  setStatusLoading : (bool)   => ({type: types.SET_STATUS_LOADING, payload: bool}),
  setStatusError   : (bool)   => ({type: types.SET_STATUS_ERROR, payload: bool}),
};


/* ===== SELECTORS ===== */
// export const selectors = {};


/* ===== REDUCER ===== */
export default function reducer(state=initialState, action) {
  switch (action.type) {

    case types.SET_STATUS: {
      return {
        ...state,
        currentStatuses : action.payload,
      };
    }

    case types.SET_STATUS_LOADING: {
      return { ...state, loading : action.payload };
    }

    case types.SET_STATUS_ERROR: {
      return { ...state, error : action.payload };
    }
    
    default: { return state; }
  }
}
