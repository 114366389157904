import React from 'react';
import * as config from '../../../config';
import "../../Landing/NoOpiCard/NoOpiCard.css";
import "../ChangeHistory.css";
import { Row, Col } from 'react-bootstrap';
import ReadMore from '../../Landing/OpiCard/ReadMore';
import Moment from 'react-moment';

// Returns the amount of time incident has been completed, started or not started
const IncidentTime = ({actual_start, actual_end, submitted_on, planned_start}) => {
    let time = new Date()
    let currentTime = new Date()
    let text = "Completed "

    if((submitted_on != null) || (submitted_on != undefined))
    {
        time = submitted_on
    }

    // Checks if incident has not ended
    else if((actual_end === null) && (actual_start!= null))
    {
        time = actual_start
        text="Started "
    }

    // Checks if the incident has not started
    else if(actual_start === null && actual_end === null && planned_start != null)
    {
        text = "Planned Start "
        time = planned_start
    }

    // Checks if the incident has not started
    else if(actual_start === null && actual_end === null && planned_start === null)
    {
        text = "Not Started"
    }

    // Checks if incident has ended
    else
    {
        time = actual_end
        text = "Completed"
    }

  return (<>{text} {text !== "Not Started" && <Moment fromNow unix>{time}</Moment>}</>)
}

// Returns the status of the incident based on the actual_start and actual_end
const StatusButton = ({actual_start, actual_end, submitted_on}) => {
    //defaults
    let buttonType = "btn-success"
    let buttonText = "Completed"

    if((submitted_on != null) || (submitted_on != undefined))
    {
      let buttonType = "btn-success"
      let buttonText = "Completed"
    }

    // Checks if incident has not been started
    else if(!actual_start && !actual_end)
    {
        buttonText = "Not Started"
        buttonType = "btn-danger"
    }

    //Checks if incident is in progress
    else if ((actual_start!== null) && (actual_end === null))
    {
        buttonText = "In Progress"
        buttonType = "btn-warning"
    }
    //returns button
    return (
        <button type="button" className={`btn btn-block rounded change-history-card-status-button ${buttonType}`} disabled>{buttonText}</button>
    )
}

const Reference = ({change_type, catalog_type, ticket_id, jira_url}) => {
    let reference = '';
    if(change_type === "manual") {
        reference = !ticket_id ? '(No Reference Provided)' : <a className="jira-link" href={`${jira_url}/browse/${ticket_id}`} target="_blank" rel="noopener noreferrer">{ticket_id}</a>;
    } else if (change_type === "auto") {
        // "RFC" is the type of ticket this links to for Auto changes (business logic)
        let ticketValue = !ticket_id ? 'No RFC Provided' : <a className="jira-link" href={`${jira_url}/browse/${ticket_id}`} target="_blank" rel="noopener noreferrer">{ticket_id}</a>;
        reference = <>{catalog_type} ({ticketValue})</>
    }

    return reference;
}

const ChangeHistoryCard = ({id, actual_end, actual_start, change_type, cis, data_centers, description, catalog_type, planned_end, planned_start, services, status, submitted_by, summary, ticket_id, submitted_on, github_owner, github_repo, github_sha, github_tag}) => {
    const jiraUrl = config.REACT_APP_JIRA_URL
    return (
        <div className="card card-shadow ml-1 mr-1 mb-4 mt-1 pl-2 pr-2">
            <Row className="header">
                <Col className= "change-table-header mt-3 changeHistoryCardSummary" sm={9} md={8} lg={10}>
                    <ReadMore details={summary} characterLimit={65} />
                </Col>
                <Col className="change-table-header mt-3 mb-3" sm={3} md={4} lg={2}>
                    <StatusButton
                      actual_start = {actual_start}
                      actual_end = {actual_end}
                      submitted_on = {submitted_on}
                    />
                </Col>
                {
                    /* Per 9/15/20 discussion, only "auto" tickets will display descriptions at launch. We will potentially revisit this. */
                    change_type == "auto" &&
                    <Col className= "change-table-header mb-3 changeHistoryCardDescription" sm={9} md={8} lg={10}>
                        <ReadMore details={description} characterLimit={120} />
                    </Col>
                }
            </Row>
            <div className="pb-0 pt-0 pl-0 pr-0">
                <Row>
                    <Col sm={6} md={6} lg={6} className="mt-2 mb-2">
                        <div className="referenceLink change-history-card-item">
                            <b>Reference: </b>
                            <Reference
                                change_type = {change_type}
                                catalog_type = {catalog_type}
                                ticket_id = {ticket_id}
                                jira_url = {jiraUrl}
                            />
                        </div>
                        <div className="changeHistoryCardOwnedBy change-history-card-item">
                            <b>Owned By: </b>
                            {
                                submitted_by !== null
                                ?
                                <>{submitted_by}</>
                                :
                                <>(None Provided)</>
                            }
                        </div>
                        <div className="startedTime change-history-card-item">
                            <b>Status: </b>
                            <IncidentTime
                                actual_start = {actual_start}
                                actual_end = {actual_end}
                                submitted_on = {submitted_on}
                                planned_start = {planned_start}
                            />
                        </div>
                        {change_type == "auto" && <div className="ellipsis changeHistoryCardGithubOwner change-history-card-item"><b>Github Owner:</b> {github_owner} </div>}
                        {change_type == "auto" &&  <div className="ellipsis changeHistoryCardGithubRepo change-history-card-item"><b>Github Repo:</b> {github_repo} </div>}
                    </Col>
                    <Col sm={6} md={6} lg={6} className="mt-2 mb-2">
                        <div className="ellipsis changeHistoryCardDataCenters change-history-card-item"><b>Data Centers: </b> {data_centers.join(', ')} </div>
                        <div className="ellipsis changeHistoryCardServices change-history-card-item"><b>Services:</b> {services.join(', ')} </div>
                        <div className="ellipsis changeHistoryCardCI change-history-card-item"><b>CIs:</b> {cis.join(', ')} </div>
                        {change_type == "auto" && <div className="ellipsis changeHistoryCardGithubSHA change-history-card-item"><b>Github SHA:</b> {github_sha} </div>}
                        {change_type == "auto" && <div className="ellipsis changeHistoryCardGithubTag change-history-card-item"><b>Github Tag:</b> {github_tag} </div>}
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default ChangeHistoryCard;
