// {lg: layout1, md: layout2, ...}
export const layouts = {
  lg: [
    { i: 'progress-bar',            x: 0,  y: 0, w: 12, h: 0.2, minW: 2, maxW: 12, minH: 0.2, maxH: 0.3 },
    { i: 'customer-communications', x: 0,  y: 1, w: 4,  h: 4.6,   minW: 2, maxW: 12, maxH: 12 },
    // { i: 'slack',            x: 10, y: 1, w: 2,  h: 4,   minW: 2, maxW: 12, maxH: 12 },
    { i: 'incident-details',        x: 4,  y: 1, w: 3,  h: 4.6,   minW: 2, maxW: 12, maxH: 12 },
    { i: 'incident-team',           x: 7,  y: 1, w: 5,  h: 1,   minW: 2, maxW: 12, maxH: 12 },
    { i: 'customer-impact',         x: 7,  y: 4, w: 5,  h: 3.6,   minW: 2, maxW: 12, maxH: 12 },
    // { i: 'customer-cases',          x: 9,  y: 1, w: 5,  h: 3.6,   minW: 2, maxW: 12, maxH: 12 },

  ],
  md: [
    { i: 'progress-bar',            x: 0, y: 0, w: 4, h: 0.2,  minW: 2, maxW: 12, minH: 0.2, maxH: 0.3 },
    { i: 'customer-communications', x: 0, y: 4, w: 2,  h: 2,   minW: 2, maxW: 12, maxH: 12 },
    // { i: 'slack',            x: 0, y: 6, w: 2,  h: 2,   minW: 2, maxW: 12, maxH: 12 },
    { i: 'incident-details',        x: 0, y: 2, w: 2,  h: 2,   minW: 2, maxW: 12, maxH: 12 },
    { i: 'incident-team',           x: 2, y: 2, w: 2,  h: 2,   minW: 2, maxW: 12, maxH: 12 },
    { i: 'customer-impact',         x: 2, y: 4, w: 2,  h: 2,   minW: 2, maxW: 12, maxH: 12 },
    // { i: 'customer-cases',          x: 0, y: 6, w: 2,  h: 2,   minW: 2, maxW: 12, maxH: 12 },
  ],
  sm: [
    { i: 'progress-bar',            x: 0, y: 0, w: 2,  h: 0.2, minW: 2, maxW: 12, minH: 0.2, maxH: 0.3 },
    { i: 'customer-communications', x: 0, y: 1, w: 2,  h: 2,   minW: 2, maxW: 12, maxH: 12 },
    // { i: 'slack',            x: 0, y: 0, w: 2,  h: 2,   minW: 2, maxW: 12, maxH: 12 },
    { i: 'incident-details',        x: 0, y: 0, w: 2,  h: 2,   minW: 2, maxW: 12, maxH: 12 },
    { i: 'incident-team',           x: 0, y: 0, w: 2,  h: 1,   minW: 2, maxW: 12, maxH: 12 },
    { i: 'customer-impact',         x: 0, y: 1, w: 2,  h: 2,   minW: 2, maxW: 12, maxH: 12 },
    // { i: 'customer-cases',          x: 0, y: 1, w: 2,  h: 2,   minW: 2, maxW: 12, maxH: 12 },
  ],
};
