import React from 'react'
import { Col, Row, Card, Button, OverlayTrigger, Popover } from 'react-bootstrap';
import infoImage from '../../../assets/282532_Information_R_blue.png';
import { useTranslation } from 'react-i18next';

const styles = {
  // TO-DO: remove styling for info icon when RenderToolTip logic is placed inside ChangeHistoryCard component
  infoImg: {
    maxHeight     : '1.9rem',
    verticalAlign : 'top'
  },
  inline: {
    display : 'inline',
  }
};

const ChangeHistoryTileHeader = ({
  record_display_style,
  search_change_is_loading,
  sort_direction,
  // methods
  setStateField,
  submitSearchChanges
}) => {

  const { t } = useTranslation(["translation"]);
  // TO-DO place RenderToolTip inside of ChangeHistoryCard component
  const RenderTooltip = ({content, searchChangeLoading}) => {
    return (
      <OverlayTrigger
        placement = "right"
        delay     = {{ show: 50, hide: 50 }}
        overlay   = {
          <Popover id="info-tooltip" className="p-2 card-shadow">
            {content}
          </Popover>
        }
        disabled = {searchChangeLoading}
        >
        <img src = {infoImage} style = {{ ...styles.infoImg, ...styles.inline }} />
      </OverlayTrigger>
    )
  };

  const setDisplayStyle = async(value) => {
    setStateField('record_display_style', value);
  }

  const setSortDirection = async(value) => {
    submitSearchChanges({sort_direction: value})
  }

  return (
    <>
      <Card.Header>
        <Row>
          <Col sm={2} md={2} lg={2}>
            <div className="change-history-header-icons">
              <svg onClick={() => setDisplayStyle('card')} width="32" height="32" stroke={record_display_style == 'card' ? 'var(--info)' : 'currentColor'} viewbox="0 0 32 32" className="bi bi-caret-up-fill change-history-header-icon change-history-header-icons-card " xmlns="http://www.w3.org/2000/svg">
                <title>{t('containers.changeHistoryTileHeader.card')}</title>
                <rect id="svg_4" height="30.51654" width="30.18556" y="0.73863" x="0.87412" stroke-width="1.0" fill-opacity="0" fill="none"/>
                <rect id="svg_5" height="7.414" width="25.28702" y="2.79072" x="3.38958" fill-opacity="null" stroke-opacity="null" stroke-width="1.0" fill="#fff"/>
                <rect id="svg_6" height="7.414" width="25.28702" y="13.64692" x="40.26098" fill-opacity="null" stroke-opacity="null" stroke-width="1.0" fill="#fff"/>
                <rect id="svg_8" height="7.414" width="25.28702" y="12.323" x="3.32339" fill-opacity="null" stroke-opacity="null" stroke-width="1.0" fill="#fff"/>
                <rect id="svg_9" height="7.414" width="25.28702" y="21.92148" x="3.32339" fill-opacity="null" stroke-opacity="null" stroke-width="1.0" fill="#fff"/>
              </svg>
              <svg onClick={() => setDisplayStyle('table')} width="32px" height="32px" viewBox="0 0 200.147 200.147" fill={record_display_style == 'table' ? 'var(--info)' : 'currentColor'} className="bi bi-caret-up-fill change-history-header-icon change-history-header-icons-table " xmlns="http://www.w3.org/2000/svg">
                <title>{t('containers.changeHistoryTileHeader.table')}</title>
                <path d="M0,0v200.147h200.147V0H0z M193.322,38.38H46.79V6.821h146.529V38.38H193.322z M39.976,83.928 v33.237H6.821V83.928C6.821,83.928,39.976,83.928,39.976,83.928z M6.821,77.113V45.201h33.151v31.913H6.821z M39.976,123.986 v30.048H6.821v-30.048H39.976z M46.79,123.986h146.529v30.048H46.79V123.986z M46.79,117.161V83.924h146.529v33.237H46.79z M46.79,77.113V45.201h146.529v31.913H46.79z M39.976,6.821V38.38H6.821V6.821H39.976z M6.821,160.855h33.151v32.464H6.821 V160.855z M46.79,193.322v-32.464h146.529v32.464H46.79z"/>
              </svg>
            </div>
          </Col>
          <Col className="text-center" sm={8} md={8} lg={8}>
            <h4 style={{display:"inline"}}>{t('containers.changeHistoryTileHeader.title')}</h4>
            <RenderTooltip searchChangeLoading={search_change_is_loading} content={t('containers.changeHistoryTileHeader.toolTip')}/>
          </Col>
          <Col className="change-history-header-icons pl-0 text-right justify-content-center" sm={2} md={2} lg={2}>
            <div>
              <div className="change-history-header-icons">
                <svg onClick={submitSearchChanges} width="1.9em" height="1.9em" viewbox="0 0 24 24" className="bi bi-caret-up-fill change-history-header-icon change-history-header-icons-refresh " fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <title>{t('containers.changeHistoryTileHeader.refresh')}</title>
                  <path d="M13.5 2c-5.621 0-10.211 4.443-10.475 10h-3.025l5 6.625 5-6.625h-2.975c.257-3.351 3.06-6 6.475-6 3.584 0 6.5 2.916 6.5 6.5s-2.916 6.5-6.5 6.5c-1.863 0-3.542-.793-4.728-2.053l-2.427 3.216c1.877 1.754 4.389 2.837 7.155 2.837 5.79 0 10.5-4.71 10.5-10.5s-4.71-10.5-10.5-10.5z"/>
                </svg>
                <svg onClick={() => setSortDirection('ASC')} width="1.4em" height="1.4em" viewBox="0 0 16 16" className="bi bi-caret-up-fill change-history-header-icon change-history-header-icons-top-arrow " fill={sort_direction === "ASC" ? "var(--info)" : "currentColor"} xmlns="http://www.w3.org/2000/svg">
                  <title>{t('containers.changeHistoryTileHeader.ascending')}</title>
                  <path d="M7.247 4.86l-4.796 5.481c-.566.647-.106 1.659.753 1.659h9.592a1 1 0 0 0 .753-1.659l-4.796-5.48a1 1 0 0 0-1.506 0z"/>
                </svg>
                <svg onClick={() => setSortDirection('DESC')} width="1.4em" height="1.4em" viewBox="0 0 16 16" className="bi bi-caret-down-fill change-history-header-icon change-history-header-icons-bottom-arrow" fill={sort_direction === "DESC" ? "var(--info)" : "currentColor"} xmlns="http://www.w3.org/2000/svg">
                  <title>{t('containers.changeHistoryTileHeader.descending')}</title>
                  <path d="M7.247 11.14L2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"/>
                </svg>
              </div>
            </div>
          </Col>
        </Row>
      </Card.Header>
    </>
  );
};

export default ChangeHistoryTileHeader;
