import React       from 'react';
import ReactTable  from 'react-table-6';
import * as config from '../../../config';
import 'react-table-6/react-table.css';


const styles = {
  'headers': {
    'textAlign'    : 'left',
    'lineHeight'   : '2rem',
  },
  'headerCenter': {
    'textAlign'    : 'center',
    'lineHeight'   : '2rem',
  },
  'img': {
    'height'       : 'auto',
    'width'        : '24px',
  },
  'table': {
    /* TODO this is an approximation to keep the horizontal scroll visible and fixed to the bottom. If it can be properly "flex"-ified, we should do so. */
    // 'height'       : 'calc(96vh - 206px)',
    // 'border'       : 'none'
  },
  'tableContainer': {
    // 'paddingTop'   : '1%'
    'fontSize': '.94rem'
  },
  'tablePadding': {
    'borderLeft'   : '1px solid #ddd',
    'borderRight'  : '1px solid #ddd',
    'borderBottom' : '1px solid #ddd',
  },
  'unset' : {
    'whiteSpace': 'unset'
  },
  'prewrap' : {
    'whiteSpace': 'pre-wrap'
  },
};



const AdministrationTypeTable = ({props}) => {
  // table data
  const jiraUrl = config.REACT_APP_JIRA_URL;
  const { data: rows, columns, type } = props;
  const { headerCenter, headers, tableContainer, container, table, unset, prewrap } = styles;
  const defaultSort  = [];
  if (!rows || rows.length < 1) return <></>
  return (
      <div style={tableContainer} className={"change-history-table-view AdminTable-" + type}>
        <ReactTable
          data            = {rows}
          defaultSorted   = {defaultSort}
          columns         = {columns}
          style           = {table}
          className       = "-striped -highlight"
          showPagination  = {false}
          sortable        = {false}
          defaultPageSize = {rows.length}
          />
    </div>
  );
};

export default AdministrationTypeTable;
