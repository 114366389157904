import React, { useState } from 'react';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  actions as uiActions,
} from '../../../ducks/ui/ui.index';
import {
  actions as incidentActions
} from '../../../ducks/incident/incident.index';
import { Modal, Button, Form, Tooltip, OverlayTrigger, Row } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import {Formik, Field} from 'formik';
import * as yup from 'yup';
import infoImage from '../../../assets/282532_Information_R_blue.png';
import { useTranslation } from 'react-i18next';
/* ========= PACKAGE IMPORTS ========= */
const curDate = new Date();
const schema = yup.object().shape({
  message_type: yup.string().required(),
  body: yup.string().required(),
  language: yup.string().notRequired(),
  //not displayed in form
  message_id: yup.string().notRequired(),
  jira_id: yup.string().notRequired(),
  priority_type: yup.string().notRequired(),
  is_test: yup.string().notRequired(),
  relocalize: yup.string().notRequired().oneOf(['Y', 'N']),
  japaneseBody: yup.string().notRequired(),
  broadcast: yup.string().notRequired(),
});

const mapStateToProps = ({uiState, userState, incidentState, templateState}) => ({
  pageLoading         :  uiState.pageLoading,
  activeLocales       :  uiState.activeLocales,
  email               :  userState.user.email,
  authorization       : userState.user.authorization,
  p2AlertTypes        :  incidentState.p2AlertTypes,
  p1AlertTypes        :  incidentState.p1AlertTypes, 
  selectedIncident    : incidentState.selectedIncident,
  templates           :  templateState.message_templates,
});
const mapDispatchToProps =  (dispatch) => {
  const combinedActionCreators = {
    setPageLoading           :  uiActions.setPageLoading,
    submitUpdateMessage      :  incidentActions.submitUpdateMessage,
    requestIncidentByJiraId  :  incidentActions.requestIncidentByJiraId,
  };

  return bindActionCreators(combinedActionCreators, dispatch);
};

const EDIT_IS_INTERNAL = 'edit_is_internal_message'

const UpdateMessageModal = ({messageId, messageType, body, priorityType, submitUpdateMessage, requestIncidentByJiraId, selectedIncident, p2AlertTypes, p1AlertTypes, jiraId, setPageLoading, activeLocales, templates, authorization, language='en'}) => {
  const { t } = useTranslation(["translation"]);

  
  //copy these variables as the createmessagemodal can modify them and it would affect here.
  let p2Types = [];
  let p1Types = [];
  p2AlertTypes.forEach(type => {
    p2Types.push(type);
  })
  p1AlertTypes.forEach(type => {
    p1Types.push(type);
  })
  // if internal, remove all external message types
  if ((Object.keys(selectedIncident).length > 0 && selectedIncident['isInternal'] == 'Y')){
    var i = p1Types.length
    while (i--) {
      if (!p1Types[i].toLowerCase().includes('internal')) { 
        p1Types.splice(i, 1);
      } 
    }
    i = p2Types.length
    while (i--) {
      if (!p2Types[i].toLowerCase().includes('internal')) { 
        p2Types.splice(i, 1);
      } 
    }
  }
  if ((!authorization.is_admin && !authorization.allowed_actions.includes(EDIT_IS_INTERNAL))){
    var i = p1Types.length
    while (i--) {
      if (p1Types[i].toLowerCase().includes('internal')) { 
        p1Types.splice(i, 1);
      } 
    }
    i = p2Types.length
    while (i--) {
      if (p2Types[i].toLowerCase().includes('internal')) { 
        p2Types.splice(i, 1);
      } 
    }
  }
  let styles = {
    clickableLink  :  {
      cursor    :  'pointer',
      color     :  'blue',
    },
    error  :  {
      color     :  'red',
    },
    button        : {
      width       :  '8rem',
    },
    buttonRight   : {
      width       : '8rem',
      marginLeft  : '5px'
    },
    buttonError  :  {
      color     :  'red',
      marginRight:  '5px',
    },
    infoImg: {
      maxHeight : '1.9rem',
    },
    inlineMiddleVAlign: {
      display : 'inline',
      verticalAlign : 'middle'
    },
  }
  let buttonMessage = '';
  const [modalIsOpen,setIsOpen] = useState(false);
  const [relocalize, setRelocalize] = useState('Y');
  const openModal = () => {
    setIsOpen(true);
  }
  const closeModal = () => {
    setIsOpen(false);
  }

  const displayButton = (values, errors) => {
    let displayButton = true;
    buttonMessage = '';
    if (values.message_type === messageType && values.body === body[values.language]){
      displayButton = false;
      buttonMessage = 'Must Change A Value';
    }
    if (errors.body || !values.body){
      displayButton = false;
      buttonMessage = 'Issue with Message Body';
    }
    return displayButton;
  }

  const onChangeTemplate = (e, values, field, setValues) => {
    const templateVal = e.target.value;
    if (templateVal == 'None'){
      setValues({...values,  selected_template: templateVal});
      return;
    }
    const vals = templates
    let template = null;
    vals.forEach(val => {
      if (val.pkey == templateVal){
        template = val;
      }
    })
    setValues({...values,  selected_template: template.pkey, body: template.body});
    field.onChange(e);
  }

  const onChangeLanguage = (e, values, setValues) => {
    const givenLanguage = e.target.value;
    if (givenLanguage !== 'en'){
      setValues({...values,  body: body[givenLanguage], language: givenLanguage, relocalize: 'N'});
    }
    else{
      setValues({...values,  body: body[givenLanguage], language: givenLanguage});
    }
  }

  const handleChange = (e, setValues, values) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    validate(values, name, value);
  };

  const validate = (values, name, value) => {
    let valid = true;
    if (name == 'body' && value == body && values.message_type == messageType){
      buttonMessage = 'Must Modify A Value';
      valid = false;
    }
    if (name == 'message_type' && value == messageType && values.body == body){
      buttonMessage = 'Must Modify A Value';
      valid = false;
    }
    if (!values.body){
      buttonMessage = 'Value Missing';
      valid = false;
    }
    if (valid){
      buttonMessage = '';
    }
    
  };

  const onChangeRelocalize = (e, values, setValues) => {
    const relocalize = e.target.value;
    if (relocalize === 'Y'){
      setValues({...values, language: 'en', body:body['en'], relocalize: relocalize});
    }
    else{
      setValues({...values, relocalize: relocalize});
    }
  }

  const submitForm = (fields) => {
    setPageLoading(true);
    submitUpdateMessage(fields, priorityType);
    closeModal();
    return true;
  }

  return (
    <div>
      <OverlayTrigger
        key='left'
        placement='left'
        overlay={
        <Tooltip id={`tooltip-left`}>
          {t('containers.incidentManagementUpdateMessage.toolTip')}
        </Tooltip>
      }
      >
      <div style={styles.clickableLink} onClick={openModal} className={"data-cy-update-message-button-" + messageId}>
        {t('containers.incidentManagementUpdateMessage.buttonUpdate')}
      </div>
      </OverlayTrigger>

      
      <Modal show={modalIsOpen} onHide={closeModal} className="data-cy-update-message-modal">
        <Formik   
            validationSchema={schema}
            onSubmit={values => submitForm(values)}
            initialValues={{
              message_type: messageType,
              body: body['en'],
              message_id: messageId,
              jira_id: jiraId,
              priority_type: priorityType,
              is_test: 'N',
              language: 'en',
              relocalize: relocalize,
              broadcast: 'N',
            }}
        >
        {({
          handleSubmit,
          handleBlur,
          values,
          errors,
          touched,
          setValues,
        }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Modal.Header closeButton>
                <Modal.Title>{t('containers.incidentManagementUpdateMessage.update')}{messageId}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form.Group>
                  <Form.Label>{t('containers.incidentManagementUpdateMessage.messageType')}</Form.Label>
                  <Form.Control as="select" className="data-cy-update-message-modal-type" name="message_type" onChange={e => handleChange(e, setValues, values)} onBlur={handleBlur} value={values.message_type}>
                    { priorityType == 'P2' && p2Types.map((type, i) => (
                      <option key={i} className={"data-cy-update-message-modal-" + i}>{type}</option>
                    ))}
                    { priorityType == 'P1' && p1Types.map((type, i) => (
                      <option key={i} className={"data-cy-update-message-modal-" + i}>{type}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Label>{t('containers.incidentManagementUpdateMessage.language')}</Form.Label>
                  <Form.Control as="select" className="data-cy-update-language" name="language" onChange={e => onChangeLanguage(e, values, setValues)} onBlur={handleBlur} value={values.language}>
                    { activeLocales.map((locale, i) => (
                      <option key={i} value={locale.system_key} className={"data-cy-update-message-modal-" + i}>{locale.display_name}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
                <Form.Group>
                    <Form.Label>Insert Template Text</Form.Label>
                    <OverlayTrigger
                      key='right'
                      placement='right'
                      overlay=
                      {
                        <Tooltip id={`tooltip-right`}>
                          Inserts text into the Message Body field. WARNING will delete Message Body contents.
                        </Tooltip>
                      }>
                      <img src = {infoImage} style = {{ ...styles.infoImg, ...styles.inlineMiddleVAlign }} />
                    </OverlayTrigger>
                    <Field>
                      {({ field }) => (
                        <Form.Control as="select" name="template_text" value={values.selected_template} onBlur={handleBlur} onChange={e => onChangeTemplate(e, values, field, setValues)}>
                          <option key='None' value='None'></option>
                          { templates.map((type, i) => {
                            if (type['message_type'] == values.message_type)return ( <option key={'template-' + i} value={type.pkey} id={type.body}>{type.title}</option>)
                            })}
                          
                        </Form.Control>
                      )}
                    </Field>
                  </Form.Group>
                <Form.Group>
                  <Form.Label>{t('containers.incidentManagementUpdateMessage.messageBody')}</Form.Label>
                  <OverlayTrigger
                      key='bottom'
                      placement='bottom'
                      overlay=
                      {
                        <Tooltip id={`tooltip-bottom`}>
                          {t('containers.incidentManagementUpdateMessage.toolTip2')}
                        </Tooltip>
                      }>
                      <img src = {infoImage} style = {{ ...styles.infoImg, ...styles.inlineMiddleVAlign }} />
                    </OverlayTrigger>
                  <Form.Control
                  type="textarea"
                  as="textarea"
                  className="data-cy-update-message-modal-body"
                  value={values.body}
                  rows="6"
                  onChange={e => handleChange(e, setValues, values)}
                  onBlur={handleBlur}
                  name="body"></Form.Control>
                  {errors.body && touched.body ? (
                    <div style={styles.error}>{errors.body}</div>
                  ) : null}
                </Form.Group>
                <Form.Group>
                  <div style={{'margin': '1rem 0'}}>{t('containers.incidentManagementUpdateMessage.translateMessage')}</div>
                  <Field>
                      {({ field }) => (
                        <Form.Group as={Row} className="data-cy-create-message-modal-relocalize" name="relocalize" value={values.relocalize} onBlur={handleBlur} onChange={e => handleChange(e, setValues, values)} >
                            <Form.Check
                            type="radio"
                            name="relocalize"
                            label={t('containers.incidentManagementCreateMessage.yes')}
                            value="Y"
                            className="data-cy-create-message-modal-relocalize-Y"
                            id="relocalizeYes"
                            style={styles.marginRight}
                            checked={values.relocalize === 'Y'}
                            onChange={e => onChangeRelocalize(e, values, setValues)}
                            style={{'marginRight': '1rem'}}
                          />
                          <Form.Check
                            type="radio"
                            label={t('containers.incidentManagementCreateMessage.no')}
                            name="relocalize"
                            value="N"
                            className="data-cy-create-message-modal-broadcast-N"
                            id="relocalizeNo"
                            checked={values.relocalize === 'N'}
                            onChange={e => onChangeRelocalize(e, values, setValues)}
                          />
                        </Form.Group>

                      )}
                    </Field>
                    {errors.broadcast && touched.broadcast ? (
                        <div style={styles.error}>{errors.broadcast}</div>
                      ) : null}
                </Form.Group>
              
              </Modal.Body>
              <Modal.Footer>
                <Row>
                  <div style={styles.buttonError}>{buttonMessage}</div>
                  <Button style={styles.button} variant="secondary" className="data-cy-update-message-modal-close" onClick={closeModal}>
                  {t('containers.incidentManagementUpdateMessage.close')}
                  </Button>
                  <Button style={styles.buttonRight} className="data-cy-update-message-modal-submit" disabled={!displayButton(values, errors)} type="submit">{t('containers.incidentManagementUpdateMessage.submit')}</Button>
                  
                </Row>
              </Modal.Footer>
            </Form>
          )}
          </Formik>
      </Modal>
    </div>
  );
}

export default connect(mapStateToProps,mapDispatchToProps)(UpdateMessageModal);