import React from 'react';
import Tooltip from "react-simple-tooltip";
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

export const StatusDetails = ({severityList}) => {
  const { t } = useTranslation(["translation"]);
  const styles = {
    statusMargin: { marginTop: "1%" },
    tooltip: { whiteSpace: "nowrap" },
  };

  const displayStatusDetails = (
    severityList.map((item, index) => (
      <Col sm={12}key={item.id} style={styles.statusMargin} >
        <Tooltip content={item.description} fadeDuration={200} style={styles.tooltip} radius={10} placement="top">
          <Row data-tip data-for={item.id}>
            <Col sm={1} className={`${index}-symbol`}>
              <div className={`${item.system_key.toUpperCase()} symbol`}></div>
            </Col>
            <Col className={`${index}-text`}>
              <div>{t('containers.landingStatusDetails.' + item.display_value)}</div>
            </Col>
          </Row>
        </Tooltip>
      </Col>
    ))
  );

  return (
    <Col sm={12}className='card services status-details card-shadow' key={"service-details-col"}>
      <div className="service-card">
        <div className="card-header row d-flex">
          <Col sm={12}>
            <h3 className="my-auto">{t('containers.landingStatusDetails.status')}</h3>
          </Col>
        </div>
        <Row>
          {displayStatusDetails}
        </Row>
      </div>
    </Col>
  );
};
