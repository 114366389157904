import React, { Component, useState, useEffect }   from 'react';
import { Button, Card, CardColumns } from 'react-bootstrap';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';
import AuthorizationWrapper from '../../components/authorizationWrapper';
import AdministrationModal from '../Administration/AdministrationModal/AdministrationModal';
import {Users} from './Users/Users';
import {MdArrowDropDown, MdArrowDropUp} from 'react-icons/md';
import { Roles } from './Roles/Roles';
import { Actions } from './Actions/Actions';
import { capitalizeFirstLetter } from './../../utils/adminHelperFunctions';
import { useTranslation } from 'react-i18next';

import { actions as administrationActions } from '../../ducks/administration/administration.index'
const mapStateToProps = ({userState, administrationState}) => ({
  administrationState : administrationState,
  userState           : userState
});
const mapDispatchToProps =  (dispatch) => {
  const combinedActionCreators = {
    getEntitiesByType: administrationActions.getEntitiesByType,
    setEntitiesByType: administrationActions.setEntitiesByType,
  };

  return bindActionCreators(combinedActionCreators, dispatch);
};

const styles = {
  container : { width: '90%', margin: '0 auto', marginBottom: '1rem' },
  card      : { boxShadow : 'rgba(0, 0, 0, 0.2) 0px 0px 10px -2px', marginTop: "1rem" },
  expandButton : { width: '5%', textAlign: 'center' }
}
const viewAdminPageAction = "view_admin_page";
const RenderCards = ({ userState, roles, users, actions}) => {
  const { t } = useTranslation(["translation"]);
  const AddNewButton = ({ type }) => {
    const textName = 'containers.administration.button' + type
    const handleClick = (e) => {
      // placeholder for "Add New" action calls
      e.preventDefault();
      setDisplayModal(true);
      setModalType(type + 's');
      setModalTitle('Create ' + capitalizeFirstLetter(type));
    }
    return (
      <Button className={`AddNew-${type}`} onClick={(e)=>handleClick(e)} size="sm" variant="outline-secondary">{t(textName)}</Button>
    )
  }

  const CloseModal = () => {
    setDisplayModal(false);
  }
  const emptyObject = {};
  const [displayUsers, setDisplayUsers]       = useState(true);
  const [displayRoles, setDisplayRoles]       = useState(false);
  const [displayActions, setDisplayActions]   = useState(false);
  const [displayModal, setDisplayModal]       = useState(false);
  const [modalType, setModalType]             = useState('');
  const [modalTitle, setModalTitle]           = useState('');
  //so data will correctly rerender when changed.
  const [stateUsers, setStateUsers]           = useState(users);
  const [stateRoles, setStateRoles]           = useState(roles);
  const [stateActions, setStateActions]       = useState(actions);
  useEffect(() => {
    setStateUsers(users);
  }, [users])
  useEffect(() => {
    setStateRoles(roles);
  }, [roles])
  useEffect(() => {
    setStateActions(actions);
  }, [actions])
  const managementCards = [
    { title: "User Management",   body: <Users users={stateUsers} userState={userState}/>,   visibility: "view_admin_users_tile", type: "user",
       displayVar: displayUsers, displayVarFunc: setDisplayUsers },
    { title: "Role Management",   body: <Roles roles={stateRoles} userState={userState}/>, visibility: "view_admin_roles_tile",   type: "role",
       displayVar: displayRoles, displayVarFunc: setDisplayRoles },
    { title: "Action Management", body:<Actions actions={stateActions} userState={userState}/>, visibility: "view_admin_actions_tile", type: "action",
       displayVar: displayActions, displayVarFunc: setDisplayActions },
  ];
  return (
    managementCards.map(card => (
      <AuthorizationWrapper requiredAction={viewAdminPageAction} key={card.type}>
        <AuthorizationWrapper requiredAction={card.visibility} key={card.type}>
          <AdministrationModal
            openState={displayModal} 
            title={modalTitle} 
            type={modalType}
            action='create'
            selectedData={emptyObject} 
            close={CloseModal}/>
          <Card style={{...styles.card}}>
            <Card.Header>
              <b className="pull-left">{card.title}</b>
              <div className="pull-right clickable" style={styles.expandButton}>
                {card.displayVar && <MdArrowDropUp className={`CollapseButton-${card.type}`} size={28} onClick={() => card.displayVarFunc(!card.displayVar)}/>}
                {!card.displayVar && <MdArrowDropDown className={`ExpandButton-${card.type}`} size={28} onClick={() => card.displayVarFunc(!card.displayVar)}/>}
              </div>
              <span className="pull-right">
                <AddNewButton type={card.type}/>
              </span>

            </Card.Header>
            {card.displayVar && <Card.Body className="p-0">{card.body}</Card.Body>}
          </Card>
        </AuthorizationWrapper>
      </AuthorizationWrapper>
    ))
  )
}


class Administration extends Component {


  static propTypes = {
  };

  componentDidMount = () => {
    this.props.getEntitiesByType('users');
    this.props.getEntitiesByType('roles');
    this.props.getEntitiesByType('actions');
  }

  render = () => {

    return (
      <div style={{...styles.container}}>
        <RenderCards
          roles      = { this.props.administrationState.roles }
          users      = { this.props.administrationState.users }
          actions    = { this.props.administrationState.actions }
          userState  = { this.props.userState }
        />
      </div>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Administration);
