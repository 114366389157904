import { takeEvery, put, call, select } from "redux-saga/effects";

import { getStatus } from './status.api';
import { types, actions } from './status.index';
import { actions as toastActions } from '../toast/toast.index';
import { actions as errorActions } from '../error/error.index';

export function* fetchStatus() {
  try {
    let response =  yield call(getStatus);
    let payload  =  response.data.payload;

    yield put(actions.setStatus(payload));
    yield put(actions.setStatusLoading(false));

  }
  catch(error) {
    // handle ui effects
    const message      =  `error updating datacenter statuses: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));
    yield put(actions.setStatusError(true));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
  }
}

// watcher saga
export default function statusSagas() {
  return [
    takeEvery(types.REQUEST_STATUS, fetchStatus)
  ];
}
