import React ,{useEffect, useState, useCallback} from 'react';
import { Card, Button, Row, InputGroup, FormControl } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect }            from 'react-redux';
import { MdArrowBack, MdArrowForward } from 'react-icons/md';
import {
  actions as adminActions
} from '../../../ducks/administration/administration.index';
import {capitalizeFirstLetter} from '../../../utils/adminHelperFunctions';
import debounce from 'lodash.debounce';
import { useTranslation } from 'react-i18next';

const styles = {
  container: {width: '100%'},
  dragInstructions: {display: 'flex', paddingTop: '40px', margin: '0 auto'},
  dragContainer: {display: 'contents', textAlign: 'center'},
  submitButton: {margin: '0 auto'},
  identifierContainer: {display: 'block'},
  rowSpacer: {margin: '10px auto'},
  buttonStyle: {margin: '0 10px'},
  error: {color: 'red'},
}

const userType = 'users';
const actionType = 'actions';
const roleType = 'roles';
const createAction = 'create';
const editAction = 'edit';
const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const mapStateToProps = ({administrationState }) => ({
  roles               :  administrationState.roles,
  users               :  administrationState.users,
  actions             :  administrationState.actions,
});
const mapDispatchToProps =  (dispatch) => {
  const combinedActionCreators = {
    setEntitiesByType           :  adminActions.setEntitiesByType,
    editEntity                  :  adminActions.editEntity,
    createEntity                :  adminActions.createEntity,
  };

  return bindActionCreators(combinedActionCreators, dispatch);
};

const getObjectIndex = (values, obj, key) => {
  for (let i = 0; i < values.length; i++){
    if (values[i][key] === obj[key]){
      return i;
    }
  }
  return -1;
}

const CreateEditModal = ({selectedData, selectedOptions, availableOptions, type, action, close, typeData, identifier, editEntity, createEntity, compareData, colName}) => {
  const { t } = useTranslation(["translation"]);
  const checkValidIdentifier = (value) => {
    if (type === userType){
      if (!value.match(emailRegex)){
        setErrorMessage('Invalid Email');
        setValid(false);
        return;
      }
    }
    if (action === createAction){
      for (let item of compareData){
        if (item[identifier] === value){
          setErrorMessage(`${capitalizeFirstLetter(identifier)} Already Exists`);
          setValid(false);
          return;
        }
      }
    }
    setValid(true);
    return;
  }
  const debouncedSave = useCallback(
		debounce(nextValue => checkValidIdentifier(nextValue), 700),
		[], // will be created only once initially
	);
  const [availOptions, setAvailOptions] = useState(availableOptions);
  const [selectOptions, setSelectOptions] = useState(selectedOptions);
  const [description, setDescription] = useState(selectedData.description);
  const [valid, setValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  
  let temp = [];
  let idAccessor = '';
  let nameAccessor = '';
  let dataArrayAccessor = '';
  let idArrayAccessor = '';
  if (type === userType){
    dataArrayAccessor = roleType;
    idAccessor = 'role_id';
    idArrayAccessor = 'role_ids';
    nameAccessor = 'display_name';
  }
  else if (type === roleType){
    dataArrayAccessor = actionType;
    idAccessor = 'action_id';
    idArrayAccessor = 'action_ids'
    nameAccessor = 'display_name';
  }
  if (action === createAction && Object.keys(selectedData).length === 0){
    selectedData[identifier] = '';
    selectedData[idArrayAccessor] = [];
    selectedData[dataArrayAccessor] = [];
  }
  const [identifierVal, setIdentifierVal] = useState(selectedData[identifier]);
  useEffect(() => {
    if (action === editAction){
      checkValidIdentifier(identifierVal);
    }
  }, [])
 
 

  const submitEdit = () => {
    selectedData[identifier] = identifierVal;
    if (type === actionType || type === roleType){
      const regex = / /g;
      selectedData['system_name'] = identifierVal.toLowerCase().replace(regex, '_');
    }
    if (type !== userType){
      selectedData['description'] = description;
    }
    if (action === createAction){
      //slice will remove the last char, making it singular and not plural.
      createEntity(type.slice(0, -1), selectedData);
    }
    else if (action === editAction){
      editEntity(type.slice(0, -1), selectedData);
    }
    close();
  }

  const handleIdentifierChange = (e) => {
    setIdentifierVal(e.target.value);
    debouncedSave(e.target.value);
    //checkValidIdentifier(e.target.value);
  }

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  }

  const handleDragEnter = e => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragLeave = e => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDragOver = e => {
    e.preventDefault();
    e.stopPropagation();
  };
  const handleDrop = (e, target) => {
    e.preventDefault();
    var display_val = JSON.parse(e.dataTransfer.getData("Text"));
    //dropped in the selectedData section
    //add available option into selected option
    //available options dragged in as objects
    if (target == 'selected'){

      //remove from orig array. It's like this due to useState array
      temp = [...availOptions];
      temp.splice(getObjectIndex(availOptions, display_val, nameAccessor), 1);
      setAvailOptions(temp);
      setSelectOptions([...selectOptions, display_val[nameAccessor]]);
      selectedData[idArrayAccessor].push(display_val[idAccessor]);
      selectedData[dataArrayAccessor].push(display_val.system_name);
    }
    //dropped in the availableOption section
    //remove option from selected option
    //selected options dragged in as string
    else if (target == 'available'){
      let objectVal = {}
      for (const obj of typeData){
        if (obj.display_name == display_val){
          objectVal = obj;
        }
      }
      setAvailOptions([...availOptions, objectVal]);

      temp = [...selectOptions];
      temp.splice(selectOptions.indexOf(display_val), 1);
      setSelectOptions(temp);

      selectedData[idArrayAccessor].splice(selectedData[idArrayAccessor].indexOf(objectVal[idAccessor]), 1);
      selectedData[dataArrayAccessor].splice(selectedData[dataArrayAccessor].indexOf(objectVal.system_name), 1);

    }
    e.preventDefault();
    e.stopPropagation();
  };
  const dragStart = (e, value) => {
    e.dataTransfer.setData("text/plain", JSON.stringify(value));
  }
  const dragging = (e) => {
    e.preventDefault();
    e.stopPropagation();
  }


  return (
    <div style={styles.container} className={`Modal-${type}-${action}`}>
      <Row style={styles.rowSpacer}>
        <InputGroup style={styles.identifierContainer}>
          <h4>{capitalizeFirstLetter(identifier)}</h4>
          <FormControl
            value={identifierVal}
            onChange={(e) => handleIdentifierChange(e)}
          />
          { !valid && <div style={styles.error}>{errorMessage}</div>}
          { type !== userType && <div>
            <h4>{t('containers.administrationTypeModal.textDescription')}</h4>
            <FormControl
              value={description}
              onChange={(e) => handleDescriptionChange(e)}
            />
          </div> }
        </InputGroup>
      </Row>
      { type !== actionType && <Row style={styles.rowSpacer}>
        <div style={styles.dragContainer}>
        <div
            onDrop={e => handleDrop(e, 'available')}
            onDragOver={e => handleDragOver(e)}
            onDragEnter={e => handleDragEnter(e)}
            onDragLeave={e => handleDragLeave(e)}
          >
            <h4>{'Unassigned ' + colName}</h4>
              {availOptions.map((option, i) => (
                <Card
                key={'unassigned ' + i}
                draggable="true"
                onDragStart={e => dragStart(e, option)}
                onDrag={e => dragging(e)}
                >{option[nameAccessor]}</Card>
            ))}
          </div>
          <div style={styles.dragInstructions}>
            <MdArrowBack size={28}/>
            <div>{t('containers.administrationTypeModal.textDrag')}</div>
            <MdArrowForward size={28}/>
          </div>
          <div
           onDrop={e => handleDrop(e, 'selected')}
           onDragOver={e => handleDragOver(e)}
           onDragEnter={e => handleDragEnter(e)}
           onDragLeave={e => handleDragLeave(e)}
          >
            <h4>{'Assigned ' + colName}</h4>
            {selectOptions.map((option, i) => (
              <Card
                key={'assigned' + i}
                draggable="true"
                onDragStart={e => dragStart(e, option)}
                onDrag={e => dragging(e)}
              >{option}</Card>
            ))}
          </div>  
        </div>
      </Row>}
      <br/>
      <Row style={styles.rowSpacer}>
        <div style={styles.rowSpacer}>
          <Button style={styles.buttonStyle} className={`CloseButton-${type}-${action}`} variant="secondary"  onClick={() => close()}>
          {t('containers.administrationTypeModal.buttonCancel')}
          </Button>
          <Button disabled={!valid} style={styles.buttonStyle} variant="primary" onClick={() => submitEdit()}>
            {action === editAction && t('containers.administrationTypeModal.buttonUpdate')}
            {action === createAction && t('containers.administrationTypeModal.buttonCreate')}
          </Button>
        </div>
      </Row>
    </div>
  )
}
export default connect(mapStateToProps,mapDispatchToProps)(CreateEditModal);