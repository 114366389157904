import React, { Component } from 'react';
import { uiProptypes, actions as uiActions } from '../../ducks/ui/ui.index';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import './NetworkDetector.css'
import { Translation } from 'react-i18next';

/* ========= REDUX STATE CONNECTORS ========= */
const mapStateToProps = ({ uiState }, props) => {
  return {
    isDisconnected : uiState.isDisconnected
  };
};

const mapDispatchToProps = (dispatch) => {
  const combinedActionCreators = {
    setIsDisconnected : uiActions.setIsDisconnected,
  };

  return bindActionCreators(combinedActionCreators, dispatch);
};

const styles = {
  underline : {
    textDecoration: 'underline'
  }
}

class NetworkDetector extends Component {
  static propTypes = {
    setIsDisconnected : uiProptypes.setIsDisconnected,
  };

  componentDidMount() {
    // window.addEventListener('online', this.handleDisconnection);
    window.addEventListener('offline', this.handleDisconnection);
    this.handleDisconnection();
    
  }

  componentWillUnmount() {
    // window.removeEventListener('online', this.handleDisconnection);
    window.removeEventListener('offline', this.handleDisconnection);
  }



  refreshPage = () => window.parent.location = window.parent.location.href;

  handleDisconnection = () => {
    this.props.setIsDisconnected(navigator.onLine ? false : true);
  }

  render() {
    const { isDisconnected } = this.props;
    return (
      isDisconnected &&
        <div className="elementToFadeIn">
          <div className="alert alert-danger mb-0 card-shadow" role="alert">
            <Translation>
              {(t) => 
              <p className="mb-0 text-center">
                <b>
                  {t('components.networkDetector.body1')}
                </b>
                {t('components.networkDetector.body2')}
                <a style={styles.underline} onClick={this.refreshPage} href="#" class="alert-link">
                  {t('components.networkDetector.body3')}
                </a>
              </p>
              }
            </Translation>
          </div>
        </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(NetworkDetector);
