/* ========= PACKAGE IMPORTS ========= */
import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { Row } from 'react-bootstrap';
import { Translation } from 'react-i18next';
/* ========= REDUX IMPORTS ========= */
import { actions as uiActions }    from '../../../ducks/ui/ui.index';
import {
  actions as incidentActions,
  priorityTypes,
  editablePriortyTypes,
}   from '../../../ducks/incident/incident.index';

/* ========= COMPONENT IMPORTS ========= */
import Loading            from '../../../components/loading';
import ReviewDetailsTable from '../ReviewDetailsTable/ReviewDetailsTable';
import CreateMessageModal from '../MessageModals/CreateMessageModal';
import AuthorizationWrapper from '../../../components/authorizationWrapper';

/* ========= REDUX STATE CONNECTORS ========= */
const mapStateToProps = ({uiState, incidentState}, props) => {
  let jiraId;

  try { jiraId = window.location.pathname.split('/')[2]; }
  catch { props.history.push('/not-found'); }

  return {
    jiraId              :  jiraId,
    pageLoading         :  uiState.pageLoading,
    incidentMessages    :  incidentState.incidentMessages,
    p2Incidents         :  incidentState.p2Incidents,
    p1Incidents         :  incidentState.p1Incidents,
    messagesLoading     : incidentState.messagesLoading,
    selectedIncident    : incidentState.selectedIncident,
    specificIncidentLoading     : incidentState.specificIncidentLoading,
  };
};
const p2RequiredAction = 'create_p2_message';
const p1RequiredAction = 'create_p1_message';
const mapDispatchToProps = (dispatch) => {
  const combinedActionCreators = {
    setPageLoading          :  uiActions.setPageLoading,
    requestIncidentMessages :  incidentActions.requestIncidentMessages,
    setMessagesLoading      :  incidentActions.setMessagesLoading,
    setSelectedIncident     : incidentActions.setSelectedIncident,
    requestIncidentByJiraId : incidentActions.requestIncidentByJiraId,
  };
  return bindActionCreators(combinedActionCreators, dispatch);
};

const styles = {
  'topContainer': {
    'margin'   :  'auto',
    'maxWidth' :  'max-content',
  },
  'pageTitle': {
    'marginBottom' :  '20px',
    'width'        :  '73vw',
    'textAlign'   :  'center',
  },
  'button': {
    'float'     : 'none',
    'margin'    : '0 auto',
    'marginLeft': '0 auto',
    'marginTop' : '20px',
  }
};
class ReviewIncidentDetail extends Component {

  componentDidMount = () => {
    this.props.requestIncidentByJiraId(this.props.jiraId);
  }

  render = () => {
    const { topContainer, pageTitle } = styles;
    const { selectedIncident, jiraId, specificIncidentLoading } = this.props;  
    return (
      <div style={topContainer}>
        {/* HEADER */}
        {selectedIncident['isInternal'] == 'Y' && <div><h1 style={pageTitle}>{jiraId}<Translation>{(t) => t('containers.landingOpiCard.internalHeader')}</Translation> - <Translation>{(t) => t('containers.incidentManagmentReviewIncidentDetails.messages')}</Translation></h1></div>}
        {selectedIncident['isInternal'] == 'N' && <h1 style={pageTitle}>{jiraId} - <Translation>{(t) => t('containers.incidentManagmentReviewIncidentDetails.messages')}</Translation></h1>}
        { /* CONTENT */
        specificIncidentLoading
        ? <Loading height='75vh' />
        : <> 
            <ReviewDetailsTable RowData={selectedIncident['messages']} Incident={selectedIncident}/>
            <Row>
              <AuthorizationWrapper requiredAction={selectedIncident['priorityType'] == 'P2' ? p2RequiredAction : p1RequiredAction}>
                <div style={styles.button}>
                  <CreateMessageModal jiraId={selectedIncident['jiraId']} priorityType={selectedIncident['priorityType']} Internal={selectedIncident['isInternal']}/>
                </div>
              </AuthorizationWrapper>
            </Row>   
            <Row>
              <a href="https://wiki.concur.com/confluence/pages/viewpage.action?spaceKey=RDTV&title=TPM+P2+Process+%28NEW%29+with+P2s+on+Concur+Open"><Translation>{(t) => t('containers.incidentManagmentReviewIncidentDetails.help')}</Translation></a>
            </Row>
          </>        
        }
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewIncidentDetail);