import React, { useState } from 'react';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  actions as uiActions,
} from '../../ducks/ui/ui.index';
import {
  actions as templateActions
} from '../../ducks/template/template.index';
import { Modal, Button, Form, Row } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import {Formik} from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
/* ========= PACKAGE IMPORTS ========= */
const schema = yup.object().shape({
  message_type: yup.string().required(),
  body: yup.string().required(),
  title: yup.string().required(),
  affected_service : yup.string().required(),
  //not displayed in form
  created_by: yup.string().notRequired(),
});

const mapStateToProps = ({uiState, userState, incidentState}) => ({
  pageLoading         :  uiState.pageLoading,
  activeLocales       :  uiState.activeLocales,
  email               :  userState.user.email,
  p2AlertTypes        :  incidentState.p2AlertTypes,
  p1AlertTypes        :  incidentState.p1AlertTypes, 
});
const mapDispatchToProps =  (dispatch) => {
  const combinedActionCreators = {
    setPageLoading           :  uiActions.setPageLoading,
    submitCreateTemplate      :  templateActions.submitCreateTemplate,
  };

  return bindActionCreators(combinedActionCreators, dispatch);
};

const SERVICE_LIST = ['General', 'Analysis / Intelligence', 'Expense', 'FTP', 'Imaging', 'Invoice', 'Outtask', 'Travel', 'Travel (GDS Specific)', 'ExpenseIt', 'TripIt', 'Locate'];
const CreateTemplateModal = ({template_type, submitCreateTemplate, p1AlertTypes, setPageLoading, email}) => {
  const { t } = useTranslation(["translation"]);
  let styles = {
    clickableLink  :  {
      cursor    :  'pointer',
      color     :  'blue',
    },
    error  :  {
      color     :  'red',
    },
    button        : {
      width       :  '8rem',
    },
    buttonRight   : {
      width       : '8rem',
      marginLeft  : '5px'
    },
    buttonError  :  {
      color     :  'red',
      marginRight:  '5px',
    },
    infoImg: {
      maxHeight : '1.9rem',
    },
    buttonRightDisabled: {
      width       : '8rem',
      marginLeft  : '5px',
      cursor      : 'default'
    },
    inlineMiddleVAlign: {
      display : 'inline',
      verticalAlign : 'middle'
    },
  }
  const [modalIsOpen,setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  }
  const closeModal = () => {
    setIsOpen(false);
  }

  const displayButton = (values) => {
    if (values.title && values.body && values.affected_service){
      return true;
    }
    return false;
  }

  const submitForm = (fields) => {
    setPageLoading(true);
    submitCreateTemplate(fields);
    closeModal();
    return true;
  }

  return (
    <div>
      <Button style={{'marginBottom': '5px'}} variant="primary"  onClick={openModal} className={"data-cy-update-template-button-"}>Create Template</Button>
      <Modal show={modalIsOpen} onHide={closeModal} className="data-cy-update-template-modal">
        <Formik   
            validationSchema={schema}
            onSubmit={values => submitForm(values)}
            initialValues={{
              message_type: p1AlertTypes[0],
              body: '',
              title: '',
              notification_type: template_type == 'notification' ? 'Maintenance Announcement' : '',
              template_type: template_type,
              affected_service: SERVICE_LIST[0],
              created_by: email,
            }}
        >
        {({
          handleSubmit,
          handleBlur,
          handleChange,
          values,
          errors,
          touched,
          setValues,
        }) => (
            <Form noValidate onSubmit={() => submitForm(values)}>
              <Modal.Header closeButton>
                <Modal.Title>Create Template</Modal.Title>
              </Modal.Header>
             <Modal.Body>
                {template_type == 'message' && <Form.Group>
                  <Form.Label>Message Type</Form.Label>
                  <Form.Control as="select" className="data-cy-update-template-modal-type" name="message_type" onChange={handleChange} onBlur={handleBlur} value={values.message_type}>
                    { p1AlertTypes.map((type, i) => (
                      <option key={i} className={"data-cy-update-template-modal-" + i}>{type}</option>
                    ))}
                  </Form.Control>
                </Form.Group>}
                {template_type == 'notification' && <Form.Group style={{display: 'flex'}}>      
                  <div style={{marginRight: '3vw'}}>
                    <Form.Label>Type</Form.Label>     
                    <Form.Control as="select" name="notification_type" value={values.notification_type} onChange={handleChange}>
                      <option value="Maintenance Announcement">Maintenance Announcement</option>
                      <option value="Maintenance Update">Maintenance Update</option>
                      <option value="Maintenance Complete">Maintenance Complete</option>
                      <option value="Maintenance Cancelled">Maintenance Cancelled</option>
                      <option value="Personalized Concur Open">Personalized Concur Open</option>
                    </Form.Control>
                  
                  </div>
                </Form.Group>}
                {template_type == 'message' && <Form.Group>
                  <Form.Label>Affected Service</Form.Label>
                  <Form.Control as="select" className="data-cy-update-template-modal-type" name="affected_service" onChange={handleChange} onBlur={handleBlur} value={values.affected_service}>
                    { SERVICE_LIST.map((service, i) => (
                      <option key={i} value={service} className={"data-cy-update-template-modal-service-" + i}>{service}</option>
                    ))}
                  </Form.Control>
                </Form.Group>}

                <Form.Group>
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                  type="textarea"
                  as="textarea"
                  className="data-cy-update-template-modal-body"
                  value={values.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="title"></Form.Control>
                  {errors.title && touched.title ? (
                    <div style={styles.error}>Title is a required field</div>
                  ) : null}
                </Form.Group>

                
               
                <Form.Group>
                  <Form.Label>Body</Form.Label>
                  <Form.Control
                  type="textarea"
                  as="textarea"
                  className="data-cy-update-template-modal-body"
                  value={values.body}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  rows={5}
                  name="body"></Form.Control>
                  {errors.body && touched.body ? (
                    <div style={styles.error}>Body is a required field</div>
                  ) : null}
                </Form.Group>
               
              
              </Modal.Body>
              <Modal.Footer>
                <Row>
                  <Button style={styles.button} variant="secondary" className="data-cy-update-template-modal-close" onClick={closeModal}>Close</Button>
                  <Button style={!displayButton(values) ? styles.buttonRightDisabled : styles.buttonRight} disabled={!displayButton(values)} className="data-cy-update-template-modal-submit" type="submit">{t('containers.incidentManagementUpdateMessage.submit')}</Button>                  
                </Row>
              </Modal.Footer> 
            </Form>
          )}
          </Formik>
      </Modal>
    </div>
  );
}

export default connect(mapStateToProps,mapDispatchToProps)(CreateTemplateModal);