import React, { useState, useEffect }        from 'react';
import { Card, Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';

/* ========= COMPONENT IMPORTS ========= */
import AuthorizationWrapper from '../../../components/authorizationWrapper';

const BannerControls = ({bannerText, bannerDisplay, submitUpdateBanner, email}) => {

  const styles = {
    bannerText : {paddingLeft: '1rem', margin: '1rem', backgroundColor: '#e1e1e8'},
    button      : {'float': 'right', 'marginRight': '1rem'},
  }

  const [banner_display, set_banner_display] = useState(bannerDisplay.toString());

  useEffect(() => {
    set_banner_display(bannerDisplay.toString());
  }, [bannerDisplay])

  const handleChange = e => {
    e.persist();
    set_banner_display(e.target.value);
  };

  const submit = () => {
    submitUpdateBanner(banner_display == "true", email);
  }

const requiredEditBannerAction = 'edit_service_disruption_banner';

  return (
      <Card>
          <Card.Title style={{'alignSelf': 'center', 'margin': '5px 0'}}><h3>Service Disruption Banner</h3></Card.Title>
          <Form onSubmit={e => submit()} style={{'marginBottom': '5px'}}>
            <div style={{'marginLeft': '1rem'}}>
              <Form.Check 
                value={false} 
                type="radio" 
                label="Don't Show"
                onChange={handleChange}
                checked={banner_display == "false"}></Form.Check>
              <Form.Check 
                value={true} 
                type="radio" 
                label="Show"
                onChange={handleChange}
                checked={banner_display == "true"}></Form.Check>
            </div>
            <div style={styles.bannerText}><b>Review — this is what will be displayed on open.concur.com</b><br/>{bannerText}</div>
            <AuthorizationWrapper requiredAction={requiredEditBannerAction}><Button style={styles.button} type="Submit" variant="warning">Submit</Button></AuthorizationWrapper>
          </Form>
      </Card>
  );
}

export default BannerControls;
