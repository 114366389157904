import React, {useState, useEffect}        from 'react';
import { Link }     from 'react-router-dom';
import ReactTable   from 'react-table';
import * as config from '../../../config';
import { ImCheckmark } from "react-icons/im";
import {HiRocketLaunch} from 'react-icons/hi2';
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce } from 'react-table'
import { Table, Card, Row, ToggleButtonGroup, ToggleButton, InputGroup, FormControl } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { AiOutlineUnorderedList } from "react-icons/ai";
import styled from 'styled-components'
import {
  dateTimeToPSTString,
  joinObjectArrayToString,
  sortOpi,
  sortDate,
} from '../../../utils/formatFunctions'

const Styles = styled.div`
  headers{
    textAlign  :  'left',
    lineHeight :  '2rem',
  },
  img{
    height :  'auto',
    width  :  '24px',
  },
  table{
    height :  '75vh',
    border :  'none',
  },
  tableContainer{
    fontSize        :  '.94rem',
    boxSizing       :  'border-box',
    borderRadius    :  '4px',
    backgroundColor :  'rgb(250, 250, 250)',
    boxShadow'      :  'rgba(0, 0, 0, 0.2) 0px 0px 10px -2px',
  },
  tablePadding{
    borderLeft   : '1px solid #ddd',
    borderRight  : '1px solid #ddd',
    borderBottom : '1px solid #ddd',
  },
  unset{
    whiteSpace: 'unset'
  },
  prewrap {
    whiteSpace: 'pre-wrap'
  },
};`

const N_TYPE_SORTING = [
  'Personalized Concur Open', 
  'Maintenance Announcement',
  'Maintenance Update',
  'Maintenance Cancelled',
  'Maintenance Complete'
]


function RenderTable({ columns, data }) {
  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      // defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
    },
    useFilters, // useFilters!
    useGlobalFilter // useGlobalFilter!
  )

  // Define a default UI for filtering
  function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
  }) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
      setGlobalFilter(value || undefined)
      document.getElementById("search-bar").focus()
    }, 800)

    return (
      <span>
        <InputGroup>
          <InputGroup.Prepend style={{margin: 'auto .5rem'}}>Filter{' '}</InputGroup.Prepend>
          <FormControl id="search-bar" value={value || ""}
            onChange={e => {
              setValue(e.target.value);
              onChange(e.target.value);
            }}
            placeholder={`${count} records...`}
            style={{
              fontSize: '1.1rem',
              borderTop: '0',
              borderBottom: '0'
            }} />
        </InputGroup>
      </span>
    )
  }

  return (
    <>
     <Card>
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </Card>
      <Table bordered {...getTableProps()}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i) => {
                const {render, getHeaderProps} = column
                return (
                  <th key={'header' + i} {...getHeaderProps()}>{render("Header")}</th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            let styling = row && row.values.flag == 'Special' ? {backgroundColor: 'hsl(120deg 100% 50% / 17%)'} : row.values.flag == 'Emergency' ? {backgroundColor: 'hsl(33deg 100% 50% / 17%)'} : {};            
            return (

              <tr key={'row' + i} {...row.getRowProps()} style={styling}>
                {row.cells.map((cell, j) => {
                  return <td key={"row" + i + 'col' + j} {...cell.getCellProps({
                    style: {
                      minWidth: cell.column.minWidth,
                      width: cell.column.width,
                    },
                  })}>{cell.render("Cell")}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}

const MaintenanceEventTable = ({RowData, ActiveRowData, ActiveNotificationIDs}) => {
  const [row_data, set_row_data] = useState(ActiveRowData)
  const [historic, setHistoric] = useState(false);
  const { t } = useTranslation(["translation"]);
  const headerStyle = {
    textAlign  :  'left',
    lineHeight :  '2rem',
  }
  useEffect(() => {
    if (!historic){
      ActiveRowData.sort((a, b) => {
        return a.start_date > b.start_date ? 1 : -1;
      })
      ActiveRowData.forEach(data => {
        data.notifications.sort((a, b) => {
          return N_TYPE_SORTING.indexOf(a.n_type) > N_TYPE_SORTING.indexOf(b.n_type) ? 1 : -1;
        })
      })
      set_row_data(ActiveRowData);
    }
    else{
      RowData.sort((a, b) => {
        return b.start_date > a.start_date ? 1 : -1;
      })
      RowData.forEach(data => {
        data.notifications.sort((a, b) => {
          return N_TYPE_SORTING.indexOf(a.n_type) > N_TYPE_SORTING.indexOf(b.n_type) ? 1 : -1;
        })
      })
      set_row_data(RowData);
    }
  }, [historic, RowData, ActiveRowData]);

  const handleCsv = (arr) => {
    arr = arr ? arr = arr.split(',').map(function (value){ return value.trim(); }) : [];
    //make sure if the last character is a , that there's no empty array indexes
    let filteredArray = arr.filter(function (item) {
      return item != null && item != '';
    })
    return filteredArray;
  }

  const columns = React.useMemo(
    () => [
        {
          Header: <b data-cy='data-center-header'>Data Centers</b>,
          headerStyle: headerStyle,
          id: 'data_centers',
          width: 200,
          accessor: (data) => <div>{data.data_centers.map((item, i) => { return (<div style={{'display': 'flex'}} key={i}><div key={item.name + i} >{item.name} </div></div>)})}</div>,
          filter: 'includes'
        },
        {
          Header: <b data-cy='data-center-header'>Title</b>,
          headerStyle: headerStyle,
          id: 'title',
          width: 500,
          accessor: (data) => data.title,
          filter: 'includes'
        },
        {
          Header: <b data-cy='end-date-header'>Notifications</b>,
          headerStyle: headerStyle,
          id: 'notifications',
          width: 260,
          minWidth: 260,
          accessor    : (data) => <div>{
            data.notifications.map((item, i) => { 
              return (<div style={{'display': 'flex'}} key={i}>
                  <div key={item.n_type + i} style={{'display': 'flex'}}>{item.n_type} </div>
                  {item.mail_queued && <ImCheckmark style={{color: 'green', marginLeft: 'auto', fontSize: '22px', maxWidth: '10%'}}/>}
                  {(item.n_type == 'Personalized Concur Open' && ActiveNotificationIDs && ActiveNotificationIDs.includes(item.id) ) && <HiRocketLaunch style={{color: 'green', marginLeft: 'auto', fontSize: '22px', maxWidth: '10%'}}/>}
                </div>)
            })}</div>        
        },
        {
          Header: <b data-cy='start-date-header'>Start Date</b>,
          headerStyle: headerStyle,
          id: 'startDate',
          width: 150,
          accessor: (data) => dateTimeToPSTString(data.start_date),
          filter: 'includes',
          sortMethod    :  (a, b) => sortDate(a, b),
        },
        {
          Header: <b data-cy='end-date-header'>End Date</b>,
          headerStyle: headerStyle,
          id: 'endDate',
          width: 150,
          accessor: (data) => dateTimeToPSTString(data.end_date),
          filter: 'includes',
          sortMethod    :  (a, b) => sortDate(a, b),
        },
        {
          Header      : <b data-cy='service-header'>Flag</b>,
          headerStyle : headerStyle,
          id          : 'flag',
          width       : 70,
          accessor    : (data) => data.flag == 'special' ? 'Special' : data.flag == 'emergency' ? 'Emergency' : data.flag == 'penetration_testing' ? 'Penetration Testing' : data.flag == 'freeze_window' ? 'Freeze Window' : data.flag == 'monolith_deployment' ? 'Monolith Deployment' : data.flag == 'audit' ? 'Audit' : '',
        },
        {
          Header      : <b data-cy='service-header'>Jira Id</b>,
          headerStyle : headerStyle,
          id          : 'jira_id',
          width       : 70,
          accessor    : (data) => <div>{handleCsv(data.jira_id).map((item, i) => { return (<a key={i} href={`${config.REACT_APP_JIRA_URL}/browse/${item}`}>{item} </a>)})}</div>
        },
        {
          Header      : <b data-cy='service-header'>Audience</b>,
          headerStyle : headerStyle,
          id          : 'is_internal',
          width       : 70,
          accessor    : (data) => <div>{data.is_internal == 'Y' ? 'Internal' : 'External + Internal'}</div>
        },
        {
          Header: <b data-cy='data-center-header'>Details</b>,
          headerStyle: headerStyle,
          id: 'id',
          width: 50,
          accessor: (data) => <Link to={`/maintenance_event/review/${data.id}`} style={{marginLeft: '10px'}} ><AiOutlineUnorderedList style={{fontSize: "35px"}}/></Link>
        }
      ],
    []
  )
  return (
    <Styles>
      <Card>
        <ToggleButtonGroup type="radio" name="options" defaultValue={false}>
          <ToggleButton key="tgb-radio-1" id="tbg-radio-1" variant="outline-secondary" value={false} onChange={() => setHistoric(false)}>
            Current + Upcoming
          </ToggleButton>
          <ToggleButton key="tgb-radio-2" id="tbg-radio-2" variant="outline-secondary" value={true} onChange={() => setHistoric(true)}>
            All
          </ToggleButton>
        </ToggleButtonGroup>
      </Card>
     
      <RenderTable columns={columns} data={row_data} />
    </Styles>
      
  )
}

export default MaintenanceEventTable;

