import React, { Component } from 'react';
import {
  Button, ListGroup
} from 'react-bootstrap';
import 'antd/dist/antd.css';
import { Spin } from 'antd';

class CIList extends Component {

  render() {
    const { ciList, selectedServices } = this.props;
    if (!ciList || !Array.isArray(ciList) || ciList.length === 0)
      return (
        <div>
          <h6 className="heading">Selected CI's  </h6>
          <p>No CI's Selected Yet!!</p>
        </div>
      )
    let ciListView = ciList.map((ciList, index) => (
      <ListGroup.Item key={index}>{ciList.category}: {ciList.name}
        <div className="add-button float-left mr-2">
          <Button variant="primary" className={`cypress-ci-remove-${ciList.name}`} size="sm" onClick={() => this.props.handleRemoveFromList(ciList.category, ciList.name)}>-</Button>
        </div>
        {
          selectedServices.length > 0 &&
          <div className="mt-4">
            <div style={{'display': 'flex', 'width': '62%'}}> <div style={{'marginRight': 'auto'}}>Impact on global scale :</div><div className={`cypress-ci-global-impact-${ciList.name}`}> {ciList.globalScale ?? ""} {ciList.globalScale === "" && this.props.globalImpactLoading && <Spin active="true"/>}</div>       </div>
            <div style={{'display': 'flex', 'width': '62%'}}> <div style={{'marginRight': 'auto'}}>Impact on datacenter scale :</div><div className={`cypress-ci-dc-impact-${ciList.name}`}> {ciList.dataCenterScale ?? ""} {ciList.dataCenterScale === "" && this.props.dcImpactLoading && <Spin active="true"/>}</div></div>
          </div>

        }
      </ListGroup.Item>
    ));
    return (
      <div style={{'marginTop': '15px'}}>
        <div className="pb-2">
          <h6 className="heading mr-2">Selected CI's </h6>
          <Button variant="primary" size="sm" className="add-button cypress-ci-clear-list" onClick={this.props.handleClearList}>Clear List</Button>
        </div>
        <div>
          {
            selectedServices.length > 0
              ? (
                <div>
                  <h6 className="heading">Selected Services are:</h6>
                  {selectedServices.join()}
                </div>
              )
              : <div className="ml-2"style={{background:"#F5F5F5"}}> Select Services</div>
          }
        </div>
        <div>
          <ListGroup variant="flush">
            {ciListView}
          </ListGroup>
        </div>
      </div>
    )
  }
}

export default CIList;
