/* ========= PACKAGE IMPORTS ========= */
import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { Card, Row, Col } from 'react-bootstrap';

/* ========= REDUX IMPORTS ========= */
import {
  customerProptypes,
  actions as customerActions,
} from '../../ducks/customerDetails/customerDetails.index';
/* ========= COMPONENT IMPORTS ========= */
import SearchBar from './SearchBar/SearchBar';
import CustomerIncidentsTable from './CustomerIncidents/CustomerIncidentsTable';
import { CustomerInfoTile } from './CustomerInfoTile/customerInfoTile'
import CustomerCSV from '../../components/CustomerCSV/CustomerCSV'
import { Translation } from 'react-i18next';
import i18next from 'i18next';

/* ========= REDUX STATE CONNECTORS ========= */
const mapStateToProps = ({ customerDetailsState, uiState }) => ({
  customerCatalog          : customerDetailsState.customerCatalog,
  customerCatalogLoading   : customerDetailsState.customerCatalogLoading,
  selectedCompanyIncidents : customerDetailsState.selectedCompanyIncidents,
  selectedCompany          : customerDetailsState.selectedCompany,
  incidentLoading          : customerDetailsState.incidentLoading,
  error                    : customerDetailsState.error,
});
const mapDispatchToProps = (dispatch) => {
  const combinedActionCreators = {
    searchCustomerCatalog       : customerActions.searchCustomerCatalog,
    requestCompanyIncidentsById : customerActions.requestCompanyIncidentsById,
    setSelectedCompany          : customerActions.setSelectedCompany,
  }
  return bindActionCreators(combinedActionCreators, dispatch);
};
const styles = {
  internalOnly: {
    fontSize : '1rem', color : 'red'
  },
  searchBar: {
    padding : '.5rem',
  },
  mainCard: {
    height : '72vh',
  },
  alert: {
    display        : 'flex',
    justifyContent : 'center',
    alignItems     : 'center',
  },
  container: {
    width: '90%',
    margin: '0 auto'
  }
}

const formatForCustomerPage = (incidents) => {
  let language = (i18next.language && !i18next.language.toLocaleLowerCase().includes('en')) ? i18next.language : 'en';
  return incidents.map((incident) => {
    // Grab the IRCA and RCA messages. In case somehow there are multiple IRCAs or multiple RCAs posted to an incident, this will also concatenate them with pipe.
    incident.irca = incident.messages.filter((msg) => msg.message_type.toLowerCase() === 'intermediate root cause analysis').map((msg) => msg.body[language]).join(" | ");
    incident.rca = incident.messages.filter((msg) => msg.message_type.toLowerCase() === 'root cause analysis').map((msg) => msg.body[language]).join(" | ");
    return incident
  });
};

const AlertCard = ({error}) => {
  let message = error
                ? <Translation>{(t) => t('containers.customerDetails.error')}</Translation>
                : <Translation>{(t) => t('containers.customerDetails.empty')}</Translation>
  let level   = error ? 'danger' : 'primary'
  return (
    <Col className='mb-4 mx-0 px-0'>
      <Card className='' style={{ ...styles.mainCard, ...styles.alert }} className='card-shadow'>
        <div className={`alert alert-${level} mb-0`} role='alert'>
          <p className='mb-0 text-center'><b>{message}</b></p>
        </div>
      </Card>
    </Col>
  )
}

class Customers extends Component {
  static propTypes = {
    requestCompanyIncidentsById: customerProptypes.requestCompanyIncidentsById,
  };

  render = () => {
    const { error, customerCatalogLoading, customerCatalog, requestCompanyIncidentsById, searchCustomerCatalog, selectedCompany, selectedCompanyIncidents, incidentLoading } = this.props;
    const selectedCompanyIncidentsFormatted = formatForCustomerPage(selectedCompanyIncidents);
    return (
      <div style={styles.container}>
        <Row className='mx-0 px-0'>
          <Col className='mx-0 px-0'>
            <div className='mb-2' style={styles.internalOnly}><Translation>{(t) => t('containers.customerDetails.internal')}</Translation></div>
          </Col>
        </Row>
        <Row className='mx-0 px-0'>
          <Col className='mb-4 mx-0 px-0'>
            <Card style={{ ...styles.searchBar }} className='card-shadow'>
              <SearchBar
                error                       = {error}
                searchCustomerCatalog       = {searchCustomerCatalog}
                customerCatalog             = {customerCatalog}
                customerCatalogLoading      = {customerCatalogLoading}
                requestCompanyIncidentsById = {requestCompanyIncidentsById}
              />
              <CustomerCSV
                companyIncidents = {selectedCompanyIncidentsFormatted}
                companyName = {`${selectedCompany ? selectedCompany["name"] : null}`}
              />
            </Card>
          </Col>
        </Row>
        <Row className='mb-4 mx-0 px-0'>
          {
            selectedCompany && !error
            // IF there is a selected company in the redux store AND there are no errors, display that companies data
            ? <>
                <Col className='mb-4 pl-0' sm={12} md={3} lg={3}>
                  <Card className='card-shadow customer-info-tile-card' style={{...styles.mainCard }}>
                    <CustomerInfoTile
                      selectedCompany          = {selectedCompany}
                      selectedCompanyIncidents = {selectedCompanyIncidentsFormatted}
                    />
                  </Card>
                </Col>
                <Col className='mb-4 mr-0 px-0'sm={12} md={9} lg={9}>
                <Card className='card-shadow customer-incident-table-card' style={{ ...styles.mainCard }}>
                  <CustomerIncidentsTable
                    incidentLoading = {incidentLoading}
                    incidents       = {selectedCompanyIncidentsFormatted}
                    selectedCompany = {selectedCompany}
                  />
                </Card>
                </Col>
              </>
            // ELSE, display a card saying please search and select a company
            : <AlertCard error={error} />
          }
        </Row>
      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Customers);

