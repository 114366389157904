/* ========= PACKAGE IMPORTS ========= */
import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { Row, Card, ListGroup, ListGroupItem, Button } from 'react-bootstrap';
import { Link }     from 'react-router-dom';
import NotificationTable from './NotificationTable';
import { Translation } from 'react-i18next';
import {
  dateTimeToPSTString,
} from '../../../utils/formatFunctions'
/* ========= REDUX IMPORTS ========= */
import { actions as uiActions }    from '../../../ducks/ui/ui.index';
import {
  actions as V2MaintenanceEventActions,
  selectors as V2MaintenanceEventSelectors,
} from '../../../ducks/v2MaintenanceEvent/v2MaintenanceEvent.index';
import UpdateMaintenanceEventModal from '../MaintenanceEventModals/UpdateMaintenanceEventModal';
import DeleteMaintenanceEventModal from '../MaintenanceEventModals/DeleteMaintenanceEventModal';
/* ========= COMPONENT IMPORTS ========= */
import Loading            from '../../../components/loading';
import AuthorizationWrapper from '../../../components/authorizationWrapper';
import { string } from 'prop-types';
import CreateNotificationModal from '../NotificationModals/CreateNotificationModal';

/* ========= REDUX STATE CONNECTORS ========= */
const mapStateToProps = ({uiState, v2MaintenanceEventState}, props) => {
  let maintenanceEventId;
  maintenanceEventId = props.match.params['id'];
  return {
    maintenanceEventId          :  maintenanceEventId,
    pageLoading                 :  uiState.pageLoading,
    maintenance_events          :  v2MaintenanceEventState.maintenance_events,
    active_notifications        :  v2MaintenanceEventState.active_notifications,
    maintenance_event_details   :  V2MaintenanceEventSelectors.selectMaintenanceEventDetails(v2MaintenanceEventState, maintenanceEventId),
    all_data_centers            :  v2MaintenanceEventState.all_data_centers,
    maintenance_event_state     :  v2MaintenanceEventState
  };
};
const editAction = 'edit_maintenance_event';
const deleteAction = 'delete_maintenance_event';
const requiredTemplateAction = 'edit_template';

const mapDispatchToProps = (dispatch) => {
  const combinedActionCreators = {
    setPageLoading                : uiActions.setPageLoading,
    V2RequestMaintenanceEvents    : V2MaintenanceEventActions.V2RequestMaintenanceEvents,
    V2RequestActiveNotifications  : V2MaintenanceEventActions.V2RequestActiveNotifications,
    V2SetMaintenanceEventDetails  : V2MaintenanceEventActions.V2SetMaintenanceEventDetails,
    V2RequestAllDataCenters       : V2MaintenanceEventActions.V2RequestAllDataCenters,
    V2SubmitDeleteMaintenanceEvent: V2MaintenanceEventActions.V2SubmitDeleteMaintenanceEvent,
  };
  return bindActionCreators(combinedActionCreators, dispatch);
};

const styles = {
  'topContainer': {
    'margin'   :  'auto',
    'maxWidth' :  'max-content',
  },
  'pageTitle': {
    'marginBottom' :  '20px',
    'width'        :  '73vw',
    'textAlign'   :  'center',
    'margin'      : '0 auto'
  },
  'button': {
    'float'     : 'none',
    'margin'    : '0 auto',
    'marginLeft': '0 auto',
    'marginTop' : '20px',
  },
  'half': {
    'width': '50vw',
    'borderTopWidth': '0'
  },
  'card': {
    'margin': '1vh auto'
  }
};

class ReviewMaintenanceEvent extends Component {

  componentDidMount = () => {
    this.props.V2SetMaintenanceEventDetails(this.props.maintenance_event_details);  
    this.props.V2RequestActiveNotifications();
  }

  render = () => {
    const { topContainer, pageTitle } = styles;
    const { pageLoading, maintenance_events, maintenance_event_details, maintenanceEventId, submitDeleteMaintenanceEvent, active_notifications } = this.props; 
    let activeNotificationIDs = [];
    active_notifications.forEach((item) => {
      activeNotificationIDs.push(item.id)
    })
    const handleCsv = (arr) => {
      if (!arr){
        return [];
      }
      arr = arr.split(',').map(function (value){ return value.trim(); });
      //make sure if the last character is a , that there's no empty array indexes
      let filteredArray = arr.filter(function (item) {
        return item != null && item != '';
      })
      return filteredArray;
    }

    let string_dcs = ''
    let split_jira_id = maintenance_event_details ? handleCsv(maintenance_event_details.jira_id) : [];
    if (maintenance_event_details && maintenance_event_details.data_centers){
      maintenance_event_details.data_centers.forEach((dc) => {
        string_dcs += dc.name + ', ';
      })
      string_dcs = string_dcs.slice(0, -2);
    }
    
    return (
      <div style={topContainer}>
        {/* HEADER */}
        <Row>
          <h1 style={pageTitle}>Maintenance Event Details</h1>
          <h6 style={{margin: 'auto'}}>All Times In PST</h6>
        </Row>
        { /* CONTENT */
        
        pageLoading
        ? <Loading height='75vh' />
        : <> 
            <div>
              {maintenance_event_details && <Card style={styles.card}>
                <Card.Header as="h5">{maintenance_event_details.title}
                <div style={{float: 'right', display: 'flex'}}>
                  <AuthorizationWrapper requiredAction={editAction}>
                    <div><UpdateMaintenanceEventModal maintenance_event_details={this.props.maintenance_event_details}></UpdateMaintenanceEventModal></div>
                  </AuthorizationWrapper>
                  <AuthorizationWrapper requiredAction={deleteAction}>
                  <div><DeleteMaintenanceEventModal submitDeleteMaintenanceEvent={submitDeleteMaintenanceEvent} maintenance_event_details={this.props.maintenance_event_details}></DeleteMaintenanceEventModal></div>
                  </AuthorizationWrapper>
                </div>
                </Card.Header>

                  <ListGroup horizontal>
                      <ListGroup.Item style={styles.half}> <b>ID: </b>{maintenance_event_details.id}</ListGroup.Item>
                      <ListGroup.Item style={styles.half}> <b>Audience: </b>{maintenance_event_details.is_internal == 'Y' ? 'Internal' : 'External + Internal'}</ListGroup.Item>
                      <ListGroup.Item style={styles.half}> <b>Flag: </b>{maintenance_event_details.flag == 'special' ? 'Special' : maintenance_event_details.flag == 'emergency' ? 'Emergency' : maintenance_event_details.flag == 'penetration_testing' ? 'Penetration Testing' : maintenance_event_details.flag == 'freeze_window' ? 'Freeze Window' : maintenance_event_details.flag == 'monolith_deployment' ? 'Monolith Deployment' : maintenance_event_details.flag == 'audit' ? 'Audit' : ''}</ListGroup.Item>
                  </ListGroup>
                  <ListGroup horizontal>
                    <ListGroup.Item style={styles.half}> <b>Start Date: </b>{dateTimeToPSTString(maintenance_event_details.start_date)}</ListGroup.Item>
                    <ListGroup.Item style={styles.half}> <b>End Date: </b>{dateTimeToPSTString(maintenance_event_details.end_date)}</ListGroup.Item>
                  </ListGroup>
                  <ListGroup horizontal>      
                    <ListGroup.Item style={styles.half}> <b>Created By: </b>{maintenance_event_details.created_by}</ListGroup.Item>
                    <ListGroup.Item style={styles.half}> <b>Created Date: </b>{dateTimeToPSTString(maintenance_event_details.created_date)}</ListGroup.Item>
                  </ListGroup>
                  <ListGroup horizontal>
                    <ListGroup.Item style={{width: '100%'}} ><b>Data Centers: </b>{string_dcs}</ListGroup.Item>
                   
                  </ListGroup>
                  <ListGroup horizontal style={{width: '100%'}}>
                  <ListGroup.Item><b>Jira ID: </b></ListGroup.Item>
                   {split_jira_id.map((item) => {
                      if (item){
                        return (
                        <ListGroup.Item key={item} style={{width: 100 /split_jira_id.length + '%'}}>{item}</ListGroup.Item>
                        )
                      }
                    })}
                  </ListGroup>

              </Card>
            }
            </div>
            <Row>
              <h4 >Notifications</h4>
                <AuthorizationWrapper requiredAction={requiredTemplateAction}>
                  <div style={{'height': '2.5rem', 'marginTop': 'auto', 'float': 'right', 'marginLeft': 'auto', 'marginRight': '1vw'}}>
                    <Button variant="info" ><Link to={`/template_management/notification`} style={{"color": "white"}}>Manage Templates</Link></Button>
                  </div>
                </AuthorizationWrapper>
                {maintenance_event_details && <CreateNotificationModal mwin_id={maintenance_event_details.id} mwin_start_date={maintenance_event_details.start_date} mwin_end_date={maintenance_event_details.end_date} ></CreateNotificationModal>}
            </Row>
      
              {maintenance_event_details && maintenance_event_details.notifications && maintenance_event_details.notifications.length > 0 && 
               <NotificationTable RowData={maintenance_event_details.notifications} ActiveNotificationIDs={activeNotificationIDs}/>}
          </>        
        }
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewMaintenanceEvent);
