import http from "../../services/httpService";

export const getDataCenters = async () => {
  const result = await http.get(`/datacenter`);
  return result;
};

export const getCisByCiCategory = async (dc, ci_category) => {
  const result = await http.get(`/get_all_ci_by_ci_category?dc=${dc}&ci_category=${ci_category}`);
  return result;
}

export const getServicesByDataCenter = async (dc) => {
  const result = await http.get(`/get_services_by_datacenter?dc=${dc}`);
  return result;
};

export const getCiCategoriesByDataCenter = async (dc) => {
  const result = await http.get(`/get_ci_categories_by_datacenter?dc=${dc}`);
  return result;
};

export const getImpact = async (payload) => await http.post('/api/affectedcompanies', payload);

export const getSearchResults = async (dc, search_query) => {
  const result = await http.get(`/get_search_results?dc=${dc}&search_query=${search_query}`);
  return result;
}
