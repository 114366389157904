import axios from 'axios';
import * as config from '../config';

axios.defaults.baseURL = config.REACT_APP_API_URL;
if(config.REACT_APP_AUTH_EMAIL_ADDRESS) {
  // This means you're in local development. Setting this to false avoids "cross-domain" request issues stemming from localhost port differences.
  axios.defaults.withCredentials = false;
} else {
  // This is the normal workflow, where the cookie is expected to be passed with each API request.
  axios.defaults.withCredentials = true;
}

const instance = axios.create();
instance.interceptors.response.use(null, (error) => {
  const expectedError = error.response
    && error.response.status >= 400
    && error.response.status < 500;
  if (!expectedError) {
    console.log(error);
  }
  return Promise.reject(error);
});

function setJwt(jwt) {
  // instance.defaults.headers.common['x-auth-token'] = jwt;
}

export default {
  get    : instance.get,
  post   : instance.post,
  put    : instance.put,
  delete : instance.delete,
  setJwt,
};
