import React, { useState, useEffect } from 'react';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions as uiActions }    from '../../ducks/ui/ui.index';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Loading from '../../components/loading';
import subscriptionsImage       from "../../assets/subscriptions-image.png";
import {
  actions as subscriptionActions,
} from '../../ducks/subscription/subscription.index';
const mapStateToProps = ({uiState, subscriptionState}) => ({
  pageLoading         :  uiState.pageLoading,
  userExists          : subscriptionState.userExists,
});
const mapDispatchToProps =  (dispatch) => {
  const combinedActionCreators = {
    setPageLoading              :  uiActions.setPageLoading,
    requestUserExists           :  subscriptionActions.requestUserExists,
    
  };

  return bindActionCreators(combinedActionCreators, dispatch);
};
let searchSubmitted = false;

const styles = {
  center : {
    display: 'flex',
    maxWidth: '50rem', 
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  error: {
    color: 'red',
    display: 'flex',
    maxWidth: '50rem',
    marginLeft: 'auto',
    marginRight: 'auto', 
    paddingLeft: '12px'
  },
  button: {
    minWidth: '8rem'
  },
  title: {
    textAlign: 'center', 
    color: '#f0ab00'
  }
}

const SearchBar = ({requestUserExists, userExists, pageLoading}) => {
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (userExists && !pageLoading && email){
      //encode to hex as . characters flagged by nginx for file encoding urls. Also good to encode emails in urls in general.
      let test = email.split("").map(c => c.charCodeAt(0).toString(16).padStart(2, "0")).join("");
      let url = window.location.origin + '/comm_history/u/' + test;
      window.location.href = url;
    }
  }, [userExists, pageLoading])

  const onSubmit = (e) => {
    requestUserExists(email);
    searchSubmitted = true;
    e.preventDefault();
    return false;
  }

  const search = (e) => {
    requestUserExists(email);
    searchSubmitted = true;
  }
  return (
    <div>
      <Form onSubmit={e => onSubmit(e)}>
        <div style={styles.center}>
          <Form.Control type="email" placeholder="name@example.com" value={email} onChange={e => setEmail(e.target.value)} />
          <Button  style={styles.button} type="button" onClick={e => search(e)}>Submit</Button>
        </div>
        {searchSubmitted && pageLoading && <Loading/>}
        {!userExists && searchSubmitted && !pageLoading && <div style={styles.error}>User Does Not Exist</div>}
      </Form>
      
    </div>
  );
}

const SubscriberSearch = ({requestUserExists, userExists, pageLoading}) => {
  return (
    <div>
      <img
        className =  "img-fluid mt-3 sap-sub-image mx-auto d-block"
        alt       =  "sap illustration of cityscape. Humans interact with communication devices"
        src       =  {subscriptionsImage} />
      <h1 style={styles.title}>Subscriber Search</h1>
      <SearchBar requestUserExists={requestUserExists} userExists={userExists} pageLoading={pageLoading}/>
    </div>
  );
}
export default connect(mapStateToProps,mapDispatchToProps)(SubscriberSearch);