import { takeEvery }      from 'redux-saga/effects';
import { toast }          from 'react-toastify';
import { types, actions } from './toast.index';


function* createToast(action) {
  try {
    const {message, toastId} = action.payload;
    const options = {
      ...action.payload.options,
      onClose: actions.deleteToast,
      toastId
    };
    yield toast(message, options);
  }
  catch (e) {
    console.log('create toast error: ', e);
  }
}


function* updateToast(action) {
  const { message, toastId} = action.payload;
  const options = {
    ...action.payload.options,
    render: message
  };
  yield toast.update(toastId, options);
}


// watcher saga
export default function toastSagas() {
  return [
    takeEvery(types.CREATE, createToast),
    takeEvery(types.UPDATE, updateToast),
  ];
}
