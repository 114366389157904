import React, {useState, useRef} from 'react'
import {
  Row,
  Col,
  Dropdown,
  Form,
  Overlay,
  Tooltip,
} from 'react-bootstrap'
import 'font-awesome/css/font-awesome.min.css'

import EditImpactSettingsModal from './EditImpactSettingsModal'



const SaveImpactSettings = ({
  impactSettings,
  updateCustomerImpactFilter,
  setSelectedCustomerImpactFilter,
  generateAndAddCustomerImpactSetting,
  setCustomerImpactSettings,
  putCustomerImpactSettings,
}) => {

  // modal variables
  const [showModal, setShowModal]          =  useState(false);
  const [userManagedSettings, setSettings] =  useState(impactSettings);

  const handleShowModal     =  (bool) => setShowModal(bool);
  const handleRemoveSetting =  (index) => {
    const updatedSettings = userManagedSettings.filter ((_, i) => i !== index);
    setSettings(updatedSettings);
  };
  const handleCancelSettingUpdate = () => {
    setSettings(impactSettings);
    handleShowModal(false);
  };
  const handleSaveSettings = () => {
    setCustomerImpactSettings(userManagedSettings);
    putCustomerImpactSettings(userManagedSettings);
    handleShowModal(false);
  }

  // handles saveAs user created input
  const [formClass, setFormClass] = useState('');
  const [settingName, setSettingNameInput] = useState('');
  const target = useRef(null);
  const [showValidationMessage, setValidationMessage] = useState(false);
  const handleCreateNewClick = () => {
    if (settingName === '') {
      // Name missing
      setFormClass('is-invalid');
      setValidationMessage(true);
      return;
    }
    else if (formClass !== '') {
      setFormClass('');
      setValidationMessage(false);
    }

    generateAndAddCustomerImpactSetting(settingName);
    setSettingNameInput('');
  }

  return (
    <div>
      <Row className="mt-2 mb-2 container-fluid align-items-center p-0">

        {/* user-input & save-as */}
        {/* <Form.Group>
          <Form.Label className="ml-2"> Save the current filter </Form.Label>
        </Form.Group> */}
        <Col>
        <Form.Group>
          <Form.Control
            ref         =  {target}
            className   =  {formClass}
            type        =  "text"
            placeholder =  "Name your filter"
            value       =  {settingName}
            onChange    =  { (e) => setSettingNameInput(e.target.value)} />
          <Overlay
            target    =  {target.current}
            show      =  {showValidationMessage}
            placement =  "left">
            {(props) => ( <Tooltip {...props}> Your filter requires a name </Tooltip> )}
          </Overlay>
        </Form.Group>
        </Col>
      <Col>
        <Form.Group>
          <Dropdown className="d-inline-block">
            <Dropdown.Toggle variant="secondary" id="dropdown-basic" size="sm"> Save Filter As </Dropdown.Toggle>
            <Dropdown.Menu>
              { impactSettings.map( (setting) => (
                  <Dropdown.Item key={`saveAs-${setting.nickname}`} onClick={() => updateCustomerImpactFilter(setting.id)}>
                    {setting.nickname}
                  </Dropdown.Item>
                ))
              }
              <Dropdown.Divider />
              <Dropdown.Item><button className="btn btn-sm" onClick={handleCreateNewClick}> Create New </button></Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Form.Group>
        </Col>
        {/* load/edit previous settings */}
        <Col>
        <Form.Group  className="float-right">
          <Dropdown>
            <Dropdown.Toggle variant="secondary" id="dropdown-basic" size="sm">Saved Filters</Dropdown.Toggle>
            <Dropdown.Menu>
              {impactSettings.map( (setting) => (
                <Dropdown.Item
                  key     =  {`loadSaved-${setting.nickname}`}
                  onClick =  { () => setSelectedCustomerImpactFilter(setting) }>
                  {setting.nickname}
                </Dropdown.Item> ))
              }
              <Dropdown.Divider />
              <Dropdown.Item onClick={() => handleShowModal(true)}>Manage Saved Settings</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Form.Group>
        </Col>
      </Row>

      <EditImpactSettingsModal
        // component values
        showModal                 =  {showModal}
        handleShowModal           =  {handleShowModal}
        handleRemoveSetting       =  {handleRemoveSetting}
        handleCancelSettingUpdate =  {handleCancelSettingUpdate}
        handleSaveSettings        =  {handleSaveSettings}

        // redux values
        setCustomerImpactSettings =  {setCustomerImpactSettings}
        userManagedSettings       =  {userManagedSettings} />
    </div>
  );
}




export default SaveImpactSettings;
