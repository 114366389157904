/* ========= PACKAGE IMPORTS ========= */
import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { Button, ButtonGroup, Card, Row, ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { Translation } from 'react-i18next';

/* ========= REDUX IMPORTS ========= */
import { actions as uiActions }    from '../../ducks/ui/ui.index';
/* ========= COMPONENT IMPORTS ========= */
import Loading            from '../../components/loading';
import MaintenanceEventTable        from './MaintenanceEventTable/MaintenanceEventTable';
import AuthorizationWrapper from '../../components/authorizationWrapper';
import CreateMaintenanceEventModal from './MaintenanceEventModals/CreateMaintenanceEventModal';


/* ========= REDUX STATE CONNECTORS ========= */
const mapStateToProps = ({uiState, maintenanceEventState}) => ({
    pageLoading                 :  uiState.pageLoading,
    maintenance_events          :  maintenanceEventState.maintenance_events,
    active_maintenance_events   :  maintenanceEventState.active_maintenance_events,
    all_data_centers            :  maintenanceEventState.all_data_centers,
});

const mapDispatchToProps = (dispatch) => {
  const combinedActionCreators = {
    setPageLoading            :  uiActions.setPageLoading,
  };
  return bindActionCreators(combinedActionCreators, dispatch);
};

const styles = {
  'topContainer': {
    'margin'   :  'auto',
    'maxWidth' :  'max-content',
  },
  'pageTitle': {
    'marginBottom' :  '20px',
    'width'        :  '73vw',
    'textAlign'    :  'center',
    'margin'       :  '0 auto'
  },
};

const createAction = 'create_maintenance_window';

class MaintenanceEvent extends Component {
  componentDidMount = () => {
  }

  render = () => {
    const { topContainer, pageTitle } = styles;
    const { pageLoading, maintenance_events, active_maintenance_events } = this.props;
    

    return (
      <div style={topContainer}>
        {/* HEADER */}
        <div style={{display: '-webkit-box'}}>
          <h1 style={pageTitle}><Translation>{(t) => t('containers.maintWindows.title')}</Translation></h1>
          <h6 style={{margin: '1rem'}}>All Times In PT</h6>
          <AuthorizationWrapper requiredAction={createAction}>
            <div style={{margin: "auto 0"}}>
              <CreateMaintenanceEventModal></CreateMaintenanceEventModal>
            </div>
          </AuthorizationWrapper>
        </div>
        { /* CONTENT */
          pageLoading
          ? <Loading height="75vh" />
          : 
          <MaintenanceEventTable RowData={maintenance_events} ActiveRowData={active_maintenance_events}/>
            
        }

      </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceEvent);

