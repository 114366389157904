import { func, string, shape, bool, oneOfType, oneOf } from 'prop-types';

/* ===== STATE_DEFINITION ===== */
export const initialState = {
  users   : [],
  roles   : [],
  actions : [],
};


/* ===== PROP_TYPES ===== */
export const administrationProptypes = {
  getEntitiesByType : func,
  setEntitiesByType : func,
  createEntity : func,
  editEntity: func,
  deleteEntity: func
}


/* ===== TYPES ===== */
export const types = {
  GET_ENTITIES_BY_TYPE : '[administration] GET_ENTITIES_BY_TYPE',
  SET_ENTITIES_BY_TYPE : '[administration] SET_ENTITIES_BY_TYPE',
  CREATE_ENTITY        : '[administration] CREATE_ENTITY',
  EDIT_ENTITY          : '[administration] EDIT_ENTITY',
  DELETE_ENTITY        : '[administration] DELETE_ENTITY',
};


/* ===== ACTION_CREATORS ===== */
export const actions = {
  getEntitiesByType : (entityType)       => ({ type: types.GET_ENTITIES_BY_TYPE, payload: entityType }),
  setEntitiesByType : (entityType, data) => ({ type: types.SET_ENTITIES_BY_TYPE, payload: { entityType, data }}),
  createEntity      : (entityType, data) => ({ type: types.CREATE_ENTITY, payload: { entityType, data }}),
  editEntity        : (entityType, data) => ({ type: types.EDIT_ENTITY, payload: { entityType, data }}),
  deleteEntity      : (entityType, data) => ({ type: types.DELETE_ENTITY, payload: { entityType, data }}),
}


/* ===== SELECTORS ===== */
export const selectors =  {

};


/* ===== REDUCER ===== */
export default function reducer(state=initialState, action) {
  switch (action.type) {
    case types.SET_ENTITIES_BY_TYPE: {
      const { entityType, data } = action.payload
      return {...state, [entityType] :  data[entityType], };
    }
    default :  { return state; }
  }
}
