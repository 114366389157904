import React  from 'react';

import Loading      from '../../../components/loading';
import CardDetails  from '../CardDetails/CardDetails';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';

const IncidentManagement = ({info}) => {
  const infoKeys        =  Object.keys(info);
  const { t } = useTranslation(["translation"]);
  const errorMessage    =  <div className="card-body"> <p>{t('containers.detailsIncidentManagement.error')}</p> </div>;
  const awaitingContent =  infoKeys.length ===  0;


  if(!awaitingContent) {
    // UI transformation logic for "link-type" values
    // @TODO this type of logic should be done at a centralized pre-rendering phase so that individual components don't each have to define it
    for(let key in info) {
      if(info[key]["displayType"] === "link") {
        info[key]["value"] = <a target="_blank" rel="noopener noreferrer" href={`${info[key]["linkHref"]}`}>{`${info[key]["linkDisplayValue"]}`}</a>;
      }
    }
  }

  return (
    <div className="card-body pt-1">
      { info.error && errorMessage }
      { awaitingContent && <><Skeleton count={2} height={30}/> </>}
      {
        !info.error
          && !awaitingContent
          && infoKeys.map( (field) => <CardDetails key={field} property={info[field].displayName} data={info[field].value} colWidth={{key: 3, value: null}}/> )
      }
    </div>
  );
}

export default IncidentManagement;
