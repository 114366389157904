import React, { Component }   from 'react';
import { Button, Card, CardColumns } from 'react-bootstrap';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';
import AuthorizationWrapper from '../../components/authorizationWrapper';
import NavBar from './NavBar/NavBar';
import ServiceListButtons from './ServiceListButtons/ServiceListButtons';
import CICategories from './CICategories/CICategories';
import CI from './CI/CI';
import CIList from './CIList/CIList';
import CopyToClipboard from './CopyToClipboard/CopyToClipboard';
import './Dora.css';
import { actions as doraActions } from '../../ducks/dora/dora.index'
const mapStateToProps = ({userState, doraState}, props) => {
  let data_center;
  data_center = props.match.params['data_center'];
  return {
    doraState           : doraState,
    userState           : userState,
    data_center         : data_center
  }
};
const mapDispatchToProps =  (dispatch) => {
  const combinedActionCreators = {
    requestAllDataCenters : doraActions.requestAllDataCenters,
    requestCisByCategory : doraActions.requestCisByCategory,
    requestServicesByDc : doraActions.requestServicesByDc,
    requestCiCategoriesByDc : doraActions.requestCiCategoriesByDc,
    requestImpactGlobal : doraActions.requestImpactGlobal,
    requestImpactDataCenter : doraActions.requestImpactDataCenter,
    requestSearchResults : doraActions.requestSearchResults,
    setSearchBarInput : doraActions.setSearchBarInput,
    setSelectedServices : doraActions.setSelectedServices,
    setServices : doraActions.setServices,
    setCiList : doraActions.setCiList,
    setCiCategories : doraActions.setCiCategories,
    setDataCenterName : doraActions.setDataCenterName,
    setCi : doraActions.setCi,
    setCiLoading: doraActions.setCiLoading,
    setShowEasterEgg: doraActions.setShowEasterEgg,
  };

  return bindActionCreators(combinedActionCreators, dispatch);
};



const viewDoraPageAction = "view_dora";

class Dora extends Component {
  static propTypes = {
  };
  

  componentDidMount = () => {
    this.props.setCiList([]);
    this.props.requestAllDataCenters();
    if (this.props.data_center){
      this.props.setDataCenterName(this.props.data_center);
      this.props.requestServicesByDc(this.props.data_center);
      this.props.requestCiCategoriesByDc(this.props.data_center);
    }
    else{
      this.props.requestServicesByDc('US2');
      this.props.requestCiCategoriesByDc('US2');
    }
    this.urlParseData(window.location.search);
  }
  

  urlParseData(url) {
    let urlParams = new URLSearchParams(url)
    if (urlParams.getAll('selectedCiList')){
      const list = urlParams.getAll('selectedCiList');
      let newCiStateList = [];
      list.forEach(item => {
        newCiStateList.push(JSON.parse(item))
      });
      this.props.setCiList(newCiStateList);
    }
    if (urlParams.get('searchInput')){
      this.props.setSearchBarInput(urlParams.get('searchInput'));
    }
    if (urlParams.getAll('selectedServices')){
      let selectedServices = urlParams.getAll('selectedServices');
      // selectedServices is fetched from URL
      // initially it's array with one element having the list as string, so it's converted to array of strings
      if (selectedServices.length > 0) {
        selectedServices = selectedServices[0].split(',')
      }
      this.props.setSelectedServices(selectedServices);
    }
  }


  handleSearchBarInput = (value) => {
    let urlParams = new URLSearchParams(window.location.search);
    let searchBarInput = value;
    this.props.setSearchBarInput(searchBarInput);
    if (searchBarInput !== "") {
      this.props.requestSearchResults(this.props.doraState.dataCenterName, searchBarInput);
      urlParams.set('searchInput', searchBarInput);
      this.props.history.push(`${this.props.doraState.dataCenterName || 'US2'}?${urlParams}`)
    } else {
      this.props.requestCiCategoriesByDc(this.props.doraState.dataCenterName);
      urlParams.delete('searchInput');
      this.props.history.push(`${this.props.doraState.dataCenterName || 'US2'}?${urlParams}`)
    }
  }

  handleServicesList = (e) => {
    let urlParams = new URLSearchParams(window.location.search);
    
    const selectedServices  = this.props.doraState.selectedServices;
    if (selectedServices.includes(e.target.id)){
      selectedServices.splice(selectedServices.indexOf(e.target.id), 1);
    }
    else{
      selectedServices.push(e.target.id)
    }
    if (selectedServices.length > 0) {
      urlParams.append('selectedServices', selectedServices.toString());
      urlParams.set('selectedServices', selectedServices.toString());
      this.props.setSelectedServices(selectedServices);
      if (this.props.doraState.ciList.length < 10){
        //If you hit add all and have a huge ci list, this will spam scryer. This limits the possible api calls.
        this.props.doraState.ciList.forEach(ci => {
          this.props.requestImpactGlobal(selectedServices, [ci.name], this.props.doraState.dataCenterList, this.props.doraState.ciCategories, this.handleImpactHistory);
          this.props.requestImpactDataCenter(selectedServices, [ci.name], this.props.doraState.dataCenterName, this.props.doraState.ciCategories, this.handleImpactHistory);
        })
      }
    } 
    else {
      urlParams.delete('selectedServices')
    }
    this.props.history.push(`/dora/${this.props.doraState.dataCenterName || 'US2'}?${urlParams}`)
  }

  handleCICategoryButton = async (e) => {
    let urlParams = new URLSearchParams(window.location.search);
    await this.props.setCiCategories(e.target.value ? [e.target.value] : []);
    this.props.setCi([]);
    if (this.props.doraState.ciCategories.length > 0) {
      await this.props.requestCisByCategory(this.props.doraState.dataCenterName, this.props.doraState.ciCategories)
      urlParams.set('searchInput', this.props.doraState.ciCategories);
      this.props.history.push(`${this.props.doraState.dataCenterName || 'US2'}?${urlParams}`)
    } 
    else {
      this.props.requestCiCategoriesByDc('US2');
      this.props.setSearchBarInput("");
      this.props.setCi([]);
      urlParams.delete('searchInput');
      this.props.history.push(`${this.props.doraState.dataCenterName || 'US2'}?${urlParams}`)
    }
  }


  handleAddAllToList = (e) => {
    let urlParams = new URLSearchParams(window.location.search);
    urlParams.delete('selectedCiList')
    let ciList = this.props.doraState.ciList;
    this.props.doraState.ci.forEach((ci) => {
      ciList.push({'category': this.props.doraState.ciCategories, 'name': ci, 'globalScale': '', 'dataCenterScale': ''})
    })
    this.props.setCiList(ciList);
    urlParams.delete('selectedCiList')
    this.props.doraState.ciList.forEach((item) => {
      urlParams.append('selectedCiList', JSON.stringify( item ));
    })
    this.props.history.push(`${this.props.doraState.dataCenterName || 'US2'}?${urlParams}`)
  }

  handleImpactHistory = () => {
    let urlParams = new URLSearchParams(window.location.search);
    urlParams.delete('selectedCiList')
    this.props.doraState.ciList.forEach((item) => {
      urlParams.append('selectedCiList', JSON.stringify( item ));
    })
    this.props.history.push(`${this.props.doraState.dataCenterName || 'US2'}?${urlParams}`)
  }


  handleAddToList = (ci, index) => {
    // adds a CI to the CI List
    // sets the state of the selected CI's add button to disabled i.e update disabledAddToListButton
    // updates the URL with the search input, selected CI's and disabledAddToListButtons
    let ciList = this.props.doraState.ciList;
    ciList.push({'category': this.props.doraState.ciCategories, 'name': ci, 'globalScale': '', 'dataCenterScale': ''})
    this.props.setCiList(ciList);
    if (this.props.doraState.selectedServices.length > 0){
      this.props.requestImpactGlobal(this.props.doraState.selectedServices, [ci], this.props.doraState.dataCenterList, this.props.doraState.ciCategories, this.handleImpactHistory);
      this.props.requestImpactDataCenter(this.props.doraState.selectedServices, [ci], this.props.doraState.dataCenterName, this.props.doraState.ciCategories, this.handleImpactHistory);
    } 
  }

  handleRemoveFromList = (ciCategory, name) => { 
    let ciList = this.props.doraState.ciList;
    for (let i = 0; i < ciList.length; i++){
      if (ciList[i].name == name && ciList[i].category == ciCategory){
        ciList.splice(i, 1);
      }
    }
    this.props.setCiList(ciList);
    this.handleImpactHistory();
  }

  handleClearList = () => {
    this.props.setCiList([]);
    this.props.setCiLoading(true);
    let urlParams = new URLSearchParams(window.location.search);
    urlParams.delete('selectedCiList')
    this.props.history.push(`${this.props.doraState.dataCenterName || 'US2'}?${urlParams}`)
  }

  handleEasterEgg = () => {
    this.props.setShowEasterEgg(!this.props.doraState.showEasterEgg)
  }

  handleChangeDc = (dc) => {
    this.props.history.push(dc)
    this.props.setServices([]);
    this.props.setSelectedServices([]);
    this.props.setCiCategories([]);
    this.props.setCi([]);
    this.props.setDataCenterName(dc);
    this.props.requestServicesByDc(dc);
    this.props.requestCiCategoriesByDc(dc);
  }

  render = () => {
    return (
      <AuthorizationWrapper requiredAction={viewDoraPageAction} key='dora'>
        <div className="w-100">
          {
            !this.props.doraState.errorMessage &&  <NavBar activeDc={this.props.doraState.dataCenterName} setSearchBarInput={this.props.setSearchBarInput} searchBarInput={this.props.doraState.searchBarInput} handleSearchBarInput={this.handleSearchBarInput} dcs={this.props.doraState.dataCenterList} handleChangeDc={this.handleChangeDc} handleEasterEgg={this.handleEasterEgg}/>
          }
        </div>
        {this.props.doraState.showEasterEgg && <div>Swiper No Swiping</div>}
        {this.props.doraState.dataCenterList.length > 0 &&<div>
          <div>
            <ServiceListButtons services={this.props.doraState.services} servicesLoadin={this.props.doraState.servicesLoading} selectedServices={this.props.doraState.selectedServices} handleServicesList={this.handleServicesList} />
            {this.props.doraState.ciCategories &&<div className="row m-4">
              <div className="left-column">
                <div className="pb-5">
                  <CICategories ciCategories={this.props.doraState.ciCategories} ci={this.props.doraState.ci} emptyResponse={this.props.doraState.emptyResponse} handleCICategoryButton={this.handleCICategoryButton}  />
                </div>
                  {this.props.doraState.ci && this.props.doraState.ciCategories.length > 0 && <CI ci={this.props.doraState.ci} ciLoading={this.props.doraState.ciLoading} ci_category={this.props.doraState.ciCategories} disabledAddToListButton={true} handleAddAllToList={this.handleAddAllToList} handleAddToList={this.handleAddToList} />}
              </div>
              <div className="right-column">
                <CopyToClipboard ciList={this.props.doraState.ciList} />
                <CIList ciList={this.props.doraState.ciList} dcImpactLoading={this.props.doraState.dcImpactLoading} globalImpactLoading={this.props.doraState.dcImpactLoading} selectedServices={this.props.doraState.selectedServices} dataCenterName={this.props.doraState.dataCenterName} history={this.props.history} handleRemoveFromList={this.handleRemoveFromList} handleClearList={this.handleClearList} />
              </div>
              
            </div>}
          </div>
        </div>}
      </AuthorizationWrapper>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Dora);
