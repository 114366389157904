import React from 'react'
import ReactTable from "react-table-6"
import "react-table-6/react-table.css"
import _ from 'lodash'

import {convertStringToSearchArray} from '../../../utils/formatFunctions'


const CustomerImpactTable = ({affectedCompanies, currentFilters, setSelectedFilterColumn}) => {

  const columnFilterMethod = (filter, rows) => {

    // Get the filter identifier
    const id = filter.pivotId || filter.id;

    // Construct a clean list of search terms based on the filter text value
    let list = convertStringToSearchArray(filter.value);

    if(list.length === 0) {
      // No search string defined for this column; simply return all rows
      return rows;
    }

    // A search string is present in the user's filters; find rows that match the filter value criteria
    let matchRows = [];
    for(let row of rows) {
      for(let item of list) {
        // This is each "search term" separated by comma
        if(String(row[id]).toLowerCase().includes(item)) {
          matchRows.push(row);
          break;
        }
      }
    }

    return matchRows;
  };

  const columns = [
    {
      Header: 'Customer Name',
      id: 'customer_name',
      accessor: (data) => data.name ? data.name : 'Not Available',
      filterAll: true,
      filterMethod: columnFilterMethod
    },
    {
      Header: 'Entity ID',
      id:'entity_code',
      accessor: (data) => data.entity_code ? data.entity_code : 'Not Available',
      filterAll: true,
      filterMethod: columnFilterMethod
    },
    {
      Header: 'Outtask ID',
      id: 'outtask_id',
      accessor: (data) => data.id ? data.id : 'Not Available',
      filterAll: true,
      filterMethod: columnFilterMethod
    },
  ];

  // Convert columns into list for convenient access
  let cols = [];
  columns.forEach(function(col) {
    cols.push(col["id"]);
  });

  // Determine if column value in currentFilters differs from the prospective new value
  const filtersAreDifferent = (column, currentFilters, newFilterValue) => {
    let areDifferent = true;
    currentFilters.forEach((filter, index) => {
      if(filter["id"] === column) {
        if(_.isEqual(filter["value"], newFilterValue)) {
          areDifferent = false;
        }
      }
    });

    return areDifferent;
  };

  // This functionality will supply the table with what it needs to update the data store
  const onFilteredChange = (filters) => {
    cols.forEach((col, index) => {
      // Check each column
      let foundFlag = false;
      filters.forEach((item) => {
        // Look for the filter to see if it exists
        if(item.id === col) {
          foundFlag = true;
        }
      });

      if(foundFlag === false) {
        // Filter is empty now
        setSelectedFilterColumn(col, []);
      }
    });

    filters.forEach((filter, index) => {
      let column = filter.id;
      let newValue = filter.value;
      if(filtersAreDifferent(column, currentFilters, newValue)) {
        // This conditional prevents refiring / re-rendering table component (fix infinite loop bug)
        setSelectedFilterColumn(column, newValue);
      }
    });
  };

  return (
    <ReactTable
      data            =  {affectedCompanies}
      columns         =  {columns}
      defaultPageSize =  {10}
      showPageSizeOptions = {false}
      className       =  '-striped -highlight'
      defaultFiltered = {currentFilters}
      filterable
      style={{
        maxHeight: "80%" // This will force the table body to overflow and scroll, since there is not enough room
      }}
      onFilteredChange = {onFilteredChange}
    />
  );
}

export default CustomerImpactTable;
