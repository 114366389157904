import React, { Component } from 'react';
import './ProgressBar.css';
import { formatDurationToNearestMinute } from '../../../utils/formatFunctions';
import Moment from 'react-moment';
import { Translation } from 'react-i18next';

const styles = {
  fontStyle: {
    fontWeight: '600'
  }
}

const Filler = (props) => {
  const { percent } = props;
  let color;
  if (percent <= 50) color = '#4fb81c';
  if (percent > 50 && percent < 100) color = '#f0AB00';
  if (percent >= 100) color = 'red';

  return (
    <div className="filler" style={{ width: `${percent}%`, backgroundColor: color }} >
    </div>
  );
};

class ProgressBar extends Component {
  state = {
    percent           : 0,
    formattedTimeLeft : 'loading...',
    millisecondsLeft  : 1,
    formattedTimeOver : 0,
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.increment(this.props.start, this.props.slaEndTime);
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  increment = (startTime, slaEndTime) => {
    startTime      = new Date(startTime);
    slaEndTime     = new Date(slaEndTime);
    const now        = new Date();
    const duration   = startTime - slaEndTime;
    const timeLeft   = startTime - now;
    let slaPercent = (timeLeft / duration) * 100;
    if (slaPercent > 100) slaPercent = 100;

    this.setState({ percent: slaPercent });
  }

  render() {
    const { percent, formattedTimeElapsed } = this.state;
    return (
      <div className="progress-bar-back">
        <p className="start" style={styles.fontStyle}><Translation>{(t) => t('containers.detailsProgressBar.time')}</Translation><Moment date={this.props.start} durationFromNow interval={1000} filter={formatDurationToNearestMinute} /></p>
        <Filler percent={percent} time={formattedTimeElapsed} />
      </div>
    );
  }
}

export default ProgressBar;
