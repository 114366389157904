/* ========= PACKAGE IMPORTS ========= */
import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

/* ========= REDUX IMPORTS ========= */
import { actions as uiActions }    from '../../ducks/ui/ui.index';
/* ========= COMPONENT IMPORTS ========= */
import Loading            from '../../components/loading';
import AuthorizationWrapper from '../../components/authorizationWrapper';
import {
  actions as incidentActions,
} from '../../ducks/incident/incident.index';

import BarChartVisx from './BarCharts/barChartVisx'
// import WordCloud from './WordCloud/WordCloud';

/* ========= REDUX STATE CONNECTORS ========= */
const mapStateToProps = ({incidentState, uiState}) => ({
  historicIncidents :  incidentState.historicIncidents,
  pageLoading       :  uiState.pageLoading,
});

const mapDispatchToProps = (dispatch) => {
  const combinedActionCreators = {
    setPageLoading            :  uiActions.setPageLoading,
    requestAllHistoricIncidents :  incidentActions.requestAllHistoricIncidents,
  };
  return bindActionCreators(combinedActionCreators, dispatch);
};

const viewAction = 'view_charts';
const styles = {
  'topContainer': {
    'margin'   :  'auto',
    'maxWidth' :  'max-content',
  }
};
class Charts extends Component {
  componentDidMount = () => {
    //sometimes the page wont be marked as not loading. No loading is actually done here so this is put to resolve that just incase.
    this.props.requestAllHistoricIncidents();
    this.props.setPageLoading(false);
  }

  render = () => {
    const { topContainer } = styles;
    const { historicIncidents, loading } = this.props;
    let averageData = {};
    let lengthData = {};
    let us2LengthArray = [];
    let us2Sum = 0;
    let eu2LengthArray = [];
    let eu2Sum = 0;
    let chinaLengthArray = [];
    let chinaSum = 0;
    let psccLengthArray = [];
    let psccSum = 0;
    let franceLengthArray = [];
    let franceSum = 0;
    // let us2Words = "";
    // let eu2Words = "";
    // let chinaWords = "";
    // let psccWords = "";
    // let franceWords = "";
    if (historicIncidents.length > 0){
      historicIncidents.forEach(incident => {
        if (incident.endDate){
          let length = new Date(incident.endDate).getTime() - new Date(incident.startDate).getTime();
          length = length / 60000;
          if (length > 0){

          
            incident.affectedDataCenters.forEach(dc => {
              if (dc == 'Concur Cloud for Public Sector'){
                psccLengthArray.push(length);
                psccSum += length;
                // incident.messages.forEach(message => {
                //   psccWords += message.body.en;              
                // })
                // psccWords = psccWords.replace(/[^a-zA-Z ]/g, '');
              }
              if (dc == 'China'){
                chinaLengthArray.push(length);
                chinaSum += length;
                // incident.messages.forEach(message => {
                //   chinaWords += message.body.en;                 
                // })
                // chinaWords = chinaWords.replace(/[^a-zA-Z ]/g, '');
              }
              if (dc == 'US2'){
                us2LengthArray.push(length);
                us2Sum += length;
                // incident.messages.forEach(message => {
                //   us2Words += message.body.en;
                // })
                // us2Words = us2Words.replace(/[^a-zA-Z ]/g, '');
              }
              if (dc == 'EU2'){
                eu2LengthArray.push(length);
                eu2Sum += length;
                // incident.messages.forEach(message => {
                //   eu2Words += message.body.en;
                // })
                // eu2Words = eu2Words.replace(/[^a-zA-Z ]/g, '');
              }
              if (dc == 'France DC'){
                franceLengthArray.push(length);
                franceSum += length;
                // incident.messages.forEach(message => {
                //   franceWords += message.body.en;
                // })
                // franceWords = franceWords.replace(/[^a-zA-Z ]/g, '');
              }
            })
          }
        }
      });
      averageData['US2'] = us2Sum / us2LengthArray.length;
      lengthData['US2'] = us2LengthArray.length;
      averageData['EU2'] = eu2Sum / eu2LengthArray.length;
      lengthData['EU2'] = eu2LengthArray.length;
      averageData['China'] = chinaSum / chinaLengthArray.length;
      lengthData['China'] = chinaLengthArray.length;
      averageData['PSCC'] = psccSum / psccLengthArray.length;
      lengthData['PSCC'] = psccLengthArray.length;
      averageData['France'] = franceSum / franceLengthArray.length;
      lengthData['France'] = franceLengthArray.length;

    }
    return (
      <AuthorizationWrapper requiredAction={viewAction}>
        <div style={topContainer}>
        { loading ? <Loading height="75vh" />
          :
          <div>
            {/* testing <div style={{'display': 'flex'}}>
              <div> US2
                <WordCloud width={350} height={350} data={us2Words}/>
              </div>
              <div> EU2
                <WordCloud width={350} height={350} data={eu2Words}/>
              </div>
            </div>
            <div style={{'display': 'flex'}}>
              <div> China
                <WordCloud width={350} height={350} data={chinaWords}/>
              </div>
              <div> PSCC
                <WordCloud width={350} height={350} data={psccWords}/>
              </div>
            </div>
            <div style={{'display': 'flex'}}>
              <div> France
                <WordCloud width={350} height={350} data={franceWords}/>
              </div>
            </div> */}
          
            <div style={{'display': 'flex'}}>
              {historicIncidents.length > 0 && <div style={{'display': 'grid', 'textAlign': 'center'}}>
                Average Length of Incidents
                <BarChartVisx givenData={averageData} width={350} height={350}></BarChartVisx>
              </div>}
              {historicIncidents.length > 0 && <div style={{'display': 'grid', 'textAlign': 'center'}}>
                Number of Incidents
                <BarChartVisx givenData={lengthData} width={350} height={350}></BarChartVisx>
              </div>}
            </div>
          </div>

        }

      </div>
    </AuthorizationWrapper>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(Charts);

