import _ from 'lodash';
import { takeEvery, put, call, select } from "redux-saga/effects";
import { searchChanges, searchChangeEntity } from './changeHistory.api';
import { actions as userActions } from '../user/user.index'
import { types, actions as changeActions } from './changeHistory.index';
import { actions as errorActions }  from '../error/error.index';
import { actions as toastActions } from '../toast/toast.index';
import moment from 'moment';

export function* submitSearchChangeEntity(action) {
  // extract relevant variables
  const payload =  action.payload;
  const entity  =  action.payload.entity;

  // submit it to changeEntity api endpoint
  const response = yield call(searchChangeEntity, payload);

  //extract only relevant data
  const data = response.data
  // handle the response from db -> ui datastore
  yield put(changeActions.setSearchChangeEntity(data, `entity_results_${entity}s`))
  yield put(changeActions.setStateField(`loading_${entity}s`, false))
}




// searchChangeRecords
export function* submitSearchChanges(action) {
  // @TODO ideally we should have a central reference for filter validation errors,
  // and this function should be able to reference them
  const { changeHistoryState } = yield select( (state) => state );

  yield put(changeActions.setStateField('searchChangeLoading', true))

  const payload =  {
    "search_params": {
      "data_centers"     : changeHistoryState.selected_data_centers,
      "services"         : changeHistoryState.selected_services,
      "cis"              : changeHistoryState.selected_cis,
      "keyword"          : changeHistoryState.keywords.trim(),
      "search_type"      : changeHistoryState.search_type,
   },
    "change_type"      : changeHistoryState.change_type_options[_.findIndex(changeHistoryState.change_type_options, (item) => item.selected === true)].systemKey,
    "sort_by"          : changeHistoryState.sort_by,
    "sort_direction"   : action.payload.sort_direction,
    "results_per_page" : changeHistoryState.results_per_page,
    "page_number"      : action.payload.page_number,
  }
  if(changeHistoryState.absolute_selected) {
    // We're using "absolute" time
    payload.search_params['date_range_start'] = formatSearchChangeDate(changeHistoryState.date_range_start)
    payload.search_params['date_range_end'] = formatSearchChangeDate(changeHistoryState.date_range_end)
  } else {
    // We're using "relative" time
    const selectedTimeUnit = changeHistoryState.relative_time_unit_options[_.findIndex(changeHistoryState.relative_time_unit_options, (item) => item.selected === true)].systemKey;
    [ payload.search_params['date_range_start'], payload.search_params['date_range_end'] ] = formatSearchChangeDateRelative(changeHistoryState.relative_time_number, selectedTimeUnit);
  }

  if(payload.search_params['date_range_start'] === null) {
    delete payload.search_params['date_range_start']
  }
  if(payload.search_params['date_range_end'] === null) {
    delete payload.search_params['date_range_end']
  }

  try {
    // Attempt to update the user's profile object using the applied filters
    yield put(userActions.putChangeHistorySettings({
      "change_type_options"        : changeHistoryState.change_type_options,
      "relative_time_unit_options" : changeHistoryState.relative_time_unit_options,
      "relative_time_number"       : changeHistoryState.relative_time_number,
      "absolute_selected"          : changeHistoryState.absolute_selected,
      "date_range_start"           : changeHistoryState.date_range_start,
      "date_range_end"             : changeHistoryState.date_range_end,
      "keywords"                   : changeHistoryState.keywords,
      "selected_data_centers"      : changeHistoryState.selected_data_centers,
      "selected_services"          : changeHistoryState.selected_services,
      "selected_cis"               : changeHistoryState.selected_cis,
      "record_display_style"       : changeHistoryState.record_display_style,
      "search_type_options"        : changeHistoryState.search_type_options,
      "search_type"                : changeHistoryState.search_type,
    }));
  } catch(error) {
    // Log the error, but fail silently
    console.log(error);
  }

  try {

    // Submit it to searchChange api endpoint
    const response = yield call(searchChanges, payload)
    const data = response.data
    yield put(changeActions.setPageLoading(true))

    // Checks if data was recieved from API call, if so sets the data for component rerendering
    let resultsFound = ((Array.isArray(data.rows)) && (data.rows.length>0));
    yield put(changeActions.setSearchChanges(data, payload.sort_direction, payload.page_number, resultsFound))

    // Clear the error state in case one was set. Remove the loading icon.
    yield put(changeActions.setChangeHistoryError(false));
    yield put(changeActions.setStateField('searchChangeLoading', false));

  } catch(error) {

    // handle ui effects
    yield put(changeActions.setStateField('searchChangeLoading', false));
    yield put(toastActions.createToast(`Unable to fetch Change records: ${error}`, {type: 'error', autoClose: true}));
    yield put(changeActions.setChangeHistoryError(true));
    yield put(changeActions.setChangeHistoryErrorMessage(error));

    // Set the search results to empty payload
    yield put(changeActions.setSearchChanges([], payload.sort_direction, payload.page_number, false))

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));

  }
}

// watcher saga

export default function changeHistorySagas() {
  return [
    takeEvery(types.SUBMIT_ENTITY_SEARCH, submitSearchChangeEntity),
    takeEvery(types.SUBMIT_SEARCH_CHANGES, submitSearchChanges),
  ];
}

const formatSearchChangeDate = (input) => {
  if (input === null || input == undefined || !input instanceof Date) return null
  return moment(input).utc().format('YYYY-MM-DD HH:mm:00')
};

const formatSearchChangeDateRelative = (number, units) => {
  // Converts from relative time (number, units e.g. 7, days) to timestamps at the moment of invocation.
  // Provides the effective start_time as the first value, and effective end_time as the second value.
  return [
    moment().utc().subtract(number, units).format('YYYY-MM-DD HH:mm:ss'),
    moment().utc().format('YYYY-MM-DD HH:mm:ss')
  ]
};
