import React, { useState } from 'react';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  actions as uiActions,
} from '../../../ducks/ui/ui.index';
import {
  actions as maintenanceEventActions
} from '../../../ducks/v2MaintenanceEvent/v2MaintenanceEvent.index';
import { Modal, Button, Form, Tooltip, OverlayTrigger, Row, Col, Card } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
/* ========= PACKAGE IMPORTS ========= */

const mapStateToProps = ({uiState, userState}) => ({
  pageLoading         :  uiState.pageLoading,
  email               :  userState.user.email, 
});
const mapDispatchToProps =  (dispatch) => {
  const combinedActionCreators = {
    setPageLoading                :  uiActions.setPageLoading,
    V2SubmitDeleteNotification      :  maintenanceEventActions.V2SubmitDeleteNotification,
  };

  return bindActionCreators(combinedActionCreators, dispatch);
};

const DeleteNotificationModal = ({V2SubmitDeleteNotification, id}) => {
  const [modalIsOpen,setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  }
  const closeModal = () => {
    setIsOpen(false);
  }

  const submitDelete = (id) => {
    V2SubmitDeleteNotification(id);
    closeModal();
  }

  return (
    <div>
      {/* Overlay Tooltip for button */}
      <OverlayTrigger
        key='left'
        placement='left'
        overlay=
        {
          <Tooltip id={`tooltip-left`}>Deletes this Notification</Tooltip>
        }
      >
        <div className="data-cy-edit-message-modal-button"  onClick={openModal} style={{cursor: 'pointer', color: 'blue'}}>Delete</div>
      </OverlayTrigger>
      
      <Modal show={modalIsOpen} onHide={closeModal} className="data-cy-edit-message-modal">
        <Modal.Header closeButton>
          <Modal.Title>Delete Notification</Modal.Title>
        </Modal.Header>
        <Card>
          <Card.Body>
            <div>Confirm: Are you sure you want to delete this?</div>
          </Card.Body>
        </Card>
        <div style={{display: 'flex'}}>
          <Button variant="secondary" onClick={closeModal} className="data-cy-create-message-modal-close" style={{margin: '1vw', width: '8rem', marginRight: 'auto'}}>Cancel</Button>
          <Button variant="danger" onClick={() => submitDelete(id)} style={{float: 'right', width: '8rem', margin: '1vw'}}>Delete</Button>
        </div>
      </Modal>
    </div>
  );
}

export default connect(mapStateToProps,mapDispatchToProps)(DeleteNotificationModal);