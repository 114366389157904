import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
const styles = {
  'rowItem': {
    'paddingBottom' : '2%',
    'marginBottom'  : '4px',
  },
  'header' : {
    'fontWeight' : 'bold',
    'padding'    : '0',
  },
  'body' : {
    'padding'    : '0',
  }
};

const CardDetails = ({ property, data, colWidth }) => {
  let keyWidth   = colWidth ? colWidth.key   : 12;
  let valueWidth = colWidth ? colWidth.value : 12;
  const { rowItem, header, body } = styles;
  const propertyCssName = property.split(' ').filter( (word) => !word.includes('(')).join('-').toLowerCase();
  const { t } = useTranslation(["translation"]);
  
  return (
    <Row key={property} style={rowItem}>
      <Col sm={keyWidth} style={header}>{property}</Col>
      <Col sm={valueWidth} style={body} className={`${propertyCssName}-cypress`}>{data === null ? t('containers.cardDetails.not'): data ? data : <Skeleton /> }</Col>
    </Row>
  );
};

CardDetails.propTypes = {
  data: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([null]),
    PropTypes.object
  ]),
  property: PropTypes.string,
};

export default CardDetails;
