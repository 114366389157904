import React, { Component } from 'react';
import { CSVLink } from 'react-csv';
import { Button } from 'react-bootstrap';
import moment from 'moment';
import './CustomerCSV.css'
import { getActiveStatus } from '../../utils/formatFunctions'

let headers = [
  {label: "Severity", key: "severity"},
  {label: "OPI Number", key: "jira_id"},
  {label: "Status", key: "status"},
  {label: "Affected Service(s)", key: "affected_services"},
  {label: "Start Date", key: "start_date"},
  {label: "End Date", key: "end_date"},
  {label: "Duration (minutes)", key: "duration"},
  {label: "Intermediate Root Cause Analysis", key: "irca"},
  {label: "Root Cause Analysis", key: "rca"}
];

function mapCsv(incident) {
  /* Calculate incident duration in minutes. Use current timestamp if no end timestamp provided. */
  let diffSeconds = (incident["end_epoch"] || moment()) - incident["start_epoch"];
  let diffMinutes = Math.floor(diffSeconds/60);

  /* Render status text based on activeStatus response */
  let status = getActiveStatus(incident["start_date"], incident["end_date"], incident["status"]) ? "Active" : "Closed";

  return {
    ...incident,
    'duration': diffMinutes,
    'status': status
  }
}

export default function CustomerCSV(props) {
  const { companyIncidents, companyName } = props;
  return (
    <CSVLink
      className = "csv-link"
      data      = {companyIncidents.map(mapCsv)}
      headers   = {headers}
      filename  = {`${companyName ? companyName : "No Company Name"}-${moment().format("YYYY-MM-DD")}.csv`}
    >
      <Button
        className = "csv-button"
        disabled  = {companyIncidents.length === 0}
        title     = {companyIncidents.length === 0 ? "No data to export" : ""}
      >
        Download CSV
      </Button>
    </CSVLink>
  );
}
