/* ===== STATE_DEFINITION ===== */
const initialState = {
  ciList: [],
  ciCategories: [],
  ci: [],
  dataCenterList: [],
  disabledAddToListButton: [],
  services: [],
  selectedServices: [],
  dataCenterName: "EMEA",
  searchBarInput: "",
  errorMessage: "",
  emptyResponse: false,
  ciLoading: false,
  servicesLoading: false,
  globalImpactLoading: false,
  dcImpactLoading: false,
  showEasterEgg: false,
};

/* ===== TYPES ===== */
export const types = {
  REQUEST_ALL_DATA_CENTERS        : '[dora] REQUEST_ALL_DATA_CENTERS',
  REQUEST_CIS_BY_CATEGORY         : '[dora] REQUEST_CIS_BY_CATEGORY',
  REQUEST_SERVICES_BY_DC          : '[dora] REQUEST_SERVICES_BY_DC',
  REQUEST_CI_CATEGORIES_BY_DC     : '[dora] REQUEST_CI_CATEGORIES_BY_DC',
  REQUEST_IMPACT_GLOBAL           : '[dora] REQUEST_IMPACT_GLOBAL',
  REQUEST_IMPACT_DATA_CENTER      : '[dora] REQUEST_IMPACT_DATA_CENTER',
  REQUEST_SEARCH_RESULTS          : '[dora] REQUEST_SEARCH_RESULTS',
  SET_DATA_CENTER_NAME            : '[dora] SET_DATA_CENTER_NAME',
  SET_DATA_CENTER_LIST            : '[dora] SET_DATA_CENTER_LIST',
  SET_SERVICES                    : '[dora] SET_SERVICES',
  SET_SELECTED_SERVICES           : '[dora] SET_SELECTED_SERVICES',
  SET_CI_CATEGORIES               : '[dora] SET_CI_CATEGORIES',
  SET_CI_LIST                     : '[dora] SET_CI_LIST',
  SET_CI                          : '[dora] SET_CI',
  SET_PERCENTAGE_GLOBAL           : '[dora] SET_PERCENTAGE_GLOBAL',
  SET_PERCENTAGE_DATA_CENTER      : '[dora] SET_PERCENTAGE_DATA_CENTER',
  SET_SEARCH_BAR_INPUT            : '[dora] SET_SEARCH_BAR_INPUT',
  SET_ERROR_MESSAGE               : '[dora] SET_ERROR_MESSAGE',
  SET_CI_LOADING                  : '[dora] SET_CI_LOADING',
  SET_SERVICES_LOADING            : '[dora] SET_SERVICES_LOADING',
  SET_GLOBAL_IMPACT_LOADING       : '[dora] SET_GLOBAL_IMPACT_LOADING',
  SET_DC_IMPACT_LOADING           : '[dora] SET_DC_IMPACT_LOADING',
  SET_SHOW_EASTER_EGG             : '[dora] SET_SHOW_EASTER_EGG',
};


/* ===== ACTION_CREATORS ===== */
export const actions = {
  requestAllDataCenters       : ()                                => ({type: types.REQUEST_ALL_DATA_CENTERS}),
  requestCisByCategory        : (dc, category)                    => ({type: types.REQUEST_CIS_BY_CATEGORY, payload: {dc, category}}),
  requestServicesByDc         : (dc)                              => ({type: types.REQUEST_SERVICES_BY_DC, payload: dc}),
  requestCiCategoriesByDc     : (dc)                              => ({type: types.REQUEST_CI_CATEGORIES_BY_DC, payload: dc}),
  requestImpactGlobal         : (services, cis, allDcs, category, callback) => ({type: types.REQUEST_IMPACT_GLOBAL, payload: {services, cis, allDcs, category, callback}}),
  requestImpactDataCenter     : (services, cis, dcs, category, callback)    => ({type: types.REQUEST_IMPACT_DATA_CENTER, payload: {services, cis, dcs, category, callback}}),
  requestSearchResults        : (dc, search_query)                => ({type: types.REQUEST_SEARCH_RESULTS, payload: {dc, search_query}}),
  setDataCenterName           : (dc)                              => ({type: types.SET_DATA_CENTER_NAME, payload: dc}),
  setDataCenterList           : (dcs)                             => ({type: types.SET_DATA_CENTER_LIST, payload: dcs}),
  setServices                 : (services)                        => ({type: types.SET_SERVICES, payload: services}),
  setSelectedServices         : (services)                        => ({type: types.SET_SELECTED_SERVICES, payload: services}),
  setCiCategories             : (categories)                      => ({type: types.SET_CI_CATEGORIES, payload: categories}),
  setCiList                   : (cis)                             => ({type: types.SET_CI_LIST, payload: cis}),
  setCi                       : (ci)                              => ({type: types.SET_CI, payload: ci}),
  setPercentageGlobal         : (percentage, category, ci)        => ({type: types.SET_PERCENTAGE_GLOBAL, payload: {percentage, category, ci}}),
  setPercentageDataCenter     : (percentage, category, ci)        => ({type: types.SET_PERCENTAGE_DATA_CENTER, payload: {percentage, category, ci}}),
  setSearchBarInput           : (input)                           => ({type: types.SET_SEARCH_BAR_INPUT, payload: input}),
  setErrorMessage             : (message)                         => ({type: types.SET_ERROR_MESSAGE, payload: message}),
  setCiLoading                : (isLoading)                       => ({type: types.SET_CI_LOADING, payload: isLoading}),
  setServicesLoading          : (isLoading)                       => ({type: types.SET_SERVICES_LOADING, payload: isLoading}),
  setGlobalImpactLoading      : (isLoading)                       => ({type: types.SET_GLOBAL_IMPACT_LOADING, payload: isLoading}),
  setDcImpactLoading          : (isLoading)                       => ({type: types.SET_DC_IMPACT_LOADING, payload: isLoading}),
  setShowEasterEgg            : (isShown)                         => ({type: types.SET_SHOW_EASTER_EGG, payload: isShown})
};


/* ===== SELECTORS ===== */
export const selectors = {
};


/* ===== REDUCER ===== */
export default function reducer(state=initialState, action) {
  switch (action.type) {

    case types.SET_DATA_CENTER_NAME: {
      return { ...state, dataCenterName : action.payload };
    }
    case types.SET_DATA_CENTER_LIST: {
      return { ...state, dataCenterList : action.payload };
    }
    case types.SET_SERVICES: {
      return { ...state, services : action.payload };
    }
    case types.SET_SELECTED_SERVICES: {
      return { ...state, selectedServices : action.payload };
    }
    case types.SET_CI_CATEGORIES: {
      return { ...state, ciCategories : action.payload };
    }
    case types.SET_CI_LIST: {
      return { ...state, ciList : action.payload };
    }
    case types.SET_CI: {
      return { ...state, ci: action.payload };
    }
    case types.SET_PERCENTAGE_GLOBAL: {
      let ciList = state.ciList;
      ciList.forEach(ci => {
        if (ci.category[0] == action.payload.category[0] && ci.name == action.payload.ci){
          ci.globalScale = action.payload.percentage;
        }
      })
      return { ...state, ciList : ciList };
    }
    case types.SET_PERCENTAGE_DATA_CENTER: {
      let ciList = state.ciList;
      ciList.forEach(ci => {
        if (ci.category[0] == action.payload.category[0] && ci.name == action.payload.ci){
          ci.dataCenterScale = action.payload.percentage;
        }
      })
      return { ...state, ciList : ciList };
    }
    case types.SET_SEARCH_BAR_INPUT: {
      return { ...state, searchBarInput : action.payload };
    }
    case types.SET_ERROR_MESSAGE: {
      return { ...state, errorMessage : action.payload };
    }
    case types.SET_CI_LOADING: {
      return { ...state, ciLoading : action.payload };
    }
    case types.SET_SERVICES_LOADING: {
      return { ...state, servicesLoading : action.payload };
    }
    case types.SET_GLOBAL_IMPACT_LOADING: {
      return { ...state, globalImpactLoading : action.payload };
    }
    case types.SET_DC_IMPACT_LOADING: {
      return { ...state, dcImpactLoading : action.payload };
    }
    case types.SET_SHOW_EASTER_EGG: {
      return { ...state, showEasterEgg : action.payload };
    }
    default: { return state; }
  }
}
