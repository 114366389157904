export const NODE_ENV                      = process.env.NODE_ENV
export const REACT_APP_STAGE               = process.env.REACT_APP_STAGE
export const PUBLIC_URL                    = process.env.PUBLIC_URL
export const REACT_APP_AUTH_EMAIL_ADDRESS  = process.env.REACT_APP_AUTH_EMAIL_ADDRESS
export const REACT_APP_FLOKTA_URL          = process.env.REACT_APP_FLOKTA_URL
export const REACT_APP_FLOKTA_REDIRECT_URL = process.env.REACT_APP_FLOKTA_REDIRECT_URL
//default these two for local cypress testing
export const REACT_APP_JIRA_URL            = !process.env.REACT_APP_JIRA_URL ? 'https://jirauat.concurqa.com' : process.env.REACT_APP_JIRA_URL
export const REACT_APP_OPEN_URL            = !process.env.REACT_APP_OPEN_URL ? 'https://openuat.nonprod.cnqr.tech' : process.env.REACT_APP_OPEN_URL
export const REACT_APP_API_URL             = process.env.REACT_APP_API_URL

export const LOCAL_USER = {
  'email'          :  REACT_APP_AUTH_EMAIL_ADDRESS,
  'AppPreferences' :  {},
  'AppRights'      :  {
    'restricted_page_access': ['change_history', 'incident_management']
  },
  'authorization'  : {
    'is_admin'        : true,
    'allowed_actions' : []
  }
}
