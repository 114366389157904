import React,  {useState, useEffect, Fragment}       from 'react';
import { Link }     from 'react-router-dom';
import { useTable, useSortBy, usePagination } from 'react-table'
import { Table, Col, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import {
  dateTimeToUTCString,
  joinArrayToString,
  sortOpi,
  sortDate,
} from '../../../utils/formatFunctions'
const styles = {
  'headers': {
    'textAlign'  :  'left',
    'lineHeight' :  '2rem',
  },
  'img': {
    'height' :  'auto',
    'width'  :  '24px',
  },
  'table': {
    'height' :  '75vh',
    'border' :  'none',
  },
  'tableContainer': {
    'fontSize'        :  '.94rem',
    'boxSizing'       :  'border-box',
    'borderRadius'    :  '4px',
    'backgroundColor' :  'rgb(250, 250, 250)',
    'boxShadow'       :  'rgba(0, 0, 0, 0.2) 0px 0px 10px -2px',
  },
  'tablePadding': {
    'borderLeft'   : '1px solid #ddd',
    'borderRight'  : '1px solid #ddd',
    'borderBottom' : '1px solid #ddd',
  },
  'unset' : {
    'whiteSpace': 'unset'
  },
  'prewrap' : {
    'whiteSpace': 'pre-wrap'
  },
};
const PAGE_SIZE = 10;



function RenderTable({ columns, values }) {
  const { t } = useTranslation(["translation"]);

  const data = React.useMemo(() => 
  values
  , [values]);

  const sorting = React.useMemo(
    () => [
      {
        id: "start_date",
        desc: true
      }
    ],
    []
  );
  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? 'desc' : 'asc') : '';
  };

  const widthDict = {
    'jira_id': 122,
    'affectedService': 260,
    'data_center': 300,
    'start_date': 230,
    'end_date': 230

  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: sorting,
        pageIndex: 0,
        pageSize: 10
      }
    },
    useSortBy,
    usePagination,
  )
  return (
    <Fragment>
      <Table bordered {...getTableProps()} className="cy-history-table">  
        <thead>
          {headerGroups.map(headerGroup => (
            <tr className='rt-thead' {...headerGroup.getHeaderGroupProps()} >
              {headerGroup.headers.map((column, i) => {
                const {render, getHeaderProps} = column
                return (
                  <th style={{ width: column.size || widthDict[column.id] }} className={generateSortingIndicator(column) + " " + column.id + "-header"} key={'header' + i} {...getHeaderProps(column.getSortByToggleProps())}>{render("Header")}</th>
                )
              })}
            </tr>
          ))}
        </thead>
        {page.length > 0 && <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            let styling = row && row.original.is_internal && row.original.is_internal == 'Y' ? {backgroundColor: '#00a9f24a'} : {};            
            prepareRow(row);
            return (
              <Fragment key={row.getRowProps().key}>
                <tr style={styling}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    );
                  })}
                </tr>
              </Fragment>
            );
          })}
        </tbody>}
        {page.length == 0 &&  <><tr><td/><td/><td/><td/><td/><td/></tr>
        <div style={styles.noData} className='rt-noData'>{t('containers.incidentManagmentReviewDetailsTable.noData')}</div>
        <tr><td/><td/><td/><td/><td/><td/></tr>
        <tr><td/><td/><td/><td/><td/><td/></tr>
        <tr><td/><td/><td/><td/><td/><td/></tr>
        <tr><td/><td/><td/><td/><td/><td/></tr>
        <tr><td/><td/><td/><td/><td/><td/></tr>
        <tr><td/><td/><td/><td/><td/><td/></tr></>}
      </Table>
      <div style={{display: 'flex', textAlign: 'center', paddingBottom: '1rem'}}>
        <Col md={5} >
          <Button
            variant="secondary"
            onClick={() => gotoPage(0)}
            style={{width: '15vw', marginRight: '5px'}}
            disabled={!canPreviousPage}
          >
            {"First"}
          </Button>
          <Button
            variant="secondary"
            onClick={previousPage}
            style={{width: '15vw'}}
            disabled={!canPreviousPage}
          >
            {"Prev"}
          </Button>
        </Col>
        <Col md={2} style={{ marginTop: 7 }}>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </Col>
        <Col md={5}>
          <Button variant="secondary" style={{width: '15vw', marginRight: '5px'}} onClick={nextPage} disabled={!canNextPage}>
            {"Next"}
          </Button>
          <Button
            variant="secondary"
            style={{width: '15vw'}}
            onClick={() => gotoPage(pageCount - 1)}
            disabled={!canNextPage}
          >
            {"Last"}
          </Button>
        </Col>
      </div>
    </Fragment>
  );
}



const ReviewTable = ({p1Data, p2Data, priority_type, requestIncidents, listSize}) => {
  const [page, setPage] = useState('');
  const [sortCol, setSortCol] = useState('start_date');
  const [sortDirection, setSortDirection] = useState('DESC');
  const [totalIncidents, setTotalIncidents] = useState(listSize);
  useEffect(() => {
    if (priority_type == 'P2'){
      setRowData(p2Data);
    }
    else{
      setRowData(p1Data);
    }
   
  }, [p1Data, priority_type])


  const { headers, tableContainer } = styles;
  const { t } = useTranslation(["translation"]);
  const [rowData, setRowData] = useState(p1Data);
  if (document.getElementsByClassName("-center")[0]){
    document.getElementsByClassName("-center")[0].style.display = 'none'
  }
  useEffect(() => {
    //Pages populated with data until page 10, on page 8, more data is pulled.
    if (totalIncidents - (PAGE_SIZE * page) == 40){
      requestIncidents(priority_type, 100, totalIncidents, sortCol, sortDirection);
      setTotalIncidents(totalIncidents + 100);
    }
  }, [page])

  function sortEndDate(rowA, rowB, id, desc) {
    if (!rowA.values[id]) return 1;
    if (!rowB.values[id]) return -1;
    if (rowA.values[id] > rowB.values[id]) return 1; 
    if (rowB.values[id] > rowA.values[id]) return -1;
    return 0;
  }

  const columns = React.useMemo(
    () => [
        {
          Header: <b style={styles.test} data-cy='id-header'>{t('containers.incidentManagementTable.incident')}</b>,
          headerStyle: headers,
          id: 'jira_id',
          size: '122px',
          Cell          : ({value}) => (<Link style={{width: '122px'}} to={`/edit/${value}`} >{value}</Link>),
          accessor      : "jira_id",
          sortMethod    : (a, b) => sortOpi(a, b),
        },
        {
          Header      : <b data-cy="internal-header">{t('containers.incidentManagementTable.internal')}</b>,
          headerStyle : headers,
          id          : 'is_internal',
          size       : '100px',
          accessor    : data => <div style={{width: '100px'}}>{data.is_internal == 'Y' ? 'Internal' : 'External'}</div>,
        },
        {
          Header: <b data-cy='service-header'>{t('containers.incidentManagementTable.service')}</b>,
          headerStyle: headers,
          id: 'affectedService',
          accessor: 'affectedService',
          size: '260px',
          Cell:  (value) => (<div style={{width: '260px'}}>{joinArrayToString(value.row.original.affected_services)}</div>),
        },
        {
          Header: <b data-cy="data-center-header">{t('containers.incidentManagementTable.data')}</b>,
          headerStyle: headers,
          id: 'data_center',
          accessor: 'data_center',
          size: '300px',
          Cell    : (value) => (<div style={{width: '300px'}}>{joinArrayToString(value.row.original.data_centers)}</div>),
        },

        {
          Header: <b data-cy="start-date-header">{t('containers.incidentManagementTable.start')}</b> ,
          headerStyle: headers,
          id: 'start_date',
          accessor: 'start_date',
          size: '230px',
          Cell      : ({value}) => (<div style={{width: '230px'}}>{dateTimeToUTCString(value)}</div>),
          sortMethod    :  (a, b) => sortDate(a, b),
        },

        {
          Header: <b data-cy='end-date-header'>{t('containers.incidentManagementTable.end')}</b> ,
          headerStyle: headers,
          id: 'end_date',
          accessor: 'end_date',
          size: '230px',
          Cell: ({value}) => (<div style={{width: '230px'}}>{value === null ? 'Ongoing Incident' :  dateTimeToUTCString(value)}</div>),  
          sortType: sortEndDate
        },
      ]
    )

  return (
    <div style={tableContainer}>
      <RenderTable columns={columns} values={rowData} />
    </div>
  );
}

export default ReviewTable;
