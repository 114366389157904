/* ========= PACKAGE IMPORTS ========= */
import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';

/* ========= REDUX IMPORTS ========= */
import { actions as uiActions }    from '../../ducks/ui/ui.index';
import { actions as v2MaintenanceEventActions }    from '../../ducks/v2MaintenanceEvent/v2MaintenanceEvent.index';
/* ========= COMPONENT IMPORTS ========= */
import Loading            from '../../components/loading';
import MaintenanceEventTable        from './MaintenanceEventTable/MaintenanceEventTable';
import AuthorizationWrapper from '../../components/authorizationWrapper';
import CreateMaintenanceEventModal from './MaintenanceEventModals/CreateMaintenanceEventModal';


/* ========= REDUX STATE CONNECTORS ========= */
const mapStateToProps = ({uiState, v2MaintenanceEventState}) => ({
    pageLoading                 :  uiState.pageLoading,
    loading                     :  v2MaintenanceEventState.loading,
    maintenance_events          :  v2MaintenanceEventState.maintenance_events,
    active_maintenance_events   :  v2MaintenanceEventState.active_maintenance_events,
    active_notifications        :  v2MaintenanceEventState.active_notifications,
    active_notification_ids     :  v2MaintenanceEventState.active_notification_ids,
    all_data_centers            :  v2MaintenanceEventState.all_data_centers,
});

const mapDispatchToProps = (dispatch) => {
  const combinedActionCreators = {
    setPageLoading            :  uiActions.setPageLoading,
    V2RequestActiveNotifications : v2MaintenanceEventActions.V2RequestActiveNotifications,
  };
  return bindActionCreators(combinedActionCreators, dispatch);
};

const styles = {
  'topContainer': {
    'margin'   :  'auto',
    'maxWidth' :  'max-content',
  },
  'pageTitle': {
    'marginBottom' :  '20px',
    'width'        :  '73vw',
    'textAlign'    :  'center',
    'margin'       :  '0 auto'
  },
};

const createAction = 'create_maintenance_event';

class MaintenanceEvent extends Component {
  componentDidMount = () => {
    //sometimes the page wont be marked as not loading. No loading is actually done here so this is put to resolve that just incase.
    this.props.V2RequestActiveNotifications();
    this.props.setPageLoading(false);
  }

  render = () => {
    const { topContainer, pageTitle } = styles;
    const { pageLoading, maintenance_events, active_maintenance_events, active_notification_ids, loading } = this.props;
    return (
      <div style={topContainer}>
        {/* HEADER */}
        <div style={{display: '-webkit-box'}}>
          <h1 style={pageTitle}>Maintenance Events</h1>
          <h6 style={{margin: '1rem'}}>All Times In PT</h6>
          <AuthorizationWrapper requiredAction={createAction}>
            <div style={{margin: "auto 0"}}>
              <CreateMaintenanceEventModal></CreateMaintenanceEventModal>
            </div>
          </AuthorizationWrapper>
        </div>

      { /* CONTENT */
        loading ? <Loading height="75vh" />
        :
        <MaintenanceEventTable RowData={maintenance_events} ActiveRowData={active_maintenance_events} ActiveNotificationIDs={active_notification_ids}/>

      }

    </div>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(MaintenanceEvent);

