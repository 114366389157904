/* eslint-disable react/require-default-props */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import { string } from 'prop-types';
import { Translation } from 'react-i18next';
class ReadMore extends Component {

  state = {
    open: false,
    moreDetailsSpan: {
      display: "none",
    },
    ellipsis: {
      display: "inline",
    }
  };

  static propTypes = {
    details: string,
    characterLimit: Number,
  };

  styles = {
    readMoreButton: {
      cursor: "pointer",
      textDecoration: "underline"
    },
  }

  handleClick = () => {
    const { open } = this.state;
    this.setState({
      open: !open,
      moreDetailsSpan: {
        display: !open ? "inline" : "none",
      },
      ellipsis: {
        display: !open ? "none" : "inline",
      },
    });
  };

  render() {
    const { open, moreDetailsSpan, ellipsis } = this.state;
    const { details, characterLimit } = this.props;
    let output;

    if (details == null) return "";

    if (details.length > characterLimit) {
      output = (
        <>
          {details.substring(0, characterLimit)}
          <span className="dots" style={ellipsis} onClick={this.handleClick} role="button">...</span>
          <span className="more" style={moreDetailsSpan}>
            {details.substring(characterLimit, details.length)}
          </span>
          <br/>
          <Translation>{(t) => <span
            className="read-more-button"
            role="button"
            onClick={this.handleClick}
            style={this.styles.readMoreButton}
          >
            {open ? t('containers.landingReadMore.less') : t('containers.landingReadMore.more')}
          </span>
          }</Translation>
        </>
      );
    } else {
      output = details;
    }
    return output;
  }
}


export default ReadMore;
