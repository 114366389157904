import { takeEvery, put, call, select } from "redux-saga/effects";
import { searchCustomerCatalog, getIncidentsByCompanyId, } from './customerDetails.api';
import { types as customerTypes, actions as customerActions } from './customerDetails.index';
import { actions as errorActions }  from '../error/error.index';
import i18next from 'i18next';

const INCIDENT_RESPONSE_SIZE = 750;

export function* fetchCustomerCatalogSearch(action) {
  try {
    yield put(customerActions.setCustomerLoading(true));
    
    let response =  yield call(searchCustomerCatalog, action.payload.replace(/&/g, 'ampersand'));
    let payload  =  response.data.companies;
    yield put(customerActions.setCustomerCatalog(payload));
    yield put(customerActions.setCustomerLoading(false));
  }
  catch(error) {
    // handle ui effects
    yield put(customerActions.setCustomerError(true));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
  }
}

export function* fetchCompanyIncidentsById(action) {
  const [company] = action.payload
  try {
    let language = i18next.language && !i18next.language.toLocaleLowerCase().includes('en') ? i18next.language : 'en';
    yield put(customerActions.setIncidentLoading(true));
    yield put(customerActions.setSelectedCompany(company));
    let response =  yield call(getIncidentsByCompanyId, company.id, language, 0, INCIDENT_RESPONSE_SIZE);
    let payload  =  response.data.incidents;
    let more_results = response.data.more_results;
    let counter = 1;
    while (more_results){
      let overflow_response =  yield call(getIncidentsByCompanyId, company.id, language, INCIDENT_RESPONSE_SIZE * counter, INCIDENT_RESPONSE_SIZE);
      payload = payload.concat(overflow_response.data.incidents)
      more_results = overflow_response.data.more_results;
      counter += 1;
    }

    yield put(customerActions.setCompanyIncidents(payload));
    yield put(customerActions.setIncidentLoading(false));
  }
  catch(error) {
    // handle ui effects
    yield put(customerActions.setCustomerError(true));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
  }
}


// watcher saga
export default function customerDetailsSagas() {
  return [
    takeEvery(customerTypes.SEARCH_CUSTOMER_CATALOG, fetchCustomerCatalogSearch),
    takeEvery(customerTypes.REQUEST_COMPANY_INCIDENTS, fetchCompanyIncidentsById),
  ];
}
