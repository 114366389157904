/* ========= PACKAGE IMPORTS ========= */
import React, { Component }      from 'react';
import { connect }               from "react-redux";
import { bindActionCreators }    from 'redux';

/* ========= REDUX IMPORTS ========= */
import {
  incidentProptypes,
  actions as incidentActions,
  selectors as incidentSelectors,
} from '../../ducks/incident/incident.index';


import {
  statusProptypes,
  actions as statusActions,
} from '../../ducks/status/status.index';
import { uiProptypes, actions as uiActions } from '../../ducks/ui/ui.index';

/* ========= COMPONENT IMPORTS ========= */
import ServicesCard      from "./ServicesCard/ServicesCard";
import LastRefresh       from '../../components/LastRefresh/LastRefresh';
import './Landing.css';
import { StatusDetails } from './StatusDetails/StatusDetails';
import { IncidentColumn } from './IncidentColumn/IncidentColumn';


/* ========= REDUX STATE CONNECTORS ========= */
const mapStateToProps = ({ incidentState, statusState, uiState, severityState }, ownProps) => ({
  activeIncidents        : incidentState.activeIncidents,
  currentStatuses        : statusState.currentStatuses,
  pageLoading            : uiState.pageLoading,
  lastRefresh            : uiState.lastRefresh,
  browserWindowIsVisible : uiState.browserWindowIsVisible,
  isDisconnected         : uiState.isDisconnected,
  user_email             : ownProps.user_email,
  allIncidentsLoading    : incidentState.allIncidentsLoading,
  incidentError          : incidentState.error,
  statusLoading          : statusState.loading,
  statusError            : statusState.error,
  severityList           : severityState.severityList,
});
const mapDispatchToProps = (dispatch) => {
  const combinedActionCreators = {
    requestAllActiveIncidents : incidentActions.requestAllActiveIncidents,
    requestStatus             : statusActions.requestStatus,
    setPageLoading            : uiActions.setPageLoading,
    setIsDisconnected         : uiActions.setIsDisconnected,
  };

  return bindActionCreators(combinedActionCreators, dispatch);
};


class Landing extends Component {
  interval = null;

  static propTypes = {
    currentStatuses           : statusProptypes.currentStatuses,
    activeIncidents           : incidentProptypes.activeIncidents,
    requestAllActiveIncidents : incidentProptypes.requestAllActiveIncidents,
    requestStatus             : incidentProptypes.requestStatus,
    lastRefresh               : uiProptypes.lastRefresh,
    pageLoading               : uiProptypes.pageLoading,
    setPageLoading            : uiProptypes.setPageLoading,
    browserWindowIsVisible    : uiProptypes.browserWindowIsVisible,
    setIsDisconnected         : uiProptypes.setIsDisconnected,
  };

  componentDidMount = () => {
    this.intervalTask();
    this.interval = setInterval(this.intervalTask, 30000);
  }

  componentWillUnmount = () => {
    this.props.setPageLoading(true);
    clearInterval(this.interval);
  }

  componentWillReceiveProps(nextProps){
    /*
      If a user minimizes the window (browserWindowIsVisible == false)
      this will clear the interval and no API requests will be triggered.
      If a user brings the browser window back up, it will trigger the api calls
      and restart the interval.
    */
    if(this.props.browserWindowIsVisible !== nextProps.browserWindowIsVisible){
      if(nextProps.browserWindowIsVisible === true){
        this.intervalTask();
        this.interval = setInterval(this.intervalTask, 30000);
      } else {
        clearInterval(this.interval);
      }
    }
  }

  intervalTask = () => {
    if (this.props.isDisconnected === false) {
      this.props.requestAllActiveIncidents();
      this.props.requestStatus();
    } else {
      clearInterval(this.interval);
    }
  }

  render = () => {

    const {
      lastRefresh,
      activeIncidents,
      currentStatuses,
      allIncidentsLoading,
      incidentError,
      statusLoading,
      statusError,
      severityList,
    } = this.props;
    const recentIncidents = incidentSelectors.selectIncidentsByRecent(activeIncidents);

    const styles = {
      col: { paddingLeft: 0 },
      container: { width: '90%', margin: '0 auto' }
    };

    return (
        <div style={styles.container}>
          <LastRefresh data={lastRefresh} />
          <div className="row service-opi-view" key={"row"}>
            <div className='col-sm-12 col-lg-6' key={"col"} style={styles.col}>
              <ServicesCard statuses={currentStatuses} loading={statusLoading} error={statusError} />
              <StatusDetails severityList={severityList} />
            </div>
            <div className="col-sm-12 col-lg-6 opi-card-div">
              {IncidentColumn(recentIncidents, allIncidentsLoading, incidentError)}
            </div>
          </div>
        </div>
    );
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(Landing);
