import React from 'react';

import { getShortName } from '../../../utils/formatFunctions';
import { statusProptypes } from '../../../ducks/status/status.index';
import * as config from '../../../config';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton';
import { useTranslation } from 'react-i18next';
import { Translation } from 'react-i18next';

const errorCard = () => {
  return (
    <Translation>{(t) =>
      <div className="card services service-card card-shadow">
        <div className="card-header row">
          <h4 className="col" style={{paddingLeft: "0"}}>{t('containers.landingServicesCard.error')}</h4>
        </div>
        <div className="card-body">
        {t('containers.landingServicesCard.error2')}
        </div>
      </div>
    }</Translation>
  );
};

export default function ServiceStatus({ statuses, loading, error }) {
  const { t } = useTranslation(["translation"]);
  if (error) return errorCard();

  let openLink = config.REACT_APP_OPEN_URL;
  let serviceKeys;
  let services;
  let dataCenterSet = new Set();
  let dataCenters;
  // TODO: create a well thought out try/catch block
  try {
    serviceKeys = Object.keys(statuses);
    services    = statuses;

    // get a unique list of datacenters present in the status object
    for (let service in services) {
      for (let dc in services[service]) {
        dataCenterSet.add(dc)
      }
    }
  dataCenters = Array.from(dataCenterSet);

  } catch (err) {
    console.error(err);
  }

  const dataCenterDisplayNames = {
    'eu'            : t('containers.landingServicesCard.emea'),
    'cn'            : t('containers.landingServicesCard.china'),
    'usg'           : t('containers.landingServicesCard.ccps'),
    'us2'           : t('containers.landingServicesCard.us2'),
    'eu2'           : t('containers.landingServicesCard.eu2'),
    'concurgovprod' : t('containers.landingServicesCard.concurgovprod'),
  }

  const styles={
    smallPadding: {
      paddingLeft: "5px",
      paddingRight: "5px",
    },
    dcText: {
      "fontSize": '.9rem'
    }
  }

  const displayDataCenterFlags = (
    dataCenters.map((item, index) => (
      <div key={item + index} className="col" style={styles.smallPadding}>
        <div className={`${item.toUpperCase()} symbol`}></div>
        <div className="text-center" style={styles.dcText}>{dataCenterDisplayNames[item] || item.toUpperCase()}</div>
      </div>
    ),
    )
  );

  const showLoading = () => {
    return (
      <div className="col">
        <SkeletonTheme color="#ededed" highlightColor="#ffffff">
          <Skeleton height={20}/>
        </SkeletonTheme>
      </div>
    )
  }

  const displayServiceStatuses = (serviceName) => {
    const output = [];
    dataCenters.forEach((singleDataCenter, index) => {
      const currentServiceStatus = services[serviceName][singleDataCenter] || "NA";

      const urlName = serviceName.replace(/\//u, '_'); // needed for Analysis/Intelligence

      const currentSymbolDiv = <div className={`${currentServiceStatus} symbol ${singleDataCenter}-${getShortName(serviceName)}`}></div>;

      const currentStatusDiv = <div className="col" key={singleDataCenter + index} style={styles.smallPadding}>
        {currentServiceStatus === "normal" || currentServiceStatus === "NA" || currentServiceStatus === "NORMAL"
          ? currentSymbolDiv
          : <a href={`${openLink}/details/p1/${singleDataCenter}/${urlName}/Current%20Status`} target='_blank' rel="noopener noreferrer">{currentSymbolDiv}</a>
        }
        </div>;
      output.push(currentStatusDiv);
    });
    return output;
  };

  return (
    <div className="card services service-card card-shadow">
      <div className="card-header row d-flex justify-content-center">
        <h4 className="col-xl-4 col-lg-12 col-md-12 col-sm-12 my-auto" style={styles.smallPadding}>{t('containers.landingServicesCard.service')}</h4>
        {loading ? showLoading() : displayDataCenterFlags}
      </div>
      {
        serviceKeys.map((serviceName, index) => (
          <div className={`${getShortName(serviceName)}-row row d-flex justify-content-center list-group-item`} key={serviceName + index} >
            <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12" style={styles.smallPadding}>
            {/* adding a space between Analysis and Intelligence so that they're treated as separate words and are displayed properly in UI */}
              {t('containers.landingServicesCard.' + getShortName(serviceName))}
            </div>
            {loading ? showLoading() : displayServiceStatuses(serviceName)}
          </div>
        ),
        )
      }
    </div>
  );
}

ServiceStatus.propTypes = { statuses: statusProptypes.currentStatuses };
