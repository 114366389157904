import React, { useState } from 'react';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  actions as uiActions,
} from '../../ducks/ui/ui.index';
import {
  actions as templateActions
} from '../../ducks/template/template.index';
import { Modal, Button, Row, Col } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";

import { useTranslation } from 'react-i18next';
/* ========= PACKAGE IMPORTS ========= */
const mapStateToProps = ({uiState}) => ({
  pageLoading         :  uiState.pageLoading,
});
const mapDispatchToProps =  (dispatch) => {
  const combinedActionCreators = {
    setPageLoading           :  uiActions.setPageLoading,
    submitDeleteTemplate      :  templateActions.submitDeleteTemplate,
  };

  return bindActionCreators(combinedActionCreators, dispatch);
};

const DeleteTemplateModal = ({templateId, messageType, body, title, affectedService, submitDeleteTemplate, setPageLoading}) => {
  const { t } = useTranslation(["translation"]);
  let styles = {
    clickableLink  :  {
      cursor    :  'pointer',
      color     :  'blue',
    },
    button        : {
      width       :  '8rem',
    },
    buttonRight   : {
      width       : '8rem',
      marginLeft  : '5px'
    },
  }
  const [modalIsOpen,setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  }

  const closeModal = () => {
    setIsOpen(false);
  }

  const submitDelete = (templateId) => {
    setPageLoading(true);
    submitDeleteTemplate(templateId);
    closeModal();
    return true;
  }

  return (
    <div>
     <div style={styles.clickableLink} onClick={openModal} className={"data-cy-delete-template-button-"}>
        Delete
      </div>
      <Modal show={modalIsOpen} onHide={closeModal} className="data-cy-delete-template-modal">
        <Modal.Header>
          <Row><h3>Delete Template</h3></Row> 
        </Modal.Header>
        <Modal.Body >
          <Row>
            <div><b>Message Type:&nbsp;</b></div>
            <div>{messageType}</div>
          </Row>
          <Row>
            <div><b>Affected Service:&nbsp;</b></div>
            <div>{affectedService}</div>
          </Row>
          <Row>
            <div><b>Title:&nbsp;</b></div>
            <div>{title}</div>
          </Row>
          <Row>
            <div><b>Body:&nbsp;</b></div>
            <div>{body}</div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            <Button style={styles.button} variant="secondary" className="data-cy-delete-template-modal-close" onClick={closeModal}>
              {t('containers.incidentManagementUpdateMessage.close')}
            </Button>
            <Button style={styles.buttonRight} variant="danger" className="data-cy-delete-template-modal-submit" onClick={e => submitDelete(templateId)}>{t('containers.incidentManagementUpdateMessage.buttonDelete')}</Button>   
          </Row>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default connect(mapStateToProps,mapDispatchToProps)(DeleteTemplateModal);