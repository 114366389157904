export const canModifyUser = (requestingUser, targetUser) => {
  // This function is useful for determining whether a requesting user is allowed to modify a specific user in the system.
  // This function is NOT interested in whether the requesting user has the correct "allowed_actions" to edit/delete users in general.
  // Instead, it focuses on two criteria:
  //   - Does the requesting user have the "is_admin" flag?
  //   - Does the requesting user have (at least) all the same roles assigned as the target user?
  // If the answer to EITHER of the above questions is "Yes", this function returns True.
  // For concrete examples / expected behavior, check out the unit tests in this directory.
  return requestingUser["is_admin"] || (targetUser["role_ids"].filter(value => !requestingUser["role_ids"].includes(value)).length === 0);
}

export const canModifyRole = (requestingUser, targetRole) => {
  // This function is useful for determining whether a requesting user is allowed to modify a specific user in the system.
  // This function is NOT interested in whether the requesting user has the correct "allowed_actions" to edit/delete users in general.
  // Instead, it focuses on two criteria:
  //   - Does the requesting user have the "is_admin" flag?
  //   - Does the requesting user have (at least) all the same roles assigned as the target user?
  // If the answer to EITHER of the above questions is "Yes", this function returns True.
  // For concrete examples / expected behavior, check out the unit tests in this directory.
  return requestingUser["is_admin"] || (targetRole["actions"].filter(value => !requestingUser["allowed_actions"].includes(value)).length === 0);
}

export const getAssignableRoles = (requestingUser, allRoles) => {
  // Users are only ever allowed to assign roles that they themselves have.
  // This function simply filters all of the roles in the system down to just the ones the requesting user has.
  // (Unless the requesting user has the "is_admin" flag, in which case it just returns all the roles back.)
  // For concrete examples / expected behavior, check out the unit tests in this directory.
  return requestingUser["is_admin"] ? allRoles : (allRoles.filter(value => requestingUser["role_ids"].indexOf(value['role_id']) > -1))
}

export const getAssignableActions = (requestingUser, allActions) => {
  // Users are only ever allowed to assign roles that they themselves have.
  // This function simply filters all of the roles in the system down to just the ones the requesting user has.
  // (Unless the requesting user has the "is_admin" flag, in which case it just returns all the roles back.)
  // For concrete examples / expected behavior, check out the unit tests in this directory.
  return requestingUser["is_admin"] ? allActions : (allActions.filter(value => requestingUser["allowed_actions"].indexOf(value['system_name']) > -1))
}


export const capitalizeFirstLetter = (string) => {
  const regex = /_/g;
  string = string.replace(regex, ' ');
  const words = string.split(' ');
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substr(1);
  }
  return words.join(" ");
}
