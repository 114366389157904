import React from 'react';
import CardDetails from '../../Details/CardDetails/CardDetails';
import { Row, Card } from 'react-bootstrap';
export const CustomerInfoTile = ({ selectedCompany, selectedCompanyIncidents }) => {
  selectedCompany.active = "No"
  // loop through the incidents array and return true on the first active incident it finds
  selectedCompanyIncidents.some(incident => {
    if (incident.status === "ACTIVE" || incident.status === "INVESTIGATION") {
      selectedCompany.active = "Yes"
      return true
    }
  })
  const customerInfoMap = {
    'name'        : 'Company Name',
    'id'          : 'Outtask ID',
    'entity_code' : 'Entity ID',
    'data_center' : 'Data Center',
    'active'      : 'Active Incident?',
  };
  return (
    <>
      <Card.Header className="row d-flex">
        <h4 className="my-auto">Customer Info</h4>
      </Card.Header>
      <Card.Body>
        {
          Object.keys(customerInfoMap).map((property) => (
            <CardDetails key={property} property={customerInfoMap[property] || property} data={selectedCompany[property]} />
          ))
        }
      </Card.Body>
    </>
  );
};