import React from 'react';
import { Col } from 'react-bootstrap';
import { dateTimeToUTCString } from '../../../utils/formatFunctions';
import ListGroup from 'react-bootstrap/ListGroup';
import { useTranslation } from 'react-i18next';
const styles = {
  'internal': {
    'color': '#00A9F2',
  },
  'header' : {
    'fontWeight' : 'bold',
    'padding'    : '0',
  },
  'body' : {
    'fontWeight' : 'light',
    'padding'    : '0',
    'whiteSpace' : 'pre-wrap',
  }
};

// Shape of a message
// {
//   "title": "Issue Identified",
//   "body": "We are working to determine the user impact and will provide information as it becomes available.",
//   "communication_type": "openmt_message",
//   "created_epoch": 1580151960
// }

const sortMessages = (messages, sortAsc) => {
  return [...messages].sort((a, b) => {
    a = a.created_epoch
    b = b.created_epoch
    switch (sortAsc) {
      case true:
        return a - b
      case false:
        return b - a
      default:
        return a - b
    }
  })
}

const { internal, header, body } = styles;

const RenderMessage = ({message, displayLocale}) => {
  return (
    <ListGroup.Item className={`${message.created_epoch}-cypress`} style={message.title.toLowerCase().includes('internal') ? internal : {}}> 
      <div sm={12} style={header}className="cypress-title">{message.title}</div>
      <div sm={12} style={body} className="cypress-created-epoch">{dateTimeToUTCString(message.created_epoch * 1000)}</div>
      {displayLocale == 'ja' && <Col sm={12} style={body}  className="cypress-body">{message.body.ja}</Col>}
      {displayLocale == 'en' && <Col sm={12} style={body}  className="cypress-body">{message.body.en}</Col>}
    </ListGroup.Item>
  );
}

const RenderList = ({messages, displayLocale}) => {
  return (
    <ListGroup variant="flush">
      {
        messages.map((message) => {
          return <RenderMessage message={message} key={message.created_epoch} displayLocale={displayLocale}/>
        })
      }
    </ListGroup>
  )
}

const CustomerCommunications = (props) => {
  const {messages, sortAsc, displayLocale} = props;
  const { t } = useTranslation(["translation"]);
  let returnMessages;
  if (!messages) {
    returnMessages = <div>{t('containers.detailsCustomerCommunications.error')}</div>
  } else  if (messages.length === 0) {
    returnMessages = <div>{t('containers.detailsCustomerCommunications.empty')}</div>
  } else {
    let sortedMessages = sortMessages(messages, sortAsc);
    returnMessages = <RenderList messages={sortedMessages} displayLocale={displayLocale}/>
  }
  return returnMessages
};

export default CustomerCommunications;
