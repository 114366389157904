import http from "../../services/httpService";
import * as config from "../../config";


let apiEndpoint = '/templates';


export const getTemplates = async () => {
  const result = await http.get(`${apiEndpoint}`);
  return result;
};

export const getTemplateChangeHistory = async (template_id) => {
  const result = await http.get(`get_template_change_history/?template_id=${template_id}/`);
  return result;
}

export const createTemplate = async (payload) => await http.post(`${apiEndpoint}`, payload);
export const updateTemplate = async (payload) => await http.put(`${apiEndpoint}`, payload);
export const deleteTemplate = async (template_id) => await http.get(`delete_template/?template_id=${template_id}`);

