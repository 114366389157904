import React       from 'react';
import { useTable, useSortBy } from 'react-table'
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as config from '../../../config';
import './HistoryTable.css'

import {
  dateTimeToUTCString,
  joinArrayToString,
  formatNumberWithDelimiters,
  sortSymbol,
  sortOpi,
} from "../../../utils/formatFunctions";
import { incidentProptypes } from '../../../ducks/incident/incident.index';
import 'react-table-6/react-table.css';
import ReadMore from '../../Landing/OpiCard/ReadMore';
import { useTranslation } from 'react-i18next';

const styles = {
  'container' : {
    'boxSizing'       : 'border-box',
    'borderRadius'    : '10px',
    'backgroundColor' : 'rgb(250, 250, 250)',
  },
  'headers': {
    'textAlign'    : 'left',
    'lineHeight'   : '2rem',
  },
  'headerCenter': {
    'textAlign'    : 'center',
    'lineHeight'   : '2rem',
  },
  'img': {
    'height'       : 'auto',
    'width'        : '24px',
  },
  'table': {
    'height'       : '84vh',
    'border'       : 'none',
  },
  'tableContainer': {
    // 'paddingTop'   : '1%'
    'fontSize': '.94rem'
  },
  'tablePadding': {
    'borderLeft'   : '1px solid #ddd',
    'borderRight'  : '1px solid #ddd',
    'borderBottom' : '1px solid #ddd',
  },
  'unset' : {
    'whiteSpace': 'unset'
  },
  'prewrap' : {
    'whiteSpace': 'pre-wrap'
  }
};


function RenderTable({ columns, values }) {
  const { t } = useTranslation(["translation"]);

  const data = React.useMemo(() => 
  values
  , [values]);

  const sorting = React.useMemo(
    () => [
      {
        id: "startDate",
        desc: true
      }
    ],
    []
  );
  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? 'desc' : 'asc') : '';
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: sorting
      }
    },
    useSortBy,
  )
  return (
    <>
      <Table bordered {...getTableProps()} className="cy-history-table" style={styles.allStyle}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr className='rt-thead' {...headerGroup.getHeaderGroupProps()} >
              {headerGroup.headers.map((column, i) => {
                const {render, getHeaderProps} = column
                return (
                  <th className={generateSortingIndicator(column) + " " + column.id + "-header"} key={'header' + i} {...getHeaderProps(column.getSortByToggleProps())}>{render("Header")}</th>
                )
              })}
            </tr>
          ))}
        </thead>
        {rows.length > 0 && <tbody {...getTableBodyProps()} className="rt-tbody">
          {rows.map((row, i) => {
            prepareRow(row);
            let styling = row && row.original.isInternal && row.original.isInternal == 'Y' ? {backgroundColor: '#00a9f24a'} : {};            
            return (

              <tr className='rt-tr-group' key={'row' + i} {...row.getRowProps()} style={styling}>
                {row.cells.map((cell, j) => {
                  return <td className='rt-td' key={"row" + i + 'col' + j} {...cell.getCellProps({
                    style: {
                      minWidth: cell.column.minWidth,
                      width: cell.column.width,
                    },
                  })}>{cell.render("Cell")}</td>;
                })}
              </tr>
            );
          })}
        </tbody>}
        {rows.length == 0 &&  <><tr><td/><td/><td/><td/><td/><td/></tr>
        <div style={styles.noData} className='rt-noData'>{t('containers.incidentManagmentReviewDetailsTable.noData')}</div>
        <tr><td/><td/><td/><td/><td/><td/></tr>
        <tr><td/><td/><td/><td/><td/><td/></tr>
        <tr><td/><td/><td/><td/><td/><td/></tr>
        <tr><td/><td/><td/><td/><td/><td/></tr>
        <tr><td/><td/><td/><td/><td/><td/></tr>
        <tr><td/><td/><td/><td/><td/><td/></tr></>}
      </Table>
    </>
  );
}

export const findRCA = (messages, displayLocale) => {
  // sort in desc order so newest messages are first in the array
  // if there are multiple IRCAs and no RCAs, we will want to show the most recent IRCA
  if (!messages){
    return;
  }
  let sortedMessages = messages.sort((a, b) => b.created_epoch - a.created_epoch)
  let rca;
  try {
    let found = sortedMessages.find(({title}) => title === "Root Cause Analysis");
    // found will == undefined if no RCA is available so then check for IRCAs
    if (!found) {
      found   = sortedMessages.find(({title}) => title === "Intermediate Root Cause Analysis");
    };
    // this try block will fail at found.body if no IRCA is found and default to and empty string
    rca = <span style={styles.prewrap}><ReadMore details={found.body[displayLocale]} characterLimit={50} /></span>;
  } catch (error) {
    rca = '';
  };
  return rca;
};


const HistoryTable = (props) => {
  // table data
  const jiraUrl = config.REACT_APP_JIRA_URL;
  const {historicIncidents, windowDimensions, displayLocale} = props;
  const { headerCenter, headers, tableContainer, container, table, unset } = styles;
  const breakPoint   = 1270;
  const { t } = useTranslation(["translation"]);
  const columns = React.useMemo(
    () => [
        {
          Header: <b data-cy='info-header'>i</b>,
          headerStyle: headerCenter,
          id: 'symbol',
          width: 50,
          accessor      : data => <div className={`${data.symbol} symbol`}></div>,
          sortMethod    : (a, b) => sortSymbol(a, b)
        },
        {
          Header: <b data-cy='details-header'>{t('containers.historyTable.details')}</b>,
          headerStyle: headers,
          id: 'jira_id',
          width: 100,
          accessor:  data => <Link to={`/details/${data.jiraId}`} className={`${data.jiraId} view more`}>{t('containers.historyTable.viewDetails')}</Link>,
          sortMethod    : (a, b) => sortSymbol(a, b)
        },
        {
          Header: <b className="opi-header">{t('containers.historyTable.opi')}</b>,
          headerStyle: headers,
          id: 'jiraId',
          width: 120,
          accessor    : data => <a href={`${jiraUrl}/browse/${data.jiraId}`} target="_blank" rel="noopener noreferrer" >{data.jiraId}</a>,
          sortMethod  : (a, b) => sortOpi(a, b),
        },
        {
          Header      : <b className="internal-header">{t('containers.historyTable.internal')}</b>,
          headerStyle : headers,
          id          : 'isInternal',
          width       : 100,
          accessor    : data => <div>{data.isInternal == 'Y' ? 'Internal' : 'External'}</div>,
        },
        {
          Header: <b className="dc-header">{t('containers.historyTable.data')}</b> ,
          headerStyle: headers,
          id: 'affectedDataCenters',
          width: 200,
          accessor      : (data) => joinArrayToString(data.affectedDataCenters),
        },
        {
          Header: <b>{t('containers.historyTable.start')}</b> ,
          headerStyle: headers,
          id: 'startDate',
          accessor: 'startDate',
          Cell: ({value}) => <div><p>{dateTimeToUTCString(value)}</p></div>,
          width: 240,     
        },
        {
          Header      :  <b className="end-header">{t('containers.historyTable.end')}</b>,
          headerStyle : headers,
          id          : 'endDate',
          accessor    : 'endDate',
          Cell: ({value}) => <div><p>{dateTimeToUTCString(value)}</p></div>,
          width       : 240,
          
          sortMethod         : (a, b) => new Date(a).getTime() - new Date(b).getTime(),
        },
        {
          Header      :  <b className="services-header">{t('containers.historyTable.affected')}</b>,
          headerStyle : headers,
          id          : 'affectedServices',
          width       : 320,
          accessor    : (data) => joinArrayToString(data.affectedServices),
        },    
        {
          Header      :  windowDimensions.width >= breakPoint ? <b className="companies-header">{t('containers.historyTable.total')}</b> : <b className="companies-header">{t('containers.historyTable.tca')}</b>,
          headerStyle : headers,
          id          : 'affectedCompanies',
          accessor    : 'affectedCompanies',
          width       : 120,
          filter      : 'includes',
          Cell        : ({value}) => formatNumberWithDelimiters(value),
        },
        {
          Header      : windowDimensions.width >= breakPoint ? <b className="rca-header">{t('containers.historyTable.root')}</b> : <b className="rca-header">{t('containers.historyTable.rca')}</b>,
          headerStyle : headers,
          id          : 'messages',
          width       : 260,
          accessor    : data => findRCA(data.messages, displayLocale),
        },
       
      ],
    []
  )

  // render block
  return (
    <div className="card-shadow" style={container}>
      <div style={tableContainer}>
        <RenderTable columns={columns} values={historicIncidents}/>
      </div>
    </div>
  );
};

export default HistoryTable;

HistoryTable.propType = incidentProptypes.historicIncidents;
