import { takeEvery, put, call, select } from "redux-saga/effects";

import { getSeverities }  from './severity.api';
import { types, actions } from './severity.index';
import { actions as errorActions } from '../error/error.index';
import { actions as toastActions } from '../toast/toast.index';

export function* fetchSeverities() {
  try {
    let response =  yield call(getSeverities);
    let payload  =  response.data.severity_list;
    yield put(actions.setSeverities(payload));
  }
  catch(error) {
    // handle ui effects
    const message      =  `error from the server: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
  }
}


export default function severitySagas() {
  return [
    takeEvery(types.REQUEST_SEVERITIES, fetchSeverities),
  ];
}
