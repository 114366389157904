import React from 'react';
import Moment from 'react-moment';
import { formatDurationToNearestMinute } from '../../../utils/formatFunctions';

const styles = {
  fontStyle: {
    fontWeight: '600'
  }
}

const renderDuration = (start, end) => {
  return (
    <Moment
      duration = {start}
      date     = {end}
      filter   = {formatDurationToNearestMinute}
    />
  );
};

const ResolvedBar = (props) => {
  const { start, end } = props;
  return (
    // Basic render for resolved OPIs - we can decide on verbiage later on
    <div className="resolved-bar d-flex justify-content-center align-items-center">
      <div style={styles.fontStyle}>
        Incident Resolved In: {renderDuration(start, end)}
      </div>
    </div>
  );
};

export default ResolvedBar;
