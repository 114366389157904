import React from 'react';
// import { Link } from "react-router-dom";
import Moment from 'react-moment';
import { string, number } from 'prop-types';
import { Link } from 'react-router-dom';
import Skeleton, {SkeletonTheme} from 'react-loading-skeleton';
import * as config from '../../../config';
import { useTranslation } from 'react-i18next';
import { Translation } from 'react-i18next';

import {
  formatDurationToNearestMinute,
  dateTimeToUTCString,
  joinArrayToString,
  formatNumberWithDelimiters,
} from "../../../utils/formatFunctions";
import { incidentProptypes } from '../../../ducks/incident/incident.index';
import './OpiCard.css';
import ReadMore from './ReadMore';

const styles = {
  overAllCard: {
    borderRight  : "none",
    borderBottom : "none",
  },
  internal: {
    backgroundColor: '#00a9f24a'
  },
  internalHeader: {
    backgroundColor: '#00a9f278',
    borderBottom: 'None'
  },
  internalBorder: {
    border: 'none'
  },
  opiCardStyle: {
    paddingLeft   : 0,
    paddingBottom : "none",
    flexBasis: "content",
    paddingRight: 0
  },
  statusSymbol: {
    display : "inline-block"
  },
  opiTitleStyle: {
    marginBottom : 0,
    paddingLeft  : ".5rem",
    display      : "inline-block",
    paddingRight : 0,
  },
  internalOnly : {
    fontSize     : "1rem",
    paddingLeft  : 0,
    color        : 'red',
    flexBasis    : "content",
    display      : "inline-block",
    paddingRight : 0,
  },
  startDateStyle: {
    fontSize : ".8rem",
  },
  viewMoreDetailsButton: {
    border       : "1px solid rgba(0, 0, 0, 0.125)",
    borderRight  : "none",
    borderLeft   : "none",
    borderBottom : "none",
    margin       : "0",
    borderRadius : "none",
    fontSize     : "1.15rem",
    fontWeight   : "600"
  },
};

const { opiCardStyle, opiTitleStyle, startDateStyle, viewMoreDetailsButton, overAllCard, internal, internalHeader, internalBorder, statusSymbol, internalOnly } = styles; // es6 object destructuring

const infoBox = (data, isInternal) => {
  const { id, name, value, startDate } = data; // es6 object destructuring
  return (
    <div key={name} className="col-lg-3 col-sm-6" >
      <div className="card inner-card text-center" style={isInternal == 'Y' ? internalBorder : {}}>
        <div className={`card-header card-header-${id}`} style={isInternal == 'Y' ? internalHeader : {}}>
          {name}
          {startDate ? <div style={startDateStyle} className={`start-date`}> {startDate} </div> : null}
        </div>
        <div className={`inner-card-text card-body-${id}`} style={isInternal == 'Y' ? internal : {}}>
          {value}
        </div>
      </div>
    </div>
  );
};

const refreshPage = () => window.parent.location = window.parent.location.href;

const loadingAndErrorCard = ({title, body, button}) => {
  return (
    <div className={`card border-3 card-shadow`} style={overAllCard}>
      <div className="card-header">
        {title}
      </div>
      <div className="card-body">
        {body}
      </div>
      {button}
    </div>
  );
};

const states = {
  loading: {
    title : <div className       = "col-lg-3 col-sm-12"><SkeletonTheme color = "#ededed" highlightColor   = "#ffffff"><Skeleton height = {34}/></SkeletonTheme></div>,
    body  : <SkeletonTheme color = "#ededed" highlightColor                  = "#ffffff"><Skeleton height = {120}/></SkeletonTheme>,
    button: <SkeletonTheme color = "#ededed" highlightColor                  = "#ffffff"><Skeleton height = {20}/></SkeletonTheme>,
  },
  error: {
    title  : <h4><Translation>{(t) => t('containers.landingOpiCard.error')}</Translation></h4>,
    body   : <Translation>{(t) => t('containers.landingOpiCard.error2')}</Translation>,
    button : <button type="button" onClick={refreshPage} className={'btn btn-outline-secondary btn-sm'} style={viewMoreDetailsButton}><span><Translation>{(t) => t('containers.landingOpiCard.reload')}</Translation></span> </button>,
  }
}

infoBox.propTypes = {
  id        : number,
  name      : string,
  value     : string,
  startDate : string,
};

export default function OpiCard({ data, loading, error }) {
  const { t } = useTranslation(["translation"]);
  if (error) return loadingAndErrorCard(states.error);
  if (loading) return loadingAndErrorCard(states.loading);
  const jiraUrl = config.REACT_APP_JIRA_URL;
  
  const {
    startDate,
    affectedCompanies,
    affectedDataCenters,
    affectedServices,
    jiraId,
    symbol,
    details,
    isInternal,
  } = data; // destructuring the props object
 

  const infoBoxData = [
    {
      id: 0,
      name: t('containers.landingOpiCard.start'),
      value: <div>{t('containers.landingOpiCard.time')} <Moment date={startDate} durationFromNow interval={10000} filter={formatDurationToNearestMinute} /></div>,
      startDate: dateTimeToUTCString(startDate),
    },
    {
      id: 1,
      name: t('containers.landingOpiCard.dataCenters'),
      value: joinArrayToString(affectedDataCenters),
    },
    {
      id: 2,
      name: t('containers.landingOpiCard.services'),
      value: joinArrayToString(affectedServices),
    },
    {
      id: 3,
      name: t('containers.landingOpiCard.number'),
      value: formatNumberWithDelimiters(affectedCompanies),
    }
  ];

  return (
    <div className={`card border-3 opi-card card-shadow ${jiraId}`} style={overAllCard}>
      <div className="card-header row justify-content-between">
        <div className="col opi-title-symbol-container" style={opiCardStyle}>
          <div className={`${symbol} symbol ${jiraId}-symbol`} style={statusSymbol}></div>
          <h3 className="opi-title-number" style={opiTitleStyle}>
            <a className="jira-opi-link" href={`${jiraUrl}/browse/${jiraId}`} target="_blank" rel="noopener noreferrer">{jiraId + (isInternal == 'Y' ? t('containers.landingOpiCard.internalHeader') : '')}</a>
          </h3>
        </div>
        <span className="col text-right align-middle" style={internalOnly}>{t('containers.landingOpiCard.internal')}</span>
      </div>
      <div className="row info-box" style={isInternal == 'Y' ? internal : {}}>
        {infoBoxData.map((detail) => infoBox(detail, isInternal))}
      </div>
      <div style={isInternal == 'Y' ? internal : {}}>
        <div className="row details-container" >
          <div className={`col-lg col-sm-12 ${jiraId}-details`} >
            <ReadMore details={details} characterLimit={300} />
          </div>
        </div>
      </div>
      <Link to={`/details/${jiraId}`} className={`btn btn-outline-secondary btn-sm viewMoreDetailsButton-${jiraId}`} style={viewMoreDetailsButton}>{t('containers.landingOpiCard.viewMore')}</Link>
    </div>
  );
}

OpiCard.propTypes = incidentProptypes.incident;
