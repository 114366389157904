import React       from 'react';
import ReactTable  from 'react-table-6';
import * as config from '../../../config';
import { epochTimeToUTCString, joinArrayToString, sortDate } from "../../../utils/formatFunctions";
import { incidentProptypes } from '../../../ducks/incident/incident.index';
import 'react-table-6/react-table.css';
import ReadMore from '../../Landing/OpiCard/ReadMore';


const styles = {
  'container' : {
    'boxSizing'       : 'border-box',
    'borderRadius'    : '0',
    'backgroundColor' : 'rgb(250, 250, 250)',
  },
  'headers': {
    'textAlign'    : 'left',
    'lineHeight'   : '2rem',
  },
  'headerCenter': {
    'textAlign'    : 'center',
    'lineHeight'   : '2rem',
  },
  'img': {
    'height'       : 'auto',
    'width'        : '24px',
  },
  'table': {
    /* TODO this is an approximation to keep the horizontal scroll visible and fixed to the bottom. If it can be properly "flex"-ified, we should do so. */
    'height'       : 'calc(96vh - 206px)',
    'border'       : 'none'
  },
  'tableContainer': {
    // 'paddingTop'   : '1%'
    'fontSize': '.94rem'
  },
  'tablePadding': {
    'borderLeft'   : '1px solid #ddd',
    'borderRight'  : '1px solid #ddd',
    'borderBottom' : '1px solid #ddd',
  },
  'unset' : {
    'whiteSpace': 'unset'
  },
  'prewrap' : {
    'whiteSpace': 'pre-wrap'
  },
};



const ChangeHistoryAutoTable = (props) => {
  // table data
  const jiraUrl = config.REACT_APP_JIRA_URL;
  const { data: rows, windowDimensions} = props;
  const { headerCenter, headers, tableContainer, container, table, unset, prewrap } = styles;
  const defaultSort  = [];
  const columns      = [
    {
      'Header'      : <b className="opi-header">Summary</b>,
      'headerStyle' : headers,
      'id'          : 'summary',
      'accessor'    : (data) => data.summary,
      'style'       : prewrap,
      'width'       : 300,
      //sortMethod  : (a, b) => sortOpi(a, b),
    },
    {
      'Header'      : <b className="actual-end-header">Submitted On</b>,
      'headerStyle' : headers,
      'id'          : 'submitted_on',
      'accessor'    : (data) => epochTimeToUTCString(data.submitted_on),
      'style'       : unset,
      'width'       : 260,
      sortMethod    : (a, b) => sortDate(a, b),
    },
    {
      'Header'      : <b className="submitted-by-header">Submitted By</b>,
      'headerStyle' : headers,
      'id'          : 'submitted_by',
      'accessor'    : (data) => data.submitted_by,
      'width'       : 240,
      'style'       : unset,
    },
    {
      'Header'      : <b className="info-header">Catalog Type</b>,
      'headerStyle' : headers,
      'id'          : 'catalog_type',
      //'style'     : {textAlign: "center"},
      'width'       : 300,
      'accessor'    : (data) => data.catalog_type,
      //sortMethod  : (a, b) => sortSymbol(a, b),
    },
    {
      'Header'      : <b className="info-header">Reference</b>,
      'headerStyle' : headers,
      'id'          : 'master_ticket',
      //'style'     : {textAlign: "center"},
      'width'       : 200,
      'accessor'    : (data) => data.ticket_id,
      Cell          : data => (data.value ? <a href={`${jiraUrl}/browse/${data.value}`} target="_blank" rel="noopener noreferrer" >{data.value}</a> : "(None Provided)"),
      //sortMethod  : (a, b) => sortSymbol(a, b),
    },
    {
      'Header'      : <b className="dc-header">Description</b> ,
      'headerStyle' : headers,
      'id'          : 'description',
      'accessor'    : (data) => <ReadMore details={data.description} characterLimit={65} />,
      'width'       : 400,
      'style'       : unset,
    },
    {
      'Header'      : <b className="data-centers-header">Data Centers</b>,
      'headerStyle' : headers,
      'id'          : 'data_centers',
      'accessor'    : (data) => joinArrayToString(data.data_centers),
      'style'       : unset,
      'width'       : 260,
    },
    {
      'Header'      : <b className="services-header">Services</b>,
      'headerStyle' : headers,
      'id'          : 'services',
      'accessor'    : (data) => joinArrayToString(data.services),
      'style'       : unset,
      'width'       : 260,
    },
    {
      'Header'      : <b className="cis-header">CIs</b>,
      'headerStyle' : headers,
      'id'          : 'cis',
      'accessor'    : (data) => <ReadMore details={joinArrayToString(data.cis)} characterLimit={65} />,
      'style'       : unset,
      'width'       : 260,
    },
    {
      'Header'      : <b className="dc-header">Github Owner</b> ,
      'headerStyle' : headers,
      'id'          : 'github_owner',
      'accessor'    : (data) => data.github_owner,
      'width'       : 120,
      'style'       : unset,
    },
    {
      'Header'      : <b className="dc-header">Github Repo</b> ,
      'headerStyle' : headers,
      'id'          : 'github_repo',
      'accessor'    : (data) => data.github_repo,
      'width'       : 110,
      'style'       : unset,
    },
    {
      'Header'      : <b className="dc-header">Github SHA</b> ,
      'headerStyle' : headers,
      'id'          : 'github_sha',
      'accessor'    : (data) => data.github_sha,
      'width'       : 100,
      'style'       : unset,
    },
    {
      'Header'      : <b className="dc-header">Github Tag</b> ,
      'headerStyle' : headers,
      'id'          : 'github_tag',
      'accessor'    : (data) => data.github_tag,
      'width'       : 100,
      'style'       : unset,
    },
  ];

  // render block
  return (
    <div className="card-shadow" style={container}>
      <div style={tableContainer} className="change-history-table-view">
        <ReactTable
          data            = {rows}
          defaultSorted   = {defaultSort}
          columns         = {columns}
          style           = {table}
          className       = "-striped -highlight"
          showPagination  = {false}
          sortable        = {false}
          defaultPageSize = {rows.length}
          />
      </div>
    </div>
  );
};

export default ChangeHistoryAutoTable;

ChangeHistoryAutoTable.propType = incidentProptypes.rows;
