import React from 'react';
import './i18n';
import { render as renderDom } from 'react-dom';
import App from './containers/App/App.js';
import store from './redux/store';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';

if (window.Cypress) {
  // Automatically consider Cypress "logged in" because (1) Cypress does not need to access the back-end, and (2) Cypress is not being used to test auth
  // DEVELOPER NOTE: This is not a security risk; all front-end artifacts are manipulable by the user. The security lives on the back-end, in that all API requests must be authenticated.
  store.dispatch({type: '[user] SET_USER', payload: { email: "cypress.test@concur.com", isLoggedIn: true, appRights: {}, authorization: {"is_admin": true, "allowed_actions" : []} }});
  window.store = store;
}

const root = document.createElement('div');
document.body.appendChild(root);
renderDom(<CookiesProvider><Provider store={store}><App /></Provider></CookiesProvider>, root);
