import sortBy from 'lodash/sortBy';
import { string, number, arrayOf, shape, oneOfType, oneOf, func, object } from 'prop-types';
import { formatIncidentData } from '../../utils/formatFunctions';


export const priorityTypes = {
  'p1': 'P1',
  'p2': 'P2',
};

export const editablePriortyTypes = ['P1','P2'];

/* ===== STATE_DEFINITION ===== */
const initialState = {
  // P1 Content
  activeIncidents   : [],
  historicIncidents : [],
  detailedIncidents : [],
  incidentMessages  : [],
  selectedIncident  : {},
  allIncidentsLoading  : true,
  error             : false,
  messageSortAsc    : false,
  specificIncidentLoading  : true,

  // P2 Content
  p2Incidents: [],
  p1Incidents: [],
  emailFields: {},
  p2AlertTypes: [],
  p1AlertTypes: [],

  // Banner
  bannerText        : '',
  bannerDisplay     : false,
  messagesLoading   : false,
};


/* ===== PROP_TYPES ===== */
const incidentShape = {
  opi                 :  string,
  affectedCompanies   :  number,
  affectedDataCenters :  arrayOf(string),
  affectedServices    :  arrayOf(string),
  affectedUsers       :  number,
  details             :  string,
  endDate             :  oneOfType([
    string,
    oneOf([null]),
  ]),
  slaEndTime    :  string,
  startDate     :  string,
  status        :  string,
  symbol        :  string,
  rca           :  string,
  messages      :  arrayOf(object),
};

export const incidentProptypes = {
  incident                    :  incidentShape,
  selectedIncident            :  incidentShape,
  activeIncidents             :  arrayOf(shape(incidentShape)),
  historicIncidents           :  arrayOf(shape(incidentShape)),
  requestAllActiveIncidents   :  func,
  requestAllHistoricIncidents :  func,
  requestStatus               :  func,
  requestIncidentDetails      :  func,
  setIncidentDetails          :  func,
  requestAffectedCompanies    :  func,
  setMessageSortAsc           :  func,
  requestEmailFields          :  func,
  setEmailFiends              :  func,
  submitCreateMessage         :  func,
  submitUpdateMessage         :  func,
  submitDeleteMessage         :  func,
  setP2AlertTypes             :  func,
  setP1AlertTypes             :  func,
  requestP2AlertTypes         :  func,
  requestBannerState          :  func,
  submitUpdateBanner          :  func,
  setBannerText               : func,
  setBannerDisplay            : func,
  setSpecificIncidentLoading  : func,
};


/* ===== TYPES ===== */
export const types = {
  REQUEST_ALL_ACTIVE_INCIDENTS    : '[incidents] REQUEST_ALL_ACTIVE',
  REQUEST_ALL_HISTORIC_INCIDENTS  : '[incidents] REQUEST_ALL_HISTORIC',
  REQUEST_INCIDENT_DETAILS        : '[incidents] REQUEST_DETAILS',
  REQUEST_INCIDENT_BY_ID          : '[incidents] REQUEST_INCIDENT_BY_ID',
  SET_SELECTED_INCIDENT           : '[incidents] SET_SELECTED_INCIDENT',
  REQUEST_AFFECTED_COMPANIES      : '[incidents] REQUEST_AFFECTED_COMPANIES',
  SET_ALL_ACTIVE_INCIDENTS        : '[incidents] SET_ALL_ACTIVE',
  SET_ALL_HISTORIC_INCIDENTS      : '[incidents] SET_ALL_HISTORIC',
  SET_INCIDENT_DETAILS            : '[incidents] SET_DETAILS',
  REQUEST_INCIDENTS_FAILED        : '[incidents] REQUEST_FAILED',
  SET_ALL_INCIDENTS_LOADING       : '[incidents] SET_ALL_INCIDENTS_LOADING',
  SET_INCIDENT_ERROR              : '[incidents] SET_ERROR',
  SET_MESSAGE_SORT                : '[incidents] SET_MESSAGE_SORT',
  REQUEST_INCIDENT_MESSAGES       : '[incidents] REQUEST_INCIDENT_MESSAGES',
  SET_INCIDENT_MESSAGES           : '[incidents] SET_INCIDENT_MESSAGES',
  REQUEST_INCIDENTS               :  '[incidents] REQUEST_INCIDENTS',
  SET_INCIDENTS                   :  '[incidents] SET_INCIDENTS',
  SET_SPECIFIC_INCIDENT_LOADING   :  '[incidents] SET_SPECIFIC_INCIDENT_LOADING',
  APPEND_INCIDENTS                :  '[incidents] APPEND_INCIDENTS',
  REQUEST_EMAIL_FIELDS            :  '[incidents] REQUEST_EMAIL_FIELDS',
  SET_EMAIL_FIELDS                :  '[incidents] SET_EMAIL_FIELDS',
  SUBMIT_CREATE_MESSAGE           :  '[incidents] SUBMIT_CREATE_MESSAGE',
  SUBMIT_UPDATE_MESSAGE           :  '[incidents] SUBMIT_UPDATE_MESSAGE',
  SUBMIT_DELETE_MESSAGE           :  '[incidents] SUBMIT_DELETE_MESSAGE',
  SET_P2_ALERT_TYPES              :  '[incidents] SET_P2_ALERT_TYPES',
  SET_P1_ALERT_TYPES              :  '[incidents] SET_P1_ALERT_TYPES',
  REQUEST_P2_ALERT_TYPES          :  '[incidents] REQUEST_P2_ALERT_TYPES',
  REQUEST_BANNER_STATE            :  '[incidents] REQUEST_BANNER_STATE',
  SUBMIT_UPDATE_BANNER            :  '[incidents] SUBMIT_UPDATE_BANNER',
  SET_BANNER_TEXT                 :  '[incidents] SET_BANNER_TEXT',
  SET_BANNER_DISPLAY              :  '[incidents] SET_BANNER_DISPLAY',
  SET_MESSAGES_LOADING            :  '[incidents] SET_MESSAGES_LOADING',
};


/* ===== ACTION_CREATORS ===== */
export const actions = {
  requestAllActiveIncidents   : ()            => ({type: types.REQUEST_ALL_ACTIVE_INCIDENTS}),
  requestAllHistoricIncidents : ()            => ({type: types.REQUEST_ALL_HISTORIC_INCIDENTS}),
  setAllActiveIncidents       : (incidents)   => ({type: types.SET_ALL_ACTIVE_INCIDENTS, payload: incidents}),
  setAllHistoricIncidents     : (incidents)   => ({type: types.SET_ALL_HISTORIC_INCIDENTS, payload: incidents}),
  requestIncidentDetails      : (id)          => ({type: types.REQUEST_INCIDENT_DETAILS, payload: id}),
  requestAffectedCompanies    : (id)          => ({type: types.REQUEST_AFFECTED_COMPANIES, payload: id}),
  requestIncidentByJiraId     : (id)          => ({type: types.REQUEST_INCIDENT_BY_ID, payload: id}),
  setSelectedIncident         : (incident)    => ({type: types.SET_SELECTED_INCIDENT, payload: incident}),
  setIncidentDetails          : (incident)    => ({type: types.SET_INCIDENT_DETAILS, payload: incident}),
  setIncidentError            : (bool)        => ({type: types.SET_INCIDENT_ERROR, payload: bool}),
  setMessageSortAsc           : ()            => ({type: types.SET_MESSAGE_SORT}),
  requestEmailFields          : ()                        => ({type: types.REQUEST_EMAIL_FIELDS}),
  setEmailFields              : (emailFields)             => ({type: types.SET_EMAIL_FIELDS, payload: emailFields}),
  submitCreateMessage         : (message, priorityType)   => ({type: types.SUBMIT_CREATE_MESSAGE, payload:{message, priorityType}}),
  submitUpdateMessage         : (message, priorityType)   => ({type: types.SUBMIT_UPDATE_MESSAGE, payload:{message, priorityType}}),
  submitDeleteMessage         : (message_id, priorityType, jira_id)   => ({type: types.SUBMIT_DELETE_MESSAGE, payload:{message_id, priorityType, jira_id}}),
  requestIncidentMessages     : (id, priorityType)        => ({type: types.REQUEST_INCIDENT_MESSAGES, payload: {id, priorityType}}),
  setIncidentMessages         : (incidentMessages, priorityType)        => ({type: types.SET_INCIDENT_MESSAGES, payload: {incidentMessages, priorityType}}),
  requestIncidents            : (incidentType, response_size, start_at, sort_col, sort_direction)            => ({type: types.REQUEST_INCIDENTS, payload: {incidentType, response_size, start_at, sort_col, sort_direction}}),
  appendIncidents             : (incidentType, incidents) => ({type: types.APPEND_INCIDENTS, payload: {incidents, incidentType}}),
  setIncidents                : (incidentType, incidents) => ({type: types.SET_INCIDENTS, payload: {incidents, incidentType}}),
  setP2AlertTypes             : (alertTypes)              => ({type: types.SET_P2_ALERT_TYPES, payload: alertTypes}),
  setP1AlertTypes             : (alertTypes)              => ({type: types.SET_P1_ALERT_TYPES, payload: alertTypes}),
  requestP2AlertTypes         : ()                        => ({type: types.REQUEST_P2_ALERT_TYPES}),
  requestBannerState          : ()                        => ({type: types.REQUEST_BANNER_STATE}),
  submitUpdateBanner          : (showBanner, email)       => ({type: types.SUBMIT_UPDATE_BANNER, payload: {showBanner, email}}),
  setBannerText               : (bannerText)              => ({type: types.SET_BANNER_TEXT, payload: bannerText}),
  setBannerDisplay            : (showBanner)              => ({type: types.SET_BANNER_DISPLAY, payload: showBanner}),
  setMessagesLoading          : (bool)                    => ({type: types.SET_MESSAGES_LOADING, payload: bool}),
  setAllIncidentsLoading          : (bool)                    => ({type: types.SET_ALL_INCIDENTS_LOADING, payload: bool}),
  setSpecificIncidentLoading         : (bool)                    => ({type: types.SET_SPECIFIC_INCIDENT_LOADING, payload: bool}),
};


/* ===== SELECTORS ===== */
export const selectors = {
  selectIncidentsByRecent :  (activeIncidents)           => sortBy(activeIncidents, 'startDate').reverse(),
  selectIncidentDetails   :  (detailedIncidents, jiraId) => detailedIncidents.find( (incident) => incident.jiraId === jiraId),
  selectDetails           :  (state, jiraId)             => {
    const incident =  state.activeIncidents.find( (incident)      => incident.jiraId.toLowerCase() === jiraId.toLowerCase())
                      || state.historicIncidents.find( (incident) => incident.jiraId.toLowerCase() === jiraId.toLowerCase());
    const details  =  state.detailedIncidents.find( (incident)    => incident.jiraId.toLowerCase() === jiraId.toLowerCase());
    return {...incident, ...details};
  },
  selectIncidentDetailsForJiraComponent :  (incidentDetails)  => formatIncidentData(incidentDetails),
  selectIncidentManagementData          :  (jiraInfo, slackInfo) => {
    const allInfo = {...jiraInfo, ...slackInfo};
    const valuesToShow =  [ 'bridgeId', 'channelURL', 'error', 'error_message' ];
    const incidentManagementData = allInfo === undefined
      ? {}
      : valuesToShow
        .filter( (detail) => allInfo[detail] !== undefined)
        .map( (detail) => ({ key: detail, value: allInfo[detail] }))
        .reduce( (returnObj, detail) => ({...returnObj, [detail.key] : detail.value}), {});
    return incidentManagementData;
  },
};


/* ===== REDUCER ===== */
export default function reducer(state=initialState, action) {
  switch (action.type) {

    case types.SET_ALL_ACTIVE_INCIDENTS: {
      return { ...state, activeIncidents : action.payload };
    }

    case types.SET_ALL_HISTORIC_INCIDENTS: {
      if (state.historicIncidents && state.historicIncidents.length > 0 && action.payload.length > 0){
        let items = action.payload.concat(state.historicIncidents)
        action.payload = items;
      }
      return { ...state, historicIncidents : action.payload };
    }
    case types.SET_INCIDENT_DETAILS: {
      const payload    =  action.payload;
      if (payload.affected_companies){
        if (state.selectedIncident.affected_companies && state.selectedIncident.affected_companies.length > 0){
          let items = state.selectedIncident.affected_companies.concat(payload.affected_companies);
          payload.affected_companies = items;
        }
      }
      return { ...state, selectedIncident : {...state.selectedIncident, ...payload} };
    }

    case types.SET_SELECTED_INCIDENT: {
      if (Object.keys(action.payload).length === 0){
        return { ...state, selectedIncident: action.payload };
      }
      if (state.selectedIncident && state.selectedIncident.affected_companies && state.selectedIncident.affected_companies.length > 0){
        action.payload['affected_companies'] = state.selectedIncident.affected_companies;
      }
      if (state.selectedIncident && state.selectedIncident.jira_info){
        action.payload['jira_info'] = state.selectedIncident.jira_info;
      }
      if (state.selectedIncident && state.selectedIncident.slack_info){
        action.payload['slack_info'] = state.selectedIncident.slack_info;
      }
      return { ...state, selectedIncident: action.payload };
    }

    case types.SET_ALL_INCIDENTS_LOADING: {
      return { ...state, allIncidentsLoading : action.payload };
    }

    case types.SET_INCIDENT_ERROR: {
      return { ...state, error : action.payload };
    }

    case types.SET_MESSAGE_SORT: {
      const bool = !state.messageSortAsc
      return { ...state, messageSortAsc : bool };
    }
    case types.SET_INCIDENTS: {
      /* since we have no use case (yet) for  */
      const {incidents, incidentType} = action.payload;
      let result = state;

      if (incidentType === priorityTypes.p2) {
        result = { ...state, p2Incidents: incidents };
      }
      else if (incidentType === priorityTypes.p1){
        result = { ...state, p1Incidents: incidents };
      }
      return result;
    }
    case types.APPEND_INCIDENTS: {
      /* since we have no use case (yet) for  */
      const {incidents, incidentType} = action.payload;
      let result = state;

      if (incidentType === priorityTypes.p2) {
        let existingIncidents = state.p2Incidents;
        result = { ...state, p2Incidents: existingIncidents.concat(incidents) };
      }
      else if (incidentType === priorityTypes.p1){
        let existingIncidents = state.p1Incidents;
        result = { ...state, p1Incidents: existingIncidents.concat(incidents) };
      }
      return result;
    }
    case types.SET_EMAIL_FIELDS: {
      return { ...state, emailFields: action.payload };
    }
    case types.SET_INCIDENT_MESSAGES: {
      const messages = action.payload.incidentMessages;
      let acceptedPriorities = action.payload.priorityType;
      const filteredMessages = messages.filter( (message) => acceptedPriorities.toLowerCase() == message.priority_type.toLowerCase());
      const updateState = { ...state, incidentMessages: filteredMessages };
      return updateState;
    }

    case types.SET_P2_ALERT_TYPES: {
      return { ...state, p2AlertTypes: action.payload};
    }
    case types.SET_P1_ALERT_TYPES: {
      return { ...state, p1AlertTypes: action.payload};
    }
    case types.SET_BANNER_TEXT: {
      return { ...state, bannerText: action.payload};
    }
    case types.SET_BANNER_DISPLAY: {
      return { ...state, bannerDisplay: action.payload};
    }
    case types.SET_MESSAGES_LOADING: {
      return {...state, messagesLoading: action.payload};
    }
    case types.SET_SPECIFIC_INCIDENT_LOADING: {
      return {...state, specificIncidentLoading: action.payload};
    }
    default: { return state; }
  }
}
