import { takeEvery, put, call, select } from "redux-saga/effects";

import {
  getTemplates,
  getTemplateChangeHistory,
  createTemplate,
  updateTemplate,
  deleteTemplate,
 } from './template.api';
import { types as templateTypes, actions as templateActions } from './template.index';
import { actions as uiActions }     from '../ui/ui.index';
import { actions as errorActions }  from '../error/error.index';
import { actions as toastActions }  from '../toast/toast.index';


export function* fetchTemplates() {
  try {
    let response =  yield call(getTemplates);
    let payload  =  response.data.templates;
    yield put(templateActions.setMessageTemplates(payload.filter(template => template.template_type == 'message')));
    yield put(templateActions.setNotificationTemplates(payload.filter(template => template.template_type == 'notification')));
    yield put(uiActions.setRefreshTime());
    yield put(uiActions.setPageLoading(false));
  }
  catch(error) {
    // handle ui effects
    const message      =  `Unable to check for templates: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
  }
}

export function* fetchTemplateChangeHistory(action) {
  try {
    // extract relevant variables
    let payload =  action.payload;
    let response =  yield call(getTemplateChangeHistory, payload);
    payload  =  response.data.template_change_history;

    yield put(templateActions.setTemplatesChangeHistory(payload));
    yield put(uiActions.setRefreshTime());
    yield put(uiActions.setPageLoading(false));
  }
  catch(error) {
    // handle ui effects
    const message      =  `Unable to check for template change history: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));
    yield put(uiActions.setPageLoading(true));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
  }
}


export function* submitCreateTemplate(action) {
  try{
    // extract relevant variables
    const payload =  action.payload;
    // submit it to changeEntity api endpoint
    const response = yield call(createTemplate, payload);
    yield put(templateActions.requestTemplates());
    yield put(toastActions.createToast('template Created Successfully!', {type: 'success'}))
  }
  catch (error) {
    const message      =  `There was an issue creating the template. Please try again.`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));
  }
}

export function* submitUpdateTemplate(action) {
  try{
    // extract relevant variables
    const payload =  action.payload;
    // submit it to changeEntity api endpoint
    const response = yield call(updateTemplate, payload);
    yield put(templateActions.requestTemplates());
    yield put(toastActions.createToast('Template Updated Successfully!', {type: 'success'}))
  }
  catch (error) {
    const message      =  `There was an issue updating the template. Please try again.`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));
  }
}

export function* submitDeleteTemplate(action) {
  try{
    // extract relevant variables
    const payload =  action.payload;
    // submit it to changeEntity api endpoint
    const response = yield call(deleteTemplate, payload);
    yield put(templateActions.requestTemplates());
    yield put(toastActions.createToast('Template deleted Successfully!', {type: 'success'}))
  }
  catch (error) {
    const message      =  `There was an issue deleting the template. Please try again.`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));
  }
}

// watcher saga
export default function templateSagas() {
  return [
    takeEvery(templateTypes.REQUEST_TEMPLATES, fetchTemplates),
    takeEvery(templateTypes.REQUEST_TEMPLATE_CHANGE_HISTORY, fetchTemplateChangeHistory),
    takeEvery(templateTypes.SUBMIT_CREATE_TEMPLATE, submitCreateTemplate),
    takeEvery(templateTypes.SUBMIT_UPDATE_TEMPLATE, submitUpdateTemplate),
    takeEvery(templateTypes.SUBMIT_DELETE_TEMPLATE, submitDeleteTemplate),
  ];
}
