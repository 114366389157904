import React, { useMemo } from 'react'
import { AxisBottom, AxisLeft } from '@visx/axis'
import { Bar } from '@visx/shape'
import { Group } from '@visx/group'
import { GradientTealBlue } from '@visx/gradient'
import { scaleBand, scaleLinear } from '@visx/scale'

const verticalMargin = 120

// accessors
const getLetter = (d) => d.letter
const getLetterFrequency = (d) => Number(d.frequency) * 100

const margins = {
  left: 45,
}

export function BarChartVisx({ width, height, givenData, events = false }) {
  // bounds
  const xMax = width - margins.left
  const yMax = height - verticalMargin
  let keys = Object.keys(givenData);
  let values = Object.values(givenData);
  // scales, memoize for performance
  const xScale = useMemo(
    () =>
      scaleBand({
        range: [0, xMax],
        round: true,
        domain: keys,
        padding: 0.4,
      }),
    [xMax],
  )

  const yScale = useMemo(
    () =>
      scaleLinear({
        range: [yMax, 0],
        round: true,
        domain: [0, Math.max(...values)],
      }),
    [yMax],
  )

  return width < 10 ? null : (
    <svg width={width} height={height}>
      <GradientTealBlue id="teal" />
      <rect width={width} height={height} fill="url(#teal)" rx={14} />
      <Group top={verticalMargin / 2} left={margins.left}>
        {keys.map((d) => {

          const barWidth = xScale.bandwidth()
          const barHeight = yMax - (yScale(givenData[d]) ?? 0)
          const barX = xScale(d)
          const barY = yMax - barHeight
          return (
            <Bar
              key={`bar-${d}`}
              x={barX}
              y={barY}
              width={barWidth}
              height={barHeight}
              fill="rgba(255, 235, 59, .5)"
              onClick={() => {
                if (events) alert(`clicked: ${JSON.stringify(givenData[d])}`)
              }}
            />
          )
        })}
        <AxisBottom
          numTicks={keys.length}
          top={yMax}
          scale={xScale}
          tickLabelProps={() => ({
            fill: '#ffeb3b',
            fontSize: 11,
            textAnchor: 'middle',
          })}
        />
        <AxisLeft
          scale={yScale.nice()}
          numTicks={10}
          top={0}
          tickLabelProps={(e) => ({
            fill: '#ffeb3b',
            fontSize: 10,
            textAnchor: 'end',
            x: -12,
            y: (yScale(e) ?? 0) + 3,
          })}
        />
      </Group>
    </svg>
  )
}
export default BarChartVisx;