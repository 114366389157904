import React, { useState } from 'react';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  actions as uiActions,
} from '../../../ducks/ui/ui.index';
import {
  actions as maintenanceEventActions
} from '../../../ducks/v2MaintenanceEvent/v2MaintenanceEvent.index';
import { Modal, Button, Form, Tooltip, OverlayTrigger, Row, Col } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment-timezone';
import {Formik, Field} from 'formik';
import * as yup from 'yup';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from 'react-i18next';
import {
  turnObjectArrayToArray
} from '../../../utils/formatFunctions'
/* ========= PACKAGE IMPORTS ========= */

const schema = yup.object().shape({
  is_test: yup.string().required().oneOf(['Y', 'N']),
  start_date: yup.string().required(),
  end_date: yup.string().required(),
  title: yup.string().required(),
  data_centers: yup.array().required(),
  created_by: yup.string().required(),
  flag: yup.string().optional(),
  jira_id: yup.string().optional(),
});

const mapStateToProps = ({uiState, userState, maintenanceEventState}) => ({
  pageLoading         :  uiState.pageLoading,
  email               :  userState.user.email, 
  all_data_centers    :  maintenanceEventState.all_data_centers
});
const mapDispatchToProps =  (dispatch) => {
  const combinedActionCreators = {
    setPageLoading                :  uiActions.setPageLoading,
    V2SubmitCreateMaintenanceEvent :  maintenanceEventActions.V2SubmitCreateMaintenanceEvent,
  };

  return bindActionCreators(combinedActionCreators, dispatch);
};
const PDT = 420; //7 hours, used if daylight savings is active
const PST = 480; //8 hours, used if daylight savings is not active
const MILISECOND_CONVERT = 60000;

const CreateMaintenanceEventModal = ({V2SubmitCreateMaintenanceEvent, setPageLoading, email, all_data_centers}) => {
  const [timezone, setTimezone] = useState('Etc/Greenwich');
  const { t } = useTranslation(["translation"]);
  const [startDate, setStartDate] = useState(new Date(new Date().toLocaleString('en', {timeZone: 'America/Los_Angeles'})));
  const isDST = () => {
    return moment.tz('America/Los_Angeles').isDST();
  }
  const dstActive = isDST();
  const tzIdentifier = dstActive ? "-0700" : "-0800";
  const pacificTimeOffset = dstActive ? PDT : PST;
  const curOffset = startDate.getTimezoneOffset();
  const offsetDif = curOffset - pacificTimeOffset;
  const [endDate, setEndDate] = useState(new Date(new Date(new Date(new Date().setHours(new Date().getHours() + 3))).toLocaleString('en', {timeZone: 'America/Los_Angeles'})));
  const [formattedStartDate, setFormattedStartDate] = useState(moment(new Date().toLocaleString('en', {timeZone: timezone})).format('MM/DD/YYYY, hh:mm A'))
  const [formattedEndDate, setFormattedEndDate] = useState(moment(new Date().toLocaleString('en', {timeZone: timezone})).format('MM/DD/YYYY, hh:mm A'))
  const [isInternal, setIsInternal] = useState('Y');
  const [flag, setFlag] = useState('');
  const [jiraId, setJiraId] = useState('');
  const [title, setTitle] = useState('');
  const [notificationBody, setNotificationBody] = useState('');
  const [dataCenterField, setDataCenterField] = useState(['']);
  const data_center_dc_names = turnObjectArrayToArray(all_data_centers, 'dc_name');
  let styles = {
    error         :  {
      color       :  'red',
    },
    buttonError   :  {
      color       :  'red',
      marginLeft  :  '5px',
    },
    button        : {
      width       :  '8rem',
    },
    buttonRight   : {
      width       : '8rem',
      margin      : '1rem'
    },
    buttonLeft: {
      width       : '8rem',
      margin      : '1rem auto 1rem 1rem',
      float       : 'left'
    },
    buttonRightDisabled: {
      width       : '8rem',
      marginLeft  : '1rem',
      cursor      : 'default'
    },
    title: {
      textAlign: 'center',
      color: 'red',
    },
    marginRight: {
      marginRight: '1rem',
    },
    confirmationText: {
      marginLeft: '.5rem',
      marginBottom: '.5rem',
      marginTop: '.5rem',
    },
    confirmationHeader: {
      marginBottom: '.5rem',
      marginTop: '.5rem',
      minWidth: '120px'
    },
    infoImg: {
      maxHeight : '1.9rem',
    },
    inlineMiddleVAlign: {
      display : 'inline',
      verticalAlign : 'middle'
    },
  }
  const [modalIsOpen,setIsOpen] = useState(false);
  const [showConfirmation,setShowConfirmation] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  }
  const closeModal = () => {
    setIsOpen(false);
  }

  const formatPstDate = (date) => {
    let tempDate = moment(date).format('YYYY-MM-DDTHH:mm:ssZZ');
    return tempDate.slice(0, -5) + tzIdentifier;
  }

  const resetValues = () => {
    setTitle('');
    setNotificationBody('');
    setEndDate(new Date());
    setStartDate(new Date());
    setDataCenterField(['']);
    setFlag('');
    setJiraId('');
  }

  return (
    <div>
      {/* Overlay Tooltip for button */}
      <OverlayTrigger
        key='left'
        placement='left'
        overlay=
        {
          <Tooltip id={`tooltip-left`}>Creates a new Maintenance Event</Tooltip>
        }
      >
        <Button className="data-cy-create-message-modal-button" variant="primary" onClick={openModal}>Create</Button>
      </OverlayTrigger>
      
      <Modal show={modalIsOpen} onHide={closeModal} className="data-cy-create-message-modal">
      <Formik   
          enableReinitialize
          validationSchema={schema}
          onSubmit={(values, {resetForm}) => {
            values.start_date = formatPstDate(values.start_date);
            values.end_date = formatPstDate(values.end_date);
            V2SubmitCreateMaintenanceEvent(values);
            setShowConfirmation(false);
            setPageLoading(false);
            closeModal();
            //resets all formik specific values (errors, touched, isSubmitting, ect)
            resetForm();
            //resets the value variables 
            resetValues();
          }}
          initialValues={{
            is_test: 'N',
            start_date: startDate,
            end_date: endDate,
            is_internal: isInternal,
            title: title,
            flag: flag,
            jira_id: jiraId,
            notification_body: notificationBody,
            data_centers: dataCenterField,
            created_by: email,
          }}
        >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldTouched,
          values,
          errors,
          touched,
          setValues,
        }) => 
        (
          <Form noValidate onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>Create Maintenance Event</Modal.Title>
            </Modal.Header>
            {/* Confirmation Step Title */}
           
            <Modal.Body>
              {/* Main Step Body */}
              <div>
                <Form.Group style={{marginBottom: '0px'}}>
                  <div style={{width: '40%', display: 'flex'}}>
                    <div style={{marginRight: '3vw'}}>
                      <Form.Label>Start Date</Form.Label>
                      <DatePicker
                          onChange            = {(date) => {
                            setStartDate(date); 
                            if (date > endDate){
                              setEndDate(date); 
                              setFormattedEndDate(moment(new Date(date).toLocaleString('en', {timeZone: timezone})).format('MM/DD/YYYY, hh:mm A'));
                            }
                            setFormattedStartDate(moment(new Date(date).toLocaleString('en', {timeZone: timezone})).format('MM/DD/YYYY, hh:mm A'));
                           
                          }}
                          selected            = {startDate}
                          showTimeInput       = "true"
                          dateFormat          = "Pp"
                          style               = {{width: '100%'}}  
                          required
                      />
                    </div>
                    <div>
                      <Form.Label>End Date</Form.Label>
                      <DatePicker
                          onChange            = {(date) => {
                            setEndDate(date); 
                            setFormattedEndDate(moment(new Date(date).toLocaleString('en', {timeZone: timezone})).format('MM/DD/YYYY, hh:mm A'));}}
                          selected            = {endDate}
                          showTimeInput       = "true"
                          dateFormat          = "Pp"
                          style               = {{width: '100%'}}  
                          minDate             = {startDate}
                          required
                          
                      />
                    </div>
                  </div>
                </Form.Group>
                <Row style={{marginBottom: '1rem'}}>
                  <div style={{width: '40%', display: 'flex', fontSize: '14px'}}>{formattedStartDate}</div>
                  <div style={{width: '40%', display: 'flex', fontSize: '14px', marginLeft: '2.7vw'}}>{formattedEndDate}</div>
                </Row>
               
                <Form.Group style={{display: 'block', width: '40%'}}>   
                  <Form.Label>Preview Timezone</Form.Label>
                  <Form.Control 
                    as="select" 
                    name="timezone" 
                    value={timezone}  
                    onChange={(e) => {
                      //these dates come in as pst, but with the timezone denotion of your local time like -0500
                      //this converts that offset to pst, so it can then be converted into the desired timezone accurately
                      const adjustedEndDate = new Date(endDate.getTime() - offsetDif * MILISECOND_CONVERT)
                      const adjustedStartDate = new Date(startDate.getTime() - offsetDif * MILISECOND_CONVERT)
                      setFormattedEndDate(moment.tz(adjustedEndDate, e.target.value).format('MM/DD/YYYY, hh:mm A'))
                      setFormattedStartDate(moment.tz(adjustedStartDate, e.target.value).format('MM/DD/YYYY, hh:mm A'))
                      setTimezone(e.target.value);
                    }}>
                    <option value="Europe/Paris">{dstActive ? "CEST" : "CET"}</option>
                    <option value="Etc/Greenwich">GMT</option>
                    <option value="US/Central">{dstActive ? "CDT" : "CST"}</option>
                    <option value="America/Los_Angeles">{dstActive ? "PDT" : "PST"}</option>
                  </Form.Control>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="textarea"
                    as="textarea"
                    className="data-cy-create-message-modal-title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    onBlur={handleBlur}
                    name="title"></Form.Control>
                    {errors.title && touched.title ? (
                      <div style={styles.error}>{errors.title}</div>
                    ) : null}
                </Form.Group>
                <Form.Group>
                <Form.Label>Affected Data Centers</Form.Label>
                <Form.Control as="select" multiple value={dataCenterField} onChange={e => setDataCenterField([].slice.call(e.target.selectedOptions).map(item => item.value))}>
                  {(data_center_dc_names).map((dc, index) => {
                      return (<option value={all_data_centers[index]['system_name']} id={dc} key={dc} name={dc}>{dc}</option>)
                    })}
                </Form.Control>
                </Form.Group>
                <Form.Group style={{display: 'flex'}}>      
                  <div style={{marginRight: '3vw', width: '17vw'}}>
                    <Form.Label>Flag</Form.Label>     
                    <Form.Control as="select" value={flag} onChange={e => setFlag(e.target.value)}>
                      <option value="">None</option>
                      <option value="special">Special</option>
                      <option value="emergency">Emergency</option>
                      <option value="penetration_testing">Penetration Testing</option>
                      <option value="freeze_window">Freeze Window</option>
                      <option value="monolith_deployment">Monolith Deployment</option>
                      <option value="audit">Audit</option>
                    </Form.Control>
                  </div>
                </Form.Group>  
                <Form.Group>
                  <Form.Label>Audience</Form.Label>
                  <Field>
                    {({ field }) => (
                      <Form.Group as={Row} className="data-cy-create-message-modal-is_internal" name="is_internal" value={values.is_internal} onBlur={handleBlur} onChange={e => setIsInternal(e.target.value)} >
                          <Form.Check
                          type="radio"
                          name="is_internal"
                          label="Internal"
                          value="Y"
                          className="data-cy-create-message-modal-is_internal-Y"
                          id="is_internal"
                          style={styles.marginRight}
                          checked={values.is_internal === 'Y'}
                          onChange={e =>  handleChange(e, setValues, values)}
                        />
                        <Form.Check
                          type="radio"
                          label="External + Internal"
                          name="is_internal"
                          value="N"
                          className="data-cy-create-message-modal-is_internal-N"
                          id="emailNo"
                          checked={values.is_internal === 'N'}
                          onChange={handleChange}
                        />
                      </Form.Group>

                    )}
                  </Field>
                  {errors.broadcast && touched.broadcast ? (
                      <div style={styles.error}>{errors.broadcast}</div>
                    ) : null}
                </Form.Group>
                <Form.Group>
                  <Form.Label>Jira Id (Optional)(CSV)</Form.Label>
                  <Form.Control
                    type="input"
                    as="input"
                    className="data-cy-create-message-modal-notification-body"
                    value={jiraId}
                    onChange={(e) => setJiraId(e.target.value)}
                    onBlur={handleBlur}
                    name="jira_id">
                  </Form.Control>
                </Form.Group>
                <Form.Group>

                </Form.Group>
              </div>
            </Modal.Body>
             
            
            <Modal.Footer>
              <div style={{display: 'contents'}}>
                <Button variant="secondary" onClick={closeModal} className="data-cy-create-message-modal-close" style={styles.buttonLeft}>Cancel</Button>
                <Button style={styles.buttonRight} className="data-cy-create-message-modal-ok" type="submit">Save</Button>
              </div>
            </Modal.Footer>
          </Form>
        )}
        </Formik>

      </Modal>
    </div>
  );
}

export default connect(mapStateToProps,mapDispatchToProps)(CreateMaintenanceEventModal);