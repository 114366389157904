import React, { Component } from 'react';
import {
  Button, ListGroup
} from 'react-bootstrap';
import 'antd/dist/antd.css';
import { Spin } from 'antd';


class CICategories extends Component {

  render() {
    const { ciCategories, emptyResponse, ci , handleCICategoryButton } = this.props;
    let autoCompleteSearchResultsCITypes = ciCategories.map((ciCategory, index) => (
      <ListGroup.Item action className={`cypress-ci-category-${ciCategory}`} onClick={handleCICategoryButton} key={index} value={ciCategory}>{ciCategory}</ListGroup.Item>
    ));
    let showSpinner = (ciCategories.length === 0 && !emptyResponse);
    return (
      <div>
        <h6 className="heading mr-2">CI Categories</h6>
        <Spin spinning = {showSpinner}/>
        {ciCategories && ciCategories.length !== 0 && ci.length!== 0 && <Button variant="primary" size="sm" className="add-button" value="" onClick={handleCICategoryButton}>Reset</Button>}
        <div>
          <ListGroup variant="flush">
            {emptyResponse && ciCategories && ciCategories.length === 0 &&
              <div>
                <ListGroup variant="flush">
                  <ListGroup.Item>No matching CI Categories found</ListGroup.Item>
                </ListGroup>
              </div>}
            {autoCompleteSearchResultsCITypes}
          </ListGroup>
        </div>
      </div>
    )
  }
}

export default CICategories;
