import React from 'react';
import { Card, Button, Row } from 'react-bootstrap';
import { bindActionCreators } from 'redux';
import { connect }            from 'react-redux';
import {capitalizeFirstLetter} from './../../../utils/adminHelperFunctions';
import {
  actions as adminActions
} from '../../../ducks/administration/administration.index';
import { useTranslation } from 'react-i18next';
const styles = {
  container: {width: '100%'},
  dragInstructions: {display: 'flex', paddingTop: '40px', margin: '0 auto'},
  dragContainer: {display: 'contents', textAlign: 'center'},
  buttonContainer: {margin: '10px auto'},
  buttonStyle: {margin: '0 10px'},
  displayArray: {display: 'table', 'margin': '5px'}
}

const mapStateToProps = ({administrationState }) => ({
  roles               :  administrationState.roles,
  users               :  administrationState.users,
});
const mapDispatchToProps =  (dispatch) => {
  const combinedActionCreators = {
    deleteEntity                :  adminActions.deleteEntity,
  };

  return bindActionCreators(combinedActionCreators, dispatch);
};


const DeleteModal = ({selectedData, type, close, identifier, arrayValues, deleteEntity}) => {
  const { t } = useTranslation(["translation"]);
  const submitDelete = () => {
    deleteEntity(type.slice(0, -1), selectedData);
    close();
  }

  return (
    <div style={styles.container} className={`Modal-${type}-delete`}>
      <div>
        <h4>{capitalizeFirstLetter(identifier) + ':  '}</h4>
        <div>
          {selectedData[identifier]}
        </div>
      </div>
      { type !== 'users' && <div>
        <h4>{t('containers.administrationTypeModal.textOtherDescription')} </h4>
        <div>
          {selectedData['description']}
        </div>
      </div> }
      { type !== 'actions' && <Row style={styles.displayArray}>
        <h4>
          {type == 'users' && t('containers.administrationTypeModal.textRoles')}
          {type == 'roles' && t('containers.administrationTypeModal.textActions')}
        </h4>
        {arrayValues.map((val, i) => (
          <Card
          key={i}
          >{val}</Card>
        ))}
      </Row> }
      <Row>
        <div style={styles.buttonContainer}>
          <Button style={styles.buttonStyle} variant="secondary" onClick={() => close()}>
            {t('containers.administrationTypeModal.buttonCancel')}
          </Button>
          <Button style={styles.buttonStyle} variant="danger" onClick={() => submitDelete()}>
          {t('containers.administrationTypeModal.buttonDelete')}
          </Button>
        </div>
      </Row>
    </div>
  )
}
export default connect(mapStateToProps,mapDispatchToProps)(DeleteModal);