/* ========= PACKAGE IMPORTS ========= */
import React, { Component } from 'react';
import { connect } from "react-redux";
import { Link }     from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Translation } from 'react-i18next';
import { Button, Row, Form } from 'react-bootstrap';
/* ========= REDUX IMPORTS ========= */
import { actions as uiActions }    from '../../ducks/ui/ui.index';
import {
  actions as incidentActions,
  priorityTypes,
}   from '../../ducks/incident/incident.index';

import BannerControls from './Banner/BannerControls';

/* ========= COMPONENT IMPORTS ========= */
import Loading            from '../../components/loading';
import ReviewTable        from './ReviewTable/ReviewTable';
import AuthorizationWrapper from '../../components/authorizationWrapper';


/* ========= REDUX STATE CONNECTORS ========= */
const mapStateToProps = ({uiState, incidentState, userState}) => ({
    pageLoading      : uiState.pageLoading,
    email            : userState.user.email,
    p2Incidents      : incidentState.p2Incidents,
    p1Incidents      : incidentState.p1Incidents,
    allIncidentsLoading : incidentState.allIncidentsLoading,
    bannerText       : incidentState.bannerText,
    bannerDisplay    : incidentState.bannerDisplay,
   
});

const mapDispatchToProps = (dispatch) => {
  const combinedActionCreators = {
    setPageLoading   :  uiActions.setPageLoading,
    requestIncidents :  incidentActions.requestIncidents,
    submitUpdateBanner : incidentActions.submitUpdateBanner,
  };
  return bindActionCreators(combinedActionCreators, dispatch);
};

const styles = {
  'topContainer': {
    'margin'   :  'auto',
    'maxWidth' :  'max-content',
  },
  'pageTitle': {
    'width'        :  '73vw',
    'textAlign'    :  'center',
  },
};

const requiredAction = 'view_incident_management_page';
const requiredBannerAction = 'view_service_disruption_banner';
const requiredTemplateAction = 'edit_template';
const INCIDENT_LIST_SIZE = 100;
let chosenPriorityType = "P1";
class IncidentManagement extends Component {
  componentDidMount = () => {
    this.props.requestIncidents(priorityTypes.p2, INCIDENT_LIST_SIZE, 0, 'start_date', 'DESC');
    this.props.requestIncidents(priorityTypes.p1, INCIDENT_LIST_SIZE, 0, 'start_date', 'DESC');
    chosenPriorityType = "P1";
  }

  render = () => {
    const { topContainer, pageTitle } = styles;
    const { pageLoading, p2Incidents, p1Incidents, bannerText, bannerDisplay, submitUpdateBanner, email, allIncidentsLoading } = this.props;
    
    return (
      <AuthorizationWrapper requiredAction={requiredAction}>
        <div style={topContainer}>
          {/* HEADER */}
          {!pageLoading && <AuthorizationWrapper requiredAction={requiredBannerAction}><BannerControls bannerText={bannerText} bannerDisplay={bannerDisplay} submitUpdateBanner={submitUpdateBanner} email={email}></BannerControls></AuthorizationWrapper>}
          <Row>
            <Form.Group style={{'marginBottom': '5px', 'marginTop': 'auto'}} className="data-cy-create-message-modal-internal" name="internal" value={chosenPriorityType} 
            onChange={
              e => {
                chosenPriorityType = e.target.value;
                if (chosenPriorityType == 'P1'){
                  this.props.requestIncidents(priorityTypes.p1, INCIDENT_LIST_SIZE, 0, 'start_date', 'DESC');
                }
                else{
                  this.props.requestIncidents(priorityTypes.p2, INCIDENT_LIST_SIZE, 0, 'start_date', 'DESC');
                }
              }}>
              <Form.Check
                inline
                label="P1"
                value="P1"
                name="group1"
                defaultChecked
                type='radio'
                id={`inline-radio-1`}
              />
              <Form.Check
                inline
                label="P2"
                value="P2"
                name="group1"
                type='radio'
                id={`inline-radio-2`}
              />
            </Form.Group>
            <h1 style={pageTitle}><Translation>{(t) => t('containers.incidentManagement.title')}</Translation></h1>
            <AuthorizationWrapper requiredAction={requiredTemplateAction}><div style={{'justifyContent': 'end', 'marginLeft': 'auto', 'marginBottom': '5px', 'height': '2.5rem', 'marginTop': 'auto'}}><Button variant="info"><Link to={`/template_management/message`} style={{"color": "white"}}>Manage Templates</Link></Button></div></AuthorizationWrapper>
          </Row>
          { /* CONTENT */
            allIncidentsLoading
            ? <Loading height="75vh" />
            : <div>
                <ReviewTable p1Data={p1Incidents} p2Data={p2Incidents} priority_type={chosenPriorityType} requestIncidents={this.props.requestIncidents} listSize={INCIDENT_LIST_SIZE}/>
              </div>
          }

        </div>
      </AuthorizationWrapper>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(IncidentManagement);

