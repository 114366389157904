import React from 'react';
import { useTable, useSortBy } from 'react-table'
import { dateTimeToUTCString, sortDate } from '../../../utils/formatFunctions';
import { Table } from 'react-bootstrap';
import UpdateMessageModal from './../MessageModals/UpdateMessageModal';
import DeleteMessageModal from './../MessageModals/DeleteMessageModal';
import ReadMore from '../../Landing/OpiCard/ReadMore';
import AuthorizationWrapper from '../../../components/authorizationWrapper';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import '../../History/HistoryTable/HistoryTable.css';

const styles = {
  'headers': {
    'textAlign'  :  'left',
    'lineHeight' :  '2rem',
  },
  'img': {
    'height' :  'auto',
    'width'  :  '24px',
  },
  'table': {
    'height' :  '60vh',
    'border' :  'none',
    'textAlign'   :  'left',
  },
  'tableContainer': {
    'fontSize'        :  '.94rem',
    'boxSizing'       :  'border-box',
    'borderRadius'    :  '4px',
    'backgroundColor' :  'rgb(250, 250, 250)',
    'boxShadow'       :  'rgba(0, 0, 0, 0.2) 0px 0px 10px -2px',
  },
  'tablePadding': {
    'borderLeft'   : '1px solid #ddd',
    'borderRight'  : '1px solid #ddd',
    'borderBottom' : '1px solid #ddd',
  },
  'unset' : {
    'whiteSpace': 'unset'
  },
  'prewrap' : {
    'whiteSpace': 'pre-wrap'
  },
  'noData': {
    'display': 'block',
    'position': 'absolute',
    'background': 'hsla(0,10%,100%,1)',
    'transition': 'all .3s ease',
    'pointerEvents': 'none',
    'padding': '20px',
    'maxWidth': '50vw',
    'marginLeft': '11vw'
  },
  'allStyle': {
    'z-index': '1',
    'padding': '20px',
  }
};

function RenderTable({ columns, values }) {
  const { t } = useTranslation(["translation"]);

  const data = React.useMemo(() => 
    values
    , [values]);

  const sorting = React.useMemo(
    () => [
      {
        id: "created_date",
        desc: false
      }
    ],
    []
  );
  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? 'desc' : 'asc') : '';
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: sorting
      }
    },
    useSortBy,
  )
  return (
    <>
      <Table bordered {...getTableProps()} className="cy-review-details-table" style={styles.allStyle}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i) => {
                const {render, getHeaderProps} = column
                return (
                  <th className={generateSortingIndicator(column) + " " + column.id + "-header"} key={'header' + i} {...getHeaderProps(column.getSortByToggleProps())}>{render("Header")}</th>
                )
              })}
            </tr>
          ))}
        </thead>
        {rows.length > 0 && <tbody {...getTableBodyProps()} className="rt-tbody">
          {rows.map((row, i) => {
            prepareRow(row);
            let styling = row && row.values.message_type && row.values.message_type.props.children.toLowerCase().includes('internal') ? {backgroundColor: '#00a9f24a'} : {};            
            return (

              <tr key={'row' + i} {...row.getRowProps()} style={styling}>
                {row.cells.map((cell, j) => {
                  return <td key={"row" + i + 'col' + j} {...cell.getCellProps({
                    style: {
                      minWidth: cell.column.minWidth,
                      width: cell.column.width,
                    },
                  })}>{cell.render("Cell")}</td>;
                })}
              </tr>
            );
          })}
        </tbody>}
        {rows.length == 0 &&  <><tr><td/><td/><td/><td/><td/><td/></tr>
        <div style={styles.noData} className='rt-noData'>{t('containers.incidentManagmentReviewDetailsTable.noData')}</div>
        <tr><td/><td/><td/><td/><td/><td/></tr>
        <tr><td/><td/><td/><td/><td/><td/></tr>
        <tr><td/><td/><td/><td/><td/><td/></tr>
        <tr><td/><td/><td/><td/><td/><td/></tr>
        <tr><td/><td/><td/><td/><td/><td/></tr>
        <tr><td/><td/><td/><td/><td/><td/></tr></>}
      </Table>
    </>
  );
}


const ReviewDetailsTable = ({RowData, Incident}) => {
  const { t } = useTranslation(["translation"]);
  const { tableContainer } = styles;
  const PriorityType = Incident['priorityType'];
  const requiredEditAction = PriorityType == 'P2' ? 'edit_p2_message' : 'edit_p1_message';
  const requiredDeleteAction = PriorityType == 'P2' ? 'delete_p2_message' : 'delete_p1_message';
  const headerStyle = {
    textAlign  :  'left',
    lineHeight :  '2rem',
  }
  const columns = React.useMemo(
    () => [
        {
          Header: <b data-cy='id-header'>{t('containers.incidentManagmentReviewDetailsTable.id')}</b>,
          headerStyle: headerStyle,
          id: 'id',
          width: 100,
          accessor: (data) => data.id,
          filter: 'includes'
        },
        {
          Header: <b data-cy='message_type-header'>{t('containers.incidentManagmentReviewDetailsTable.message')}</b>,
          headerStyle: headerStyle,
          id: 'message_type',
          width: 270,
          accessor: (data) => <p>{t('containers.alertTypes.' + data.message_type.replace(/ /g,"_"))}</p>,
          filter: 'includes'
        },
        {
          Header: <b data-cy='end-date-header'>Sent Date</b>,
          headerStyle: headerStyle,
          id: 'created_date',
          width: 240,
          minWidth: 260,
          sortMethod    :  (a, b) => sortDate(a, b),
          accessor: 'created_date',
          Cell    : (value) => <div><p>{dateTimeToUTCString(value.value)}</p> <p> {'(Local: ' + new Date(value.value).toLocaleString() + ')'}</p></div>,      
        },
        {
          Header: <b data-cy='start-date-header'>{t('containers.incidentManagmentReviewDetailsTable.body')}</b>,
          headerStyle: headerStyle,
          id: 'messageBody',
          width: 450,
          accessor: (data) => <ReadMore details={i18next.language == 'ja' ? data.body.ja : data.body.en} characterLimit={110}/>,
          filter: 'includes',
          sortMethod    :  (a, b) => sortDate(a, b),
        },
        {
          Header: <b data-cy='end-date-header'>{t('containers.incidentManagmentReviewDetailsTable.update')}</b>,
          headerStyle: headerStyle,
          id: 'updateMessage',
          width: 160,
          accessor: (data) => <AuthorizationWrapper requiredAction={requiredEditAction}><UpdateMessageModal messageId={data.id} jiraId={data.jira_id} messageType={data.message_type} body={data.body} startDate={new Date()} priorityType={PriorityType} Internal={Incident['isInternal']}/></AuthorizationWrapper>,
          filter: 'includes',
          sortMethod    :  (a, b) => sortDate(a, b),
        },
        {
          Header      : <b data-cy='service-header'>{t('containers.incidentManagmentReviewDetailsTable.delete')}</b>,
          headerStyle : headerStyle,
          id          : 'deleteMessage',
          width       : 150,
          accessor    : (data) => <AuthorizationWrapper requiredAction={requiredDeleteAction}><DeleteMessageModal messageId={data.id} jiraId={data.jira_id} messageType={data.message_type} body={data.body.en} priorityType={PriorityType}/></AuthorizationWrapper>,
        },
       
      ],
    []
  )

  return (
    <div style={tableContainer}>
      <RenderTable columns={columns} values={RowData} />
    </div>
  );
}

export default ReviewDetailsTable;