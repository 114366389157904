import { combineReducers } from "redux";

import incident        from '../ducks/incident/incident.index';
import status          from '../ducks/status/status.index';
import toasts          from '../ducks/toast/toast.index';
import ui              from '../ducks/ui/ui.index';
import user            from '../ducks/user/user.index';
import error           from '../ducks/error/error.index';
import subscription    from '../ducks/subscription/subscription.index';
import severity        from '../ducks/severity/severity.index';
import customerDetails from '../ducks/customerDetails/customerDetails.index';
import changeHistory   from '../ducks/changeHistory/changeHistory.index';
import administration  from '../ducks/administration/administration.index';
import maintenanceEvents from '../ducks/maintenanceEvent/maintenanceEvent.index';
import v2MaintenanceEvents from '../ducks/v2MaintenanceEvent/v2MaintenanceEvent.index';
import templates from '../ducks/template/template.index';
import dora from '../ducks/dora/dora.index';


// export all reducers/state-pieces into a single reducer
export default combineReducers(
  {
    errorState              : error,
    incidentState           : incident,
    statusState             : status,
    toastsState             : toasts,
    uiState                 : ui,
    userState               : user,
    subscriptionState       : subscription,
    severityState           : severity,
    customerDetailsState    : customerDetails,
    changeHistoryState      : changeHistory,
    administrationState     : administration,
    maintenanceEventState   : maintenanceEvents,
    v2MaintenanceEventState : v2MaintenanceEvents,
    templateState           : templates,
    doraState               : dora,
  },
);
