import React, {useCallback} from "react";
import { NavLink, Link } from "react-router-dom";
import debounce from 'lodash.debounce';
import {
  Navbar,
  Nav,
  Button,InputGroup,FormControl
} from 'react-bootstrap';
import doraLogo from "./../Assets/backpack_white.png";
import "./NavBar.css";


export default function NavBar(props) {
  const styles = {
    'navBar': { boxShadow: "0px 4px 19 px -4px rgba(200,0,0,0.75)"},
    'doraImg': { maxHeight: 32, maxWidth: 64, marginRight: '5px' },
    'activeNav': {backgroundColor: 'black', color: 'white'},
    'nav': {color: 'black'}
  }
  const debouncedSave = useCallback(
		debounce(nextValue => props.handleSearchBarInput(nextValue), 700),
		[], // will be created only once initially
	);
  const handleSearch = (e) => {
    props.setSearchBarInput(e.target.value)
    debouncedSave(e.target.value);
  }

  return (
    <div className="base-container" style={styles.navBar}>
      <div style={{display: 'flex'}}>
        <div className="logo-box">
        <img
          src={doraLogo}
          style={styles.doraImg}
          alt="Dora 2.0 Logo"
          role="button"
          onClick={() => props.handleEasterEgg()}
        />{' Dora '}
        </div>
        <div className="search-bar">
          <InputGroup className="mb-3">
            <FormControl
              placeholder=""
              aria-describedby="basic-addon2"
              onChange={handleSearch}
              value={props.searchBarInput}
            />
            <InputGroup.Append>
              <Button variant="outline-secondary">Search</Button>
            </InputGroup.Append>
          </InputGroup>
        </div>
      </div>
      <div className="dc-container">
        <Nav variant="pills" activeKey={props.activeDc} defaultActiveKey={props.activeDc} style={{"marginLeft": "1rem"}}>
        {props.dcs.map((name, index) => {
          return <Nav.Item key={name}><Nav.Link key={name} style={props.activeDc == name ? styles.activeNav : styles.nav} className={`cypress-navlink-${name}`}  onClick={() => props.handleChangeDc(name)} eventKey={name}> {name} </Nav.Link></Nav.Item>
        })}
        </Nav>
      </div>
    </div>
  );
}