import React, { Component }   from 'react';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';
import { Translation } from 'react-i18next';
import {
  subscriptionProptypes,
  actions as subscriptionActions,
  selectors,
} from '../../ducks/subscription/subscription.index';
import {
  uiProptypes,
  actions as uiActions,
} from '../../ducks/ui/ui.index';
import {
  actions as userActions,
} from '../../ducks/user/user.index';
import Loading                  from '../../components/loading';
import FormikSubscriptionsForm  from './SubscriptionForm';
import subscriptionsImage       from "../../assets/subscriptions-image.png";
import './Subscriptions.css';

const mapStateToProps = ({subscriptionState, uiState, userState}) => ({
  subscriptionOptions :  subscriptionState.subscriptionOptions,
  toggleSelectAll     :  subscriptionState.toggleSelectAll,
  pageLoading         :  uiState.pageLoading,
  email               :  userState.user.email,
  authorization       :  userState.user.authorization,
});
const mapDispatchToProps =  (dispatch) => {
  const combinedActionCreators = {
    requestUserSubscriptions :  subscriptionActions.requestUserSubscriptions,
    submitSubscriptions      :  subscriptionActions.submitSubscriptions,
    selectAllSubscriptions   :  subscriptionActions.selectAllSubscriptions,
    setPageLoading           :  uiActions.setPageLoading,

    // for manual email input
    setUser: userActions.setUser,
  };

  return bindActionCreators(combinedActionCreators, dispatch);
};


class Subscriptions extends Component {
  styles = {
    heroText  :  {
      color    :  '#f0ab00',
    },
    dashedLine: {
      borderTop :  '2px dashed black',
      color     :  'transparent',
    },
  };
  static propTypes = {
    requestUserSubscriptions :  subscriptionProptypes.requestUserSubscriptions,
    submitSubscriptions      :  subscriptionProptypes.submitSubscriptions,
    subscriptionOptions      :  subscriptionProptypes.subscriptionOptions,
    selectAllSubscriptions   :  subscriptionProptypes.selectAllSubscriptions,
    pageLoading              :  uiProptypes.pageLoading
  };

  componentDidMount = () => {
    this.props.setPageLoading(false);
    this.props.requestUserSubscriptions(this.props.email);
  }

  render = () => {
    const {
      email,
      pageLoading,
      submitSubscriptions,
      subscriptionOptions,
      selectAllSubscriptions,
      toggleSelectAll,
      authorization
    } = this.props;
    const {heroText, dashedLine}  =  this.styles;
    let formValues = selectors.selectFormOptions(subscriptionOptions);

    return (
      <div>
        {/* Hero Image/Text */}
        <div>
          <img
            className =  "img-fluid mt-3 sap-sub-image mx-auto d-block"
            alt       =  "sap illustration of cityscape. Humans interact with communication devices"
            src       =  {subscriptionsImage} />
          <h1 style={heroText} className="mt-3 medium-show text-center notification-text">
            <Translation>{(t) => t('containers.subscriptions.set')}</Translation>
          </h1>
          <h2 className="text-center display-email">{email}</h2>
          <hr style={dashedLine} />
        </div>

        { pageLoading && <Loading /> }
        { /* Subscription Options */
          <FormikSubscriptionsForm
            subscriptionOptions    =  {subscriptionOptions}
            formValues             =  {formValues}
            submitSubscriptions    =  {submitSubscriptions}
            selectAllSubscriptions =  {selectAllSubscriptions}
            toggleSelectAll        =  {toggleSelectAll}
            authorization          = {authorization}
          />
        }

      </div>
    );
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(Subscriptions);
