import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const NotFound = () => {
  const { t } = useTranslation(["translation"]);
  const styles = {
    container: {
      height: '60vh',
    },
  };
  const { container } = styles;
  return (
    <div className='row 404-container' style={container}>
      <div className='col-xl-4 col-lg-12 col-md-12  mx-auto my-auto card text-white bg-danger'>
        <div className='card-body'>
          <h1 className='card-title 404-title'>{ t('components.notFound.title') }</h1>
          <p className='card-text 404-content'>{ t('components.notFound.body') }</p>
          <Link to='/' className='btn btn-danger border-light 404-button 404-link'>{ t('components.notFound.button') }</Link>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
