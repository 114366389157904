import { createUId } from '../../utils/utilFunctions';

/* ===== STATE_DEFINITION ===== */
const initialState = [];


/* ===== TYPES ===== */
export const types = {
  CREATE  :  '[toast] CREATE',
  UPDATE  :  '[toast] UPDATE',
};


/* ===== ACTION_CREATORS ===== */
export const actions = {
  createToast: (message, options, toastId=createUId('toast')) => ({
    type    :  types.CREATE,
    payload :  { message, options, toastId },
  }),
  updateToast: (message, options, toastId) => ({
    type    :  types.UPDATE,
    payload :  { message, options, toastId },
  })
};


/* ===== REDUCER ===== */
export default function reducer(state=initialState, action) {
  switch (action.type) {
    
    case types.CREATE: { return [...state, {...action.payload}]; }
    
    case types.UPDATE: {
      // look for toast to update by id & update else return all other toasts back to state 
      const newToast = action.payload;
      return state.map(toast => {
        if (toast.toastId !== newToast.toastId) {
          return toast;
        }

        return {
          toastId :  toast.toastId,
          message :  newToast.message ? newToast.message : toast.message,
          options :  {...toast.options, ...newToast.options},
        };
        
      });
    }
    
    default: { return state; }
  }
}
