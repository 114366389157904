import React from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { withFormik, Form, Field } from 'formik';
import { subscriptionProptypes, priority_types } from '../../ducks/subscription/subscription.index';

import { useTranslation } from 'react-i18next';

const VIEW_IS_INTERNAL = 'view_is_internal_message'

const SubscriptionsForm = (props) => {
  const {
    values, // maps to selected form values to Formik's state
    handleSubmit,
    isSubmitting,
    selectAllSubscriptions,
    toggleSelectAll,
    subscriptionOptions,
    authorization,
  } = props;
  const styles = {
    container: { width: '90%', margin: '0 auto' }
  }
  const { t } = useTranslation(["translation"]);
  const onChange = (e, subs_component, name) => {
    //set value and call default formik change handler
    values[subs_component][name] = !values[subs_component][name];
    props.handleChange(e);
  }
  if (subscriptionOptions['P1']['alert_types'].length > 0){
    if (!authorization.is_admin && !authorization.allowed_actions.includes(VIEW_IS_INTERNAL)){
      var i = subscriptionOptions['P1']['alert_types'].length
      while (i--) {
        if (subscriptionOptions['P1']['alert_types'][i]['name'].toLowerCase().includes('internal')) { 
          subscriptionOptions['P1']['alert_types'].splice(i, 1);
        } 
      }
      i = subscriptionOptions['P2']['alert_types'].length
      while (i--) {
        if (subscriptionOptions['P2']['alert_types'][i]['name'].toLowerCase().includes('internal')) { 
          subscriptionOptions['P2']['alert_types'].splice(i, 1);
        } 
      }
    
    }
  }

  return (
    <Form style={styles.container}>
      {priority_types.map((priority_type) => (
        <>
        <Row className="d-flex justify-content-center">
          <h1>{priority_type}</h1>
        </Row>
          <Row className="d-flex justify-content-center">
            <Col sm={12} md={4} lg={4} xl={3} className="mt-0 mt-3-sm col-data-centers">
              <h2>{t('containers.subscriptionForm.data')}</h2>
              {subscriptionOptions[priority_type] && subscriptionOptions[priority_type].data_centers.map( (dc) => (
                <label key={priority_type + dc.id} className="d-block mb-2">
                  <Field type="checkbox" className="mr-2 subscription-checkbox" name={priority_type + dc.name} checked={values[priority_type][dc.name]} 
                  onChange={ e => onChange(e, priority_type, dc.name)}/>
                  {t('containers.dataCenters.' + dc.name.replace(/ /g,"_"))}
                </label>
              ))}
            </Col>

            <Col sm={12} md={4} lg={4} xl={3} className="mt-0 mt-3-sm col-services">
              <h2>{t('containers.subscriptionForm.services')}</h2>
              {subscriptionOptions[priority_type] && subscriptionOptions[priority_type].services.map( (service) => (
                <label key={priority_type + service.id} className="d-block mb-2">
                  <Field type="checkbox" className="mr-2 subscription-checkbox" name={priority_type + service.name}  checked={values[priority_type][service.name]} 
                  onChange={ e => onChange(e, priority_type, service.name)} /> 
                  {t('containers.services.' + service.name)}
                </label>
              ))}
            </Col>

            <Col sm={12} md={4} lg={4} xl={3} className="mt-0 mt-3-sm col-alert-types">
              <h2>{t('containers.subscriptionForm.alert')}</h2>
              {subscriptionOptions[priority_type] && subscriptionOptions[priority_type].alert_types.map( (type) => (
                <label key={priority_type + type.id} className="d-block mb-2">
                  <Field type="checkbox" className="mr-2 subscription-checkbox" name={priority_type + type.name} checked={values[priority_type][type.name]} 
                  onChange={ e => onChange(e, priority_type, type.name)}/>  
                  {t('containers.alertTypes.' + type.name.replace(/ /g,"_"))}
                </label>
              ))}
            </Col>
          </Row>
          <Row className="d-flex justify-content-center align-items-center">
            <label className="d-block mb-2">
              <Field
                type      =  "checkbox"
                className =  "mr-2 mt-3 mb-2 subscribe-all"
                name      =  "subscribeAll"
                checked   =  {values[priority_type].name}
                onChange  =  { () => selectAllSubscriptions(!toggleSelectAll[priority_type], priority_type) } />
              <b>{priority_type}: {t('containers.subscriptionForm.check')}</b>
            </label>
          </Row>
          <hr className="mt-3 mb-3" />
        </>
      ))}

      {/* Maintenance Events */}
      <Row className="d-flex justify-content-center">
        <h1>{t('containers.subscriptionForm.maintenanceEvents')}</h1>
      </Row>
      <Row className="d-flex justify-content-center">
        <Col sm={12} md={4} lg={4} xl={3} className="mt-0 mt-3-sm col-data-centers">
          <h2>{t('containers.subscriptionForm.data')}</h2>
          {subscriptionOptions['notifications'] && subscriptionOptions['notifications'].data_centers.map( (dc) => (
            <label key={'notifications' + dc.id} className="d-block mb-2">
              <Field type="checkbox" className="mr-2 subscription-checkbox" name={'notifications' + dc.name} checked={values['notifications'][dc.name]}
              onChange={ e => onChange(e, 'notifications', dc.name)}/>
              {t('containers.dataCenters.' + dc.name.replace(/ /g,"_"))}
            </label>
          ))}
        </Col>
        <Col sm={12} md={4} lg={4} xl={3} className="mt-0 mt-3-sm col-notification-types">
          <h2>{t('containers.subscriptionForm.alert')}</h2>
          {subscriptionOptions['notifications'] && subscriptionOptions['notifications'].notification_types.map( (type) => (
            <label key={'notifications' + type.id} className="d-block mb-2">
              <Field type="checkbox" className="mr-2 subscription-checkbox" name={'notifications' + type.name} checked={values['notifications'][type.name]}
              onChange={ e => onChange(e, 'notifications', type.name)}/>
              {t('containers.notificationTypes.' + type.name.replace(/ /g,"_"))}
            </label>
          ))}
        </Col>
      </Row>

      <Row className="d-flex justify-content-center align-items-center">
        <label className="d-block mb-2">
          <Field
            type      =  "checkbox"
            className =  "mr-2 mt-3 mb-2 subscribe-all"
            name      =  "subscribeAll"
            checked   =  {values['notifications'].name}
            onChange  =  { () => selectAllSubscriptions(!toggleSelectAll['notifications'], 'notifications') } />
          <b>{t('containers.subscriptionForm.maintenanceEvents')}:  {t('containers.subscriptionForm.check')}</b>
        </label>
      </Row>

      {/* Select-All/Submit Button */}
      <hr className="mt-3 mb-3" />
      <Row className="d-flex justify-content-center align-items-center">
        <div>
          <Button
            className = "ml-2 update-button"
            onClick  =  {handleSubmit}
            disabled =  {isSubmitting} >
            {t('containers.subscriptionForm.update')}
          </Button>
        </div>
      </Row>
    </Form>
  );
}

const FormikSubscriptionsForm = withFormik({
  enableReinitialize :  true,                         // forces form to update with new props (from async)
  mapPropsToValues   :  ({formValues}) => formValues, // set initial formik values to nothing
  handleSubmit       :  (values, { setSubmitting, props: { submitSubscriptions} }) => {
    submitSubscriptions(values, setSubmitting, document.cookie.replace(/(?:(?:^|.*;\s*)tokens\s*\=\s*([^;]*).*$)|^.*$/, "$1"));
  }
})(SubscriptionsForm);

export default FormikSubscriptionsForm;

SubscriptionsForm.propTypes = {
  values              :  subscriptionProptypes.selectFormValues,
  subscriptionOptions :  subscriptionProptypes.subscriptionOptions,
  submitSubscriptions :  subscriptionProptypes.submitSubscriptions,
  authorization       : subscriptionProptypes.authorization
}
