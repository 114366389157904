import sortBy from 'lodash/sortBy';
import { string, number, arrayOf, shape, oneOfType, oneOf, func, object } from 'prop-types';
import { formatIncidentData } from '../../utils/formatFunctions';


export const editablePriortyTypes = ['P1','P2'];

/* ===== STATE_DEFINITION ===== */
const initialState = {
  // P1 Content
  templates     : [],
  changeHistory : [],
};


/* ===== PROP_TYPES ===== */
const templateShape = {
  body              : string,
  title             : string,
  created_by        : string,
  created_date      : string,
  message_type      : string,
  affected_service  : string,
  notification_type : string,
  template_type     : string,
  pkey              : number,
};

const templateChangeHistoryShape = {
  prev_body         :  string,
  updated_by        :  string,
  updated_date      :  string,
  prev_message_type :  string,
  prev_affected_service : string,
  template_id       :  number,
  pkey              :  number,
}

export const templateProptypes = {
  template_detail               :  templateShape,
  message_templates             :  arrayOf(shape(templateShape)),
  notification_templates        :  arrayOf(shape(templateShape)),
  templateChangeHistory         :  arrayOf(shape(templateChangeHistoryShape)),
  requestTemplates              :  func,
  requestTemplateChangeHistory  :  func,
};


/* ===== TYPES ===== */
export const types = {
  REQUEST_TEMPLATES               :  '[incidents] REQUEST_TEMPLATES',
  REQUEST_TEMPLATE_CHANGE_HISTORY :  '[incidents] REQUEST_TEMPLATE_CHANGE_HISTORY',
  SET_MESSAGE_TEMPLATES           :  '[incidents] SET_MESSAGE_TEMPLATES',
  SET_NOTIFICATION_TEMPLATES      :  '[incidents] SET_NOTIFICATION_TEMPLATES',
  SET_TEMPLATE_CHANGE_HISTORY     :  '[incidents] SET_TEMPLATE_CHANGE_HISTORY',
  SUBMIT_CREATE_TEMPLATE          :  '[incidents] SUBMIT_CREATE_TEMPLATE',
  SUBMIT_UPDATE_TEMPLATE          :  '[incidents] SUBMIT_UPDATE_TEMPLATE',
  SUBMIT_DELETE_TEMPLATE          :  '[incidents] SUBMIT_DELETE_TEMPLATE',
};


/* ===== ACTION_CREATORS ===== */
export const actions = {
  requestTemplates              : ()              => ({type: types.REQUEST_TEMPLATES}),
  requestTemplateChangeHistory  : (template_id)   => ({type: types.REQUEST_TEMPLATE_CHANGE_HISTORY, payload: template_id}),
  setMessageTemplates           : (templates)     => ({type: types.SET_MESSAGE_TEMPLATES, payload: templates}),
  setNotificationTemplates      : (templates)     => ({type: types.SET_NOTIFICATION_TEMPLATES, payload: templates}),
  setTemplatesChangeHistory     : (changeHistory) => ({type: types.SET_TEMPLATE_CHANGE_HISTORY, payload: changeHistory}),
  submitCreateTemplate          : (template)      => ({type: types.SUBMIT_CREATE_TEMPLATE, payload: template}),
  submitUpdateTemplate          : (template)      => ({type: types.SUBMIT_UPDATE_TEMPLATE, payload: template}),
  submitDeleteTemplate          : (template_id)   => ({type: types.SUBMIT_DELETE_TEMPLATE, payload: template_id}),
};


/* ===== SELECTORS ===== */
export const selectors = {
};


/* ===== REDUCER ===== */
export default function reducer(state=initialState, action) {
  switch (action.type) {

    case types.SET_MESSAGE_TEMPLATES: {
      return { ...state, message_templates : action.payload };
    }

    case types.SET_NOTIFICATION_TEMPLATES: {
      return { ...state, notification_templates : action.payload };
    }

    case types.SET_TEMPLATE_CHANGE_HISTORY: {
      return { ...state, changeHistory : action.payload };
    }
    
    default: { return state; }
  }
}
