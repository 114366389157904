import React from 'react';
import {
  Modal,
  Button,
  OverlayTrigger,
  Tooltip,
  ListGroup,
} from 'react-bootstrap'

const styles = {
  'deleteIcon': {'color': 'red'},
};

const EditImpactSettingsModal = ({
  showModal,
  handleShowModal,
  handleRemoveSetting,
  userManagedSettings,
  handleCancelSettingUpdate,
  handleSaveSettings,
}) => {


  return (
    <Modal
      show            =  {showModal}
      onHide          =  {() => handleShowModal(false)}
      size            =  "sm"
      aria-labelledby =  "contained-modal-title-vcenter"
      centered >

      <Modal.Header className="text-center d-inline-block">
        <Modal.Title id="contained-modal-title-vcenter"> Manage Filters </Modal.Title>
      </Modal.Header>

      <Modal.Body>
      {
        userManagedSettings.map( (setting, i) => (
          <ListGroup key={`editImpactModal-${setting.nickname}`} variant="flush">
            <ListGroup.Item className="d-flex justify-content-between">
              <span>{setting.nickname}</span>
              <button
                className =  "btn btn-sm"
                style     =  {styles.deleteIcon}
                onClick   =  { () => handleRemoveSetting(i)} >
                <i className="fa fa-times"></i>
              </button>
            </ListGroup.Item>
          </ListGroup>
        ))
      }
      </Modal.Body>

      <Modal.Footer>
        <Button
          className =  "btn btn-sm btn-success"
          onClick   =  {handleSaveSettings}>
          Save
        </Button>
        <OverlayTrigger
          placement =  'bottom'
          overlay   =  { <Tooltip> Cancel any actions you have taken on this page </Tooltip> }>
          <button className="btn btn-sm btn-light" onClick={handleCancelSettingUpdate}>Cancel</button>
        </OverlayTrigger>
      </Modal.Footer>
    </Modal>
  );
}

export default EditImpactSettingsModal;
