import http from "../../services/httpService";

let apiEndpoint = '/maintenance_event';

export const getMaintenanceEvents = async () => {
  const result = await http.get(`${apiEndpoint}`);
  return result;
};
export const getAllDataCenters = async () => {
  const result = await http.get('data_centers');
  return result;
};
export const createMaintenanceEvent = async (payload) => await http.post(`${apiEndpoint}`, payload);
export const updateMaintenanceEvent = async (payload) => await http.put(`${apiEndpoint}`, payload);
export const deleteMaintenanceEvent = async (id) => await http.get(`delete_maintenance_event/?id=${id}`);