import React, { useState } from 'react';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  actions as uiActions,
} from '../../../ducks/ui/ui.index';
import {
  actions as incidentActions
} from '../../../ducks/incident/incident.index';
import { Modal, Button, Form, Tooltip, OverlayTrigger, Row } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import {Formik, Field} from 'formik';
import * as yup from 'yup';
import infoImage from '../../../assets/282532_Information_R_blue.png';
import { useTranslation } from 'react-i18next';
/* ========= PACKAGE IMPORTS ========= */
const curDate = new Date();

const schema = yup.object().shape({
  message_type: yup.string().required(),
  body: yup.string().required(),
  broadcast: yup.string().required().oneOf(['Y', 'N']),
  impact: yup.string().notRequired(),
  root_cause: yup.string().notRequired(),
  corrective_measures: yup.string().notRequired(),
  solution: yup.string().notRequired(),
  //not displayed on form
  email_fields: yup.array().notRequired(),
  jira_id: yup.string().notRequired(),
  priority_type: yup.string().notRequired(),
  is_test: yup.string().notRequired(),
});

const mapStateToProps = ({uiState, userState, incidentState, templateState}) => ({
  pageLoading         :  uiState.pageLoading,
  email               :  userState.user.email,
  authorization       :  userState.user.authorization,
  emailFields         :  incidentState.emailFields,    
  p2AlertTypes        :  incidentState.p2AlertTypes,   
  p1AlertTypes        :  incidentState.p1AlertTypes, 
  templates           :  templateState.message_templates,
  selectedIncident    :  incidentState.selectedIncident,
});
const mapDispatchToProps =  (dispatch) => {
  const combinedActionCreators = {
    setPageLoading           :  uiActions.setPageLoading,
    submitCreateMessage      :  incidentActions.submitCreateMessage,
  };

  return bindActionCreators(combinedActionCreators, dispatch);
};

const CREATE_IS_INTERNAL = 'create_is_internal_message'

const CreateMessageModal = ({jiraId, priorityType, emailFields, submitCreateMessage, setPageLoading, selectedIncident, p2AlertTypes, p1AlertTypes, templates, authorization}) => {
  const { t } = useTranslation(["translation"]);
  //copy these variables as the updatemessagemodal can modify them and it would affect here.
  let p2Types = [];
  let p1Types = [];
  p2AlertTypes.forEach(type => {
    p2Types.push(type);
  })
  p1AlertTypes.forEach(type => {
    p1Types.push(type);
  })
  // if internal, remove all external message types
  if (selectedIncident['isInternal'] == 'Y'){
    var i = p1Types.length
    while (i--) {
      if (!p1Types[i].toLowerCase().includes('internal')) { 
        p1Types.splice(i, 1);
      } 
    }
    i = p2Types.length
    while (i--) {
      if (!p2Types[i].toLowerCase().includes('internal')) { 
        p2Types.splice(i, 1);
      } 
    }
  }
  if ((!authorization.is_admin && !authorization.allowed_actions.includes(CREATE_IS_INTERNAL))){
    var i = p1Types.length
    while (i--) {
      if (p1Types[i].toLowerCase().includes('internal')) { 
        p1Types.splice(i, 1);
      } 
    }
    i = p2Types.length
    while (i--) {
      if (p2Types[i].toLowerCase().includes('internal')) { 
        p2Types.splice(i, 1);
      } 
    }
  }
  let styles = {
    error         :  {
      color       :  'red',
    },
    buttonError   :  {
      color       :  'red',
      marginLeft  :  '5px',
    },
    button        : {
      width       :  '8rem',
    },
    buttonRight   : {
      width       : '8rem',
      marginLeft  : '1rem'
    },
    buttonRightDisabled: {
      width       : '8rem',
      marginLeft  : '1rem',
      cursor      : 'default'
    },
    title: {
      textAlign: 'center',
      color: 'red',
      fontSize: '1.3rem'
    },
    marginRight: {
      marginRight: '1rem',
    },
    confirmationText: {
      marginLeft: '.5rem',
      marginBottom: '.5rem',
      marginTop: '.5rem',
    },
    confirmationHeader: {
      marginBottom: '.5rem',
      marginTop: '.5rem',
      minWidth: '120px'
    },
    infoImg: {
      maxHeight : '1.9rem',
    },
    inlineMiddleVAlign: {
      display : 'inline',
      verticalAlign : 'middle'
    },
  }
  let buttonMessage = '';
  let system_names = [];

  const [modalIsOpen,setIsOpen] = useState(false);
  const [showConfirmation,setShowConfirmation] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  }
  const closeModal = () => {
    setIsOpen(false);
  }

  const onChangeAlertType = (e, values, field, setValues) => {
    const message_type = e.target.value;
    const broadcast = values.broadcast;
    //apparently even checkbox sends a "on" value and not a bool
    if (broadcast === 'Y'){
      setValues({...values,  email_fields: emailFields[message_type]['display_names']});
      system_names = emailFields[message_type]['system_names']
    }
    else{
      setValues({...values,  email_fields: []});
    }
    field.onChange(e);
  }

  const onChangeTemplate = (e, values, field, setValues) => {
    const templateVal = e.target.value;
    if (templateVal == 'None'){
      setValues({...values,  selected_template: templateVal});
      return;
    }
    const vals = templates
    let template = null;
    vals.forEach(val => {
      if (val.pkey == templateVal){
        template = val;
      }
    })
    setValues({...values,  selected_template: template.pkey, body: template.body});
    field.onChange(e);
  }

  //custom on change logic
  const onChangeBroadcast = (e, values, field, setValues) => {
    const message_type = values.message_type;
    const inputValue = e.target.value;
    if (inputValue === 'Y'){
      setValues({...values,  email_fields: emailFields[message_type]['display_names']});
      system_names = emailFields[message_type]['system_names']
    }
    else{
      setValues({...values,  email_fields: []});
    }
    field.onChange(e);
  }

  const displayButton = (values, touched, errors) => {
    let displayButton = true;
    system_names = []
    if (!emailFields || !emailFields[values.message_type]){
      return '';
    }
    system_names = emailFields[values.message_type]['system_names'];
    buttonMessage = '';
    if (errors.body){
      displayButton = false;
      buttonMessage = t('containers.incidentManagementCreateMessage.messageBodyError');
    }
    if (!values.body){
      displayButton = false;
    }
    if (!values.broadcast){
      displayButton = false;
    }
    if (values.broadcast === 'Y'){
      for (let i = 0; i < values.email_fields.length; i++){
        if (!values[system_names[i]]){
          displayButton = false;
          if (touched[system_names[i]]){
            buttonMessage = t('containers.incidentManagementCreateMessage.' + system_names[i]);
          }
        }
      }
    }
    return displayButton;
  }

  const submitForm = (fields) => {
    if (fields.broadcast === 'Y' && !showConfirmation){
      setShowConfirmation(true);
    }
    else{
      if (fields.broadcast === 'Y'){
        //format the email template data to what hedwig expects
        const field_names = emailFields[fields.message_type].system_names;
        fields['email_template_data'] = {};
        field_names.forEach((field) => {
          fields['email_template_data'][field] = fields[field];
        })
      }
      submitCreateMessage(fields, priorityType);
      setShowConfirmation(false);
      closeModal();
    }
  }

  const backButton = (setFieldTouched) => {
    setShowConfirmation(false);
    //dynamic fields only populate after a field has been touched
    //this hacky thing waits .2 seconds then touches the body field to repopulate them
    //remove me if a solution is found to this
    setTimeout(function() {
      setFieldTouched('body', true, true);
    }, 200);
    
  }


  return (
    <div>
      {/* Overlay Tooltip for button */}
      <OverlayTrigger
        key='left'
        placement='left'
        overlay=
        {
          <Tooltip id={`tooltip-left`}>{t('containers.incidentManagementCreateMessage.tooltip')}</Tooltip>
        }
      >
        <Button className="data-cy-create-message-modal-button" variant="primary" onClick={openModal}>
        {t('containers.incidentManagementCreateMessage.create')}
        </Button>
      </OverlayTrigger>
      
      <Modal show={modalIsOpen} onHide={closeModal} className="data-cy-create-message-modal">
      <Formik   
          enableReinitialize
          validationSchema={schema}
          onSubmit={values => submitForm(values)}
          initialValues={{
            message_type: priorityType == 'P2' ? p2Types[0] : p1Types[0],
            body: '',
            broadcast: '',
            impact: '',
            root_cause: '',
            corrective_measures: '',
            solution: '',
            email_fields: [],
            jira_id: jiraId,
            priority_type: priorityType,
            is_test: 'N',
            selected_template: 'None',
            comm_src: 'Aviary',
          }}
        >
        {({
          handleSubmit,
          handleChange,
          handleBlur,
          setFieldTouched,
          values,
          errors,
          touched,
          setValues,
        }) => 
        (
          <Form noValidate onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title>{t('containers.incidentManagementCreateMessage.createFor')}{jiraId}</Modal.Title>
            </Modal.Header>
            {/* Confirmation Step Title */}
           
            <Modal.Body>
              {/* Main Step Body */}
              {!showConfirmation && 
                <div>
                  <Form.Group>
                    <Form.Label>{t('containers.incidentManagementCreateMessage.messageType')}</Form.Label>
                    <Field>
                      {({ field }) => (
                        <Form.Control as="select" className="data-cy-create-message-modal-type" name="message_type" value={values.message_type} onBlur={handleBlur} onChange={e => onChangeAlertType(e, values, field, setValues)}>
                          { priorityType == 'P2' && p2Types.map((type, i) => (
                            <option key={i}>{type}</option>
                          ))}
                          { priorityType == 'P1' && p1Types.map((type, i) => (
                            <option key={i}>{type}</option>
                          ))}
                        </Form.Control>
                      )}
                    </Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Insert Template Text</Form.Label>
                    <OverlayTrigger
                      key='right'
                      placement='right'
                      overlay=
                      {
                        <Tooltip id={`tooltip-right`}>
                          Inserts text into the Message Body field. WARNING will delete Message Body contents.
                        </Tooltip>
                      }>
                      <img src = {infoImage} style = {{ ...styles.infoImg, ...styles.inlineMiddleVAlign }} />
                    </OverlayTrigger>
                    <Field>
                      {({ field }) => (
                        <Form.Control as="select" name="template_text" value={values.selected_template} onBlur={handleBlur} onChange={e => onChangeTemplate(e, values, field, setValues)}>
                          <option key='None' value='None'></option>
                          { templates.map((type, i) => {
                            if (type['message_type'] == values.message_type)return ( <option key={'template-' + i} value={type.pkey} id={type.body}>{type.title}</option>)
                            })}
                          
                        </Form.Control>
                      )}
                    </Field>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>{t('containers.incidentManagementCreateMessage.messageBody')}</Form.Label>
                    <OverlayTrigger
                      key='right'
                      placement='right'
                      overlay=
                      {
                        <Tooltip id={`tooltip-right`}>
                          {t('containers.incidentManagementCreateMessage.toolTip')}
                        </Tooltip>
                      }>
                      <img src = {infoImage} style = {{ ...styles.infoImg, ...styles.inlineMiddleVAlign }} />
                    </OverlayTrigger>
                    <Form.Control
                    type="textarea"
                    as="textarea"
                    className="data-cy-create-message-modal-body"
                    value={values.body}
                    rows="6"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="body"></Form.Control>
                    {errors.body && touched.body ? (
                      <div style={styles.error}>{errors.body}</div>
                    ) : null}
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>{t('containers.incidentManagementCreateMessage.send')}</Form.Label>
                    <OverlayTrigger
                      key='right'
                      placement='right'
                      overlay=
                      {
                        <Tooltip id={`tooltip-right`}>
                          {t('containers.incidentManagementCreateMessage.toolTip2')}
                        </Tooltip>
                      }>
                      <img src = {infoImage} style = {{ ...styles.infoImg, ...styles.inlineMiddleVAlign }} />
                    </OverlayTrigger>
                    <Field>
                      {({ field }) => (
                        <Form.Group as={Row} className="data-cy-create-message-modal-broadcast" name="broadcast" value={values.broadcast} onBlur={handleBlur} onChange={e => onChangeBroadcast(e, values, field, setValues)} >
                           <Form.Check
                            type="radio"
                            name="broadcast"
                            label={t('containers.incidentManagementCreateMessage.yes')}
                            value="Y"
                            className="data-cy-create-message-modal-broadcast-Y"
                            id="emailYes"
                            style={styles.marginRight}
                            checked={values.broadcast === 'Y'}
                            onChange={handleChange}
                          />
                          <Form.Check
                            type="radio"
                            label={t('containers.incidentManagementCreateMessage.no')}
                            name="broadcast"
                            value="N"
                            className="data-cy-create-message-modal-broadcast-N"
                            id="emailNo"
                            checked={values.broadcast === 'N'}
                            onChange={handleChange}
                          />
                        </Form.Group>

                      )}
                    </Field>
                    {errors.broadcast && touched.broadcast ? (
                        <div style={styles.error}>{errors.broadcast}</div>
                      ) : null}
                  </Form.Group>
                  {values.email_fields.map((field, i) => 
                    {
                      return (
                        <Form.Group key={field}>
                          <Form.Label>{field}</Form.Label>
                          <Form.Control name={system_names[i]} value={values[system_names[i]]} onBlur={handleBlur} onChange={handleChange} className={"data-cy-create-message-modal-" + field} as="textarea"></Form.Control>
                          {!values[system_names[i]] && touched[system_names[i]] ? (
                          <div style={styles.error}>{field} {t('containers.incidentManagementCreateMessage.required')}</div>
                          ) : null}
                          
                        </Form.Group>
                      );
                    }
                  )}
    
                </div>
              }
              {/* Confirmation Step Body */}
              {showConfirmation && 
                <div>
                  {Object.keys(values).map((key, i) => {
                    if (values[key] && key !== 'email_fields' && key !== 'is_test'){
                      return (
                        <Row key={i}>
                          <div style={styles.confirmationHeader}><b>{key}: </b></div>
                          <div style={styles.confirmationText}>{values[key]}</div>
                        </Row> 
                      );
                    }
                  })} 
                  <Row key='internal'>
                    <div style={styles.confirmationHeader}><b>is_internal: </b></div>
                    <div style={styles.confirmationText}>{values['message_type'].toLowerCase().includes('internal') ? 'Y' : 'N'}</div>
                  </Row> 
                  <Modal.Title>
                    <div style={styles.title}>{t('containers.incidentManagementCreateMessage.confirm')}
                      <b><u>{values['message_type'].toLowerCase().includes('internal') ? 'INTERNAL' : 'EXTERNAL'}</u></b>
                      {t('containers.incidentManagementCreateMessage.emails')}
                    </div>
                  </Modal.Title>        
                </div>}
            </Modal.Body>
            <Modal.Footer>
              {/* Main Step Footer */}
              {!showConfirmation &&                
                <div>
                  <Row>
                    <Button style={styles.button} variant="secondary" onClick={closeModal} className="data-cy-create-message-modal-close">
                    {t('containers.incidentManagementCreateMessage.close')}
                    </Button>
                    <Button style={!displayButton(values, touched, errors) ? styles.buttonRightDisabled : styles.buttonRight} className="data-cy-create-message-modal-submit" disabled={!displayButton(values, touched, errors)} type="submit">{values.broadcast === 'Y' ? t('containers.incidentManagementCreateMessage.next') : t('containers.incidentManagementCreateMessage.submit')}</Button>   
                  </Row>
                  <Row>
                    <div style={styles.buttonError}>{buttonMessage}</div>
                  </Row>        
                 
                </div>
              }
              {/* Confirmation Step Footer */}
              {showConfirmation && 
                <Row>
                  <Button style={styles.button} variant="secondary" onClick={backButton.bind(this, setFieldTouched)} className="data-cy-create-message-modal-back">{t('containers.incidentManagementCreateMessage.back')}</Button>
                  <Button style={styles.buttonRight} className="data-cy-create-message-modal-ok" type="submit">{t('containers.incidentManagementCreateMessage.confirmButton')}</Button>
                </Row>
              }
            </Modal.Footer>
          </Form>
        )}
        </Formik>

      </Modal>
    </div>
  );
}

export default connect(mapStateToProps,mapDispatchToProps)(CreateMessageModal);