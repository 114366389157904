/* ========= PACKAGE IMPORTS ========= */
import React, { Component }   from 'react';
import { connect }            from "react-redux";
import { bindActionCreators } from 'redux';

/* ========= REDUX IMPORTS ========= */
import {
  incidentProptypes,
  actions as incidentActions,
} from '../../ducks/incident/incident.index';
import { uiProptypes } from '../../ducks/ui/ui.index';
import { actions as uiActions } from '../../ducks/ui/ui.index';


/* ========= COMPONENT IMPORTS ========= */
import Loading         from "../../components/loading";
import HistoryTable    from './HistoryTable/HistoryTable';
import { Translation } from 'react-i18next';

/* ========= REDUX STATE CONNECTORS ========= */
const mapStateToProps = ({incidentState, uiState}) => ({
  historicIncidents :  incidentState.historicIncidents,
  pageLoading       :  uiState.pageLoading,
  windowDimensions  :  uiState.windowDimensions,
  displayLocale     :  uiState.displayLocale,
});
const mapDispatchToProps = (dispatch) => {
  const combinedActionCreators = {
    requestAllHistoricIncidents :  incidentActions.requestAllHistoricIncidents,
    setPageLoading              :  uiActions.setPageLoading,
  }

  return bindActionCreators(combinedActionCreators, dispatch);
};

const styles = {
  internalOnly: {fontSize: "1rem", paddingLeft: "1rem", color: 'red'},
  container: { width: '90%', margin: '0 auto' }
}


class History extends Component {
  static propTypes = {
    pageLoading                 :  uiProptypes.pageLoading,
    requestAllHistoricIncidents :  incidentProptypes.requestAllHistoricIncidents,
    setPageLoading              :  uiProptypes.setPageLoading,
    historicIncidents           :  incidentProptypes.historicIncidents,
  };

  componentDidMount = () => {
    this.props.requestAllHistoricIncidents();
  }

  componentWillUnmount = () => {
    // this.props.setPageLoading(true);
  }

  render = () => {
    const { pageLoading, historicIncidents, windowDimensions, displayLocale } = this.props;
    const showTable = historicIncidents && historicIncidents.length > 0;
    return (
      <div style={styles.container}>
        <div className="mb-2" style={styles.internalOnly}><Translation>{(t) => t('containers.history.internal')}</Translation></div>
        { (!showTable) ? <Loading height="75vh" /> :
          <HistoryTable historicIncidents={historicIncidents} windowDimensions={windowDimensions} displayLocale={displayLocale}/>
        }
      </div>
    );
  }

}

export default connect(mapStateToProps, mapDispatchToProps)(History);

