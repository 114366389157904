/* ========= PACKAGE IMPORTS ========= */
import React, { Component }   from 'react'
import { connect }            from "react-redux"
import { bindActionCreators } from 'redux'
import Skeleton               from 'react-loading-skeleton'
import { Translation } from 'react-i18next';

/* ========= REDUX IMPORTS ========= */
import {
  actions as userActions,
  selectors as userSelectors,
} from '../../../ducks/user/user.index';


/* ========= COMPONENT IMPORTS ========= */
import CustomerImpactTable from './CustomerImpactTable'
import SaveImpactSettings  from './SaveImpactSettings'


/* ========= REDUX STATE CONNECTORS ========= */
const mapStateToProps = ({userState}) => ({
  selectedFilter :  userSelectors.selectCustomerImpactFilters(userState.customerImpactSettings.selected.filters),
  impactSettings :  userState.customerImpactSettings.allFilters,
});
const mapDispatchToProps = (dispatch) => {
  const combinedActionCreators = {
    setCustomerImpactSettings           :  userActions.setCustomerImpactSettings,             // save changes to all in the ui, for modal
    putCustomerImpactSettings           :  userActions.putCustomerImpactSettings,             // save changes to all to the db, for modal
    updateCustomerImpactFilter          :  userActions.updateCustomerImpactFilter,            // save override old filter button
    setSelectedCustomerImpactFilter     :  userActions.setSelectedCustomerImpactFilter,       // change selectedFilter
    setSelectedFilterColumn             :  userActions.setSelectedFilterColumn,               // update selectedFilter column data
    putCustomerImpactFilters            :  userActions.putCustomerImpactFilters,              // PUT request (update db)
    generateAndAddCustomerImpactSetting :  userActions.generateAndAddCustomerImpactSetting,   // save newfilter button
  };
  return bindActionCreators(combinedActionCreators, dispatch);
};


const styles = {
  custImpact :  { overflow: 'scroll', paddingTop: '.25rem', paddingLeft: '.25rem', paddingRight: '.25rem' },
};



class CustomerImpact extends Component {
  render = () => {
    const loading = <Skeleton height={20}/>
    const {
      affected_companies,
      selectedFilter,
      impactSettings,
      updateCustomerImpactFilter,
      setCustomerImpactSettings,
      putCustomerImpactSettings,
      setSelectedCustomerImpactFilter,
      setSelectedFilterColumn,
      generateAndAddCustomerImpactSetting,
    } = this.props;

    // loading state
    let loadingCompanies = Array(8).fill({entity_code: loading, id: loading, name: loading})
    // error case
    if (typeof affected_companies === 'object' && affected_companies['error']) {
      return (
        <div className="card-body affected_customer_error">
          <Translation>{(t) => t('containers.detailsCustomerImpact.error')}</Translation>
        </div>
      );
    }
    // normal state
    else {
      return (
        <div style={styles.custImpact}>
          <CustomerImpactTable
            setSelectedFilterColumn =  {setSelectedFilterColumn}
            currentFilters          =  {selectedFilter}
            affectedCompanies       =  {affected_companies || loadingCompanies}/>
          <SaveImpactSettings
            impactSettings                      =  {impactSettings}
            updateCustomerImpactFilter          =  {updateCustomerImpactFilter}
            setCustomerImpactSettings           =  {setCustomerImpactSettings}
            putCustomerImpactSettings           =  {putCustomerImpactSettings}
            generateAndAddCustomerImpactSetting =  {generateAndAddCustomerImpactSetting}
            setSelectedCustomerImpactFilter     =  {setSelectedCustomerImpactFilter}
          />
        </div>
      );
    }
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(CustomerImpact);
