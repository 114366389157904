import { takeEvery, put, call, select } from 'redux-saga/effects';

import { v4getUserSubscriptions, v4postUserSubscription, putClearUuid, getCommHistory, getAllUserSubscriptions } from './subscription.api';
import { types, actions as subscriptionActions, applicationName, priority_types, subscription_components } from './subscription.index';
import { actions as uiActions }    from '../ui/ui.index';
import { actions as errorActions } from '../error/error.index';
import { actions as toastActions } from '../toast/toast.index';


// worker sagas
export function* fetchUserSubscriptions(action) {
  try{
    // fetch subscriptions
    yield put(uiActions.setPageLoading(true));
    const userEmail                                  =  action.payload;
    let   response                                   =  yield call(v4getUserSubscriptions, userEmail);
    const { userSubscriptions, subscriptionOptions } =  response.data.payload;

    // transform & return
    const displaySortedSubscriptions =  sortSubscriptionsByDisplayOrder(subscriptionOptions);
    const userCurrentSubscriptions   =  interpolateUserSubscriptions(displaySortedSubscriptions, userSubscriptions);
    yield put(subscriptionActions.setUserSubscriptions(userCurrentSubscriptions));
    yield put(uiActions.setPageLoading(false));
  }
  catch (error) {
    // handle ui effects
    const message      =  `Unable to pull your subscription options: ${error}`;
    const toastOptions =  {type: 'error'};
    yield put(toastActions.createToast(message, toastOptions));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
  }
}

export function* fetchUserExists(action) {
  try{
    // fetch subscriptions
    yield put(uiActions.setPageLoading(true));
    const userEmail                                  =  action.payload;
    let   response                                   =  yield call(getAllUserSubscriptions, userEmail);
    yield put(subscriptionActions.setUserExists(response.data.payload.verified != null));
    yield put(uiActions.setPageLoading(false));
  }
  catch (error) {
    // handle ui effects
    const message      =  `Unable to pull your subscription options: ${error}`;
    const toastOptions =  {type: 'error'};
    yield put(toastActions.createToast(message, toastOptions));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
  }
}

export function* fetchCommHistory(action) {
  try{
    // fetch subscriptions
    yield put(uiActions.setPageLoading(true));
    const userEmail                                  =  action.payload;
    let   response                                   =  yield call(getCommHistory, userEmail);
    const { comms } =  response.data.payload;
    if (!comms){
      //put an empty array rather than a null value
      yield put(subscriptionActions.setCommHistory([]));
    }
    else{
      yield put(subscriptionActions.setCommHistory(comms));
    }
    yield put(uiActions.setPageLoading(false));
  }
  catch (error) {
    // handle ui effects
    const message      =  `Unable to pull comm history: ${error}`;
    const toastOptions =  {type: 'error'};
    yield put(toastActions.createToast(message, toastOptions));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
  }
}

export function* fetchAllUserSubscriptions(action) {
  try{
    // fetch subscriptions
    yield put(uiActions.setPageLoading(true));
    const userEmail                                  =  action.payload;
    let   response                                   =  yield call(getAllUserSubscriptions, userEmail);
    const { subscriptions } =  response.data.payload;
    yield put(subscriptionActions.setAllSubscriptionOptions(subscriptions));
    yield put(uiActions.setPageLoading(false));
  }
  catch (error) {
    // handle ui effects
    const message      =  `Unable to pull all subscription options: ${error}`;
    const toastOptions =  {type: 'error'};
    yield put(toastActions.createToast(message, toastOptions));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
  }
}

export function* submitUserSubscriptions(action) {
  const setUiSubmitting =  action.payload.setSubmitting;
  const subscriptions   =  action.payload.values;
  const store           =  yield select( ({subscriptionState, userState}) => ({
    email               :  userState.user.email,
    subscriptionOptions :  subscriptionState.subscriptionOptions
  }));

  // Construct the payload which we can validate against (and send if valid)
  const payload = convertFormToPayload(store.subscriptionOptions, subscriptions, store.email, action.payload.auth);

  let noneAreChecked = true;
  let atLeastOneFromEachGroupIsChecked = true;
  // Checkbox selection validation
  for (const priority_type of priority_types){
    const dc = payload[priority_type].subscriptions.data_centers.length > 0;
    const services = payload[priority_type].subscriptions.services.length > 0;
    const alert_types = payload[priority_type].subscriptions.alert_types.length > 0;
    //checking if any of these has elements
    if (dc || services || alert_types){
      noneAreChecked = false;
    }
    //checking that all columns are either empty or contain something.
    if (dc !== services || dc !== alert_types || services !== alert_types){
      atLeastOneFromEachGroupIsChecked = false;
    }
  }

  // Maintenance Event checkbox selection validation
  const me_dc = payload['notifications'].subscriptions.data_centers.length > 0;
  const me_notification_types = payload['notifications'].subscriptions.notification_types.length > 0;

  if (me_dc || me_notification_types) {
    noneAreChecked = false
  }
  // both false -> neither checked
  // both true -> at least one of each is checked
  if (me_dc !== me_notification_types) {
    atLeastOneFromEachGroupIsChecked = false;
  }

  // "noneAreChecked" means user is unsubscribing. Otherwise, at least one checkbox from each group needs to be checked in order to be a valid subscription.
  const selectionIsValid = (noneAreChecked || atLeastOneFromEachGroupIsChecked);

  if (selectionIsValid) {
    // Checkbox validation passed
    try {
      yield call(setUiSubmitting, true);
      yield call(v4postUserSubscription, payload);
      yield put(toastActions.createToast('Notification preferences have been successfully updated!', {type: 'success'}))
    }

    catch (error) {
      const message      =  `There was an unexpected error attempting to update your notification preferences: ${error}`;
      const toastOptions =  { type: 'error', autoClose: false };
      yield put(toastActions.createToast(message, toastOptions));

      // log error to db
      const entireState = yield select();
      yield put(errorActions.raiseError({ error, entireState }));
    }
    finally {
      yield call(setUiSubmitting, false);
    }
  } else {
    // Checkbox validation failed
    yield put(toastActions.createToast('Please ensure at least one box is checked per category.', {type: 'error'}));
    yield call(setUiSubmitting, false);
  }

}

export function* submitClearUuid(action){
  try{
    yield call(putClearUuid, {'email': action.payload});
  }
  catch (error) {
    // handle ui effects
    const message      =  `Unable to clear uuid: ${error}`;
    const toastOptions =  {type: 'error'};
    yield put(toastActions.createToast(message, toastOptions));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
  }

}

export function* uiSelectAllSubscriptions(action) {
  const selectedState        =  action.payload.bool;
  const priority_type        =  action.payload.priority_type;
  const currentSubscriptions =  yield select( ({subscriptionState}) => subscriptionState.subscriptionOptions);
  const allSubscribed        =  Object
    .keys(currentSubscriptions[priority_type])
    .map( (option) => ({
      service :  option,
      values  :  currentSubscriptions[priority_type][option].map( (suboption) => ({...suboption, selected: selectedState}) )
    }))
    .reduce( (allSelectedSubscriptions, option) => ({ ...allSelectedSubscriptions, [option.service]: option.values}), {});
  currentSubscriptions[priority_type] = allSubscribed;
  yield put(subscriptionActions.setUserSubscriptions(currentSubscriptions));
}

// watcher saga
export default function subscriptionSagas() {
  return [
    takeEvery(types.REQUEST_USER_SUBSCRIPTIONS, fetchUserSubscriptions),
    takeEvery(types.REQUEST_USER_EXISTS, fetchUserExists),
    takeEvery(types.SUBMIT_USER_SUBSCRIPTIONS, submitUserSubscriptions),
    takeEvery(types.SELECT_ALL_SUBSCRIPTIONS, uiSelectAllSubscriptions),
    takeEvery(types.CLEAR_UUID, submitClearUuid),
    takeEvery(types.REQUEST_COMM_HISTORY, fetchCommHistory),
    takeEvery(types.REQUEST_ALL_USER_SUBSCRIPTIONS, fetchAllUserSubscriptions)
  ];
}



// helper functions
export const interpolateUserSubscriptions = (subscriptionOptions, userSubscriptions) => {
  const userSelectedSubscriptions = {};
  for (const subs_component of subscription_components){
    userSelectedSubscriptions[subs_component] = {};
    Object
    .keys(subscriptionOptions[subs_component])
    .forEach( (option) => {   // loop thru all subscription options
      userSelectedSubscriptions[subs_component][option] = subscriptionOptions[subs_component][option]
        .map( (subOption) => ({
          ...subOption, // rebuild subscriptionOptions
          selected: userSubscriptions[subs_component][option].includes(subOption.id)} // add new key, value === user already subscribed
        )
      );
    });
  }
  return userSelectedSubscriptions;
}

export const sortSubscriptionsByDisplayOrder = (subscriptionOptions) => {
  for (const subs_component of subscription_components){
    Object
    .keys(subscriptionOptions[subs_component])
    .reduce( (optionsSortedByDisplayOrder, objKey) => ({
       ...optionsSortedByDisplayOrder,
      [objKey]: subscriptionOptions[subs_component][objKey].sort( (a, b) => a.display_order - b.display_order)
      }), {}
    );
  }
  return subscriptionOptions;
}

export const convertFormToPayload = (subscriptionOptions, formData, email, auth) => {
  // example: { Travel: true, eu: false} => {email, services: [1], data_centers: []}
  const subscriptionsPayload = {
    email,
    locale: "en",
    application: applicationName,
    auth: auth,
  };
  for (const priority_type of priority_types){
    subscriptionsPayload[priority_type] =  {
      subscriptions: {
        services     :  [],
        data_centers :  [],
        alert_types  :  []
      }
    };
    //check for subscribe all form data entry being true
    if (formData[priority_type] && formData[priority_type].subscribeAll) {
      for (const key of Object.keys(formData[priority_type])){
        formData[priority_type][key] = true;
      }
    }
  }

  // Notifications // Maintenance Events
  subscriptionsPayload['notifications'] = {
    subscriptions: {
      data_centers : [],
      notification_types : []
    }
  };
  //check for subscribe all form data entry being true
  if (formData['notifications'] && formData['notifications'].subscribeAll) {
    for (const key of Object.keys(formData['notifications'])){
      formData['notifications'][key] = true;
    }
  }

  for (const subs_component of subscription_components){
    Object
    .keys(subscriptionOptions[subs_component])
    .map((option) => ({
      name     :  option,
      selected :  subscriptionOptions[subs_component][option]
        .filter( (obj) => formData[subs_component][obj.name])
        .map(    (obj) => obj.id)
    }))
    .forEach((option) => {
      subscriptionsPayload[subs_component]["subscriptions"][option.name] = option.selected;
    })
  }
  return subscriptionsPayload;
}
