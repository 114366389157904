import { connect } from 'react-redux';

/*
  SAMPLE USAGE

  <AuthorizationWrapper requiredAction="view_incident_management_page">
    <NavLink className="nav-link" to="/incident/review">Incident Management</NavLink>
  </AuthorizationWrapper>

  The above lines of code will display a link to "Incident Management" only for users who are authorized (either
  by being an admin, or by having the "view_incident_management_page" action in their set of allowed_actions)
 */

const mapStateToProps = ({ userState }, props) => {
  return {
    userState : userState,
  };
};

const AuthorizationWrapper = (props) => {
  const {children, requiredAction, userState} = props;
  const authorization = userState.user.authorization || {};
  const userHasRequiredAction = Array.isArray(authorization.allowed_actions) && authorization.allowed_actions.indexOf(requiredAction) > -1;

  if (userState.isLoggedIn && (authorization.is_admin || userHasRequiredAction)) {
    // User is an admin, or is authorized to perform the required action; show the child elements
    return children;
  } else {
    // User is not authorized; hide the child elements
    return null;
  }
}

export default connect(mapStateToProps)(AuthorizationWrapper);
