import React, { useMemo, useState } from 'react';
import AdministrationModal from '../AdministrationModal/AdministrationModal';
import AdministrationTypeTable from '../AdministrationTypeTable/AdministrationTypeTable';
import { MdDeleteForever, MdModeEdit } from 'react-icons/md';
import {canModifyRole} from './../../../utils/adminHelperFunctions';
import { useTranslation } from 'react-i18next';
const styles = {
  container : { width: '90%', margin: '0 auto', marginBottom: '1rem' },
  card      : { 'boxShadow' : 'rgba(0, 0, 0, 0.2) 0px 0px 10px -2px', marginTop: "1rem", minHeight: '400px' },
  headers   : { 'textAlign' : 'left', 'lineHeight' : '2rem', },
  prewrap   : { 'whiteSpace': 'pre-wrap' },
  delete    : { color: 'red', cursor: 'pointer' },
  edit      : { color: '#ffc107', cursor: 'pointer' }
}

const type = 'roles';

export const Roles = ({ roles, userState }) => {
  const { user: { authorization }}    = userState
  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [modalType, setModalType]     = useState("")
  const [modalAction, setModalAction] = useState("");
  const [selectedData, setSelectedData] = useState({});
  const { t } = useTranslation(["translation"]);

  const modalData = useMemo(() => {
    switch (modalType) {
      case "Edit Role":
        setModalAction("edit");
        return selectedData
      case "Delete Role":
        setModalAction("delete");
        return selectedData
      default:
        return "DEFAULT"
    }
  },[modalType, modalIsOpen]);

  const closeModal = () => {
    setModalIsOpen(false);
  }

  const columns = useMemo(() => {
    const allowEdit   = authorization.is_admin || authorization?.allowed_actions?.includes("edit_role")
    const allowDelete = authorization.is_admin || authorization?.allowed_actions?.includes("delete_role")
    const returnArr = [
      {
        'Header'      : <b className="display-name-header">{t('containers.administrationRoles.colId')}</b>,
        'headerStyle' : styles.headers,
        'id'          : 'role_id',
        'accessor'    : (data) => data.role_id,
        'style'       : styles.prewrap,
        'width'       : 60,
        sortMethod  : (a, b) => a.role_id - b.role_id,
      },
      {
        'Header'      : <b className="display-name-header">{t('containers.administrationRoles.colName')}</b>,
        'headerStyle' : styles.headers,
        'id'          : 'display_name',
        'accessor'    : (data) => data.display_name,
        'style'       : styles.prewrap,
        'width'       : 175,
        sortMethod  : (a, b) => a.role_id - b.role_id,
      },
      {
        'Header'      : <b className="description-header">{t('containers.administrationRoles.colDescription')}</b>,
        'headerStyle' : styles.headers,
        'id'          : 'description',
        'accessor'    : (data) => data.description,
        'style'       : styles.prewrap,
        'width'       : 300,
      },
      {
        'Header'      : <b className="opi-header">{t('containers.administrationRoles.colActions')}</b>,
        'headerStyle' : styles.headers,
        'id'          : 'actions_display',
        'accessor'    : (data) => data.actions_display.filter(el => el).map(el => <div key={el}>- {el}</div>),
        'style'       : styles.prewrap,
        'width'       : 275,
      },
      {
        'Header'      : <b className="opi-header">{t('containers.administrationRoles.colAdmin')}</b>,
        'headerStyle' : styles.headers,
        'id'          : 'is_admin',
        'accessor'    : (data) => data.is_admin ? "Yes" : "No",
        'style'       : styles.prewrap,
        'width'       : 100,
      },
      {
        'Header'      : <b className="opi-header">{t('containers.administrationRoles.colManage')}</b>,
        'headerStyle' : styles.headers,
        'id'          : 'edit_role_id',
        'accessor'    : (data) => (
            <>
              { allowEdit && canModifyRole(authorization, data) && <MdModeEdit className="Edit-role" size={28} style={styles.edit} onClick={() => {
                setModalType("Edit Role");
                setSelectedData(data);
                setModalIsOpen(true);
              }}>{t('containers.administration.buttonEdit')}</MdModeEdit> }
              { allowDelete && canModifyRole(authorization, data) && <MdDeleteForever className="Delete-role" size={28} style={styles.delete} onClick={() => {
                setModalType("Delete Role");
                setSelectedData(data);
                setModalIsOpen(true);
              }}>{t('containers.administration.buttonDelete')}</MdDeleteForever> }
            </>
        ),
        'style' : styles.prewrap,
        'width' : 180,
      }
    ]
    return returnArr
  },[roles, userState])

  return (
    <div>
      <AdministrationModal
       openState={modalIsOpen} 
       title={modalType} 
       type={type} 
       action={modalAction} 
       selectedData={modalData} 
       close={closeModal}/>
      <AdministrationTypeTable props={{ data: roles, columns, type: type }} />
    </div>
  );
}
