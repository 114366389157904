import React, { forwardRef } from 'react'
import { Card, Form, DropdownButton, Button, Dropdown, OverlayTrigger, Popover, Row, Col } from 'react-bootstrap';
import _ from 'lodash'
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const RelativeTimePicker = ({
  timeNumber,
  timeUnitOptions,
  searchChangeLoading,
  maxMonthsDateRange,
  relativeTimeExceeded,
  // methods
  applyFilters,
  setStateField,
  setRelativeTimeUnitOptions
}) => {

  const { t } = useTranslation(["translation"]);
  const relative_time_unit = timeUnitOptions[_.findIndex(timeUnitOptions, (item) => item.selected === true)]

  const styles = {
    formControl       : { width: '50px', display: "inline", marginBottom: "4px" },
    formControlDanger : { borderColor: "var(--danger)" },
    dropdownButton    : { display: "inline", position: "relative", top: "-2px", marginLeft: "4px" }
  }

  const setRelativeTimeParams = (timeNumberVal, timeUnitVal) => {
    // Due to the custom nature of the validation we have to perform on this field,
    // this function is meant to evaluate the Relative Time parameters as one combined value

    if (typeof(timeNumberVal) === 'undefined' || timeNumberVal === null) timeNumberVal = timeNumber;
    if (typeof(timeUnitVal) === 'undefined' || timeUnitVal === null) timeUnitVal = relative_time_unit.systemKey;

    setStateField('relative_time_number', timeNumberVal);
    setRelativeTimeUnitOptions(timeUnitVal);

    // Calculate the time interval of our "Relative Time" selection. Determine whether it exceeds our allowed maximum and set state accordingly.
    const valueExceedsAllowedMax = (moment().subtract(timeNumberVal, timeUnitVal).unix() < moment().subtract(maxMonthsDateRange, 'months').unix());
    setStateField('relative_time_exceeded', valueExceedsAllowedMax);
  }

  return (
    <Form>
      <Form.Group controlId="relative_time_number" style={{display: "inline"}}>
        <Row style={{verticalAlign: "middle"}}>
          <Col className="pl-0" style={{margin: "auto 0"}} md={6}>
          {t('containers.changeHistoryRelativeTime.everythingInLast')}
          </Col>
          <Col className="pl-0 pr-0" style={{textAlign: "right"}} md={6}>
            <Form.Control
              onKeyPress  = {(e) => { if(e.which === 13) { /* Prevent "Enter" from refreshing page; search instead */ e.preventDefault(); applyFilters(); }}}
              value       = {timeNumber}
              variant     = "outline-secondary"
              className   = "data-cy-relative-time-number"
              type        = "text"
              placeholder = "#"
              style       = {{...styles.formControl, ...((relativeTimeExceeded || isNaN(timeNumber) || timeNumber <= 0) ? styles.formControlDanger : {})}}
              onChange    = {(e) => setRelativeTimeParams(e.target.value)} readOnly={searchChangeLoading}
            />
            <DropdownButton
              className = "data-cy-relative-time-unit-dropdown"
              title     = {parseInt(timeNumber) === 1 ? t('containers.changeHistoryTimeUnits.' + relative_time_unit.displayValSingular) : t('containers.changeHistoryTimeUnits.' + relative_time_unit.displayValPlural)}
              variant   = {relativeTimeExceeded ? "outline-danger" : "outline-secondary"}
              style     = {{...styles.dropdownButton}}
              onSelect  = {(val) => setRelativeTimeParams(null, val)}
              disabled  = {searchChangeLoading}
            >
              {
                timeUnitOptions.map(option => (
                  <Dropdown.Item
                    key       = {option.systemKey}
                    eventKey  = {option.systemKey}
                    active    = {option.selected}
                    className = "data-cy-dropdown-option"
                    value     = {option.systemKey}
                  >
                    {parseInt(timeNumber) === 1 ? t('containers.changeHistoryTimeUnits.' + option.displayValSingular) : t('containers.changeHistoryTimeUnits.' + option.displayValPlural)}
                  </Dropdown.Item>)
                )
              }
            </DropdownButton>
          </Col>
        </Row>
      </Form.Group>
    </Form>
  )
};

export default RelativeTimePicker;
