import React from 'react';
import { useTranslation } from 'react-i18next';

export default function LastRefresh(props) {
  const { data: dateTime } = props;
  const { t } = useTranslation(["translation"]);
  return (
    <p className="refresh-time">{t('components.lastRefresh.title')} {dateTime}</p>
  );
}
