import React from 'react'
import ChangeHistoryCard from '../ChangeHistoryCard/ChangeHistoryCard';
import ChangeHistoryManualTable from '../ChangeHistoryTable/ChangeHistoryManualTable';
import ChangeHistoryAutoTable from '../ChangeHistoryTable/ChangeHistoryAutoTable';
import Loading from "../../../components/loading";
import _ from 'lodash'

const styles = {
  spinner: {
    margin     : 0,
    position   : 'absolute',
    top        : '50%',
    left       : '50%',
    transform  : 'translate(-50%, -50%)',
    background : 'rgba(0,0,0,0)',
    zIndex     : 9999999
  }
}

const renderErrorState = () => {
  return (
    <div class="alert alert-danger mb-0" role="alert">
      <p class="mb-0 text-center">
        <b>There was a problem fetching your results. Please refresh the page and try again.</b>
      </p>
    </div>
  )
}
const renderErrorStateTimeout = () => {
  return (
    <div class="alert alert-danger mb-0" role="alert">
      <p class="mb-0 text-center">
        <b>Request timed out. Please filter your results further.</b>
      </p>
    </div>
  )
}
const renderNoDataState = () => {
  return (
    <div class="alert alert-primary mb-0" role="alert">
      <p class="mb-0 text-center">
        <b>No Results Found. Please try adjusting your search criteria.</b>
      </p>
    </div>
  )
}
const renderCard = (search_change_response) => {
  return (
    <div className="changeHistoryCardLoaded">
      {search_change_response.rows.map( (result) => (
        <ChangeHistoryCard
          key           = {result.id ?? 0}
          actual_end    = {result.actual_end }
          actual_start  = {result.actual_start}
          change_type   = {result.change_type ?? ""}
          cis           = {result.cis ?? ""}
          data_centers  = {result.data_centers ?? ""}
          description   = {result.description ?? ""}
          catalog_type  = {result.catalog_type ?? ""}
          planned_end   = {result.planned_end ?? ""}
          planned_start = {result.planned_start ?? ""}
          services      = {result.services ?? ""}
          status        = {result.status ?? ""}
          submitted_by  = {result.submitted_by ?? ""}
          summary       = {result.summary ?? ""}
          ticket_id     = {result.ticket_id ?? ""}
          submitted_on  = {result.submitted_on ? result.submitted_on : undefined}
          github_owner   = {result.github_owner ?? ""}
          github_repo    = {result.github_repo ?? ""}
          github_sha     = {result.github_sha ?? ""}
          github_tag     = {result.github_tag ?? ""}
        />
      ))}
    </div>
  )
}

const renderTable = (search_change_response) => {
  const table =  search_change_response.rows[0]["change_type"] == 'auto'
                ? <ChangeHistoryAutoTable data={search_change_response.rows}/>
                : <ChangeHistoryManualTable data={search_change_response.rows}/>
  return table
}

const ChangeHistoryTileBody = ({
  we_have_api_results,
  search_change_is_loading,
  search_change_response,
  record_display_style,
  change_type_options,
  error,
  errorMessage
}) => {
  const change_type = change_type_options[_.findIndex(change_type_options, (item) => item.selected === true)]
  //API call timed out (30 seconds)
  if (error && change_type.systemKey == 'auto' && errorMessage.toString().includes('504')) return renderErrorStateTimeout()
  // There was an error
  else if (error) return renderErrorState()
  // We're still loading the data
  if (search_change_is_loading) return <span style={{...styles.spinner}} className="data-cy-loading"><Loading /></span>

  // There are no errors & we're done loading
  if (we_have_api_results) {
    // There is at least 1 record to display
    return record_display_style === 'card' ? renderCard(search_change_response) : renderTable(search_change_response);
  } else {
    // There are no records to display
    return renderNoDataState()
  }
};
export default ChangeHistoryTileBody;
