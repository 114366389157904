export const createUId = (namespace) => `${namespace}_${Math.random().toString(36).substr(2, 9)}`;

export const msToTime = (milliseconds) => {
  let output;
  let hours;
  let minutes;
  let seconds;

  seconds = Math.floor(milliseconds / 1000);
  minutes = Math.floor(seconds / 60);
  seconds %= 60;
  hours = Math.floor(minutes / 60);
  minutes %= 60;
  const days = Math.floor(hours / 24);
  hours %= 24;

  const newHours = (hours < 10) ? `0${hours}` : hours;
  const newMinutes = (minutes < 10) ? `0${minutes}` : minutes;
  const newSeconds = (seconds < 10) ? `0${seconds}` : seconds;

  if (hours <= 0 && days <= 0) {
    output = `${newMinutes}:${newSeconds}`;
  } else if (hours >= 1 && days <= 0) {
    output = `${newHours}:${newMinutes}:${newSeconds}`;
  } else {
    output = `${days}:${newHours}:${newMinutes}:${newSeconds}`;
  }
  return output;
};

export const findFirstObjectInArrayByKeyValue = (key, keyValue, arr) => {
  for (let i = 0; i < arr.length; i++) {
    if (arr[i][key] === keyValue) {
      return arr[i];
    }
  }
  return null;
};
