import React        from 'react';
import ReactTable   from 'react-table-6';
import { useTranslation } from 'react-i18next';
import {HiRocketLaunch} from 'react-icons/hi2';
import {
  dateTimeToUTCString,
  sortDate,
  sortNumber,
} from '../../../utils/formatFunctions'

import UpdateNotificationModal from '../NotificationModals/UpdateNotificationModal';
import DeleteNotificationModal from '../NotificationModals/DeleteNotificationModal';
import AuthorizationWrapper from '../../../components/authorizationWrapper';
const styles = {
  'headers': {
    'textAlign'  :  'left',
    'lineHeight' :  '2rem',
  },
  'img': {
    'height' :  'auto',
    'width'  :  '24px',
  },
  'table': {
    'border' :  'none',
  },
  'tableContainer': {
    'fontSize'        :  '.94rem',
    'boxSizing'       :  'border-box',
    'borderRadius'    :  '4px',
    'backgroundColor' :  'rgb(250, 250, 250)',
    'boxShadow'       :  'rgba(0, 0, 0, 0.2) 0px 0px 10px -2px',
  },
  'tablePadding': {
    'borderLeft'   : '1px solid #ddd',
    'borderRight'  : '1px solid #ddd',
    'borderBottom' : '1px solid #ddd',
  },
  'unset' : {
    'whiteSpace': 'unset'
  },
  'prewrap' : {
    'whiteSpace': 'pre-wrap'
  },
};

const requiredEditAction = 'edit_notifications';
const requiredDeleteAction = 'delete_notifications';

const N_TYPE_SORTING = [
  'Personalized Concur Open', 
  'Maintenance Announcement',
  'Maintenance Update',
  'Maintenance Cancelled',
  'Maintenance Complete'
]


const NotificationTable = ({RowData, ActiveNotificationIDs}) => {
  const { headers, tableContainer, table, unset } = styles;
  const { t } = useTranslation(["translation"]);
  const defaultSort =  [{'id': "n_type", 'desc': false}];
  const columns     =  [
    {
      'Header'      : <b data-cy='id-header'>ID</b>,
      'headerStyle' : headers,
      'id'          : 'id',
      'width'       : 70,
      'accessor'    : 'id',
      sortMethod    : (a, b) => sortNumber(a, b),
    },
    {
      'Header'      : <b data-cy='prioritytype-header'>Type</b>,
      'headerStyle' : headers,
      'id'          : 'n_type',
      'width'       : 200,
      'accessor'    : 'n_type',
      sortMethod    : (a, b) => sortNumber(N_TYPE_SORTING.indexOf(a), N_TYPE_SORTING.indexOf(b)),
      
    },
    {
      'Header'      : <b data-cy='body-header'>Title</b>,
      'headerStyle' : headers,
      'id'          : 'title',
      'width'       : 200,
      'accessor'    : (data) => data.title.en,
    },
    {
      'Header'      : <b data-cy='body-header'>Body</b>,
      'headerStyle' : headers,
      'id'          : 'body',
      'minWidth'    : 350,
      'accessor'    : (data) => data.body.en,
    },
    {
      'Header'      :  <b data-cy='start-date-header'>{t('containers.incidentManagementTable.start')}</b>,
      'headerStyle' :  headers,
      'id'          :  'start_date',
      'width'       :  190,
      'accessor'    :  'start_date',
      Cell          :  data => data.value && <p> {new Date(new Date(data.value).toLocaleString('en', {timeZone: 'America/Los_Angeles'})).toLocaleString()}</p>,
      sortMethod    :  (a, b) => sortDate(a,b)
    },
    {
      'Header'      :  <b data-cy='end-date-header'>{t('containers.incidentManagementTable.end')}</b>,
      'headerStyle' :  headers,
      'id'          :  'end_date',
      'width'       :  190,
      'accessor'    :  'end_date',
      Cell          :  data => data.value && <p>{new Date(new Date(data.value).toLocaleString('en', {timeZone: 'America/Los_Angeles'})).toLocaleString()}</p>,
      sortMethod    :  (a, b) => sortDate(a,b)
    },
    {
      'Header'      : <b>Edit</b>,
      'headerStyle' : headers,
      'id'          : 'updateNotification',
      'width'       : 50,
      'accessor'    : (data) =>  <AuthorizationWrapper key={data.id} requiredAction={requiredEditAction}><UpdateNotificationModal body={data.body.en} comm={data.comm_src} notifId={data.id} n_type={data.n_type} notif_title={data.title.en} start_date={data.start_date} end_date={data.end_date} mwinId={data.mwin_id} is_test={data.is_test} created_by={data.created_by} send_time={data.send_time} broadcast={data.broadcast} given_subject={data.subject.en} mail_queued={data.mail_queued}/></AuthorizationWrapper>,
    },
    {
      'Header'      : <b>Delete</b>,
      'headerStyle' : headers,
      'id'          : 'deleteNotification',
      'width'       : 70,
      'accessor'    : (data) =>  <AuthorizationWrapper requiredAction={requiredDeleteAction}><DeleteNotificationModal  id={data.id}/></AuthorizationWrapper>,
    },
    {
      'Header'      : <b>Mail Sent</b>,
      'headerStyle' : headers,
      'id'          : 'mail_queued',
      'width'       : 190,
      'accessor'    : (data) =>  data.n_type == 'Personalized Concur Open' && ActiveNotificationIDs.includes(data.id) ? <div style={{color: 'green', fontSize: '1.5rem', textAlign: 'center'}}><HiRocketLaunch/> </div> : data.mail_queued ? <p> {new Date(data.mail_queued).toLocaleString()}</p> : <div></div>,
    }
  ]

  return (
    <div style={tableContainer}>
      <ReactTable
        data            =  {RowData}
        defaultSorted   =  {defaultSort}
        columns         =  {columns}
        style           =  {table}
        className       =  "-striped -highlight"
        showPagination  =  {false}
        defaultPageSize =  {RowData.length + 1}
        />
    </div>
  );
}

export default NotificationTable;
