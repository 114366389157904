import React, { Component } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import * as config from '../../../config';
import "./IncidentDetailsGrid.css";
import { Translation } from 'react-i18next';

/* ========= REDUX IMPORTS ========= */
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  incidentProptypes,
  actions as incidentActions,
  selectors as incidentSelectors,
} from '../../../ducks/incident/incident.index';

import { uiProptypes, actions as uiActions } from '../../../ducks/ui/ui.index';
import CardDetails from '../CardDetails/CardDetails';

/* ========= COMPONENT IMPORTS ========= */
// import Timeline from '../TimeLine/TimeLine';
import ProgressBar from "../ProgressBar/ProgressBar";
import LastRefresh from '../../../components/LastRefresh/LastRefresh';
import ResolvedBar from '../ResolvedBar/ResolvedBar';
// import Loading from '../../../components/loading';
import { layouts } from './layouts';
import { jiraDataNameMapping } from '../../../utils/jiraDataNameMapping';
import IncidentManagement from '../IncidentManagement/IncidentManagement';
import Skeleton from 'react-loading-skeleton';
import CustomerCommunications from '../CustomerCommunications/CustomerCommunications';
import CustomerImpact from '../CustomerImpact/CustomerImpact';

/* ========= REDUX STATE CONNECTORS ========= */
const mapStateToProps = ({ incidentState, uiState }, props) => {
  let jiraId;

  try { jiraId = props.location.pathname.split('/')[2]; }
  catch { props.history.push('/not-found'); }

  return {
    jiraId                 : jiraId,
    activeIncidents        : incidentState.activeIncidents,
    messageSortAsc         : incidentState.messageSortAsc,
    selectedIncident       : incidentState.selectedIncident,
    pageLoading            : uiState.pageLoading,
    lastRefresh            : uiState.lastRefresh,
    browserWindowIsVisible : uiState.browserWindowIsVisible,
    isDisconnected         : uiState.isDisconnected,
    displayLocale          : uiState.displayLocale
  };
};

const mapDispatchToProps = (dispatch) => {
  const combinedActionCreators = {
    requestIncidentDetails      : incidentActions.requestIncidentDetails,
    setIncidentDetails          : incidentActions.setIncidentDetails,
    setSelectedIncident         : incidentActions.setSelectedIncident,
    requestIncidentByJiraId     : incidentActions.requestIncidentByJiraId,
    requestAffectedCompanies    : incidentActions.requestAffectedCompanies,
    setMessageSortAsc           : incidentActions.setMessageSortAsc,
    setPageLoading              : uiActions.setPageLoading,
    setPageFocus                : uiActions.setPageFocus,
    setIsDisconnected           : uiActions.setIsDisconnected,
  };

  return bindActionCreators(combinedActionCreators, dispatch);
};

const ResponsiveGridLayout = WidthProvider(Responsive);

const styles = {
  internalOnly    : { fontSize: "1rem", paddingLeft: "1rem", color: 'red' },
  ascButton       : { padding: 0 },
  ascButtonActive : { textDecoration: 'underline', fontWeight: 'bold' },
  container       : { width: '90%', margin: '0 auto' }
}


class IncidentDetailGrid extends Component {
  jiraId = this.props.match.params.id;

  static propTypes = {
    activeIncidents             : incidentProptypes.activeIncidents,
    requestIncidentDetails      : incidentProptypes.requestIncidentDetails,
    requestAffectedCompanies    : incidentProptypes.requestAffectedCompanies,
    setIncidentDetails          : incidentProptypes.setIncidentDetails,
    setSelectedIncident         : incidentProptypes.setSelectedIncident,
    setMessageSortAsc           : incidentProptypes.setMessageSortAsc,
    requestStatus               : incidentProptypes.requestStatus,
    lastRefresh                 : uiProptypes.lastRefresh,
    pageLoading                 : uiProptypes.pageLoading,
    setPageLoading              : uiProptypes.setPageLoading,
    setPageFocus                : uiProptypes.setPageFocus,
    browserWindowIsVisible      : uiProptypes.browserWindowIsVisible,
    setIsDisconnected           : uiProptypes.setIsDisconnected,
    displayLocale               : uiProptypes.displayLocale,
  };

  state = {
    percent : 0,
    time    : 0,
    user    : {},
    errors  : {},
  }

  componentDidMount = async () => {
    const {setPageLoading, location, selectedIncident} = this.props;
    const jiraId       =  location.pathname.split('/')[2];
    this.props.setSelectedIncident({});
    this.props.requestIncidentByJiraId(jiraId);
    this.props.requestIncidentDetails(jiraId);
    this.props.requestAffectedCompanies(jiraId);
    const incidentDataInStore = selectedIncident.jiraId;
    incidentDataInStore ? setPageLoading(false) : this.intervalTask();
    this.interval = setInterval(this.intervalTask, 30000);
  }

  componentWillUnmount = () => {
    clearInterval(this.interval);
  }

  componentWillReceiveProps(nextProps){
    /*
      If a user minimizes the window (browserWindowIsVisible == false)
      this will clear the interval and no API requests will be triggered.
      If a user brings the browser window back up, it will trigger the api calls
      and restart the interval.
    */
    if(this.props.browserWindowIsVisible !== nextProps.browserWindowIsVisible){
      if(nextProps.browserWindowIsVisible === true){
        this.interval = setInterval(this.intervalTask, 30000);
      } else {
        clearInterval(this.interval);
      }
    }
  }

  intervalTask = async () => {
    if (this.props.isDisconnected === false) {
      const { jiraId } = this.props;
      this.props.requestIncidentByJiraId(jiraId);
      this.props.requestIncidentDetails(jiraId);
    } else {
      clearInterval(this.interval);
    }
  }

  // This is how we can control the order
  mapToViewModel = (incident) => ({
    jiraId              : incident.jiraId,
    affectedServices    : incident.affectedServices,
    affectedDataCenters : incident.affectedDataCenters,
    affectedCompanies   : incident.affectedCompanies,
    affectedUsers       : incident.affectedUsers,
    details             : incident.details,
    startDate           : incident.startDate,
    slaEndTime          : incident.slaEndTime,
    endDate             : incident.endDate,
  });

  doSubmit = async () => {
    this.setState({ loading: true });
    // await saveOpi(this.state.data);
    this.props.history.push("/opis");
  };


  render = () => {
    const jiraUrl = config.REACT_APP_JIRA_URL;
    const { lastRefresh, messageSortAsc, displayLocale, selectedIncident } = this.props;
    // const newLayouts = user;
    const formattedJiraData      =  incidentSelectors.selectIncidentDetailsForJiraComponent(selectedIncident);
    const incidentManagementData =  incidentSelectors.selectIncidentManagementData(selectedIncident.jira_info, selectedIncident.slack_info);
    const { jiraId, status, startDate, endDate, slaEndTime, messages, isInternal } = selectedIncident;
    let internal = isInternal == 'Y';
    return (
      <Translation>{(t) =>
        <div style={styles.container}>
          <div className="row">
            <div className="col-xl col-lg-12 col-md-12 col-sm-12">
              <p className="opi-title"><a href={`${jiraUrl}/browse/${jiraId}`} target="_blank" rel="noopener noreferrer" >{jiraId + (internal ? t('containers.landingOpiCard.internalHeader') : '') || <Skeleton height={30} width={200}/>}</a>
                <span style={styles.internalOnly}>{t('containers.detailsIncidentDetailsGrid.internal')}</span>
              </p>
            </div>
            <div className="col-xl col-lg-12 col-md-12 col-sm-12">
              <div className="lr-child">
                <LastRefresh data={lastRefresh} />
              </div>
            </div>
          </div>
          <ResponsiveGridLayout
            className="layout"
            layouts={layouts}
            breakpoints={{ lg: 1200, md: 996, sm: 814, xs: 480, xxs: 0 }}
            cols={{ lg: 12, md: 4, sm: 2, xs: 2, xxs: 2 }}
            margin={[8, 8]}
            isDraggable={false}
            isResizable={false}
          >
            <div key="progress-bar" className="progress-bar-card  progress-bar-card">
              {status === "ACTIVE" || status === "INVESTIGATION" || status == null
                ? <ProgressBar name={"SLA Count Down"} start={startDate} slaEndTime={slaEndTime} />
                : <ResolvedBar start={startDate} end={endDate} />
              }
            </div>
            <div key="customer-communications" className="time-line-card card time-line-card" >
              <div className="card-header row d-flex justify-content-between">
                <div className="8">{t('containers.detailsIncidentDetailsGrid.customerComms')}</div>
                <button
                  type="button"
                  className="btn btn-light asc-btn"
                  onClick={this.props.setMessageSortAsc}
                  style={styles.ascButton}>
                    <span className="ascend" style={(this.props.messageSortAsc ? styles.ascButtonActive : null)}>{t('containers.detailsIncidentDetailsGrid.asc')}</span>/<span className="descend" style={(this.props.messageSortAsc ? null : styles.ascButtonActive)}>{t('containers.detailsIncidentDetailsGrid.desc')}</span>
                </button>
              </div>
              <div className="customer-communications card-body"  style={{'padding-left': '0px', 'padding-right': '0px', 'padding-top': '0px'}}>
                {
                  messages
                  ? <CustomerCommunications messages={messages} sortAsc={messageSortAsc} key={'test'} displayLocale={displayLocale}/>
                  : <>
                      <Skeleton count={1} width={160} height={26}/>
                      <Skeleton count={2} height={26}/>
                    </>
                }
              </div>
            </div>
            {/* <div key="slack" className="grey lighten-4 card slack-card">
              <div className="box inner card-header">
                Slack
              </div>
              <div className="card-body coming-soon">
                { <Slack data={slackData} /> }
              </div>
            </div> */}
            <div key="incident-details" className="card incident-details-card">
              <div className="jirdata  inner card-header">
              {t('containers.detailsIncidentDetailsGrid.incidentDetails')}
              </div>
              <div className="card-body pt-1">
                {Object.keys(this.mapToViewModel(selectedIncident)).map((property) => (
                  <CardDetails key={property} property={t('containers.detailsIncidentDetailsGrid.' + property)} data={formattedJiraData[property]} />
                ))}
              </div>
            </div>
            {/* INCIDENT TEAM */}
            <div key="incident-team" className="grey lighten-4 card incident-team-card">
              <div className="box inner card-header">{t('containers.detailsIncidentDetailsGrid.team')}</div>
              <IncidentManagement info={incidentManagementData} />
            </div>
            <div key="customer-impact" className="card customer-impact-card">
              <div className="other inner card-header">
              {t('containers.detailsIncidentDetailsGrid.impact')}
              </div>
                <CustomerImpact affected_companies={selectedIncident.affected_companies} key="customer-impact"/>
            </div>
          </ResponsiveGridLayout>
        </div>
      }</Translation>
    );
  }
}
// export default IncidentDetailGrid;
export default connect(mapStateToProps, mapDispatchToProps)(IncidentDetailGrid);
