import React                 from 'react';
import { Form, InputGroup, } from 'react-bootstrap'
import MagnifyingGlass       from '../../../assets/281043_Search_R_orange.png';
import {AsyncTypeahead }     from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { useTranslation } from 'react-i18next';

const styles = {
  img: {
    maxHeight : '1.5rem'
  },
  menuItemChild : {
    fontSize : '1rem'
  },
  formGroup: {
    width: "calc(100% - 168px)"
  }
}


const SearchBar = ({ error, customerCatalog, requestCompanyIncidentsById, searchCustomerCatalog, customerCatalogLoading }) => {
  const { t } = useTranslation(["translation"]);
  return (
      <Form.Group className="mb-0" style={styles.formGroup}>
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text>
              <img src={MagnifyingGlass} style={styles.img} />
            </InputGroup.Text>
          </InputGroup.Prepend>
          {
            error
            // IF there is an error, show a READONLY input field with the placeholder text below
            ? <Form.Control className="customer-search-error-input" type="text" placeholder={t('containers.customerSearchBar.error')} readOnly />
            // ELSE, display the cool async type ahead component
            : <AsyncTypeahead
                id          = "async-customer-search"
                className   = "cy-async-customer-search"
                // shows the loading spinner while API requests from user inputs are resolving
                isLoading   = {customerCatalogLoading}
                // labelKey will look like "Bayer | Entity Code: p00036763jya | Id: 225" when filled in
                labelKey    = {option => `${option.name} | ${t('containers.customerSearchBar.entity')}${option.entity_code} | ${t('containers.customerSearchBar.outtask')}${option.id}`}
                // AsyncTypeahead has debouncing built in, but we also want a minimum character length before firing off any API request.
                // We don't want to search scryer for all companies that mention "a" in their name, entity code, and/or id on the first key stroke from a user.
                minLength   = {1}
                onSearch    = {searchCustomerCatalog}
                options     = {customerCatalog}
                placeholder = {t('containers.customerSearchBar.search')}
                // This forces pagination
                maxResults  = {10}
                onChange    = {(company) => {
                  // company = [{"entity_code": "p00036763jya", "id": 225,"name": "Bayer"},]
                  // company will be an empty array as a user starts to delete their search
                  // so if the array is empty, do not fire off an API request
                  if (company.length === 0) return true

                  requestCompanyIncidentsById(company)
                }}
                renderMenuItemChildren={(option) => (
                  <>
                    <span><b>{option.name}</b></span>
                    <br/>
                    <span style={styles.menuItemChild}>{t('containers.customerSearchBar.entity')}<b>{option.entity_code}</b> | {t('containers.customerSearchBar.outtask')}<b>{option.id}</b></span>
                  </>
              )}
            />
            }
        </InputGroup>
      </Form.Group>
  );
}

export default SearchBar
