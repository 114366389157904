import React from 'react';
import { Link } from "react-router-dom";
import "./NoOpiCard.css";

export default function NoOpiCard() {
  return (
    <div className="card text-center card-shadow">
      <div className="card-header">
        WooHoo!
      </div>
      <div className="card-body">
        <h5 className="card-title">No Open Incidents</h5>
        <Link to={"/history"} className="btn no-opi-history-btn">Check Out the Incident History</Link>
      </div>
    </div> 
  );
}
