import http from "../../services/httpService";
import * as config from "../../config";


export const searchCustomerCatalog = async (searchText) => {
  // we are passing in the whole user input into this function
  // this looks like 'concur130 | Entity ID: d0026585ce2e | Id: 225643' when autocomplete is finished
  // if a user starts to backspace on an autocompleted entry, we do not want to search for that whole string,
  // it will return nothing. therefor we should only match on everything up to "|" so "concur130" in this case
  const regex = /[^|]*/g;
  const [searchTextMatch] = searchText.match(regex)
  const result = await http.get(`/customer_catalog/?search_text=${searchTextMatch}`);

  return result;
};

export const getIncidentsByCompanyId = async (companyId, locale, start_at, response_size) => {
    const response = await http.get(`/incidents_by_company/?company_id=${companyId}&locale=${locale}&start_at=${start_at}&response_size=${response_size}`);
    return response;
};
