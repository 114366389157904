/* ========= PACKAGE IMPORTS ========= */
import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import { Row, Card, ListGroup, ListGroupItem, Button } from 'react-bootstrap';
import { Translation } from 'react-i18next';
import {
  dateTimeToPSTString,
} from '../../../utils/formatFunctions'
/* ========= REDUX IMPORTS ========= */
import { actions as uiActions }    from '../../../ducks/ui/ui.index';
import {
  actions as maintenanceEventActions,
  selectors as maintenanceEventSelectors,
} from '../../../ducks/maintenanceEvent/maintenanceEvent.index';
import UpdateMaintenanceEventModal from '../MaintenanceEventModals/UpdateMaintenanceEventModal';
import DeleteMaintenanceEventModal from '../MaintenanceEventModals/DeleteMaintenanceEventModal';
/* ========= COMPONENT IMPORTS ========= */
import Loading            from '../../../components/loading';
import AuthorizationWrapper from '../../../components/authorizationWrapper';
import { string } from 'prop-types';

/* ========= REDUX STATE CONNECTORS ========= */
const mapStateToProps = ({uiState, maintenanceEventState}, props) => {
  let maintenanceEventId;
  maintenanceEventId = props.match.params['id'];
  return {
    maintenanceEventId          :  maintenanceEventId,
    pageLoading                 :  uiState.pageLoading,
    maintenance_events          :  maintenanceEventState.maintenance_events,
    maintenance_event_details   :  maintenanceEventSelectors.selectMaintenanceEventDetails(maintenanceEventState, maintenanceEventId),
    all_data_centers            :  maintenanceEventState.all_data_centers,
    maintenance_event_state     :  maintenanceEventState
  };
};
const editAction = 'edit_maintenance_window';
const deleteAction = 'delete_maintenance_window';

const mapDispatchToProps = (dispatch) => {
  const combinedActionCreators = {
    setPageLoading              : uiActions.setPageLoading,
    requestMaintenanceEvents    : maintenanceEventActions.requestMaintenanceEvents,
    setMaintenanceEventDetails  : maintenanceEventActions.setMaintenanceEventDetails,
    requestAllDataCenters       : maintenanceEventActions.requestAllDataCenters,
    submitDeleteMaintenanceEvent: maintenanceEventActions.submitDeleteMaintenanceEvent,
  };
  return bindActionCreators(combinedActionCreators, dispatch);
};

const styles = {
  'topContainer': {
    'margin'   :  'auto',
    'maxWidth' :  'max-content',
  },
  'pageTitle': {
    'marginBottom' :  '20px',
    'width'        :  '73vw',
    'textAlign'   :  'center',
    'margin'      : '0 auto'
  },
  'button': {
    'float'     : 'none',
    'margin'    : '0 auto',
    'marginLeft': '0 auto',
    'marginTop' : '20px',
  },
  'half': {
    'width': '50vw'
  },
  'card': {
    'width' : '95vw', 
    'margin': '1vh auto'
  }
};

class ReviewMaintenanceEvent extends Component {

  componentDidMount = () => {
    this.props.setMaintenanceEventDetails(this.props.maintenance_event_details);  
  }

  render = () => {
    const { topContainer, pageTitle } = styles;
    const { pageLoading, maintenance_events, maintenance_event_details, maintenanceEventId, submitDeleteMaintenanceEvent } = this.props; 

    const handleCsv = (arr) => {
      if (!arr){
        return [];
      }
      arr = arr.split(',').map(function (value){ return value.trim(); });
      //make sure if the last character is a , that there's no empty array indexes
      let filteredArray = arr.filter(function (item) {
        return item != null && item != '';
      })
      return filteredArray;
    }

    let string_dcs = ''
    let split_jira_id = maintenance_event_details ? handleCsv(maintenance_event_details.jira_id) : [];
    if (maintenance_event_details && maintenance_event_details.data_centers){
      maintenance_event_details.data_centers.forEach((dc) => {
        string_dcs += dc.name + ', ';
      })
      string_dcs = string_dcs.slice(0, -2);
    }
    
    return (
      <div style={topContainer}>
        {/* HEADER */}
        <Row>
          <h1 style={pageTitle}>Maintenance Event Details</h1>
          <h6 style={{margin: 'auto'}}>All Times In PST</h6>
        </Row>
        { /* CONTENT */
        
        pageLoading
        ? <Loading height='75vh' />
        : <> 
            <Row>
              {maintenance_event_details && <Card style={styles.card}>
                <Card.Header as="h5">{maintenance_event_details.title}
                <div style={{float: 'right', display: 'flex'}}>
                  <AuthorizationWrapper requiredAction={editAction}>
                    <div><UpdateMaintenanceEventModal maintenance_event_details={this.props.maintenance_event_details}></UpdateMaintenanceEventModal></div>
                  </AuthorizationWrapper>
                  <AuthorizationWrapper requiredAction={deleteAction}>
                  <div><DeleteMaintenanceEventModal submitDeleteMaintenanceEvent={submitDeleteMaintenanceEvent} maintenance_event_details={this.props.maintenance_event_details}></DeleteMaintenanceEventModal></div>
                  </AuthorizationWrapper>
                </div>
                </Card.Header>
                <Card.Body>
                  <ListGroup horizontal>
                      <ListGroup.Item style={styles.half}> <b>ID: </b>{maintenance_event_details.id}</ListGroup.Item>
                      <ListGroup.Item style={styles.half}> <b>Audience: </b>{maintenance_event_details.is_internal == 'N' ? 'Public' : 'Internal'}</ListGroup.Item>
                      <ListGroup.Item style={styles.half}> <b>Flag: </b>{maintenance_event_details.flag == 'special' ? 'Special' : maintenance_event_details.flag == 'emergency' ? 'Emergency' : ''}</ListGroup.Item>
                  </ListGroup>
                  <ListGroup horizontal>
                    <ListGroup.Item style={styles.half}> <b>Start Date: </b>{dateTimeToPSTString(maintenance_event_details.start_date)}</ListGroup.Item>
                    <ListGroup.Item style={styles.half}> <b>End Date: </b>{dateTimeToPSTString(maintenance_event_details.end_date)}</ListGroup.Item>
                  </ListGroup>
                  <ListGroup horizontal>      
                    <ListGroup.Item style={styles.half}> <b>Created By: </b>{maintenance_event_details.created_by}</ListGroup.Item>
                    <ListGroup.Item style={styles.half}> <b>Created Date: </b>{dateTimeToPSTString(maintenance_event_details.created_date)}</ListGroup.Item>
                  </ListGroup>
                  <ListGroup horizontal>
                    <ListGroup.Item style={{width: '100%'}} ><b>Data Centers: </b>{string_dcs}</ListGroup.Item>
                   
                  </ListGroup>
                  <ListGroup horizontal style={{width: '100%'}}>
                  <ListGroup.Item><b>Jira ID: </b></ListGroup.Item>
                   {split_jira_id.map((item) => {
                      if (item){
                        return (
                        <ListGroup.Item key={item} style={{width: 100 /split_jira_id.length + '%'}}>{item}</ListGroup.Item>
                        )
                      }
                    })}
                  </ListGroup>
                </Card.Body>
              </Card>
            }
            </Row>
            <Row> 
              {maintenance_event_details && <Card style={styles.card}>
                <Card.Header as="h5">Concur Open Notification</Card.Header> 
                <Card.Body>
                  <Card.Text><b>ID: </b>{maintenance_event_details.notification_id}</Card.Text>
                  <Card.Text><b>Header: </b>{maintenance_event_details.title}</Card.Text>
                  <Card.Text><b>Body: </b>{maintenance_event_details.notification_body}</Card.Text>
                </Card.Body>
              </Card>
              }

              
            </Row>   
          </>        
        }
      </div>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReviewMaintenanceEvent);