import { string, number, arrayOf, shape, bool, object } from 'prop-types';


export const priorityTypes = {
  'p1': 'P1',
  'p2': 'P2',
};

export const editablePriortyTypes = ['P2'];

/* ===== STATE_DEFINITION ===== */
const initialState = {
  maintenance_events          : [],
  active_maintenance_events   : [],
  active_notifications        : [],
  active_notification_ids     : [],
  maintenance_event_details   : object,
  loading                     : true,
  error                       : false,
  all_data_centers            : [],
};

const maintenanceEventShape = {
  id: number,
  title: string,
  is_internal: bool,
  flag: string,
  jira_id: string,
  notifications: arrayOf(notificationPrototype),
  start_date: string,
  start_epoch: number,
  end_date: string,
  end_epoch: number,
  comm_src: string,
  created_by: string,
  created_date: string,
  created_epoch: number,
  is_test: bool,
  data_centers: arrayOf(string)
};

export const notificationPrototype = {
  body : object,
  comm_src : string, 
  created_by : string,
  created_date : string,
  created_epoch : number,
  data_centers : arrayOf(string),
  end_date : string,
  end_epoch : number,
  id : number,
  is_test : string,
  mwin_id : number,
  n_type : string,  
  start_date : string,
  send_time : string,
  start_epoch : number,
  sent_time: string,
  title : object,
}

export const maintenanceWindowProptypes = {
  maintenance_events          : arrayOf(shape(maintenanceEventShape)),
  active_maintenance_events   : arrayOf(shape(maintenanceEventShape)),
  loading                     : bool,
  error                       : bool,
};


/* ===== TYPES ===== */
export const types = {
  V2_REQUEST_MAINTENANCE_EVENTS       : '[V2MAINTENANCE_EVENTS] REQUEST_MAINTENANCE_EVENTS',
  V2_REQUEST_ACTIVE_NOTIFICATIONS     : '[V2MAINTENANCE_EVENTS] REQUEST_ACTIVE_NOTIFICATIONS',
  V2_SET_ACTIVE_NOTIFICATIONS         : '[V2MAINTENANCE_EVENTS] SET_ACTIVE_NOTIFICATIONS',
  V2_SET_ACTIVE_NOTIFICATION_IDS      : '[V2MAINTENANCE_EVENTS] V2_SET_ACTIVE_NOTIFICATION_IDS',
  V2_SUBMIT_CREATE_MAINTENANCE_EVENT  : '[V2MAINTENANCE_EVENTS] SUBMIT_CREATE_MAINTENANCE_EVENT',
  V2_SUBMIT_UPDATE_MAINTENANCE_EVENT  : '[V2MAINTENANCE_EVENTS] SUBMIT_UPDATE_MAINTENANCE_EVENT',
  V2_SET_MAINTENANCE_EVENTS           : '[V2MAINTENANCE_EVENTS] SET_MAINTENANCE_EVENTS',
  V2_SET_ACTIVE_MAINTENANCE_EVENTS    : '[V2MAINTENANCE_EVENTS] SET_ACTIVE_MAINTENANCE_EVENTS',
  V2_SET_MAINTENANCE_EVENT_DETAILS    : '[V2MAINTENANCE_EVENTS] SET_MAINTENANCE_EVENT_DETAILS',
  V2_SET_MAINTENANCE_EVENT_LOADING    : '[V2MAINTENANCE_EVENTS] SET_MAINTENANCE_EVENT_LOADING',
  V2_SET_MAINTENANCE_EVENT_ERROR      : '[V2MAINTENANCE_EVENTS] SET_MAINTENANCE_EVENT_ERROR',
  V2_SUBMIT_DELETE_MAINTENANCE_EVENT  : '[V2MAINTENANCE_EVENTS] SUBMIT_DELETE_MAINTENANCE_EVENT',
  V2_REQUEST_ALL_DATA_CENTERS         : '[V2MAINTENANCE_EVENTS] REQUEST_ALL_DATA_CENTERS',
  V2_SET_ALL_DATA_CENTERS             : '[V2MAINTENANCE_EVENTS] SET_ALL_DATA_CENTERS',
  V2_SUBMIT_CREATE_NOTIFICATION       : '[V2MAINTENANCE_EVENTS] SUBMIT_CREATE_NOTIFICATION',
  V2_SUBMIT_UPDATE_NOTIFICATION       : '[V2MAINTENANCE_EVENTS] SUBMIT_UPDATE_NOTIFICATION',
  V2_SUBMIT_DELETE_NOTIFICATION       : '[V2MAINTENANCE_EVENTS] SUBMIT_DELETE_NOTIFICATION',
  V2_SUBMIT_SEND_MAIL                 : '[V2MAINTENANCE_EVENTS] SUBMIT_SEND_MAIL',
};


/* ===== ACTION_CREATORS ===== */
export const actions = {
  V2RequestMaintenanceEvents     : ()                      => ({type: types.V2_REQUEST_MAINTENANCE_EVENTS}),
  V2RequestActiveNotifications   : ()                      => ({type: types.V2_REQUEST_ACTIVE_NOTIFICATIONS}),
  V2SetActiveNotifications       : (notifications)         => ({type: types.V2_SET_ACTIVE_NOTIFICATIONS, payload: notifications}),
  V2SetActiveNotificationIds     : (ids)                   => ({type: types.V2_SET_ACTIVE_NOTIFICATION_IDS, payload: ids}),
  V2SubmitCreateMaintenanceEvent : (maintenance_events)    => ({type: types.V2_SUBMIT_CREATE_MAINTENANCE_EVENT, payload: maintenance_events}),
  V2SubmitUpdateMaintenanceEvent : (maintenance_events)    => ({type: types.V2_SUBMIT_UPDATE_MAINTENANCE_EVENT, payload: maintenance_events}),
  V2SubmitDeleteMaintenanceEvent : (id)                    => ({type: types.V2_SUBMIT_DELETE_MAINTENANCE_EVENT, payload: id}),
  V2SetMaintenanceEvents         : (maintenance_events)    => ({type: types.V2_SET_MAINTENANCE_EVENTS, payload: maintenance_events}),
  V2SetActiveMaintenanceEvents   : (maintenance_events)    => ({type: types.V2_SET_ACTIVE_MAINTENANCE_EVENTS, payload: maintenance_events}),
  V2SetMaintenanceEventDetails   : (maintenance_event)     => ({type: types.V2_SET_MAINTENANCE_EVENT_DETAILS, payload: maintenance_event}),
  V2SetMaintenanceEventLoading   : (bool)                  => ({type: types.V2_SET_MAINTENANCE_EVENT_LOADING, payload: bool}),
  V2SetMaintenanceEventError     : (bool)                  => ({type: types.V2_SET_MAINTENANCE_EVENT_ERROR, payload: bool}),
  V2RequestAllDataCenters        : ()                      => ({type: types.V2_REQUEST_ALL_DATA_CENTERS}),
  V2SetAllDataCenters            : (data_centers)          => ({type: types.V2_SET_ALL_DATA_CENTERS, payload: data_centers}),
  V2SubmitCreateNotification     : (notification)          => ({type: types.V2_SUBMIT_CREATE_NOTIFICATION, payload: notification}),
  V2SubmitUpdateNotification     : (notification)          => ({type: types.V2_SUBMIT_UPDATE_NOTIFICATION, payload: notification}),
  V2SubmitDeleteNotification     : (id)                    => ({type: types.V2_SUBMIT_DELETE_NOTIFICATION, payload: id}),
  V2SubmitSendMail               : (id)                    => ({type: types.V2_SUBMIT_SEND_MAIL, payload: id}),
};


/* ===== SELECTORS ===== */
export const selectors = {
  selectMaintenanceEventDetails   :  (state, id) => {
    return state.maintenance_events.find( window => window.id == id);
  },
  selectCurrentMaintenanceEvent: (maintenance_events) => {
    const currentDate = new Date();
    return maintenance_events.map((item) => item.end_date > currentDate)
  },
  selectHistoricMaintenanceEvents: (state) => {
    return state.maintenance_events;
  },
};


/* ===== REDUCER ===== */
export default function reducer(state=initialState, action) {
  switch (action.type) {

    case types.V2_SET_MAINTENANCE_EVENTS: {
      return { ...state, maintenance_events : action.payload.maintenance_windows };
    }

    case types.V2_SET_ACTIVE_NOTIFICATIONS: {
      return { ...state, active_notifications : action.payload.active_notifications };
    }

    case types.V2_SET_ACTIVE_NOTIFICATION_IDS: {
      return { ...state, active_notification_ids : action.payload };
    }

    case types.V2_SET_ACTIVE_MAINTENANCE_EVENTS: {
      return { ...state, active_maintenance_events : action.payload };
    }

    case types.V2_SET_MAINTENANCE_EVENT_DETAILS: {
      return { ...state, maintenance_event_details : action.payload };
    }

    case types.V2_SET_MAINTENANCE_EVENT_LOADING: {
      return { ...state, loading : action.payload };
    }

    case types.V2_SET_MAINTENANCE_EVENT_ERROR: {
      return { ...state, error : action.payload };
    }

    case types.V2_SET_ALL_DATA_CENTERS: {
      return { ...state, all_data_centers : action.payload };
    }

    default: { return state; }
  }
}
