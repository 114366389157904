import React, { useState } from 'react';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  actions as uiActions,
} from '../../ducks/ui/ui.index';
import {
  actions as templateActions
} from '../../ducks/template/template.index';
import { Modal, Button, Form, Table, Row } from 'react-bootstrap';
import "react-datepicker/dist/react-datepicker.css";
import {Formik, Field} from 'formik';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
/* ========= PACKAGE IMPORTS ========= */
const schema = yup.object().shape({
  message_type: yup.string().required(),
  body: yup.string().required(),
  title: yup.string().required(),
  affected_service: yup.string().required(),
  //not displayed in form
  template_id: yup.string().notRequired(),
  updated_by: yup.string().notRequired(),
});

const mapStateToProps = ({uiState, userState, incidentState, templateState}) => ({
  pageLoading         :  uiState.pageLoading,
  activeLocales       :  uiState.activeLocales,
  email               :  userState.user.email,
  p2AlertTypes        :  incidentState.p2AlertTypes,
  p1AlertTypes        :  incidentState.p1AlertTypes, 
  changeHistory       :  templateState.changeHistory,
});
const mapDispatchToProps =  (dispatch) => {
  const combinedActionCreators = {
    setPageLoading           :  uiActions.setPageLoading,
    submitUpdateTemplate      :  templateActions.submitUpdateTemplate,
    requestTemplateChangeHistory : templateActions.requestTemplateChangeHistory,
  };

  return bindActionCreators(combinedActionCreators, dispatch);
};

const SERVICE_LIST = ['General', 'Analysis / Intelligence', 'Expense', 'FTP', 'Imaging', 'Invoice', 'Outtask', 'Travel', 'Travel (GDS Specific)', 'ExpenseIt', 'TripIt', 'Locate'];

const UpdateTemplateModal = ({pkey, messageType, body, title, affectedService, template_type, notificationType, submitUpdateTemplate, requestTemplateChangeHistory, email, p1AlertTypes, setPageLoading, changeHistory}) => {
  const { t } = useTranslation(["translation"]);
  let styles = {
    clickableLink  :  {
      cursor    :  'pointer',
      color     :  'blue',
    },
    error  :  {
      color     :  'red',
    },
    button        : {
      width       :  '8rem',
    },
    buttonRight   : {
      width       : '8rem',
      marginLeft  : '5px'
    },
    buttonRightDisabled: {
      width       : '8rem',
      marginLeft  : '5px',
      cursor      : 'default'
    },
    buttonLeft   : {
      width       : '12rem',
      marginRight : '20px'
    },
    buttonLeft2   : {
      width       : '12rem',
      marginRight : '320px'
    },
    buttonError  :  {
      color     :  'red',
      marginRight:  '5px',
    },
    infoImg: {
      maxHeight : '1.9rem',
    },
    inlineMiddleVAlign: {
      display : 'inline',
      verticalAlign : 'middle'
    },
  }
  let errorMessage = ''
  const [modalIsOpen,setIsOpen] = useState(false);
  const [changeHistoryOpen, setChangeHistoryOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  }
  const closeModal = () => {
    setIsOpen(false);
    setChangeHistoryOpen(false);
  }

  const displayButton = (values) => {
    if (template_type == 'message'){
      if (values.title == title && values.body == body && values.affected_service == affectedService && values.message_type == messageType){
        errorMessage = 'Must Modify A Value';
        return false;
      }
      if (!values.title || !values.body || !values.affected_service){
        errorMessage = 'Value Missing';
        return false;
      }
    }
    else if (template_type == 'notification'){
      if (values.title == title && values.body == body && values.notification_type == notificationType){
        errorMessage = 'Must Modify A Value';
        return false;
      }
      if (!values.title || !values.body){
        errorMessage = 'Value Missing';
        return false;
      }
    }
    errorMessage = '';
    return true;
  }

  const submitForm = (fields) => {
    setPageLoading(true);
    submitUpdateTemplate(fields);
    closeModal();
    return true;
  }

  const handleChange = (e, setValues, values) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    validate(values, name, value);
    
  };

  const validate = (values, name, value) => {
    let valid = true;
    // pattern is always - the changed value and existing value, then checking if the rest of the values are the same
    if (template_type == 'message'){
      if (name == 'title' && value == title && values.body == body && values.affected_service == affectedService && values.message_type == messageType){
        errorMessage = 'Must Modify A Value';
        valid = false;
      }
      if (name == 'body' && value == body && values.title == title && values.affected_service == affectedService && values.message_type == messageType){
        errorMessage = 'Must Modify A Value';
        valid = false;
      }
      if (name == 'affected_service' && value == affectedService && values.title == title && values.body == body && values.message_type == messageType){
        errorMessage = 'Must Modify A Value';
        valid = false;
      }
      if (name == 'message_type' && value == messageType && values.title == title && values.body == body && values.affected_service == affectedService){
        errorMessage = 'Must Modify A Value';
        valid = false;
      }
      if (!values.title || !values.body || !values.affected_service){
        errorMessage = 'Value Missing';
        valid = false;
      }
    }
    else if (template_type == 'notification'){
      if (name == 'title' && value == title && values.body == body && values.notification_type == notificationType){
        errorMessage = 'Must Modify A Value';
        valid = false;
      }
      if (name == 'body' && value == body && values.title == title && values.notification_type == notificationType){
        errorMessage = 'Must Modify A Value';
        valid = false;
      }
      if (name == 'notification_type' && value == notificationType && values.title == title && values.body == body){
        errorMessage = 'Must Modify A Value';
        valid = false;
      }
      if (!values.title || !values.body || !values.notification_type){
        errorMessage = 'Value Missing';
        valid = false;
      }
    }

    if (valid){
      errorMessage = '';
    }
    
  };

  const changeChangeHistory = () => {
    if (!changeHistoryOpen){
      requestTemplateChangeHistory(pkey);
      const elem = document.getElementById('template_modal')
      elem.style.maxWidth = '800px';
      setChangeHistoryOpen(true);
    }
    else{
      const elem = document.getElementById('template_modal')
      elem.style.maxWidth = '500px';
      setChangeHistoryOpen(false);
    }
  }

  return (
    <div>
        <div style={styles.clickableLink} onClick={openModal} className={"data-cy-update-template-button-"}>
          {t('containers.incidentManagementUpdateMessage.buttonUpdate')}
        </div>
      <Modal id="template_modal" show={modalIsOpen} onHide={closeModal} className="data-cy-update-template-modal">
        <Formik   
            validationSchema={schema}
            onSubmit={values => submitForm(values)}
            initialValues={{
              message_type: messageType,
              notification_type: notificationType,
              body: body,
              title: title,
              template_id: pkey,
              template_type: template_type,
              affected_service: affectedService,
              updated_by: email,
             
            }}
        >
        {({
          handleSubmit,
          handleBlur,
          values,
          errors,
          touched,
          setValues,
        }) => (
          <div>
            <Form noValidate onSubmit={() => submitForm(values)}>
              <Modal.Header closeButton>
                <Modal.Title>Update Template</Modal.Title>
              </Modal.Header>
             <Modal.Body>
                {template_type == 'message' && <Form.Group>
                  <Form.Label>Message Type</Form.Label>
                  <Form.Control as="select" className="data-cy-update-template-modal-type" name="message_type" onChange={e => handleChange(e, setValues, values)} onBlur={handleBlur} value={values.message_type}>
                    { p1AlertTypes.map((type, i) => (
                      <option key={i} className={"data-cy-update-template-modal-" + i}>{type}</option>
                    ))}
                  </Form.Control>
                </Form.Group>}
                {template_type == 'message' && <Form.Group>
                  <Form.Label>Affected Service</Form.Label>
                  <Form.Control as="select" className="data-cy-update-template-modal-type" name="affected_service" onChange={e => handleChange(e, setValues, values)} onBlur={handleBlur} value={values.affected_service}>
                    { SERVICE_LIST.map((service, i) => (
                      <option key={i} value={service} className={"data-cy-update-template-modal-service-" + i}>{service}</option>
                    ))}
                  </Form.Control>
                </Form.Group>}
                {template_type == 'notification' && <Form.Group style={{display: 'flex'}}>      
                  <div style={{marginRight: '3vw'}}>
                    <Form.Label>Notification Type</Form.Label>     
                    <Form.Control as="select" name="notification_type" value={values.notification_type} onChange={e => handleChange(e, setValues, values)}>
                      <option value="Maintenance Announcement">Maintenance Announcement</option>
                      <option value="Maintenance Update">Maintenance Update</option>
                      <option value="Maintenance Complete">Maintenance Complete</option>
                      <option value="Maintenance Cancelled">Maintenance Cancelled</option>
                      <option value="Personalized Concur Open">Personalized Concur Open</option>
                    </Form.Control>
                  
                  </div>
                </Form.Group>}

                <Form.Group>
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                  type="textarea"
                  as="textarea"
                  className="data-cy-update-template-modal-body"
                  value={values.title}
                  onChange={e => handleChange(e, setValues, values)}
                  onBlur={handleBlur}
                  name="title"></Form.Control>
                  {errors.title && touched.title ? (
                    <div style={styles.error}>Title is a required field</div>
                  ) : null}
                </Form.Group>
               
                <Form.Group>
                  <Form.Label>Body</Form.Label>
                  <Form.Control
                  type="textarea"
                  as="textarea"
                  className="data-cy-update-template-modal-body"
                  value={values.body}
                  onChange={e => handleChange(e, setValues, values)}
                  onBlur={handleBlur}
                  rows={5}
                  name="body"></Form.Control>
                  {errors.body && touched.body ? (
                    <div style={styles.error}>Body is a required field</div>
                  ) : null}
                </Form.Group>

                {errorMessage && <div style={{'color': 'red'}}>{errorMessage}</div>}
               
              
              </Modal.Body>
              <Modal.Footer>
                <Row>
                  {!changeHistoryOpen && <Button style={styles.buttonLeft} onClick={changeChangeHistory} variant="info">Show Change History</Button>}
                  {changeHistoryOpen && <Button style={styles.buttonLeft2} onClick={changeChangeHistory} variant="info">Hide Change History</Button>}
                  <Button style={styles.button} variant="secondary" className="data-cy-update-template-modal-close" onClick={closeModal}>Close</Button>
                  <Button style={!displayButton(values) ? styles.buttonRightDisabled : styles.buttonRight} disabled={!displayButton(values)} className="data-cy-update-template-modal-submit" type="submit">{t('containers.incidentManagementUpdateMessage.submit')}</Button>                  
                </Row>
              </Modal.Footer>
            </Form>
            {changeHistoryOpen && <Row>
              <Table striped>
                <thead>
                  <tr>
                    <th>Changed By</th>
                    <th>Prev Title</th>
                    <th>Prev Body</th>
                    <th>Prev Type</th>
                    <th>Prev Affected Service</th>
                  </tr>
                </thead>
                <tbody>
                {
                  changeHistory.map((val, index) => (
                    <tr key={index}>
                      <td>{val.changed_by}</td>
                      <td>{val.prev_title}</td>
                      <td>{val.prev_body}</td>
                      <td>{val.prev_message_type}</td>
                      <td>{val.prev_affected_service}</td>
                    </tr>
                  ))
                }
                </tbody>
              </Table>
            </Row>}
            </div>
          )}
          </Formik>
      </Modal>
    </div>
  );
}

export default connect(mapStateToProps,mapDispatchToProps)(UpdateTemplateModal);