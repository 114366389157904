import React, { forwardRef } from 'react'
import infoImage from '../../../assets/282532_Information_R_blue.png';
import { Card, Form, DropdownButton, Button, Dropdown, OverlayTrigger, Popover, Row, Col, InputGroup } from 'react-bootstrap';
import EntitySearchBar from './EntitySearchBar';
import RelativeTimePicker from './RelativeTimePicker';
import AbsoluteTimePicker from './AbsoluteTimePicker';
import _ from 'lodash'
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const FilterTile = ({
  change_type_options,
  search_type_options,
  relative_time_unit_options,
  relative_time_number,
  absolute_selected,
  date_range_start,
  date_range_end,
  keywords,
  loading_data_centers,
  entity_results_data_centers,
  loading_services,
  entity_results_services,
  loading_cis,
  entity_results_cis,
  selected_data_centers,
  selected_services,
  selected_cis,
  searchChangeLoading,
  max_months_date_range,
  relative_time_exceeded,

  // methods
  setChangeTypeOptions,
  setSearchTypeOptions,
  setRelativeTimeUnitOptions,
  setStateField,
  submitEntitySearch,
  submitSearchChanges,
  resetFilters
}) => {

  const { t } = useTranslation(["translation"]);
  const change_type = change_type_options[_.findIndex(change_type_options, (item) => item.selected === true)]
  const search_type = search_type_options[_.findIndex(search_type_options, (item) => item.selected === true)]
  const styles = {
    infoImg: {
      maxHeight : '1.9rem',
    },
    inlineMiddleVAlign: {
      display : 'inline',
      verticalAlign : 'middle'
    },
    fullWidth: {
      width: '100%'
    },
    dateRangeButton: {
      textAlign: 'right',
      float: 'right',
      width: '100%'
    },
    dateInputDiv: {
      textAlign : "right",
      paddingRight : "14px",
    },
    filtersHeaderLabel : {
      marginBottom: "0px",
    },
    timeTypeSpan: {
      cursor: 'pointer'
    },
    timeTypeSpanSelected: {
      cursor: 'pointer',
      fontWeight: 'bold',
      letterSpacing: '-0.04em', // Make slight adjustment to letter spacing when bold to keep visual width consistent
      color: 'var(--info)'
    },
    timeTypeContainer: {
      float : "right",
      fontSize : "12px",
      position : "relative",
      top : "5px",
    }
  };

  const RenderTooltip = ({content}) => {
    return (
      <OverlayTrigger
        placement = "right"
        delay     = {{ show: 50, hide: 50 }}
        overlay   = {
          <Popover id="info-tooltip" className="p-2 card-shadow">
            {content}
          </Popover>
        }
        disabled = {searchChangeLoading}
        >
        <img src = {infoImage} style = {{ ...styles.infoImg, ...styles.inlineMiddleVAlign }} />
      </OverlayTrigger>
    )
  };

  const setStartDate = (date) => {
    setStateField('date_range_start', date)
  }

  const setEndDate = (date) => {
    setStateField('date_range_end', date)
  }

  const getApplyDisabled = () => {
    return searchChangeLoading
      || (!absolute_selected && relative_time_exceeded) // Relative time exceeds max
      || (!absolute_selected && (isNaN(relative_time_number) || relative_time_number <= 0)) // Relative time entered is invalid
      || (absolute_selected && (!date_range_start || !date_range_end)) // Absolute time is missing start and/or end date
    ;
  }

  const getApplyTitle = () => {
    let returnStr;
    if (searchChangeLoading) {
      returnStr = t('containers.changeHistoryFilterTile.getApplyTile1');
    }
    else if (absolute_selected && (!date_range_start || !date_range_end)) {
      returnStr = t('containers.changeHistoryFilterTile.getApplyTile2');
    }
    else if (!absolute_selected && relative_time_exceeded) {
      returnStr = t('containers.changeHistoryFilterTile.getApplyTile3') + max_months_date_range + t('containers.changeHistoryFilterTile.getApplyTile4');
    }
    else if (!absolute_selected && (isNaN(relative_time_number) || relative_time_number <= 0)) {
      returnStr = t('containers.changeHistoryFilterTile.getApplyTile5');
    }
    return returnStr
  }

  const CustomStartDateInput = forwardRef(({ onClick, value }, ref) => {
    const startValue = date_range_start ? moment(date_range_start).format("MMM D, YYYY h:mm A") : t('containers.changeHistoryFilterTile.startValue')
    return (
      <Row className="mb-1">
        <Col className="pl-0 pr-0" style={{margin: "auto 0"}} md={5}>
        {t('containers.changeHistoryFilterTile.from')}
        </Col>
        <Col className="pl-0 pr-0" md={7}>
          <Button style={styles.dateRangeButton} disabled={searchChangeLoading} onClick={onClick} ref={ref} variant={date_range_start ? "outline-secondary" : "outline-danger"}>
            <div style={styles.dateInputDiv}>{startValue}</div>
          </Button>
        </Col>
      </Row>
    )
  });

  const CustomEndDateInput = forwardRef(({ onClick, value }, ref) => {
    const endValue = date_range_end ? moment(date_range_end).format("MMM D, YYYY h:mm A") : t('containers.changeHistoryFilterTile.endValue')
    return (
      <Row className="mb-1">
        <Col className="pl-0 pr-0" style={{margin: "auto 0"}} md={5}>
        {t('containers.changeHistoryFilterTile.until')}
        </Col>
        <Col className="pl-0 pr-0" md={7}>
          <Button style={styles.dateRangeButton} disabled={searchChangeLoading} onClick={onClick} ref={ref} variant={date_range_end ? "outline-secondary" : "outline-danger"}>
            <div style={styles.dateInputDiv}>{endValue}</div>
          </Button>
        </Col>
      </Row>
    )
  });

  const handleApplyFilters = () => {
    submitSearchChanges();
  }

  const handleResetFilters = () => {
    resetFilters();
    submitSearchChanges();
  }

  return (
    <>
      <Card.Header>
        <h4 style={styles.filtersHeaderLabel}>{t('containers.changeHistoryFilterTile.title')}<RenderTooltip content={t('containers.changeHistoryFilterTile.filterToolTip')}/></h4>
      </Card.Header>
      <Card.Body>
        {/* CHANGE TYPE */}
        <Row>
          <Form.Group>
            <Col className="pl-0">
              <Form.Label><h6 style={styles.inlineMiddleVAlign}>{t('containers.changeHistoryFilterTile.changeType')}</h6></Form.Label>
              <RenderTooltip content={`
                "${change_type_options[_.findIndex(change_type_options, (item) => item.systemKey === 'manual')].displayVal}" ${t('containers.changeHistoryFilterTile.manualToolTip')}
                "${change_type_options[_.findIndex(change_type_options, (item) => item.systemKey === 'auto')].displayVal}" ${t('containers.changeHistoryFilterTile.autoToolTip')}
              `}/>
            </Col>
            <Col className="pl-0" md={12}>
              <DropdownButton
                className="data-cy-change-type-dropdown"
                alignRight
                title={t('containers.changeHistoryFilterTile.' + change_type.displayVal)}
                variant="outline-secondary"
                onSelect={(val) => setChangeTypeOptions(val)}
                disabled={searchChangeLoading}
                >
                {
                  change_type_options.map(option => (
                    <Dropdown.Item
                      key={option.displayVal}
                      eventKey={option.displayVal}
                      active={option.selected}
                      className="data-cy-dropdown-option"
                      >
                      {t('containers.changeHistoryFilterTile.' + option.displayVal)}
                    </Dropdown.Item>)
                  )
                }
              </DropdownButton>
            </Col>
          </Form.Group>
        </Row>

        {/* DATE RANGE */}
        <Row className="mb-2">
          <Col className="pl-0"  md={12}>
            <h6 style={styles.inlineMiddleVAlign}>{t('containers.changeHistoryFilterTile.when')}</h6>
            <RenderTooltip style={{position: 'relative', top: '-8px'}} content={t('containers.changeHistoryFilterTile.dateToolTip1') + ` ${max_months_date_range} ` + t('containers.changeHistoryFilterTile.dateToolTip2')}/>
            <div style={styles.timeTypeContainer}>
              <span onClick={() => setStateField("absolute_selected", false)} style={{...(absolute_selected ? styles.timeTypeSpan : styles.timeTypeSpanSelected)}}>{t('containers.changeHistoryFilterTile.relative')}</span> | <span onClick={() => setStateField("absolute_selected", true)} style={{...(absolute_selected ? styles.timeTypeSpanSelected : styles.timeTypeSpan)}}>{t('containers.changeHistoryFilterTile.absolute')}</span>
            </div>
          </Col>
          <Col className="pl-0" md={12} style={{textAlign: "left", rowHeight: "2"}}>
          {
            absolute_selected ?
            <AbsoluteTimePicker
              dateRangeStart      = {date_range_start}
              dateRangeEnd        = {date_range_end}
              setStartDate        = {setStartDate}
              setEndDate          = {setEndDate}
              maxMonthsDateRange  = {max_months_date_range}
              showTimeInput       = {true}
              customStartInput    = {<CustomStartDateInput />}
              customEndInput      = {<CustomEndDateInput />}
              shouldCloseOnSelect = {false}
              disabled            = {searchChangeLoading}
              style               = {styles.fullWidth}
              yearDropdown        = {false}
            />
            :
            <RelativeTimePicker
              timeNumber          = {relative_time_number}
              searchChangeLoading = {searchChangeLoading}
              maxMonthsDateRange  = {max_months_date_range}
              timeUnitOptions     = {relative_time_unit_options}
              applyFilters        = {handleApplyFilters}
              setStateField       = {setStateField}
              relativeTimeExceeded = {relative_time_exceeded}
              setRelativeTimeUnitOptions = {setRelativeTimeUnitOptions}
            />
          }
          </Col>
        </Row>

        <Row>
          <Col className="pl-0"  md={12}>
            <Form>
              <Form.Group controlId="keywords">
                <Form.Label>
                  <h6 className="mb-0" style={styles.inlineMiddleVAlign}>{t('containers.changeHistoryFilterTile.search')}</h6>
                </Form.Label>
                <RenderTooltip searchChangeLoading={searchChangeLoading} content={t('containers.changeHistoryFilterTile.searchToolTip')}/>
                {/* <Form.Select id="searchOption" /> */}

                <InputGroup>
                  <InputGroup.Prepend>
                  <DropdownButton
                  className="data-cy-search-type-dropdown"
                  alignLeft
                  title={t('containers.changeHistoryFilterTile.' + search_type.displayVal.replace(' ', '_'))}
                  variant="outline-secondary"
                  onSelect={(val) => setSearchTypeOptions(val)}
                  disabled={searchChangeLoading}
                  >
                  {
                    search_type_options.map(option => (
                      option.visible && <Dropdown.Item
                        key={option.displayVal}
                        eventKey={option.displayVal}
                        active={option.selected}
                        className="data-cy-search-type-dropdown-option"
                        >
                        {t('containers.changeHistoryFilterTile.' + option.displayVal.replace(' ', '_'))}
                      </Dropdown.Item>)
                    )
                  }
                </DropdownButton>
                </InputGroup.Prepend>
                <Form.Control onKeyPress={(e) => { if(e.which === 13) { /* Prevent "Enter" from refreshing page; search instead */ e.preventDefault(); handleApplyFilters(); }}} value={keywords} className="data-cy-keywords" type="Keywords" placeholder={t('containers.changeHistoryFilterTile.enterKeywords')} onChange={ (e) => setStateField('keywords', e.target.value) } readOnly={searchChangeLoading}/>
              </InputGroup>
              </Form.Group>
            </Form>
          </Col>
        </Row>

        <Row className="mb-4">
          <Col className="pl-0"  md={12}>
            <h6>{t('containers.changeHistoryFilterTile.dataCenters')}</h6>
          </Col>
          <Col className="pl-0"  md={12}>
            <EntitySearchBar
              availableOptions   = {entity_results_data_centers}
              user_input         = {loading_data_centers}
              category           = 'Data_Center'
              isLoading          = {loading_data_centers}
              submitEntitySearch = {submitEntitySearch}
              setStateField      = {setStateField}
              selected           = {selected_data_centers}
              disabled           = {searchChangeLoading}
              className          = "data-cy-data-center-search"
            />
          </Col>
        </Row>

        <Row className="mb-4">
          <Col className="pl-0"  md={12}>
            <h6>{t('containers.changeHistoryFilterTile.services')}</h6>
          </Col>
          <Col className="pl-0"  md={12}>
            <EntitySearchBar
              availableOptions   = {entity_results_services}
              user_input         = {loading_services}
              category           = 'Service'
              isLoading          = {loading_services}
              submitEntitySearch = {submitEntitySearch}
              setStateField      = {setStateField}
              disabled           = {searchChangeLoading}
              selected           = {selected_services}
              className          = "data-cy-service-search"
            />

          </Col>
        </Row>

        <Row className="mb-4">
          <Col className="pl-0"  md={12}>
            <h6>{t('containers.changeHistoryFilterTile.ciTitle')}</h6>
          </Col>
          <Col className="pl-0"  md={12}>
            <EntitySearchBar
              availableOptions   = {entity_results_cis}
              user_input         = {loading_cis}
              category           = 'CI'
              isLoading          = {loading_cis}
              submitEntitySearch = {submitEntitySearch}
              setStateField      = {setStateField}
              disabled           = {searchChangeLoading}
              selected           = {selected_cis}
              className          = "data-cy-ci-search"
            />
          </Col>
        </Row>

       

        <Row>
          <Col className="pl-0 mt-4"  md={12}>
            <Button disabled={date_range_end ? false : true} className="data-cy-apply-filters btn-success" disabled={getApplyDisabled()} title={getApplyTitle()} type="button" onClick={handleApplyFilters}>{t('containers.changeHistoryFilterTile.applyFilters')}</Button>
            <Button className="data-cy-reset-filters ml-2 btn-info" disabled={searchChangeLoading} type="button" onClick={handleResetFilters}>{t('containers.changeHistoryFilterTile.resetFilters')}</Button>
          </Col>
        </Row>
      </Card.Body>
    </>
  );
};

export default FilterTile;
