import React, {useState}        from 'react';
import { useTable, useFilters, useGlobalFilter, useAsyncDebounce, useSortBy } from 'react-table'
import { Table, Card, InputGroup, FormControl } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  dateTimeToPSTString,
  sortDate,
} from '../../../utils/formatFunctions';

const Styles = styled.div`
  headers{
    textAlign  :  'left',
    lineHeight :  '2rem',
  },
  img{
    height :  'auto',
    width  :  '24px',
  },
  table{
    height :  '75vh',
    border :  'none',
  },
  tableContainer{
    fontSize        :  '.94rem',
    boxSizing       :  'border-box',
    borderRadius    :  '4px',
    backgroundColor :  'rgb(250, 250, 250)',
    boxShadow'      :  'rgba(0, 0, 0, 0.2) 0px 0px 10px -2px',
  },
  tablePadding{
    borderLeft   : '1px solid #ddd',
    borderRight  : '1px solid #ddd',
    borderBottom : '1px solid #ddd',
  },
  striped{
    backgroundColor: '#06a4e421'
  },
  unset{
    whiteSpace: 'unset'
  },
  prewrap {
    whiteSpace: 'pre-wrap'
  },
};`


function RenderTable({ columns, data }) {

  const sorting = React.useMemo(
    () => [
      {
        id: "event_time",
        desc: true
      }
    ],
    []
  );
  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? 'desc' : 'asc') : '';
  };
  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        sortBy: sorting
      },
      // defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
      
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    useSortBy
  )

  // Define a default UI for filtering
  function GlobalFilter({
    preGlobalFilteredRows,
    globalFilter,
    setGlobalFilter,
  }) {
    const count = preGlobalFilteredRows.length
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
      setGlobalFilter(value || undefined)
      document.getElementById("search-bar").focus()
    }, 800)

    return (
      <span>
        <InputGroup>
          <InputGroup.Prepend style={{margin: 'auto .5rem'}}>Filter{' '}</InputGroup.Prepend>
          <FormControl id="search-bar" value={value || ""}
            onChange={e => {
              setValue(e.target.value);
              onChange(e.target.value);
            }}
            placeholder={`${count} records...`}
            style={{
              fontSize: '1.1rem',
              borderTop: '0',
              borderBottom: '0'
            }} />
        </InputGroup>
      </span>
    )
  }

  return (
    <>
     <Card>
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </Card>
      <Table bordered {...getTableProps()}>
      <thead>
          {headerGroups.map(headerGroup => (
            <tr style={{'backgroundColor': '#06a4e421'}} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i) => {
                const {render, getHeaderProps} = column
                return (
                  <th className={generateSortingIndicator(column) + " " + column.id + "-header"} key={'header' + i} {...getHeaderProps(column.getSortByToggleProps())}>{render("Header")}</th>
                )
              })}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (

              <tr {...row.getRowProps()} style={i % 2 === 1 ? {'backgroundColor': '#06a4e421'} : {}}>
                {row.cells.map(cell => {
                  return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}

const CommHistoryTable = ({RowData}) => {
  const [row_data, set_row_data] = useState(RowData)

  const { t } = useTranslation(["translation"]);
  const headerStyle = {
    textAlign  :  'left',
    lineHeight :  '2rem',
  }

  const columns = React.useMemo(
    () => [
        {
          Header: <b data-cy='event-title-header'>OPI</b>,
          headerStyle: headerStyle,
          id: 'event_title',
          width: 220,
          accessor: (data) => data.event_title,
          filter: 'includes'
        },
        {
          Header: <b data-cy='message-type-header'>Message Type</b>,
          headerStyle: headerStyle,
          id: 'message_type',
          width: 500,
          accessor: (data) => data.message_type,
          filter: 'includes'
        },
        {
          Header: <b data-cy='email-event-header'>Event</b>,
          headerStyle: headerStyle,
          id: 'email_event',
          width: 240,
          accessor: (data) => data.email_event,
          filter: 'includes',
        },
        {
          Header: <b data-cy='application-header'>Application</b>,
          headerStyle: headerStyle,
          id: 'application',
          width: 240,
          accessor: (data) => data.application,
          filter: 'includes',
        },
        {
          Header: <b data-cy='event-time-header'>Event Time</b>,
          headerStyle: headerStyle,
          id: 'event_time',
          width: 240,
          sortMethod    :  (a, b) => sortDate(a, b),
          accessor: 'event_time',
          Cell: (data) => <div>{dateTimeToPSTString(data.value)}</div>,
          filter: 'includes',
        }    
      ],
    []
  )
  return (
    <Styles>
      <RenderTable columns={columns} data={row_data} />
    </Styles>
      
  )
}

export default CommHistoryTable;
