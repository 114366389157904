export const initialState = {
  severityList: [],
};

export const types = {
  REQUEST_SEVERITIES :  '[severity] REQUEST',
  SET_SEVERITIES     :  '[severity] SET',
};

export const actions = {
  requestSeverities :  ()        => ({type: types.REQUEST_SEVERITIES}),
  setSeverities     :  (payload) => ({type: types.SET_SEVERITIES, payload}),
};

export default function reducer(state=initialState, action) {
  switch (action.type) {
    case types.SET_SEVERITIES: {
      return { ...state, severityList: action.payload };
    }

    default: { return state; }
  }
}
