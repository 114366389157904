
/* ===== STATE_DEFINITION ===== */
const initialState = {};


/* ===== TYPES ===== */
export const types = {
  RAISE_ERROR: '[error] RAISE_ERROR',
};


/* ===== ACTION_CREATORS ===== */
export const actions = {
  raiseError: (errorObj) => ({type: types.RAISE_ERROR, payload: errorObj})
};

/* ===== SELECTORS ===== */
export const selectors = { };


/* ===== REDUCER ===== */
export default function reducer(state=initialState, action) {
  switch (action.type) {
    default: { return state; }
  }
}
