import { takeEvery, put, select, call } from 'redux-saga/effects';
import { updateProfile } from './user.api';
import { types as userTypes, actions as userActions, defaultCustomerImpactSettings } from './user.index';
import { defaultFilters as defaultChangeHistoryFilters } from '../changeHistory/changeHistory.index';
import { actions as errorActions } from '../error/error.index';
import { actions as toastActions } from '../toast/toast.index';

import { v1 as uuidv1 } from 'uuid';
import moment from 'moment';


// worker sagas
export function* processUser({payload}) {
  try {
    let { email, AppPreferences = {}, AppRights = {}, authorization = {} } = payload;

    if (AppPreferences.customer_impact_settings === undefined) {
      AppPreferences.customer_impact_settings = defaultCustomerImpactSettings.allFilters;
    }
    if (AppPreferences.change_history_settings === undefined) {
      AppPreferences.change_history_settings = defaultChangeHistoryFilters;
    }

    const customerImpactSettings =  AppPreferences.customer_impact_settings;
    const changeHistorySettings = AppPreferences.change_history_settings;
    delete AppPreferences.customer_impact_settings;
    delete AppPreferences.change_history_settings;

    yield put(userActions.setUser({ email, appPreferences: AppPreferences, appRights: AppRights, authorization: authorization }));
    yield put(userActions.setCustomerImpactSettings(customerImpactSettings));
    yield put(userActions.setChangeHistorySettings(changeHistorySettings));
  } catch (error) {
    // log error to db
    const entireState = yield select();
    yield put(errorActions.raiseError({ error, entireState }));
  }
}


export function* addCustomerImpactFilterToState({payload}) {
  const entireState =  yield select();
  const allFilters  =  entireState.userState.customerImpactSettings.allFilters;
  // This JSON parse must be done to create a "new" object, otherwise it will stay bound to the payload object
  allFilters.push(JSON.parse(JSON.stringify(payload)));

  // update redux store and dynamodb
  yield put(userActions.setCustomerImpactSettings(allFilters));
  yield put(userActions.putCustomerImpactSettings(allFilters));
}


export function* updateCustomerImpactFilterToState({payload}) {
  const entireState   =  yield select();
  const currentFilter =  entireState.userState.customerImpactSettings.selected.filters;
  const allFilters    =  entireState.userState.customerImpactSettings.allFilters;

  // find setting by id and replace with user inputted settings
  const updatedFilters = allFilters.map( (setting) => ({
    ...setting,
    filters: payload === setting.id ? currentFilter : setting.filters
  }));

  // update redux store and dynamodb
  yield put(userActions.setCustomerImpactSettings(updatedFilters));
  yield put(userActions.putCustomerImpactSettings(updatedFilters));
}


export function* putCustomerImpactFilters({payload}) {
  // combine impact settings back into appPrefs for dynamodb
  const entireState =  yield select();
  const appPrefs    =  entireState.userState.user.appPreferences;
  appPrefs.customer_impact_settings = payload;

  // package payload
  const profileObject = {
    application :  'aviary',
    preferences :  appPrefs,
  };

  // api call to update a profile
  try {
    yield updateProfile(profileObject);
    const message      =  `Customer Impact tile settings updated`;
    const toastOptions =  {type: 'success', autoClose: true};
    yield put(toastActions.createToast(message, toastOptions));
  }
  catch(error) {
    // handle ui effects
    const message      =  `Unable to update your Customer Impact tile settings: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));
  }
}

export function* putChangeHistoryFilters({payload}) {
  const entireState =  yield select();
  const appPrefs    =  entireState.userState.user.appPreferences;
  appPrefs.change_history_settings = payload;

  const profileObject = {
    application :  'aviary',
    preferences :  appPrefs,
  };

  try{
    // Update the filter state object both in the userState and in the back end
    yield put(userActions.setChangeHistorySettings(payload));
    yield updateProfile(profileObject);
  }
  catch(error) {
    // Fail silently
  }
}


function* generateCustomerImpactSetting({payload}) {
  // create a new setting
  const entireState    =  yield select();
  const currentFilters =  entireState.userState.customerImpactSettings.selected.filters;

  // add the setting to state
  const setting =  yield call(createCustomerImpactSetting, payload, currentFilters);
  yield put(userActions.addCustomerImpactFilter(setting))
}


// watcher saga
export default function userSagas() {
  return [
    takeEvery(userTypes.PROCESS_USER, processUser),
    takeEvery(userTypes.PUT_CUSTOMER_IMPACT_SETTINGS, putCustomerImpactFilters),
    takeEvery(userTypes.ADD_CUSTOMER_IMPACT_FILTER, addCustomerImpactFilterToState),
    takeEvery(userTypes.UPDATE_CUSTOMER_IMPACT_FILTER, updateCustomerImpactFilterToState),
    takeEvery(userTypes.GENERATE_CUSTOMER_IMPACT_SETTING, generateCustomerImpactSetting),
    takeEvery(userTypes.PUT_CHANGE_HISTORY_SETTINGS, putChangeHistoryFilters),
  ];
}


export const createCustomerImpactSetting = (nickname, filters) => {
  return {
    nickname,
    filters,
    id           :  uuidv1(),
    created_date :  moment().format('YYYY-MM-DD'),     // UTC time
  };
};
