import { bool, func, string } from 'prop-types';

/* ===== STATE_DEFINITION ===== */
const initialState = {
  pageLoading            : true,
  lastRefresh            : null,
  browserWindowIsVisible : true,
  isDisconnected         : false,
  windowDimensions: {
    width  : 0,
    height : 0,
  },
  displayLocale       : 'en',
  activeLocales       : [{system_key : "en", display_name : "English"}],
};

/* ===== PROP_TYPES ===== */
export const uiProptypes = {
  pageLoading             : bool,
  lastRefresh             : string,
  setPageLoading          : func,
  setPageFocus            : func,
  browserWindowIsVisible  : bool,
  disclaimerModalIsOpen   : bool,
  setIsDisconnected       : func,
  setWindowDimensions     : func,
};


/* ===== TYPES ===== */
export const types = {
  SET_LOADING_TRUE      : '[UI] SET_LOADING_TRUE',
  SET_LOADING_FALSE     : '[UI] SET_LOADING_FALSE',
  SET_REFRESH_TIME      : '[UI] SET_REFRESH_TIME',
  SET_PAGE_FOCUS        : '[UI] SET_PAGE_FOCUS',
  SET_CONNECTION_CHANGE : '[UI] SET_CONNECTION_CHANGE',
  SET_WINDOW_DIMENSIONS : '[UI] SET_WINDOW_DIMENSIONS',
  SET_UI_LOCALE         : '[UI] SET_UI_LOCALE',
  REQUEST_ACTIVE_LOCALES: '[UI] REQUEST_ACTIVE_LOCALES',
  SET_ACTIVE_LOCALES    : '[UI] SET_ACTIVE_LOCALES',
};


/* ===== ACTION_CREATORS ===== */
export const actions = {
  setPageLoading: (value) => {
    const bool = value === true ? types.SET_LOADING_TRUE : types.SET_LOADING_FALSE;
    return {type: bool, payload: value};
  },
  setRefreshTime          : ()      => ({ type: types.SET_REFRESH_TIME }),
  setPageFocus            : (value) => ({ type: types.SET_PAGE_FOCUS, payload: !value }),
  setIsDisconnected       : (value) => ({ type: types.SET_CONNECTION_CHANGE, payload: value }),
  setWindowDimensions     : (value) => ({ type: types.SET_WINDOW_DIMENSIONS, payload: value }),
  setDisplayLocale        : (value) => ({ type: types.SET_UI_LOCALE, payload: value }),
  requestActiveLocales    : ()      => ({ type: types.REQUEST_ACTIVE_LOCALES, payload : null }),
  setActiveLocales        : (value) => ({ type: types.SET_ACTIVE_LOCALES, payload : value }),
};


/* ===== SELECTORS ===== */
export const selectors = { };


/* ===== REDUCER ===== */
export default function reducer(state=initialState, action) {
  switch (action.type) {
    case types.SET_LOADING_TRUE: {
      return { ...state, pageLoading: action.payload };
    }
    case types.SET_LOADING_FALSE: {
      return { ...state, pageLoading: action.payload };
    }
    case types.SET_REFRESH_TIME: {
      return { ...state, lastRefresh: new Date().toUTCString() };
    }
    case types.SET_PAGE_FOCUS: {
      return { ...state, browserWindowIsVisible: action.payload };
    }
    case types.SET_DISCLAIMER_MODAL_STATE: {
      return { ...state, disclaimerModalIsOpen: action.payload };
    }
    case types.SET_CONNECTION_CHANGE: {
      return { ...state, isDisconnected: action.payload };
    }
    case types.SET_WINDOW_DIMENSIONS: {
      return { ...state, windowDimensions: { width: action.payload.width, height: action.payload.height } };
    }
    case types.SET_UI_LOCALE:
      return { ...state, displayLocale: action.payload };
    case types.SET_ACTIVE_LOCALES:
        return { ...state, activeLocales: action.payload };
    default: { return state; }
  }
}
