/* ========= PACKAGE IMPORTS ========= */
import React, { Component } from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from 'redux';
import CreateTemplateModal from './CreateTemplateModal';
import TemplateTable from './TemplateTable';
/* ========= REDUX IMPORTS ========= */
import { actions as uiActions }    from '../../ducks/ui/ui.index';
import {
  actions as templateActions,
}   from '../../ducks/template/template.index';


/* ========= COMPONENT IMPORTS ========= */
import Loading            from '../../components/loading';
import AuthorizationWrapper from '../../components/authorizationWrapper';


/* ========= REDUX STATE CONNECTORS ========= */
const mapStateToProps = ({uiState, templateState}, props) => {
  let type;
  type = props.match.params['type'];
  return {
    pageLoading            :  uiState.pageLoading,
    message_templates      :  templateState.message_templates,
    notification_templates :  templateState.notification_templates,
    type                   :  type
  }
};

const mapDispatchToProps = (dispatch) => {
  const combinedActionCreators = {
    setPageLoading   :  uiActions.setPageLoading,
    requestTemplates :  templateActions.requestTemplates
  };
  return bindActionCreators(combinedActionCreators, dispatch);
};

const styles = {
  'topContainer': {
    'margin'   :  'auto',
    'maxWidth' :  'max-content',
  },
  'pageTitle': {
    'marginBottom' :  '20px',
    'width'        :  '73vw',
    'textAlign'    :  'center',
  },
};

const requiredAction = 'edit_template';
const createTemplateAction = "create_template"

class TemplateManagement extends Component {
  componentDidMount = () => {
  }


  render = () => {
    const { topContainer, pageTitle } = styles;
    const { pageLoading, message_templates, notification_templates, type } = this.props;
    let types = [];
    if (type == 'message' && message_templates){
      types = Object.keys(message_templates)
    }
    else if (type == 'notification' && notification_templates){
      types = Object.keys(notification_templates)
    }
    return (
      <AuthorizationWrapper requiredAction={requiredAction}>
        <div style={topContainer}>
          {/* HEADER */}
          <h1 style={pageTitle}>Templates</h1>
          <div style={{'display': 'grid', 'justifyContent': 'end'}}>
            <AuthorizationWrapper requiredAction={createTemplateAction}><CreateTemplateModal template_type={type}></CreateTemplateModal></AuthorizationWrapper>
          </div>
          <div>
            { /* CONTENT */
              pageLoading || (type == 'message' && !message_templates) || (type == 'notification' && !notification_templates)
              ? <Loading height="75vh" />
              : <TemplateTable RowData={ type == 'message' ? message_templates : notification_templates} template_type={type}></TemplateTable>
               
            }
          </div>
        </div>
      </AuthorizationWrapper>
    );
  }
}
export default connect(mapStateToProps, mapDispatchToProps)(TemplateManagement);