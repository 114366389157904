import { takeEvery, put, call, select } from "redux-saga/effects";

import {
  getDataCenters,
  getCiCategoriesByDataCenter,
  getServicesByDataCenter,
  getImpact,
  getCisByCiCategory,
  getSearchResults
 } from './dora.api';
import { types as doraTypes, actions as doraActions } from './dora.index';
import { actions as uiActions }     from '../ui/ui.index';
import { actions as errorActions }  from '../error/error.index';
import { actions as toastActions }  from '../toast/toast.index';

export function* fetchDataCenters(action) {
  try{
    // call endpoint
    let response =  yield call(getDataCenters);
    let payload  =  response.data.payload.dataCenter;

    // update state
    yield put(doraActions.setDataCenterList(payload));
    yield put(uiActions.setRefreshTime());
  }
  catch(error) {
    // handle ui effects
    const message      =  `Unable to get dora dcs: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
  }
}
export function* fetchCiCategoriesByDataCenter(action) {
  try{
    // call endpoint
    let response =  yield call(getCiCategoriesByDataCenter, action.payload);
    let payload  =  response.data.payload.ci_categories;

    // update state
    yield put(doraActions.setCiCategories(payload));
  }
  catch(error) {
    // handle ui effects
    const message      =  `Unable to get dora ci categories: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
  }
}
export function* fetchServicesByDataCenter(action) {
  try{
    // call endpoint
    yield put(doraActions.setServicesLoading(true))
    let response =  yield call(getServicesByDataCenter, action.payload);
    let payload  =  response.data.payload.services;

    // update state
    yield put(doraActions.setServices(payload));
    yield put(doraActions.setServicesLoading(false))
  }
  catch(error) {
    // handle ui effects
    const message      =  `Unable to get dora services: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
    yield put(doraActions.setServicesLoading(false))
  }
}
const roundToTwo = (num) => {
  return +(Math.round(num + "e+2")  + "e-2");
}

export function* fetchImpactGlobal(action) {
  try{
    yield put(doraActions.setGlobalImpactLoading(true))
    // call endpoint
    let data1 = {
      services: action.payload.services,
      cis: [],
      data_centers: action.payload.allDcs
    }
    let byGlobal = yield call(getImpact, data1);
    let globalCount = byGlobal.data.payload.company_count;
    data1 = {
      services: action.payload.services,
      cis: action.payload.cis,
      data_centers: action.payload.allDcs
    }
    let response =  yield call(getImpact, data1);
    let count = response.data.payload.company_count;
    if (globalCount >= count){
      let globalPercentage = roundToTwo((count / globalCount) * 100).toString() + "%";
      yield put(doraActions.setPercentageGlobal(globalPercentage, action.payload.category, action.payload.cis));
    }
    yield put(doraActions.setGlobalImpactLoading(false))
    action.payload.callback();
  }
  catch(error) {
    // handle ui effects
    const message      =  `Unable to get dora impact: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
    yield put(doraActions.setGlobalImpactLoading(false))
  }
}
export function* fetchImpactDataCenter(action) {
  try{
    // call endpoint
    yield put(doraActions.setDcImpactLoading(true))
    let data1 = {
      services: action.payload.services,
      cis: [],
      data_centers: [action.payload.dcs]
    }
    let byDataCenter = yield call(getImpact, data1);
    let dcCount = byDataCenter.data.payload.company_count;
    data1 = {
      services: action.payload.services,
      cis: action.payload.cis,
      data_centers: [action.payload.dcs]
    }
    let response =  yield call(getImpact, data1);
    let count = response.data.payload.company_count;
    if (dcCount >= count){
      let dcPercentage = roundToTwo((count / dcCount) * 100).toString() + "%";
      yield put(doraActions.setPercentageDataCenter(dcPercentage, action.payload.category, action.payload.cis));
    }
    yield put(doraActions.setDcImpactLoading(false))
    action.payload.callback();
  }
  catch(error) {
    // handle ui effects
    const message      =  `Unable to get dora impact: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
    yield put(doraActions.setDcImpactLoading(false))
  }
}
export function* fetchCisByCategory(action) {
  try{
    // call endpoint
    yield put(doraActions.setCiLoading(true))
    let response =  yield call(getCisByCiCategory, action.payload.dc, action.payload.category);
    let payload  =  response.data.payload.ci;

    // update state
    yield put(doraActions.setCi(payload));
    yield put(doraActions.setCiLoading(false))
  }
  catch(error) {
    // handle ui effects
    const message      =  `Unable to get cis: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
    yield put(doraActions.setCiLoading(false))
  }
}
export function* fetchSearchResults(action) {
  try{
    // call endpoint
    let response =  yield call(getSearchResults, action.payload.dc, action.payload.search_query);
    // this comes in as an array, you will need to loop through these and create arrays to populate these fields
    let payload  =  response.data.payload;
    let cis = payload.ci.map(ci => { return ci.name})
    yield put(doraActions.setCi(cis));
    yield put(doraActions.setCiCategories(payload.ci_categories));
  }
  catch(error) {
    // handle ui effects
    const message      =  `Unable to get search results: ${error}`;
    const toastOptions =  {type: 'error', autoClose: false};
    yield put(toastActions.createToast(message, toastOptions));

    // send error report
    const entireState = yield select();
    yield put(errorActions.raiseError({error, entireState}));
  }
}


// watcher saga
export default function doraSagas() {
  return [
    takeEvery(doraTypes.REQUEST_ALL_DATA_CENTERS, fetchDataCenters),
    takeEvery(doraTypes.REQUEST_CIS_BY_CATEGORY, fetchCisByCategory),
    takeEvery(doraTypes.REQUEST_SERVICES_BY_DC, fetchServicesByDataCenter),
    takeEvery(doraTypes.REQUEST_CI_CATEGORIES_BY_DC, fetchCiCategoriesByDataCenter),
    takeEvery(doraTypes.REQUEST_IMPACT_GLOBAL, fetchImpactGlobal),
    takeEvery(doraTypes.REQUEST_IMPACT_DATA_CENTER, fetchImpactDataCenter),
    takeEvery(doraTypes.REQUEST_SEARCH_RESULTS, fetchSearchResults),
  ];
}
