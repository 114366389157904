import React, { useState, useEffect } from 'react';
import { connect }            from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions as uiActions }    from '../../ducks/ui/ui.index';
import { ToggleButtonGroup, ToggleButton, Button } from 'react-bootstrap';
import SubscriptionPreferenceTable from './Tables/SubscriptionPreferenceTable';
import Loading from '../../components/loading';
import CommHistoryTable from './Tables/CommHistoryTable';
import {
  actions as subscriptionActions,
} from '../../ducks/subscription/subscription.index';

const mapStateToProps = ({uiState, subscriptionState}) => ({
  pageLoading         :  uiState.pageLoading,
  commHistory         : subscriptionState.commHistory,
  allUserSubscriptions: subscriptionState.allUserSubscriptions,
});
const mapDispatchToProps =  (dispatch) => {
  const combinedActionCreators = {
    setPageLoading              :  uiActions.setPageLoading,
    requestClearUuid            :  subscriptionActions.requestClearUuid,
    requestCommHistory          : subscriptionActions.requestCommHistory,
    requestAllUserSubscriptions : subscriptionActions.requestAllUserSubscriptions,
  };

  return bindActionCreators(combinedActionCreators, dispatch);
};

const styles = {
  flex: {
    display: 'flex'
  },
  left: {
    marginLeft: 'auto'
  }
}

let email = ''

const SubscriberDetails = ({requestClearUuid, requestCommHistory, requestAllUserSubscriptions, commHistory, allUserSubscriptions}) => {
  email = window.location.pathname.split('/')[3];
  //decode hex
  email = email.split(/(\w\w)/g).filter(p => !!p).map(c => String.fromCharCode(parseInt(c, 16))).join("")
  const [preferenceTab, setPreferenceTab] = useState(false);
  useEffect(() => {
    requestCommHistory(email);
    requestAllUserSubscriptions(email);
  }, [email])
  const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  let validEmail = email.match(emailRegex);
  const submitClearUuid = () => {
    requestClearUuid(email);
  }
  return (
    <div>
      {!validEmail && <div>Invalid Email</div>}
      {validEmail && <div>
        {!preferenceTab && <div style={styles.flex}><h3>Communication History</h3><h3 style={styles.left}>{email}</h3></div>}
        {preferenceTab && <div style={styles.flex}><h3>Subscription Options</h3><h3 style={styles.left}>{email}</h3></div>}
        
        <div style={styles.flex}><div style={styles.left}><Button type="button" onClick={e => submitClearUuid()}>Reset Profile</Button></div></div>
        <ToggleButtonGroup style={styles.flex} type="radio" name="options" defaultValue={false}>
            <ToggleButton  key="tgb-radio-1" id="tbg-radio-1" variant="outline-secondary" value={false} onChange={() => setPreferenceTab(false)}>
                Communication History
            </ToggleButton>
            <ToggleButton key="tgb-radio-2" id="tbg-radio-2" variant="outline-secondary" value={true} onChange={() => setPreferenceTab(true)}>
                Subscription Options
            </ToggleButton>
        </ToggleButtonGroup>
        {!preferenceTab && !commHistory &&  <Loading />}
        {preferenceTab && !allUserSubscriptions &&  <Loading />}
        {!preferenceTab && commHistory &&  <CommHistoryTable RowData={commHistory}/>}
        {preferenceTab && allUserSubscriptions && <SubscriptionPreferenceTable RowData={allUserSubscriptions}/>}
      </div>}
    </div>
  );
}
export default connect(mapStateToProps,mapDispatchToProps)(SubscriberDetails);